/* eslint-disable */
class ReesApi {
  initialized = false

  init() {
    window.r46 =
      window.r46 ||
      // eslint-disable-next-line func-names
      function (...args) {
        ;(window.r46.q = window.r46.q || []).push(args)
      }
    const body = document.getElementsByTagName('body')[0]
    const rs = document.createElement('script')
    rs.async = true
    rs.src = process.env.VUE_APP_REES_URL
    body.appendChild(rs)

    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject('R46_INIT_TIMEOUT')
      }, 3000)

      r46(
        'init',
        process.env.VUE_APP_REES_SHOP_ID,
        () => {
          this.initialized = true
          clearTimeout(timeoutId)
          resolve()
        },
        (err) => {
          clearTimeout(timeoutId)
          reject(err)
        },
      )
    })
  }

  request(...args) {
    return r46(...args)
  }
}
export const reesApi = new ReesApi()
