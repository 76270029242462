export const contactColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'contact_id',
    width: '250px',
    scopedSlots: { customRender: 'contact_id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'contact_name',
    width: '250px',
    scopedSlots: { customRender: 'contact_name' },
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    width: '250px',
    scopedSlots: { customRender: 'comment' },
  },
  {
    title: 'Ссылка',
    dataIndex: 'value',
    key: 'value',
    width: '250px',
    scopedSlots: { customRender: 'value' },
  },
  {
    title: 'Способ связи',
    dataIndex: 'type',
    key: 'type',
    width: '180px',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Приоритет',
    dataIndex: 'priority',
    key: 'priority',
    width: '250px',
    scopedSlots: { customRender: 'priority' },
  },
]
