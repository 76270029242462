<template>
  <div>
    <h2>Мой профиль</h2>
    <a-form-model ref="form" :model="profile">
      <a-form-model-item prop="firstname" label="Имя">
        <a-input :disabled="true" v-model="profile.first_name" />
      </a-form-model-item>
      <a-form-model-item prop="lastname" label="Фамилия">
        <a-input :disabled="true" v-model="profile.last_name" />
      </a-form-model-item>
      <a-form-model-item prop="patronymic" label="Отчество">
        <a-input :disabled="true" v-model="profile.patronymic" />
      </a-form-model-item>
      <a-form-model-item prop="sex" label="Пол">
        <a-radio-group v-model="profile.sex">
          <a-radio :disabled="true" value="male"> Мужчина</a-radio>
          <a-radio :disabled="true" value="female">Женщина</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="email" label="EMAIL">
        <a-input :disabled="true" v-model="profile.email" />
      </a-form-model-item>
      <a-form-model-item prop="iin" label="ИИН">
        <a-input :disabled="true" v-model="profile.iin" />
      </a-form-model-item>
      <phone-input
        :prop="'phone'"
        label="Телефон"
        v-model="profile.phone"
        :disabled="true"
      />
      <a-form-model-item prop="lang" label="Предпочитаемый язык">
        <a-tag>{{ langs[profile.lang] }}</a-tag>
      </a-form-model-item>
      <a-form-model-item prop="roles" label="Роли">
        <a-tag v-for="role in profile.roles" :key="role" color="green">
          {{ roles[role] }}
        </a-tag>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { MASK } from 'Core/constants'
import { roles, langs } from './constants'

export default {
  data() {
    return {
      MASK,
      roles,
      langs,
    }
  },
  computed: {
    profile() {
      return this.$store.getters['user/profile']
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form {
  max-width: 45vw;

  &-item {
    margin-bottom: 0;
  }
}
</style>
