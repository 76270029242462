<template>
  <div class="product__table">
    <div class="filter__form" v-if="filters">
      <a-form-model
        layout="inline"
        ref="form"
        :model="params"
        @submit="searchHandler"
      >
        <a-form-model-item label="Название продукта">
          <a-input v-model="params.search" />
        </a-form-model-item>
        <a-form-model-item label="Категория продукта">
          <a-select
            show-search
            placeholder="Выберите категорию"
            style="width: 220px"
            :filter-option="false"
            v-model="params.category"
            option-filter-prop="slug"
            :allow-clear="true"
            @search="onSearch"
          >
            <a-select-option
              v-for="item in filters.categories"
              :key="item.slug"
              :value="item.slug"
            >
              {{ item.title.ru }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
        <a-form-model-item>
          <a-button-group v-if="selectedProductsList">
            <a-button @click="changeProductsStatus('activate')" type="primary">
              Активировать продукты
            </a-button>
            <a-button @click="changeProductsStatus('deactivate')" type="danger">
              Деактивировать продукты
            </a-button>
          </a-button-group>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table
      :columns="recognizedTablecolumns"
      :data-source="merchantProducts"
      :scroll="{ x: true }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :row-key="({ sku }) => sku"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="image_url" slot-scope="image_url">
        <img
          class="img-prev"
          width="170"
          height="170"
          :src="image_url"
          :alt="image_url"
        />
      </div>
      <div slot="title" slot-scope="title, sku">
        <template v-if="title && title.ru">
          <a
            :href="getClientAppUrl(sku.sku)"
            target="_blank"
            title="Ссылка на сайт"
          >
            {{ title.ru }}
            <a-icon type="link" />
          </a>
        </template>
        <template v-else> Не указан</template>
      </div>
      <div slot="merchant_title" slot-scope="merchant_title">
        {{ merchant_title || 'Не указан' }}
      </div>
      <div slot="sku" slot-scope="sku">
        <template v-if="sku">
          <a :href="`#/base-product/${sku}`" target="_blank">{{ sku }}</a>
        </template>
        <template v-else> Не указан</template>
      </div>
      <div slot="price" slot-scope="price">
        {{ price && price.current | currency }}
      </div>
      <div slot="category" slot-scope="category" class="product__tags">
        <a-tag color="green">
          {{ category.title.ru }}
        </a-tag>
      </div>
      <div slot="is_enabled" slot-scope="is_enabled, record">
        <a-button
          v-if="is_enabled"
          type="primary"
          @click="toggleStatusOfProduct(is_enabled, record)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="toggleStatusOfProduct(is_enabled, record)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="installment" slot-scope="installment">
        <p :style="getInstallmentStyle(installment)">
          {{ installment && installment.title.ru }}
        </p>
      </div>
      <div slot="in_stock" slot-scope="in_stock">
        {{ !!in_stock ? 'Да' : 'Нет' }}
      </div>
      <div slot="operation" slot-scope="is_enabled, product">
        <a-button
          :disabled="
            $acl.notCan({
              service: 'storefront',
              permissions: ['merchant-product-update'],
            })
          "
          type="primary"
          @click="openBindModal(product.merchant_sku, product.sku)"
          >Перепривязка
        </a-button>
      </div>
    </a-table>
    <bind-product-modal
      :is-visible="isModalVisible"
      :merchant-params="merchantParams"
      :request-params="productSearchParams"
      @closeModal="onCloseModal($event)"
      action-type="bindToProduct"
    ></bind-product-modal>
  </div>
</template>

<script>
import BindProductModal from 'Partner/pages/MerchantProducts/components/BindProductModal.vue'

import { productListMixin } from 'Partner/mixins/merchantProductListMixin'
import { recognizedTablecolumns } from 'Partner/components/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  components: {
    BindProductModal,
  },
  data() {
    return {
      recognizedTablecolumns,
      isModalVisible: false,
      merchantParams: null,
      selectedProductsList: null,
      productSearchParams: {
        search: null,
        page: 1,
        limit: 50,
      },
      selectedRowKeys: [],
    }
  },
  mixins: [productListMixin('recognized')],
  computed: {
    cityList() {
      return this.$store.getters['core/cities']
    },
    merchantId() {
      return this.$route.params.id
    },
  },
  methods: {
    getClientAppUrl(sku) {
      return `${process.env.VUE_APP_SHOWCASE_URL}product/${sku}`
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length) {
        this.selectedProductsList = selectedRows
      } else {
        this.selectedProductsList = null
      }
    },
    openBindModal(merchantSKU, productSKU) {
      this.isModalVisible = true
      this.merchantParams = {
        merchantSKU: merchantSKU,
        productSKU: productSKU,
      }
    },
    getInstallmentStyle(installment) {
      return {
        backgroundColor: installment && installment.background_color,
        color: installment && installment.text_color,
        textAlign: 'center',
      }
    },
    async onCloseModal(modalVisibility) {
      if (!modalVisibility) {
        await this.getMerchantProductsList()
      }
      this.isModalVisible = modalVisibility
    },
    async toggleStatusOfProduct(is_enabled, product) {
      try {
        this.isLoading = true
        const { sku, merchant_id } = product
        if (is_enabled) {
          await this.$API.Storefront.disableMerchantProductById(
            merchant_id,
            sku,
          )
        } else {
          await this.$API.Storefront.enableMerchantProductById(merchant_id, sku)
        }
        showSuccessMsg(
          `Продукт ${product.title.ru} ${
            is_enabled ? 'деактивирован' : 'активирован'
          }`,
        )
        await this.getMerchantProductsList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async changeProductsStatus(actionType) {
      const skus = this.selectedProductsList.map((product) => product.sku)
      try {
        actionType === 'deactivate'
          ? await this.$API.Storefront.disableMerchantProduct(this.merchantId, {
              skus,
            })
          : await this.$API.Storefront.enableMerchantProduct(this.merchantId, {
              skus,
            })
        await this.getMerchantProductsList()
        showSuccessMsg(
          `Продукты успшено ${
            actionType === 'deactivate' ? 'деактивированы' : 'активированы'
          }`,
        )
        this.selectedRowKeys = []
        this.selectedProductsList = null
      } catch (error) {
        showErrorMsg(error?.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action__buttons {
  margin: 20px 0;
  align-self: flex-end;
}

.product {
  &__table {
    display: flex;
    flex-direction: column;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }
}

::v-deep {
  .ant-table-title {
    display: none;
  }
}
</style>
