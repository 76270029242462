import { render, staticRenderFns } from "./PromocodeCreate.vue?vue&type=template&id=188ffc31&scoped=true&"
import script from "./PromocodeCreate.vue?vue&type=script&lang=js&"
export * from "./PromocodeCreate.vue?vue&type=script&lang=js&"
import style0 from "./PromocodeCreate.vue?vue&type=style&index=0&id=188ffc31&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188ffc31",
  null
  
)

export default component.exports