<template>
  <div>
    <div class="settings-wrap">
      <h2>Список баннеров</h2>
      <a-button @click="gotoCreatePage">Создать новый баннер</a-button>
    </div>
    <a-table
      :columns="bannersColumns"
      :data-source="bannerList"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      :loading="isLoading"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link
          :to="{
            name: 'BannerEdit',
            params: {
              id: id,
            },
          }"
        >
          {{ id | shortID(5) }}
        </router-link>
      </div>
      <div slot="info" slot-scope="info, record">
        <h4>{{ info }}</h4>
        <p class="info">{{ record.info.text }}</p>
      </div>
      <div slot="position" slot-scope="position">
        {{ $t(`common.${position}`) }}
      </div>
      <div slot="image" slot-scope="image, banner">
        <img class="img-prev" :src="image" :alt="banner.alt" width="170" />
      </div>
      <div slot="type" slot-scope="type">
        <a-tag v-for="item in type" :key="item">{{ item }}</a-tag>
      </div>
      <div slot="active" slot-scope="active, record">
        <a-button
          v-if="active"
          type="primary"
          @click="statusToggleHandler(record, active)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="statusToggleHandler(record, active)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="url" slot-scope="url">
        <a :href="url" target="_blank">{{ url | shortID(10) }}</a>
      </div>
      <div slot="weight" slot-scope="weight, banner">
        <a-input-number
          :id="banner.id"
          :value="weight"
          :min="0"
          @change="(value) => onWeightChange(value, banner)"
        />
        <a-button
          type="primary"
          @click="confirmWeightChange(banner)"
          class="form__button"
        >
          <a-icon type="save" />
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import { bannersColumns } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  data() {
    return {
      bannersColumns,
      bannerList: null,
      isLoading: false,
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      queryParams: {
        limit: 10,
        page: 1,
      },
    }
  },
  created() {
    this.getBannersList()
  },
  methods: {
    async statusToggleHandler(record, isActive) {
      try {
        const payload = Object.assign(record, { active: !isActive })
        await this.$API.Banner.updateBannerById(record.id, payload)
        showSuccessMsg('Удачно обновлен!')
        await this.getBannersList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getBannersList() {
      try {
        this.isLoading = true
        const { banners, total } = await this.$API.Banner.getBannersList(
          this.queryParams,
        )
        this.bannerList = banners
        this.pagination.total = total
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onPageChange({ current }) {
      this.queryParams.page = this.pagination.current = current
      this.getBannersList()
    },
    gotoCreatePage() {
      this.$router.push({
        name: 'BannerCreate',
      })
    },
    async onWeightChange(value, banner) {
      if (value && typeof value === 'number') {
        try {
          this.bannerList.map((item) => {
            if (item.id === banner.id) banner.weight = value
            return banner
          })
        } catch (error) {
          showErrorMsg(error.message)
        }
      }
    },
    async confirmWeightChange(banner) {
      try {
        await this.$API.Banner.updateBannerWeightById(banner.id, banner)
        await this.getBannersList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-title {
  margin-right: 15px;
}

.operation-popup {
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90px;
}

.form {
  &__button {
    margin-left: 10px;
  }
}
</style>
