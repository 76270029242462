<template>
  <div>
    <a-tabs
      v-model="productsType"
      default-active-key="recognized"
      tab-position="right"
    >
      <a-tab-pane key="recognized" tab="Связанные товары">
        <recognized-products-table :is-active="productsType === 'recognized'" />
      </a-tab-pane>
      <a-tab-pane key="unrecognized" tab="Несвязанные товары">
        <unrecognized-products-table
          :is-active="productsType !== 'recognized'"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import RecognizedProductsTable from 'Partner/pages/Partner/PartnerInfo/PartnerProducts/PartnerProductsTabs/RecognizedProductsTable'
import UnrecognizedProductsTable from 'Partner/pages/Partner/PartnerInfo/PartnerProducts/PartnerProductsTabs/UnrecognizedProductsTable'

export default {
  components: {
    RecognizedProductsTable,
    UnrecognizedProductsTable,
  },
  data() {
    return {
      productsType: 'recognized',
    }
  },
  methods: {
    gotoBindMerchantProduct() {
      this.$router.push({
        name: 'MerchantProductBind',
        params: {
          merchant_id: this.$route.params.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
