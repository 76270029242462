import { order } from './order'
import { common } from './common'
import { config } from './config'
import { product } from './product'

export default {
  config,
  common,
  order,
  product,
}
