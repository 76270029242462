<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-row type="flex" justify="center">
        <a-col span="16">
          <a-row class="mb-24" type="flex" justify="space-between">
            <a-col>
              <h2>Редактирование Модели</h2>
            </a-col>
            <a-col>
              <a-button-group>
                <a-button @click="duplicateModel">Дублировать Модель</a-button>
                <a-button @click="() => $router.push({ name: 'ModelList' })">
                  {{ $t('common.back') }}
                </a-button>
              </a-button-group>
            </a-col>
          </a-row>
          <a-form-model
            ref="form"
            :model="formState"
            :rules="rules"
            @submit="onEdit"
          >
            <a-form-model-item label="Slug">
              <a-input v-model="formState.slug" disabled />
            </a-form-model-item>
            <a-divider>Название</a-divider>
            <a-form-model-item prop="title.ru" label="RU(на русском)">
              <a-input v-model="formState.title.ru" />
            </a-form-model-item>
            <a-form-model-item prop="title.kz" label="KZ(на казахском)">
              <a-input v-model="formState.title.kz" />
            </a-form-model-item>
            <a-form-item>
              <a-button type="primary" html-type="submit">Сохранить</a-button>
            </a-form-item>
          </a-form-model>

          <a-form-model-item>
            <router-link :to="`/model/${modelId}/attribute/groups`">
              <a-button type="primary">
                <a-icon type="experiment" />
                Группы атрибутов
              </a-button>
            </router-link>
          </a-form-model-item>
          <router-link :to="`/model/${modelId}/attributes`">
            <a-button type="primary">
              <a-icon type="experiment" />
              Посмотреть атрибуты модели
            </a-button>
          </router-link>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'ModelEdit',
  data: () => ({
    formState,
    rules,
    isFetching: true,
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        await this.getModelById()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getModelById() {
      this.formState = await this.$API.Storefront.getModelById(this.modelId)
    },
    onEdit(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        this.isFetching = true
        await this.updateModelHandler()
        showSuccessMsg('Удачно Сохранен!')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async updateModelHandler() {
      await this.$API.Storefront.updateModelById(this.modelId, this.formState)
      this.isFetching = false
    },
    async duplicateModel() {
      try {
        const { model_id } = await this.$API.Storefront.duplicateModel(
          this.modelId,
        )
        await this.$router.push(`/model-edit/${model_id}`)
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    modelId() {
      return this.$route.params.id
    },
  },
}
</script>

<style scoped></style>
