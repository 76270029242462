export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Статус',
    dataIndex: 'refund_state',
    key: 'refund_state',
    scopedSlots: { customRender: 'refund_state' },
  },
  {
    title: 'Причина возврата',
    dataIndex: 'reason',
    key: 'reason',
    scopedSlots: { customRender: 'reason' },
  },
  {
    title: 'Номер заказа',
    dataIndex: 'order_id',
    key: 'order_id',
    scopedSlots: { customRender: 'order_id' },
  },
  {
    title: 'Дата создания заявки',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
]
