export const order = {
  id: 'ID',
  orders: 'Заказы',
  purchasePlace: 'Место покупки',
  purchaseDate: 'Дата покупки',
  customerFullName: 'Имя плательщика',
  recipientFullName: 'Имя получателя',
  subTotalSum: 'Итоговая сумма (базовая)',
  grandTotalSum: 'Итоговая сумма (куплено)',
  paymentType: 'Метод оплаты',
}
