<template>
  <div>
    <a-form-model-item>
      <a-button :style="{ marginLeft: '8px' }" @click="visible = true">
        Выбрать из списка
      </a-button>
    </a-form-model-item>
    <a-drawer
      title="Выбрать Бренд из списка"
      width="720"
      :mask-closable="true"
      :keyboard="true"
      :visible="visible"
      :after-visible-change="afterVisibleCallback"
      @close="onClose"
    >
      <a-form-model layout="inline" @submit="onSearch" @submit.native.prevent>
        <a-form-model-item>
          <a-input
            ref="search"
            v-model="searchText"
            placeholder="найти"
            @keydown.enter="onSearch"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button-group>
            <a-button type="primary" html-type="submit" :disabled="!searchText">
              Поиск
            </a-button>
            <a-button type="primary" @click="showChildrenDrawer">
              Создать новый
            </a-button>
          </a-button-group>
        </a-form-model-item>
      </a-form-model>

      <div class="brand-list">
        <a-spin :spinning="isLoading">
          <a-button
            v-for="item in dataSource"
            class="brand-list__item"
            :key="item.id"
            @click="onSelect(item)"
          >
            {{ item.title.ru }}
          </a-button>
        </a-spin>
      </div>

      <a-drawer
        title="Создание Бренда"
        width="520"
        :mask-closable="true"
        :keyboard="true"
        :after-visible-change="afterVisibleCreateCallBack"
        :visible="childrenDrawer"
        @close="onChildrenDrawerClose()"
      >
        <a-form-model
          :model="formState"
          @submit="handleSubmit"
          @submit.native.prevent
        >
          <a-form-model-item label="Название (RU)">
            <a-input
              ref="brand"
              v-model="formState.ru"
              placeholder="apple"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button-group>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="formState.ru === ''"
              >
                Сохранить
              </a-button>
              <a-button @click="onChildrenDrawerClose"> Назад</a-button>
            </a-button-group>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>

      <div class="button-absolute-box">
        <a-button @click="onClose"> Отмена</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'BrandSelector',
  data: () => ({
    visible: false,
    childrenDrawer: false,
    isLoading: false,
    dataSource: [],
    searchText: '',
    formState: {
      ru: '',
      kz: '',
    },
  }),
  methods: {
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.searchText = ''
      this.dataSource = []
    },
    showChildrenDrawer() {
      this.childrenDrawer = true
    },
    onChildrenDrawerClose() {
      this.childrenDrawer = false
      this.formState.ru = ''
      this.formState.kz = ''
    },
    handleSubmit() {
      this.visible = false
      this.childrenDrawer = false
      this.formState.kz = this.formState.ru
      this.$emit('setBrandHandler', { title: this.formState })
    },
    onSearch() {
      if (this.searchText !== '') {
        this.isLoading = true
        this.getBrandList()
      }
    },
    async getBrandList() {
      try {
        const { brands } = await this.$API.Storefront.getBrandList({
          search: this.searchText,
        })
        this.dataSource = brands
      } finally {
        this.isLoading = false
      }
    },

    onSelect(brand) {
      this.$emit('setBrandHandler', brand)
      this.visible = false
    },
    afterVisibleCallback() {
      this.$refs.search.$el.focus()
    },
    afterVisibleCreateCallBack() {
      this.$refs.brand.$el.focus()
    },
  },
}
</script>

<style scoped lang="scss">
.brand-list {
  margin-top: 20px;
  display: flex;

  &__item {
    margin: 5px 10px 5px 0;
  }
}
</style>
