<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-row type="flex" justify="center">
        <a-col span="16">
          <a-row class="mb-24" type="flex" justify="space-between">
            <a-col>
              <h2>Просмотр пермишенов</h2>
            </a-col>
            <a-col>
              <a-button @click="() => $router.push({ name: 'RoleList' })">
                {{ $t('common.back') }}
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { getRoleByIdAPI } from 'Config/api/role'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'RoleEdit',
  data: () => ({
    isFetching: true,
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        await this.getRoleById()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getRoleById() {
      this.formState = await getRoleByIdAPI(this.roleId)
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    roleId() {
      return this.$route.params.id
    },
  },
}
</script>

<style scoped></style>
