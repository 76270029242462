import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

export class PPS extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'pps',
    })
  }

  @Api
  async getPromoPageList(params) {
    const response = await this.GET(`/v1/pages`, params)
    return response
  }

  @Api
  async getPromoPageInfo(id) {
    const response = await this.GET(`/v1/pages/${id}`)
    return response
  }

  @Api
  async createPromoPage(params) {
    const response = await this.POST(`/v1/pages`, params)
    return response
  }

  @Api
  async updatePromoPage(params) {
    const response = await this.PUT(`/v1/pages`, params)
    return response
  }

  @Api
  async deletePromoPage(id) {
    const response = await this.DELETE(`/v1/pages/${id}`)
    return response
  }
}
