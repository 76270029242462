<template>
  <div>
    <quill-editor
      @change="onEditorChange($event)"
      :content="content"
      :options="editorOption"
    />
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Quill from 'quill'
import 'quill-paste-smart'
import { quillEditor } from 'vue-quill-editor'

import { ImageUpload } from 'Core/components/QuillInput/imageModule.js'
Quill.register('modules/imageUpload', ImageUpload)

export default {
  name: 'QuillInput',
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
    },
  },
  watch: {
    value(newValue) {
      if (!this.content) {
        this.content = newValue
      }
    },
  },
  computed: {
    urlForImage() {
      // todo если так передовать он не отрабатывает, нужно подумать
      const baseUrl = process.env.VUE_APP_API_URL
      const folderName = baseUrl.includes('api.airba.kz')
        ? 'f3-airba-marketplace-prod'
        : 'test-marketplace-f3'
      return `${baseUrl}/f3/api/v1/${folderName}/`
    },
  },
  data() {
    return {
      content: null,
      editorOption: {
        theme: 'bubble',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['image', 'video', 'link'],
            [{ header: [2, 3, 4, false] }],
            [{ align: [] }],
            ['clean'],
            ['fullscreen'],
          ],
          imageUpload: {
            url: this.urlForImage,
            method: 'POST',
            name: 'file',
            withCredentials: false,
            headers: {},
            csrf: { token: 'token', hash: '' },
            callbackOK: (serverResponse, next) => {
              next(serverResponse)
            },
            callbackKO: (serverError) => {
              alert(serverError)
            },
            checkBeforeSend: (file, next) => {
              next(file)
            },
          },
          clipboard: {
            allowed: {
              tags: [
                'a',
                'b',
                'strong',
                'u',
                's',
                'i',
                'p',
                'br',
                'ul',
                'ol',
                'li',
                'span',
                'h1',
                'h2',
                'h3',
                'h4',
                '',
              ],
              attributes: ['href', 'rel', 'target', 'class'],
            },
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,
            hooks: {},
          },
        },
      },
    }
  },
  methods: {
    onEditorChange({ html }) {
      this.$emit('input', html)
    },
  },
}
</script>

<style lang="scss">
.ql-editor {
  border: 1px solid #e5e5e5;
}
.ql-editor strong {
  font-weight: bold !important;
  color: #000000 !important;
}
.ql-editor p {
  font-size: 15px !important;
  line-height: 24px !important;
  margin-bottom: 20px !important;
  color: #000000 !important;
}
.ql-editor h2 {
  font-size: 40px !important;
  line-height: 56px !important;
  font-weight: 700 !important;
  margin-bottom: 16px !important;
  color: #000000 !important;
}
.ql-editor h3 {
  color: #000000 !important;
  font-size: 32px !important;
  line-height: 48px !important;
  font-weight: 600 !important;
  margin-top: 52px !important;
  margin-bottom: 16px !important;
}
.ql-bubble .ql-picker.ql-header .ql-picker-label::before,
.ql-bubble .ql-picker.ql-header .ql-picker-item::before {
  vertical-align: top;
}
.ql-editor h4 {
}
</style>
