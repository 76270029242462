export const columnNames = [
  {
    title: 'Категория',
    dataIndex: 'category_title.ru',
    key: 'category',
    scopedSlots: { customRender: 'title' },
    align: 'right',
  },
  {
    title: 'Наша комиссия',
    dataIndex: 'value',
    key: 'value',
    scopedSlots: { customRender: 'rate' },
    align: 'right',
    width: '140px',
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updatedAt' },
    align: 'right',
    width: '170px',
  },
]
