<template>
  <div>
    <a-spin :spinning="isFetching">
      <div class="settings-wrap">
        <h2><i>Редактирование</i> Комиссии по категориям</h2>
        <a-button
          type="primary"
          @click="
            () =>
              $router.push({
                name: 'ComissionView',
              })
          "
        >
          {{ $t('common.back') }}
        </a-button>
      </div>
      <div>editing in process</div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'ComissionEdit',
}
</script>

<style scoped></style>
