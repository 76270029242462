import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления акциями и рассрочками
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/payplan/swagger/index.html} */
export class PayplanService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'payplan',
    })
  }

  @Api
  async getInstallmentList(params): Promise<any> {
    const response = await this.GET('/v1/installments', params)
    return response
  }

  @Api
  async createInstallment(payload) {
    const response = await this.POST('/v1/installments', payload)
    return response
  }

  @Api
  async updateInstallmentById(id, payload) {
    const response = await this.PUT(`/v1/installments/${id}`, payload)
    return response
  }

  @Api
  async getInstallmentById(id) {
    const response = await this.GET(`/v1/installments/${id}`)
    return response
  }
}
