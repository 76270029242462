





















import { Component, Vue } from 'vue-property-decorator'
import ProductReviewList from 'Content/pages/Reviews/ProductReviews/ProductReviewList/ProductReviewList.vue'

@Component({ components: { ProductReviewList } })
class Reviews extends Vue {
  tabKey = '0'
}

export default Reviews
