import { URL } from 'Core/modules/validators/url'
import { ALPHA } from 'Core/modules/validators/alpha'

export const formState = {
  abbrev: null,
  description: null,
  link: null,
  _id: null,
  data: null,
}

export const rules = {
  abbrev: [
    {
      fullField: 'Наименование',
      required: true,
    },
    {
      validator: ALPHA,
      fullField: 'Наименование',
      langs: ['en'],
      numeric: true,
      specials: ['_'],
    },
  ],
  description: {
    type: 'string',
    required: true,
  },
  link: [
    {
      fullField: 'Ссылка',
      required: true,
    },
    {
      validator: URL,
      fullField: 'Ссылка',
    },
  ],
}
