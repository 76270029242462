/* eslint-disable */
import {generateRegexMesssage,
  generateRegexPattern,} from 'Core/modules/regexGenerator'
export const ALPHA = function (rule, value, callback, source, options) {
    try {
    const regExp = generateRegexPattern(rule)
     const message = generateRegexMesssage(rule)

    if (regExp.test(value) || value === '')
        return true
     else
        return callback(message)
    } catch (error) {
    console.log(error
        )
    }
}
