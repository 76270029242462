<template>
  <div>
    <div class="settings-wrap">
      <h2>Информация о заказе #{{ orderInfo.id }}</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'OrdersList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="OrderInfoIsLoading">
      <a-descriptions
        :column="7"
        class="description-padding"
        title="Информация о заказе"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="Номер заказа">
          {{ orderInfo.id }}
        </a-descriptions-item>
        <a-descriptions-item label="Заказы продавцов">
          <ul>
            <li v-for="(item, index) in orderInfo.sub_orders" :key="index">
              <router-link
                :to="{
                  name: 'PartnerOrderInfo',
                  params: { id: item.merchant_id, orderId: item.id },
                }"
              >
                {{ item.merchant_title }} - {{ item.id }}
              </router-link>
            </li>
          </ul>
        </a-descriptions-item>
        <a-descriptions-item label="Тип заказа">
          <template v-if="orderInfo.dividable"> Делимый заказ </template>
          <template v-else> Неделимый заказ </template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус заказа">
          <status-view
            :status-list="ORDER_STATUSES"
            :status="orderInfo.status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Способ оплаты">
          <template v-if="PaymentTypes[orderInfo.payment_type]">
            <a-icon :type="PaymentTypes[orderInfo.payment_type].icon"></a-icon>
            {{ PaymentTypes[orderInfo.payment_type].label }}
          </template>
          <template v-else>
            Неизвестный способ оплаты [{{ orderInfo.payment_type }}]
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус оплаты">
          <status-view
            :status-list="PAYMENT_STATUSES"
            :status="orderInfo.payment_status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Сумма заказа">
          {{ orderInfo.total_price | currency }}
        </a-descriptions-item>
      </a-descriptions>
      <a-collapse class="description-padding">
        <a-collapse-panel key="1" header="Информация о клиенте">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="Фио клиента">
              {{ orderInfo.customer.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Email">
              {{ orderInfo.customer.email }}
            </a-descriptions-item>
            <a-descriptions-item label="Контактные номера">
              {{ orderInfo.customer.phone }}
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="Информация о доставке">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="ФИО получателя" span="2">
              {{ orderInfo.recipient.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Телефон получателя">
              {{ orderInfo.recipient.phone }}
            </a-descriptions-item>
            <a-descriptions-item label="Адрес" span="3">
              <p>{{ orderInfo.address }}</p>
            </a-descriptions-item>
            <a-descriptions-item span="3" label="Комментарий">
              <p>{{ orderInfo.comment }}</p>
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
      </a-collapse>

      <div v-if="!OrderInfoIsLoading">
        <a-table
          v-if="isDividable"
          class="description-padding"
          :columns="columnNames"
          :data-source="orderInfo.products"
          :scroll="{ x: true }"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          row-key="id"
          :pagination="false"
          :row-selection="rowSelection"
          bordered
        >
          <div slot="sku" slot-scope="sku">
            <router-link :to="`/base-product/${sku}`" target="_blank">
              {{ sku }}
            </router-link>
          </div>
          <div slot="storage_type" slot-scope="storage_type">
            <template v-if="storage_type === 'fbo'">
              <p>На складе AIRBA</p>
            </template>
            <template v-else-if="storage_type === 'fbs'">
              <p>На складе у партнера</p>
            </template>
            <template v-else>
              <p>
                Не известный тип хранения <strong>{{ storage_type }}</strong>
              </p>
            </template>
          </div>
          <div slot="title" slot-scope="title, sku">
            <template v-if="sku && sku.sku">
              <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
                {{ title.ru }}
                <a-icon type="link" />
              </a>
            </template>
          </div>
          <div slot="merchant_title" slot-scope="merchant_title, item">
            <div
              v-if="merchant_title && item && item.merchant_id"
              class="merchant-links"
            >
              <router-link :to="`/partner/${item.merchant_id}`">
                <template v-if="merchant_title.ru">
                  {{ merchant_title.ru }} [ RU ]
                </template>
                <template v-else-if="merchant_title.kz">
                  {{ merchant_title.kz }} [ KZ ]
                </template>
                <template v-else-if="merchant_title.en">
                  {{ merchant_title.en }} [ EN ]
                </template>
              </router-link>
              <router-link :to="`/partner/${item.merchant_id}?tab=2&subTab=6`">
                Сотрудники
              </router-link>
            </div>
            <template v-else>
              Название продавца отсутсвует
              <a-icon style="color: #ff4d4f" type="warning" />
            </template>
          </div>
          <div slot="pickup_point_id" slot-scope="pickup_point_id, item">
            <router-link
              :to="`/pickup-point/merchant=${item.merchant_id}/id=${pickup_point_id}`"
              target="_blank"
            >
              {{ pickup_point_id }}
            </router-link>
          </div>
          <div slot="price" slot-scope="price">
            {{ price | currency }}
          </div>
          <div slot="total_price" slot-scope="total_price">
            {{ total_price | currency }}
          </div>
          <div slot="status" slot-scope="status">
            <status-view
              :status-list="PRODUCT_STATUSES"
              :status="status"
            ></status-view>
          </div>
        </a-table>
        <a-table
          v-else
          class="description-padding"
          :columns="columnNames"
          :data-source="orderInfo.products"
          :scroll="{ x: true }"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          row-key="id"
          :pagination="false"
          bordered
        >
          <div slot="sku" slot-scope="sku">
            <router-link :to="`/base-product/${sku}`">
              <code>{{ sku }}</code>
            </router-link>
          </div>
          <div slot="title" slot-scope="title, sku">
            <template v-if="sku && sku.sku">
              <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
                {{ title.ru }}
                <a-icon type="link" />
              </a>
            </template>
          </div>
          <div slot="merchant_title" slot-scope="merchant_title, item">
            <div
              v-if="merchant_title && item && item.merchant_id"
              class="merchant-links"
            >
              <router-link :to="`/partner/${item.merchant_id}`">
                <template v-if="merchant_title.ru">
                  {{ merchant_title.ru }} [ RU ]
                </template>
                <template v-else-if="merchant_title.kz">
                  {{ merchant_title.kz }} [ KZ ]
                </template>
                <template v-else-if="merchant_title.en">
                  {{ merchant_title.en }} [ EN ]
                </template>
              </router-link>
              <router-link :to="`/partner/${item.merchant_id}?tab=2&subTab=6`">
                Сотрудники
              </router-link>
            </div>
            <template v-else>
              Название продавца отсутсвует
              <a-icon style="color: #ff4d4f" type="warning" />
            </template>
          </div>
          <div slot="pickup_point_id" slot-scope="pickup_point_id, item">
            <router-link
              :to="`/pickup-point/merchant=${item.merchant_id}/id=${pickup_point_id}`"
              target="_blank"
            >
              {{ pickup_point_id }}
            </router-link>
          </div>
          <div slot="price" slot-scope="price">
            {{ price | currency }}
          </div>
          <div slot="total_price" slot-scope="total_price">
            {{ total_price | currency }}
          </div>
          <div slot="status" slot-scope="status">
            <status-view
              :status-list="PRODUCT_STATUSES"
              :status="status"
            ></status-view>
          </div>
        </a-table>
      </div>

      <div>
        <a-button-group class="mt--2" style="display: flex">
          <a-button v-if="isCompleded" type="danger" @click="returnHandler">
            Вернуть
          </a-button>
          <a-button
            v-if="orderInfo.dividable"
            :disabled="!canChangeSolutions || selectedRowKeys.length === 0"
            @click="showRejectModal(selectedRowKeys)"
            type="primary"
            class="ml--1"
          >
            Отменить
          </a-button>
          <a-button
            v-else
            :disabled="!canChangeSolutions"
            @click="showRejectModal(orderInfo.products)"
            type="primary"
            class="ml--1"
          >
            Отменить
          </a-button>
        </a-button-group>
      </div>

      <a-modal
        v-model="returnModal"
        title="Оформление заявки на возврат"
        cancel-text="Отмена"
        ok-text="Оформить возврат"
        @ok="handleOnReturn"
      >
        <div class="return-modal">
          <a-form-model
            ref="form"
            :model="returnForm"
            :rules="returnFormRules"
            @submit="handleOnReturn"
          >
            <a-form-model-item label="Причина">
              <a-radio-group v-model="returnForm.reason">
                <a-radio-button value="changed_mind">
                  Передумал покупать
                </a-radio-button>
                <a-radio-button value="defective"> Брак</a-radio-button>
                <a-radio-button value="other"> Другое</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item prop="name" label="Коментарий">
              <a-textarea
                allow-clear
                v-model="returnForm.comment"
                :auto-size="{ minRows: 2, maxRows: 4 }"
              />
            </a-form-model-item>
            <a-form-model-item prop="name" label="Товары">
              <div>
                <a-table
                  class="description-padding"
                  :columns="returnColumnNames"
                  :data-source="returnForm.items"
                  :scroll="{ x: true }"
                  :locale="{ emptyText: 'Данные не найдены ):' }"
                  row-key="id"
                  :pagination="false"
                  bordered
                >
                  <div slot="quantity" slot-scope="quantity, record">
                    <a-input-number
                      :disabled="!isDividable"
                      v-model="record.quantity"
                      :min="1"
                      :max="record.max_amount"
                    />
                  </div>
                </a-table>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>

      <a-modal
        :visible="rejectModalVisible"
        :title="isDividable ? 'Частичная отмена заказа' : 'Отмена заказа'"
        @ok="orderItemSolution('reject', selectedProducts, reason)"
        @cancel="closeModal"
        @close="closeModal"
      >
        <a-form-item label="Причина отмены">
          <a-select v-model="reason" style="width: 100%">
            <a-select-option
              v-for="(item, index) in CANCEL_REASONS"
              :key="index"
              :value="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-table
          :data-source="selectedProducts"
          :columns="solutionColumnsName"
          :pagination="false"
          row-key="id"
          bordered
        >
          <div slot="quantity" slot-scope="quantity, product">
            <template v-if="orderInfo.dividable">
              <a-input-number
                :default-value="product.max"
                :min="1"
                :max="product.max"
                v-model="product.quantity"
              />
            </template>
            <template v-else>
              {{ quantity }}
            </template>
          </div>
        </a-table>
        <template slot="footer">
          <a-button key="back" @click="closeModal"> Отмена </a-button>
          <a-button
            key="submit"
            type="danger"
            @click="orderItemSolution('reject', selectedProducts, reason)"
          >
            Отменить выбранные
          </a-button>
        </template>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import {
  orderInfo,
  columnNames,
  returnForm,
  returnFormRules,
  returnColumnNames,
  CANCEL_REASONS,
} from 'Order/pages/OrderInfo/constants'
import { solutionColumnsName } from 'Partner/pages/Partner/PartnerOrderInfo/constants'
import { PaymentTypes } from 'Core/constants'
import {
  ORDER_STATUSES,
  PRODUCT_STATUSES,
  PAYMENT_STATUSES,
} from 'Core/types/order'

export default {
  data() {
    return {
      ORDER_STATUSES,
      PAYMENT_STATUSES,
      PRODUCT_STATUSES,
      CANCEL_REASONS,
      PaymentTypes,
      columnNames,
      orderInfo,
      returnForm,
      returnFormRules,
      returnColumnNames,
      solutionColumnsName,
      OrderInfoIsLoading: true,
      returnModal: false,
      selectedRows: [],
      selectedRowKeys: [],
      selectedProducts: [],
      showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
      rejectModalVisible: false,
      reason: '',
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.returnForm.items = selectedRows
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.cancelStatuses.includes(record.status),
          },
        }),
      }
    },
    isDividable() {
      return this.orderInfo?.dividable
    },
    canChangeSolutions() {
      // если заказ не оплачен и тип оплаты == картой, кнопки должны быть disabled(требования бизнеса)
      if (
        this.orderInfo.payment_type === PaymentTypes.card_online.value &&
        this.orderInfo.payment_status === PAYMENT_STATUSES.not_paid.value
      ) {
        return false
      }
      return !this.cancelStatuses.includes(this.orderInfo.status)
    },
    cancelStatuses() {
      return [
        ORDER_STATUSES.canceled.value,
        ORDER_STATUSES.canceled_by_cls.value,
        ORDER_STATUSES.canceled_by_buyer.value,
        ORDER_STATUSES.canceled_by_merchant.value,
        ORDER_STATUSES.completed.value,
        ORDER_STATUSES.delivering.value,
      ]
    },
    isCompleded() {
      return this.orderInfo?.status === ORDER_STATUSES.completed.value
    },
  },
  methods: {
    compareSelectedProducts(productsList) {
      const products = this.orderInfo.products.map((item) => ({
        sku: item.sku,
        name: item.title.ru,
        quantity: item.quantity,
        max: item.quantity,
        merchant_id: item.merchant_id,
        id: item.id,
      }))
      if (this.orderInfo.dividable) {
        return products.filter((item) => productsList.indexOf(item.id) > -1)
      }
      return products
    },
    async initialize() {
      try {
        this.OrderInfoIsLoading = true
        this.orderInfo = await this.$API.OMS.getOrderItemInfo(this.orderId)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.OrderInfoIsLoading = false
      }
    },
    onRowSelect(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    returnHandler() {
      this.returnModal = true
      this.returnForm.order_id = this.orderId
      if (!this.isDividable || this.returnForm.items.length === 0) {
        this.returnForm.items = this.orderInfo.products
      }
      this.returnForm.items = this.returnForm.items.map((item) => {
        return {
          ...item,
          max_amount: item.quantity,
        }
      })
    },
    async handleOnReturn() {
      try {
        this.returnModal = false
        await this.$API.RMS.purchaseReturn(this.returnForm)
        showSuccessMsg('Возврат оформлен.')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    showRejectModal(products) {
      this.selectedProducts = this.compareSelectedProducts(products, 'reject')
      this.rejectModalVisible = true
    },
    async orderItemSolution(solution, products, reason) {
      try {
        this.isLoading = true
        const payload = products?.map((item) => ({
          sku: item.sku,
          quantity: item.quantity,
          merchant_id: item.merchant_id,
        }))
        await this.$API.OMS.cancelProducts(this.orderId, {
          products: payload,
          reason,
        })
        this.closeModal()
        showSuccessMsg(`Отмена заказа #${this.orderId} была успешно совершена`)
        await this.initialize()
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.selectedProducts = []
      this.ruleList = {}
      this.rejectModalVisible = false
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-collapse-content-box {
  padding: 0 !important;
}

.description-padding {
  margin-top: 32px;
}

.return-modal {
  max-height: 60vh;
  overflow-y: scroll;
}

.merchant-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
