import { mapStateToFSM } from 'Core/modules/helpers'

export default {
  namespaced: true,
  state: {
    brokers: null,
    isLoading: false,
  },
  mutations: {
    SET_BROKERS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_BROKERS(state, brokers) {
      state.brokers = brokers
    },
  },
  actions: {
    async fetchBrokers(context) {
      let data = null
      try {
        context.commit('SET_BROKERS_LOADING', true)
        data = await this._vm.$API.ConfigDiscovery.getBrokers()
      } finally {
        context.commit('SET_BROKERS_LOADING', false)
        context.commit('SET_BROKERS', data)
      }
    },
  },
  getters: {
    getBrokers: (state) =>
      mapStateToFSM(state.brokers, {
        label: 'title',
        value: 'id',
        variant: 'partner_code',
        icon: 'icon_url',
      }),
    isLoading: (state) => state.isLoading,
  },
}
