













import { Component, Vue } from 'vue-property-decorator'
import ClientsOrders from 'Client/components/Orders/OrderList.vue'
import { showErrorMsg } from '@/Core/utils'

@Component({
  components: {
    ClientsOrders,
  },
})
class PartnerOrders extends Vue {
  isOrdersLoading = false
  orders = []
  ordersTotalCount = 0
  $API: any

  get merchantId() {
    return this.$route.params.id
  }

  async getUserOrders(params) {
    try {
      this.isOrdersLoading = true
      const { orders, total_count } = await this.$API.OMS.getMerchantOrderList({
        merchant_id: this.merchantId,
        ...params,
      })
      this.orders = orders
      this.ordersTotalCount = total_count
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.isOrdersLoading = false
    }
  }
}

export default PartnerOrders
