<template>
  <div>
    <div class="settings-wrap">
      <h2>Информация о заказе партнера #{{ orderInfo.id }}</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'PartnerInfo',
              params: {
                id: merchantID,
              },
              query: {
                tab: '3',
              },
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="isLoading">
      <a-descriptions
        :column="7"
        class="description-padding"
        title="Информация о заказе"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="Номер заказа">
          {{ orderInfo.id }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер основного заказа">
          <router-link :to="`/order/${mainOrder}`">
            {{ mainOrder }}
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="Тип заказа">
          <template v-if="orderInfo.dividable"> Делимый заказ</template>
          <template v-else> Неделимый заказ</template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус заказа">
          <status-view
            :status-list="ORDER_STATUSES"
            :status="orderInfo.status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Способ оплаты">
          <template v-if="PaymentTypes[orderInfo.payment_type]">
            <a-icon :type="PaymentTypes[orderInfo.payment_type].icon"></a-icon>
            {{ PaymentTypes[orderInfo.payment_type].label }}
          </template>
          <template v-else>
            Неизвестный способ оплаты [{{ orderInfo.payment_type }}]
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус оплаты">
          <status-view
            :status-list="PAYMENT_STATUSES"
            :status="orderInfo.payment_status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Сумма заказа">
          {{ orderInfo.total_price | currency }}
        </a-descriptions-item>
      </a-descriptions>
      <a-collapse class="description-padding">
        <a-collapse-panel key="1" header="Информация о клиенте">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="Фио клиента">
              {{ orderInfo.customer.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Email">
              {{ orderInfo.customer.email }}
            </a-descriptions-item>
            <a-descriptions-item label="Контактные номера">
              {{ orderInfo.customer.phone }}
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="Информация о доставке">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="ФИО получателя" span="2">
              {{ orderInfo.recipient.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Телефон получателя">
              {{ orderInfo.recipient.phone }}
            </a-descriptions-item>
            <a-descriptions-item label="Адрес" span="3">
              <p>{{ orderInfo.address }}</p>
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
      </a-collapse>
      <div class="settings-wrap description-padding">
        <h2></h2>
        <a-button-group v-if="orderInfo.dividable && canChangeSolutions">
          <a-button @click="showRejectModal(orderInfo.products)" type="danger">
            Отменить все товары
          </a-button>
          <a-button
            :disabled="selectedRowKeys.length === 0"
            @click="showAcceptModal(selectedRowKeys)"
            type="primary"
          >
            Подтвердить выбранные
          </a-button>
        </a-button-group>
        <a-button-group v-else-if="canChangeSolutions">
          <a-button @click="showRejectModal(orderInfo.products)" type="danger">
            Отменить все товары
          </a-button>
          <a-button @click="showAcceptModal(orderInfo.products)" type="primary">
            Подтвердить все товары
          </a-button>
        </a-button-group>
      </div>
      <a-table
        :columns="columnNames"
        :data-source="orderInfo.products"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="orderInfo.dividable ? ({ sku }) => sku : null"
        :row-selection="
          orderInfo.dividable
            ? {
                selectedRowKeys,
                onChange: onRowSelect,
              }
            : null
        "
        :pagination="false"
        bordered
      >
        <div slot="sku" slot-scope="sku">
          <router-link :to="`/base-product/${sku}`" target="_blank">
            {{ sku }}
          </router-link>
        </div>
        <div slot="storage_type" slot-scope="storage_type">
          <template v-if="storage_type === 'fbo'">
            <p>На складе AIRBA</p>
          </template>
          <template v-else-if="storage_type === 'fbs'">
            <p>На складе у партнера</p>
          </template>
          <template v-else>
            <p>
              Не известный тип хранения <strong>{{ storage_type }}</strong>
            </p>
          </template>
        </div>
        <div slot="title" slot-scope="title, sku">
          <template v-if="sku && sku.sku">
            <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
              {{ title.ru }}
              <a-icon type="link" />
            </a>
          </template>
        </div>
        <div slot="merchant_title" slot-scope="merchant_title">
          <template v-if="merchant_title.ru">
            {{ merchant_title.ru }} [ RU ]
          </template>
          <template v-else-if="merchant_title.kz">
            {{ merchant_title.kz }} [ KZ ]
          </template>
          <template v-else-if="merchant_title.en">
            {{ merchant_title.en }} [ EN ]
          </template>
          <template v-else>
            Название продавца отсутсвует
            <a-icon style="color: #ff4d4f" type="warning" />
          </template>
        </div>
        <div slot="pickup_point_id" slot-scope="pickup_point_id, item">
          <router-link
            :to="`/pickup-point/merchant=${item.merchant_id}/id=${pickup_point_id}`"
            target="_blank"
          >
            {{ pickup_point_id }}
          </router-link>
        </div>
        <div slot="price" slot-scope="price">
          {{ price | currency }}
        </div>
        <div slot="total_price" slot-scope="total_price">
          {{ total_price | currency }}
        </div>
        <div slot="status" slot-scope="status">
          <status-view
            :status-list="PRODUCT_STATUSES"
            :status="status"
          ></status-view>
        </div>
      </a-table>

      <a-modal
        :visible="acceptModalVisible"
        title="Подтверждение заказа"
        @ok="orderItemSolution('accept', selectedProducts, '')"
        @cancel="closeModal"
        @close="closeModal"
      >
        <a-table
          :data-source="selectedProducts"
          :columns="solutionColumnsName"
          :pagination="false"
          bordered
        >
          <div slot="quantity" slot-scope="quantity, product">
            <template v-if="orderInfo.dividable">
              <a-input-number
                :default-value="product.max"
                :min="1"
                :max="product.max"
                v-model="product.quantity"
              />
            </template>
            <template v-else>
              {{ quantity }}
            </template>
          </div>
        </a-table>
        <template slot="footer">
          <a-button key="back" @click="closeModal"> Отмена </a-button>
          <a-button
            key="submit"
            type="primary"
            @click="orderItemSolution('accept', selectedProducts, '')"
          >
            Подтвердить выбранные
          </a-button>
        </template>
      </a-modal>
      <a-modal
        :visible="rejectModalVisible"
        title="Отмена заказа"
        @ok="orderItemSolution('reject', selectedProducts, reason)"
        @cancel="closeModal"
        @close="closeModal"
      >
        <a-form-item label="Причина отмены">
          <a-textarea v-model="reason"></a-textarea>
        </a-form-item>
        <a-table
          :data-source="selectedProducts"
          :columns="solutionColumnsName"
          :pagination="false"
          bordered
        >
          <div slot="quantity" slot-scope="quantity, product">
            <template v-if="orderInfo.dividable">
              <a-input-number
                :default-value="product.max"
                :min="1"
                :max="product.max"
                v-model="product.quantity"
              />
            </template>
            <template v-else>
              {{ quantity }}
            </template>
          </div>
        </a-table>
        <template slot="footer">
          <a-button key="back" @click="closeModal"> Отмена </a-button>
          <a-button
            key="submit"
            type="danger"
            @click="orderItemSolution('reject', selectedProducts, '')"
          >
            Отменить выбранные
          </a-button>
        </template>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  orderInfo,
  columnNames,
  solutionColumnsName,
} from 'Partner/pages/Partner/PartnerOrderInfo/constants'
import { PaymentTypes } from 'Core/constants'
import {
  ORDER_STATUSES,
  PAYMENT_STATUSES,
  PRODUCT_STATUSES,
} from 'Core/types/order'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { RU_LOCALE } from 'Core/modules/validator'

export default {
  name: 'PartnerOrderInfo',
  data() {
    return {
      isLoading: false,
      orderInfo,
      columnNames,
      solutionColumnsName,
      ORDER_STATUSES,
      PRODUCT_STATUSES,
      PAYMENT_STATUSES,
      PaymentTypes,
      showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
      mainOrder: null,

      selectedRowKeys: [],
      reason: '',
      selectedProducts: [],
      ruleList: {},
      acceptModalVisible: false,
      rejectModalVisible: false,
      RU_LOCALE,
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
    orderId() {
      return this.$route.params.orderId
    },
    canChangeSolutions() {
      return (
        this.orderInfo.status === ORDER_STATUSES.in_process.value &&
        this.orderInfo.products[0].status ===
          PRODUCT_STATUSES.waiting_confirmation.value
      )
    },
  },
  methods: {
    compareSelectedProducts(productsList, type) {
      if (this.orderInfo.dividable && type === 'accept') {
        return this.orderInfo.products
          .map((item) => {
            if (productsList.indexOf(item.sku) > -1) {
              return {
                sku: item.sku,
                name: item.title.ru,
                quantity: item.quantity,
                max: item.quantity,
              }
            }
          })
          .filter((item) => item)
      } else {
        return this.orderInfo.products.map((item) => {
          return {
            sku: item.sku,
            name: item.title.ru,
            quantity: item.quantity,
            max: item.quantity,
          }
        })
      }
    },
    async initialize() {
      try {
        this.OrderInfoIsLoading = true
        this.orderInfo = await this.$API.OMS.getMerchantOrderItemInfo(
          this.orderId,
        )
        this.mainOrder = this.orderId.slice(0, this.orderId.indexOf('-'))
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.OrderInfoIsLoading = false
      }
    },
    onRowSelect(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    showAcceptModal(products) {
      this.selectedProducts = this.compareSelectedProducts(products, 'accept')
      this.acceptModalVisible = true
    },
    showRejectModal(products) {
      this.selectedProducts = this.compareSelectedProducts(products, 'reject')
      this.rejectModalVisible = true
    },
    changeQuantity(value, key, column) {
      const newData = [...this.selectedProducts]
      const target = newData.filter((item) => key === item.sku)[0]
      if (target) {
        target[column] = value
        this.selectedProducts = newData
      }
    },
    async orderItemSolution(solution, products, reason) {
      try {
        this.isLoading = true
        let solutionParams = {
          solution: solution,
          reason: reason,
        }
        solutionParams.products = products.map((item) => {
          return {
            quantity: item.quantity,
            sku: item.sku,
          }
        })
        await this.$API.OMS.setMerchantOrderStatus(
          this.merchantID,
          this.orderId,
          solutionParams,
        )
        this.closeModal()
        showSuccessMsg(
          solution === 'accept'
            ? 'Заказ успешно подтвержден'
            : 'Заказ успешно отклонен',
        )
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.selectedProducts = []
      this.ruleList = {}
      this.rejectModalVisible = false
      this.acceptModalVisible = false
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-collapse-content-box {
  padding: 0 !important;
}

.description-padding {
  margin-top: 32px;
}
</style>
