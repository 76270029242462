export default {
  phoneFormat(value) {
    return value?.length === 11
      ? `+7 (${value.slice(1, 4)}) ${value.slice(4, 7)} ${value.slice(
          7,
          9,
        )} ${value.slice(9, 11)}`
      : value
  },
}
// +7 (777) 123 45 67
