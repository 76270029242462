<template>
  <div>
    <div class="settings-wrap">
      <h2>Список промокодов</h2>
      <router-link
        :to="{ name: 'PromocodeCreate' }"
        v-can="{
          service: 'promocode',
          permissions: ['specification-create'],
        }"
      >
        <a-button>Создать промокод</a-button>
      </router-link>
    </div>
    <a-form-model
      layout="inline"
      ref="form"
      :model="params"
      @submit="searchHandler"
    >
      <div class="settings-wrap">
        <a-form-model-item label="Статус промокода">
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="params.is_active"
            @change="searchHandler"
          >
            <a-radio-button
              v-for="(item, index) in statusTypes"
              :value="item.value"
              :key="index"
            >
              <a-icon :type="item.icon" />
              {{ item.name }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>

    <a-table
      :columns="promotionColumns"
      :data-source="promotions"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      :loading="isLoading"
      @change="onPageChange"
      bordered
    >
      <div slot="name" slot-scope="name, promotion">
        <router-link
          :to="{
            name: 'PromocodeEdit',
            params: { promocode_id: promotion.id },
          }"
        >
          {{ name }}
        </router-link>
      </div>
      <div slot="starts_at" slot-scope="starts_at">
        {{ starts_at && starts_at | formatDate }}
      </div>

      <div
        slot="active_promo_codes_count"
        slot-scope="active_promo_codes_count"
      >
        {{ active_promo_codes_count && active_promo_codes_count }}
      </div>

      <div slot="ends_at" slot-scope="ends_at">
        {{ ends_at && ends_at | formatDate }}
      </div>
      <div slot="is_active" slot-scope="is_active, promotion, index">
        <a-button
          v-if="is_active"
          type="primary"
          @click="statusToggleHandler(is_active, promotion, index)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="statusToggleHandler(is_active, promotion, index)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="weight" slot-scope="weight, banner">
        <a-input-number
          :id="banner.id"
          :value="weight"
          :min="0"
          @change="(value) => onWeightChange(value, banner)"
        />
        <a-button
          type="primary"
          @click="confirmWeightChange(banner)"
          class="form__button"
        >
          <a-icon type="save" />
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { promotionColumns, statusTypes } from './constants'

export default {
  data() {
    return {
      promotionColumns,
      statusTypes,
      isLoading: false,
      promotions: null,
      params: {
        limit: 10,
        page: 1,
        is_active: null,
      },
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
    }
  },
  created() {
    this.getPromotionsList()
  },
  methods: {
    async getPromotionsList() {
      try {
        this.isLoading = true
        const { specifications, count } =
          await this.$API.PromoCode.getPromoCodeList(this.params)
        this.promotions = specifications
        this.pagination.total = count
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async statusToggleHandler(status, promotion, idx) {
      try {
        const payload = JSON.parse(JSON.stringify(promotion))
        payload.is_active = !status
        await this.$API.PromoCode.updatePromoCodeById(promotion.id, payload)
        showSuccessMsg('Удачно обновлен!')
        this.promotions[idx].is_active = !status
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async onPageChange({ current }) {
      this.params.page = current
      this.pagination.current = current
      await this.getPromotionsList()
    },
    async searchHandler(event) {
      event.preventDefault()
      try {
        this.pagination.current = 1
        this.params.page = 1
        await this.getPromotionsList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async dropFilter() {
      this.pagination.current = 1
      this.params.page = 1
      this.params = {
        active: null,
        limit: 10,
        page: 1,
      }
      await this.getPromotionsList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
