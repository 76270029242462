<template>
  <div>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ y: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ store_id }) => store_id"
      :loading="isLoading"
      bordered
    >
      <div slot="store_id" slot-scope="store_id">
        <router-link
          :to="`/pickup-point/merchant=${merchantID}/id=${store_id}`"
        >
          <code>{{ store_id | shortID(4) }}</code>
        </router-link>
      </div>
      <div slot="isActive" slot-scope="isActive, record">
        <a-button
          v-if="isActive"
          type="primary"
          @click="disablePickupPointHandler(record.store_id)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="enablePickupPointHandler(record.store_id)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
    </a-table>
    <!--    <template v-if="!dataSource">-->
    <!--      <a-divider>Сменить у всех контакты менеджера</a-divider>-->
    <!--      <a-button block @click="isModalVisible = true"> Сменить </a-button>-->
    <!--    </template>-->
    <a-divider>Создание сервисной точки</a-divider>
    <a-button type="primary" block @click="goToCreate()"> Создать</a-button>

    <a-modal
      v-model="isModalVisible"
      title="Сменить у всех контакты менеджера"
      cancel-text="Отмена"
      @ok="handleOk"
    >
      <a-form-model ref="form" :rules="contactRules" :model="contactState">
        <a-form-model-item prop="info" label="Имя контакта">
          <a-input v-model="contactState.info" />
        </a-form-model-item>
        <a-form-model-item prop="phone" label="Номер контакта">
          <a-input v-model="contactState.phone" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { columnNames, contactState, contactRules } from './constants'

export default {
  name: 'PickupPointList',
  data: () => ({
    columnNames,
    contactState,
    contactRules,
    isLoading: false,
    isModalVisible: false,
    dataSource: [],
  }),
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  methods: {
    initialize() {
      try {
        this.isLoading = true
        this.getPartnerList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getPartnerList() {
      this.dataSource = await this.$API.MerchantProfile.getPickupPointList(
        this.merchantID,
      )
    },
    async disablePickupPointHandler(id) {
      try {
        this.isLoading = true
        await this.$API.MerchantProfile.disablePickupPoint(this.merchantID, id)
        showSuccessMsg('Точка успешно деактивирована!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        await this.getPartnerList()
        this.isLoading = false
      }
    },
    async enablePickupPointHandler(id) {
      try {
        this.isLoading = true
        await this.$API.MerchantProfile.enablePickupPoint(this.merchantID, id)
        showSuccessMsg('Точка успешно активирована!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        await this.getPartnerList()
        this.isLoading = false
      }
    },
    goBackToMerchantInfo() {
      this.$router.push({
        name: 'MerchantInfo',
        params: { id: this.merchantID },
      })
    },
    goToCreate() {
      this.$router.push({
        name: 'PickupPointCreate',
        params: { id: this.merchantID },
      })
    },
    handleOk() {
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.$API.MerchantProfile.updateAllPickupPointContacts(
          this.merchantID,
          this.contactState,
        )
        this.isModalVisible = false
        showSuccessMsg('Удачно обновлен!')
        alert('ASD')
        this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
