var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список шаблонов")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:({
        service: 'jam',
        permissions: ['template-create'],
      }),expression:"{\n        service: 'jam',\n        permissions: ['template-create'],\n      }"}],attrs:{"to":{ name: 'MailingTemplateCreate' }}},[_c('a-button',[_vm._v("Создать новый шаблон")])],1)],1),_c('div',{staticClass:"filter__form"},[_c('a-form-model',{ref:"form",attrs:{"layout":"inline","model":_vm.params},on:{"submit":_vm.searchHandler}},[_c('a-form-model-item',{attrs:{"label":"Канал доставки"}},[_c('a-select',{staticStyle:{"min-width":"220px"},attrs:{"placeholder":"Выберите канал доставки"},model:{value:(_vm.params.channel),callback:function ($$v) {_vm.$set(_vm.params, "channel", $$v)},expression:"params.channel"}},_vm._l((_vm.channelTypes),function(type){return _c('a-select-option',{key:type.id,attrs:{"value":type.id}},[_vm._v(" "+_vm._s(type.title)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"label":"Получатель"}},[_c('a-select',{staticStyle:{"min-width":"220px"},attrs:{"placeholder":"Выберите получателя"},model:{value:(_vm.params.receiver),callback:function ($$v) {_vm.$set(_vm.params, "receiver", $$v)},expression:"params.receiver"}},_vm._l((_vm.receiverTypes),function(type){return _c('a-select-option',{key:type.id,attrs:{"value":type.id}},[_vm._v(" "+_vm._s(type.title)+" ")])}),1)],1),_c('a-form-item',[_c('a-button-group',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Поиск")]),_c('a-button',{attrs:{"type":"dashed"},on:{"click":_vm.dropFilter}},[_vm._v(" сбросить")])],1)],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.templateColumns,"data-source":_vm.templates,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var id = ref.id;

            return id;
},"pagination":_vm.pagination,"loading":_vm.isLoading,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"readable_id",fn:function(readable_id, template){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'MailingTemplateEdit',
          params: { template_id: template.id },
        }}},[_vm._v(" "+_vm._s(readable_id)+" ")])],1)}},{key:"created_at",fn:function(created_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at && created_at))+" ")])}},{key:"receiver",fn:function(receiver){return _c('div',{},[_vm._v(" "+_vm._s(_vm.getValue('receiverTypes', receiver))+" ")])}},{key:"channel",fn:function(channel){return _c('div',{},[_vm._v(" "+_vm._s(_vm.getValue('channelTypes', channel))+" ")])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }