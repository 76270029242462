import { format } from 'Core/modules/helpers'

export const PHONE = function (rule, value, callback, source, options) {
  if (value.replace(/\D/g, '').length === 11 || value === '') {
    return true
  } else {
    return callback(
      format(options.messages[rule.validator.name], rule.fullField),
    )
  }
}

//
