export const installmentForm = {
  background_color: null,
  min_product_sum: 0,
  max_product_price: 100,
  categories: ['akkumulyatornye-batarei', 'termopot'],
  commission: 0,
  duration: null,
  end_at: null,
  start_at: null,
  text_color: null,
  title: { KZ: null, RU: null },
}
export const installmentFormRules = {
  'title.RU': {
    type: 'string',
    fullField: 'Заголовок на русском',
    required: true,
    max: 50,
  },
  'title.KZ': {
    type: 'string',
    fullField: 'Заголовок на казахском',
    required: true,
    max: 50,
  },
  categories: {
    type: 'array',
    fullField: 'Зона действия рассрочки',
    required: true,
    max: 50,
  },
  commission: [
    {
      type: 'number',
      fullField: 'Комиссионные',
      required: true,
      max: 100,
    },
  ],
  duration: {
    // type: 'number',
    fullField: 'Продолжительность',
    required: true,
    // min: 3,
    // max: 99,
  },
  min_product_sum: {
    type: 'number',
    fullField: 'Мин. сумма товара',
    required: true,
    min: 0,
    max: 99999999,
  },
  max_product_price: {
    type: 'number',
    fullField: 'Макс. сумма товара',
    required: true,
    min: 100,
    max: 99999999,
  },
  end_at: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  background_color: [
    {
      type: 'string',
      message: 'Выберите цвет',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
  text_color: [
    {
      type: 'string',
      message: 'Выберите цвет',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
}
