import { render, staticRenderFns } from "./NestedMenuCreate.vue?vue&type=template&id=01823210&scoped=true&"
import script from "./NestedMenuCreate.vue?vue&type=script&lang=js&"
export * from "./NestedMenuCreate.vue?vue&type=script&lang=js&"
import style0 from "./NestedMenuCreate.vue?vue&type=style&index=0&id=01823210&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01823210",
  null
  
)

export default component.exports