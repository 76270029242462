<template>
  <a-descriptions title="Информация об инициаторе" layout="vertical">
    <a-descriptions-item label="Наименование контрагента">
      {{
        profileInfo && profileInfo.company_info.company_name
          ? profileInfo.company_info.company_name
          : '---'
      }}
    </a-descriptions-item>
    <a-descriptions-item label="БИН контрагента">
      {{
        profileInfo && profileInfo.company_info.payment_requisites.bin
          ? profileInfo.company_info.payment_requisites.bin
          : '---'
      }}
    </a-descriptions-item>
    <a-descriptions-item label="Номер счета в банке">
      {{
        profileInfo &&
        profileInfo.company_info.payment_requisites.account_bank_number
          ? profileInfo.company_info.payment_requisites.account_bank_number
          : '---'
      }}
    </a-descriptions-item>
    <a-descriptions-item label="БИК">
      {{
        profileInfo && profileInfo.company_info.payment_requisites.bik
          ? profileInfo.company_info.payment_requisites.bik
          : '---'
      }}
    </a-descriptions-item>
    <a-descriptions-item label="КБЕ">
      {{
        profileInfo && profileInfo.company_info.payment_requisites.kbe
          ? profileInfo.company_info.payment_requisites.kbe
          : '---'
      }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
export default {
  name: 'ProfileDescription',
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
