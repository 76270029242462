<template>
  <div>
    <schedule-day
      v-for="day in companyInfo.schedule"
      :key="day.weekday"
      :day-prop="day"
      class="schedule__day"
      @scheduleChanged="onScheduleChanged(day.weekday, $event)"
    />
    <a-button
      type="primary"
      class="schedule__button"
      @click="updateCompnayInfo"
      :loading="isUpdateLoading"
    >
      Сохранить
    </a-button>
  </div>
</template>

<script>
import ScheduleDay from 'Core/components/Selector/WorkHoursRangeSelector.vue'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'PartnerScheduleInfo',
  components: {
    ScheduleDay,
  },
  props: {
    companyInfoProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      companyInfo: null,
      isUpdateLoading: false,
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  watch: {
    companyInfoProp: {
      handler(newValue) {
        this.companyInfo = newValue
      },
      immediate: true,
    },
  },
  methods: {
    onScheduleChanged(weekday, newSchedule) {
      this.companyInfo.schedule = this.companyInfo.schedule.map((day) =>
        day.weekday == weekday ? newSchedule : day,
      )
    },
    async updateCompnayInfo() {
      try {
        this.isUpdateLoading = true
        const scheduleObj = {
          schedule: this.companyInfo.schedule,
        }
        await this.$API.MerchantProfile.updateProfile(
          this.merchantID,
          scheduleObj,
        )
        showSuccessMsg('Удачно обновлен!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isUpdateLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.schedule {
  &__day {
    border-bottom: 1px solid #d2d2ec;
    padding-top: 13px;
  }

  &__button {
    margin-top: 30px;
  }
}
</style>
