import { notification } from 'ant-design-vue'

export const showErrorMsg = (msg) => {
  notification.error({
    message: 'Ошибка',
    description: msg,
  })
}

export const showSuccessMsg = (msg) => {
  notification.success({
    message: 'Успех',
    description: msg,
  })
}

export const showWarningMsg = (msg) => {
  notification.warning({
    message: 'Внимание',
    description: msg,
  })
}
