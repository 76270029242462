export const templateColumns = [
  {
    title: 'ID',
    dataIndex: 'readable_id',
    key: 'readable_id',
    width: '250px',
    scopedSlots: { customRender: 'readable_id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    width: '250px',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Тема письма',
    dataIndex: 'subject',
    key: 'subject',
    width: '250px',
    scopedSlots: { customRender: 'subject' },
  },
  {
    title: 'Получатель',
    dataIndex: 'receiver',
    key: 'receiver',
    width: '250px',
    scopedSlots: { customRender: 'receiver' },
  },
  {
    title: 'Тип канала',
    dataIndex: 'channel',
    key: 'channel',
    width: '250px',
    scopedSlots: { customRender: 'channel' },
  },
  {
    title: 'Дата создание',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '250px',
    scopedSlots: { customRender: 'created_at' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]
