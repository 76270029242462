import PartnersList from 'Partner/pages/Partner/PartnerList/PartnersList'
import PartnerInfo from 'Partner/pages/Partner/PartnerInfo/PartnerInfo'
import PartnerOrderInfo from 'Partner/pages/Partner/PartnerOrderInfo/PartnerOrderInfo'

import PartnerCreate from 'Partner/pages/Partner/PartnerCreate/PartnerCreate'
import PickupPointInfo from 'Partner/pages/PickupPoint/PickupPointInfo/PickupPointInfo'
import PickupPointCreate from 'Partner/pages/PickupPoint/PickupPointCreate/PickupPointCreate'
import PartnerProductBind from 'Partner/pages/MerchantProducts/MerchantProductBind/PartnerProductBind'

export default [
  {
    path: '/partners',
    name: 'MerchantList',
    component: PartnersList,
    meta: {
      permission: {
        service: 'merchant-profile',
        permissions: ['merchant-view'],
      },
      search: {
        name: 'Партнеры',
        icon: 'team',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/partner/create',
    name: 'PartnerCreate',
    component: PartnerCreate,
    meta: {
      permission: {
        service: 'merchant-profile',
        permissions: ['merchant-create'],
      },
      search: {
        name: 'Создать партнера',
        icon: 'user-add',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/partner/:id',
    name: 'PartnerInfo',
    component: PartnerInfo,
    meta: {
      permission: {
        service: 'merchant-profile',
        permissions: ['merchant-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/partner/:id/order/:orderId',
    name: 'PartnerOrderInfo',
    component: PartnerOrderInfo,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['merchant-product-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/pickup-point/merchant=:merchantID/id=:id',
    name: 'PickupPointInfo',
    component: PickupPointInfo,
    meta: {
      permission: {
        service: 'merchant-profile',
        //permissions: ['pickup-point-update'],
        permissions: ['merchant-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/pickup-point/create/id=:id',
    name: 'PickupPointCreate',
    component: PickupPointCreate,
    meta: {
      permission: {
        service: 'merchant-profile',
        //permissions: ['pickup-point-create'],
        permissions: ['merchant-view'],
      },
      failPath: '/censor',
    },
  },
  // {
  //   path: '/merchant-products/:merchant_id/bind',
  //   name: 'MerchantProductBind',
  //   component: MerchantProductBind,
  //   meta: {
  //     permission: {
  //       service: 'storefront',
  //       permissions: ['merchant-product-update'],
  //     },
  //     failPath: '/censor',
  //   },
  // },
  {
    path: '/merchant-products/:merchant_id/bind',
    name: 'PartnerProductBind',
    component: PartnerProductBind,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['merchant-product-update'],
      },
      failPath: '/censor',
    },
  },
]
