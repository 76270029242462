export const MASK = {
  guid: {
    mask: 'NNNNNNNN-NNNN-NNNN-NNNN-NNNNNNNNNNNN',
    tokens: { N: { pattern: /[0-9a-fA-F]/ } },
  },
  phone: '+7 ### ### ## ##',
  onlyLatin: {
    mask: 'Z*',
    tokens: { Z: { pattern: /[A-Za-z]/ } },
  },
  onlyCyrillic: {
    mask: 'Z*',
    tokens: { Z: { pattern: /[ЁёА-я]/ } },
  },
}
export const PaymentTypes = {
  card_online: {
    value: 'card_online',
    label: 'Картой онлайн',
    icon: 'credit-card',
  },
  card_offline: {
    value: 'card_offline',
    label: 'Картой офлайн',
    icon: 'container',
  },
  credit: {
    value: 'credit',
    label: 'В кредит',
    icon: 'audit',
  },
  installment: {
    value: 'installment',
    label: 'В рассрочку',
    icon: 'profile',
  },
}

export const weekDays = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
}

export const returnStatus = {
  created: {
    value: 'created',
    label: 'Ожидает возврата',
    icon: '',
    color: 'orange',
  },
  cancelled: {
    value: 'cancelled',
    label: 'Отменен по инициативе клиента',
    icon: '',
    color: 'pink',
  },
  finished: {
    value: 'finished',
    label: 'Возврат выполнен',
    icon: '',
    color: 'green',
  },
  rejected_by_support: {
    value: 'rejected_by_support',
    label: 'Возврат отклонен СП',
    icon: '',
    color: 'purple',
  },
  rejected_by_merchant: {
    value: 'rejected_by_merchant',
    label: 'Возврат отклонен Мерчантом',
    icon: '',
    color: 'blue',
  },
}

export const returnType = ['full', 'partial']
