import Currency from './currency'
import ShortID from './shortID'
import FormatDate from './formatDate'
import FIO from './fio'
import phoneFormat from './phoneNumber'

export default {
  ...Currency,
  ...ShortID,
  ...FormatDate,
  ...FIO,
  ...phoneFormat,
}
