<script>
import { FormModel } from 'ant-design-vue'
import { RU_LOCALE } from 'Core/modules/validator'

export default {
  extends: FormModel,
  props: {
    messages: {
      type: Object,
    },
  },
  data: () => ({ defaultMessages: RU_LOCALE }),
  beforeCreate() {
    this.$delete(this.$options.props, 'validateMessages')
  },
  computed: {
    validateMessages() {
      return this.messages || this.defaultMessages
    },
  },
}
</script>

<style></style>
