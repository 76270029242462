import { mapStateToFSM } from 'Core/modules/helpers'

export default {
  namespaced: true,
  state: {
    platforms: null,
    isLoading: false,
  },
  mutations: {
    SET_PLATFORMS(state, platforms) {
      state.platforms = platforms
    },
    SET_PLATFORMS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async fetchPlatforms(context) {
      let data = null
      try {
        context.commit('SET_PLATFORMS_LOADING', true)
        data = await this._vm.$API.ConfigDiscovery.getPlatformList()
      } finally {
        context.commit('SET_PLATFORMS_LOADING', false)
        context.commit('SET_PLATFORMS', data)
      }
    },
  },
  getters: {
    /** платформы в ФСМ формате. Будут использованы позже */
    platformsFSM: (state) =>
      mapStateToFSM(state.platforms, {
        label: 'name',
        value: 'id',
        variant: 'name',
      }),
    platforms: (state) => state.platforms,
    isLoading: (state) => state.isLoading,
  },
}
