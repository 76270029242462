var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{staticClass:"navigation",attrs:{"theme":"light","trigger":null,"collapsible":""},model:{value:(
    /* eslint-disable-next-line vue/no-mutating-props */
    _vm.isCollapsed
  ),callback:function ($$v) {
    /* eslint-disable-next-line vue/no-mutating-props */
    _vm.isCollapsed
  =$$v},expression:"\n    /* eslint-disable-next-line vue/no-mutating-props */\n    isCollapsed\n  "}},[_c('div',{staticClass:"navigation__logo"},[_c('img',{attrs:{"src":"images/logo.svg"}})]),_c('a-menu',{attrs:{"mode":"vertical","default-selected-keys":['Роли']}},[_vm._l((_vm.routes),function(ref){
  var name = ref.name;
  var path = ref.path;
  var icon = ref.icon;
  var subMenu = ref.subMenu;
return [(!subMenu)?_c('a-menu-item',{key:name},[_c('router-link',{attrs:{"to":path}},[_c('a-icon',{attrs:{"type":icon}}),_c('span',[_vm._v(_vm._s(name))])],1)],1):_c('a-sub-menu',{key:name},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{attrs:{"type":icon}}),_c('span',[_vm._v(_vm._s(name))])],1),_vm._l((subMenu),function(ref){
  var name = ref.name;
  var path = ref.path;
  var icon = ref.icon;
return _c('a-menu-item',{key:name},[_c('router-link',{attrs:{"to":path}},[_c('a-icon',{attrs:{"type":icon}}),_c('span',[_vm._v(_vm._s(name))])],1)],1)})],2)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }