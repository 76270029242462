<template>
  <error-boundary>
    <requester :is-inner-page="isInnerPage">
      <a-layout id="app" class="app">
        <navigation :is-collapsed="isCollapsed" v-if="isInnerPage" />
        <a-layout>
          <app-header :on-trigger-click="onTriggerClick" v-if="isInnerPage" />
          <a-layout
            :class="{
              'app__content-wrapper': true,
              '--is-login-page': !isInnerPage,
            }"
          >
            <a-layout-content class="app__content">
              <router-view />
              <fast-nav-modal></fast-nav-modal>
              <a-back-top />
            </a-layout-content>
          </a-layout>
        </a-layout>
      </a-layout>
    </requester>
  </error-boundary>
</template>

<script>
import {
  AppHeader,
  Navigation,
  ErrorBoundary,
  Requester,
} from 'Core/components/layout'
import FastNavModal from 'Core/components/FastNavModal/FastNavModal'
import ru_RU from 'ant-design-vue/es/locale/ru_RU'

export default {
  name: 'App',
  components: {
    Navigation,
    AppHeader,
    ErrorBoundary,
    Requester,
    FastNavModal,
  },
  data() {
    return {
      isCollapsed: false,
      ru_RU,
    }
  },
  methods: {
    onTriggerClick: function () {
      this.isCollapsed = !this.isCollapsed
    },
  },
  computed: {
    isInnerPage() {
      return this.$route.name !== 'Login'
    },
  },
}
</script>

<style lang="scss">
@import './assets/styles/main';

.ant-back-top {
  right: 5px !important;
  bottom: 20px !important;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: visible !important;
}
</style>
