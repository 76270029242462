<template>
  <div>
    <div class="settings-wrap">
      <h2>Список способов оплаты</h2>
      <router-link
        :to="{ name: 'ConfigPaymentCreate' }"
        v-can="{
          service: 'banner',
          permissions: ['banner-create'],
        }"
      >
        <a-button>Создать</a-button>
      </router-link>
    </div>
    <a-table
      :columns="paymentTypesColumns"
      :data-source="paymentTypes"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      bordered
    >
      <div slot="payment_id" slot-scope="payment_id">
        <router-link
          :to="{
            name: 'ConfigPaymentEdit',
            params: { payment_id },
          }"
        >
          {{ payment_id | shortID(5) }}
        </router-link>
      </div>
      <div slot="payment_icon" slot-scope="payment_icon">
        <img
          class="img-prev"
          :src="payment_icon"
          width="170"
          v-if="payment_icon"
        />
        <p v-else>Иконка не указана</p>
      </div>
      <div slot="payment_status" slot-scope="payment_status, record, index">
        <a-button
          v-if="payment_status"
          type="primary"
          @click="statusToggleHandler(record, payment_status, index)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="statusToggleHandler(record, payment_status, index)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="payment_weight" slot-scope="weight, paymentType">
        <a-input-number
          :id="paymentType.id"
          :value="weight"
          :min="0"
          @change="(value) => onPriorityChange(value, paymentType)"
        />
        <a-button
          type="primary"
          @click="confirmPriorityChange(paymentType)"
          class="form__button"
        >
          <a-icon type="save" />
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { paymentTypesColumns } from './constants'

export default {
  data() {
    return {
      paymentTypesColumns,
    }
  },
  computed: {
    paymentTypes() {
      return this.$store.getters['config/payments/payments']
    },
    isLoading() {
      return this.$store.getters['config/payments/isLoading']
    },
  },
  methods: {
    onPriorityChange(value, payment) {
      if (value && typeof value === 'number') {
        this.paymentTypes.map((item) => {
          if (item.id == payment.id) payment.weight = value
          return payment
        })
      }
    },
    async confirmPriorityChange(contact) {
      try {
        await this.$API.ConfigDiscovery.updatePayment(contact.id, contact)
        await this.getPaymentTypes()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async statusToggleHandler(record, isActive, index) {
      try {
        const payload = Object.assign(record, { is_active: !isActive })
        await this.$API.ConfigDiscovery.updatePayment(record.id, payload)
        showSuccessMsg('Удачно обновлен!')
        this.paymentTypes[index].is_active = !isActive
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__button {
    margin-left: 10px;
  }
}
</style>
