<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование брокера-партнера</h2>
      <router-link :to="{ name: 'ConfigBrokerPartnersList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isLoading">
      <a-form-model
        ref="form"
        :model="brokerForm"
        :rules="brokerPartnersFormRules"
        @submit="createBrokerConfig"
      >
        <a-form-model-item prop="title" label="Название">
          <a-input v-model="brokerForm.title" />
        </a-form-model-item>
        <a-form-model-item prop="icon_url" label="Иконка брокера-партнера">
          <upload-files
            @getImageParams="getImageParams"
            :img-url="brokerForm.icon_url"
            placeholder-text="Загрузить иконку"
          />
        </a-form-model-item>
        <a-form-model-item prop="partner_code" label="Код партнера">
          <a-input v-model="brokerForm.partner_code" />
        </a-form-model-item>

        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          v-can="{
            service: 'banner',
            permissions: ['banner-update'],
          }"
          >Сохранить
        </a-button>
        <a-button
          type="danger"
          class="form__button form__button--danger ml--1"
          @click="deleteBrokerConfig"
          v-can="{
            service: 'banner',
            permissions: ['banner-delete'],
          }"
          >Удалить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { brokerPartnersFormRules } from 'Reference/pages/ConfigBrokerPartners/ConfigBrokerPartnersCreate/constants'

export default {
  components: { UploadFiles },
  data() {
    return {
      brokerPartnersFormRules,
      isLoading: false,
      brokerForm: {
        icon_url: null,
        partner_code: null,
        title: null,
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  methods: {
    async getBrokerById() {
      try {
        this.isLoading = true
        this.brokerForm = await this.$API.ConfigDiscovery.getBrokerById(this.id)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async createBrokerConfig(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            await this.$API.ConfigDiscovery.updateBroker(
              this.id,
              this.brokerForm,
            )
            await this.$store.dispatch('config/brokers/fetchBrokers')
            showSuccessMsg('Успешно обновлен брокер')
            this.$router.push({ name: 'ConfigBrokerPartnersList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async deleteBrokerConfig() {
      try {
        this.isLoading = true
        await this.$API.ConfigDiscovery.deleteBroker(this.id)
        await this.$store.dispatch('config/brokers/fetchBrokers')
        showSuccessMsg('Успешно удален брокер')
        this.$router.push({ name: 'ConfigBrokerPartnersList' })
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    getImageParams(params) {
      this.brokerForm.icon_url = params
    },
  },
  mounted() {
    this.getBrokerById()
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;
}

.color__preview {
  width: 15px;
  height: 15px;
  border: 1px solid black;
}
</style>
