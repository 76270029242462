

















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  columnNames,
  REVIEW_STATUSES,
  REVIEW_SEARCHABLE_FIELDS,
  REVIEW_RATINGS,
} from 'Content/pages/Reviews/ProductReviews/ProductReviewList/constants'
import {
  IProductReview,
  IProductReviewParams,
} from 'Core/modules/API/@types/Feedback.type'
import { showErrorMsg } from 'Core/utils'
import StatusView from 'Core/components/StatusView/StatusView.vue'
import { MODERATION_STATUSES } from 'Core/types/review'

@Component({ components: { StatusView } })
class ProductReviewList extends Vue {
  REVIEW_STATUSES = REVIEW_STATUSES
  REVIEW_SEARCHABLE_FIELDS = REVIEW_SEARCHABLE_FIELDS
  MODERATION_STATUSES = MODERATION_STATUSES
  REVIEW_RATINGS = REVIEW_RATINGS

  columnNames = columnNames
  reviews: IProductReview[] = []
  isLoading: boolean = false
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 10,
  }

  sort: any = {
    field: null,
    order: null,
  }

  filter = {
    status: null,
    search: null,
    field: REVIEW_SEARCHABLE_FIELDS.merchant_name.value,
  }

  @Watch('filter.field')
  onFilterFieldChange() {
    this.filter.search = null
  }

  get filterPayload(): IProductReviewParams {
    const { field, search } = this.filter

    return {
      status: this.filter.status,
      page: this.pagination.current,
      limit: this.pagination.defaultPageSize,
      [field]: search || null,
      sort_key: this.sort.field,
      sort_val: this.formatSortOrder(this.sort.order),
    }
  }

  async getReviewList() {
    try {
      this.isLoading = true
      const feedback = await this.$API.Feedback.getProductReviews(
        this.filterPayload,
      )
      this.reviews = feedback.reviews
      this.pagination.total = feedback.total
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  onPageChange({ current }, _, { order, field }) {
    if (field != this.sort.field || order != this.sort.order) {
      this.pagination.current = 1
    } else this.pagination.current = current

    this.sort.field = order ? field : null
    this.sort.order = order

    this.getReviewList()
  }

  onSearch() {
    this.pagination.current = 1
    return this.getReviewList()
  }

  dropFilter() {
    this.filter = {
      status: null,
      search: null,
      field: REVIEW_SEARCHABLE_FIELDS.merchant_name.value,
    }
    this.pagination.current = 1
    this.getReviewList()
  }

  formatSortOrder(order?: string) {
    switch (order) {
      case 'descend':
        return -1
      case 'ascend':
        return 1
      default:
        return null
    }
  }

  mounted() {
    this.getReviewList()
  }
}

export default ProductReviewList
