<template>
  <div class="product">
    <div class="product-img">
      <img :src="product.image" :alt="product.image" />
    </div>
    <div class="product-info">
      <p class="product-info__title">
        <a :href="`${showcaseUrl}product/${product.sku}`" target="_blank">{{
          product.title.ru
        }}</a>
        <span> (sku: {{ product.sku }})</span>
      </p>
      <div class="product-info__color">
        <div>{{ product.color.title.ru }}</div>
        <div
          class="product-info__color-box"
          :style="{ backgroundColor: product.color.hex }"
        ></div>
      </div>
    </div>
    <div
      v-can="{
        service: 'storefront',
        permissions: ['group-update'],
      }"
      class="product-close"
      @click="deleteProductHanlder"
    >
      <a-icon type="close" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductItem',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
  }),
  methods: {
    deleteProductHanlder() {
      this.$emit('deleteProduct', this.product.sku)
    },
  },
}
</script>

<style scoped lang="scss">
.product {
  background-color: #f0f2f5;
  width: 160px;
  padding: 10px;
  margin: 10px;
  border-radius: 2px;
  border: 1px solid #c9c9c9;
  position: relative;

  &-img img {
    width: 140px;
  }

  &-info {
    padding: 7px 0;
    line-height: 1.2;

    &__title {
      font-size: 14px;

      & a {
        font-weight: bold;
      }
    }

    &__color {
      display: flex;
      align-items: center;

      &-box {
        height: 15px;
        width: 15px;
        border-radius: 100%;
        margin-left: 5px;
      }
    }
  }

  &-close {
    position: absolute;
    top: -5px;
    color: red;
    cursor: pointer;
    right: 5px;
  }
}
</style>
