<template>
  <div v-if="day">
    <div class="flex-box">
      <h3>{{ weekDays[day.weekday] }}</h3>
      <a-button
        size="small"
        class="ml--1"
        @click="addTimeRange"
        v-if="
          isMultiple ? true : !day.time_ranges || day.time_ranges.length < 1
        "
        >Добавить диапозон работы
      </a-button>
    </div>
    <div class="mb-24">
      <a-input-group
        size="large"
        v-if="day.time_ranges && day.time_ranges.length"
      >
        <div
          class="time__wrapper"
          v-for="(time, index) in day.time_ranges"
          :key="index"
        >
          <a-form-item :prop="`day.time_ranges[${index}].from`" label="Начало">
            <a-time-picker
              :value="moment(time.from, 'HH:mm')"
              format="HH:mm"
              :allow-clear="false"
              @change="
                (time, timeString) =>
                  onTimepickerChanged('from', index, timeString)
              "
            />
          </a-form-item>
          <a-form-item :prop="`day.time_ranges[${index}].to`" label="Конец">
            <a-time-picker
              :value="moment(time.to, 'HH:mm')"
              :allow-clear="false"
              @change="
                (time, timeString) =>
                  onTimepickerChanged('to', index, timeString)
              "
              format="HH:mm"
            />
          </a-form-item>
          <a-button type="danger" @click="deleteTimeRange(index)">
            <a-icon type="delete"></a-icon>
          </a-button>
        </div>
      </a-input-group>
      <h3 v-else class="day--red">Выходной день</h3>
    </div>
  </div>
</template>

<script>
import { weekDays } from 'Core/constants'
import moment from 'moment'

export default {
  name: 'WorkHoursRangeSelector',
  props: {
    dayProp: {
      type: Object,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      weekDays,
      day: null,
    }
  },
  watch: {
    dayProp: {
      handler(newValue) {
        this.day = newValue
      },
      immediate: true,
    },
  },
  methods: {
    moment,
    addTimeRange() {
      if (this.day.time_ranges && this.day.time_ranges.length) {
        this.day.time_ranges.push({
          from: this.day.time_ranges[this.day.time_ranges.length - 1].to,
          to: '18:00',
        })
      } else {
        this.day.time_ranges = []
        this.day.time_ranges.push({ from: '09:00', to: '18:00' })
      }
      this.$emit('scheduleChanged', this.day)
    },
    deleteTimeRange(idx) {
      this.day.time_ranges.splice(idx, 1)
      this.$emit('scheduleChanged', this.day)
    },
    onTimepickerChanged(timeType, idx, timeString) {
      this.day.time_ranges[idx][timeType] = timeString
      this.$emit('scheduleChanged', this.day)
    },
  },
}
</script>

<style lang="scss" scoped>
.day {
  &--red {
    margin-top: 20px;
    color: red;
    font-weight: bold;
  }
}

.flex-box {
  display: flex;

  & label {
    margin-left: 17px;
  }
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-row {
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
}

.ant-form-item {
  display: flex;
  align-items: center;
}

::v-deep {
  .ant-form-item-control,
  .ant-form-item-label {
    line-height: initial !important;
  }
}

.time {
  &__wrapper {
    display: grid;
    grid-template-columns: 192px 200px 50px;
    gap: 20px;
    align-items: end;
  }
}
</style>
