















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {
  Coordinate,
  MAP_RANGES,
  YandexMapWidgets,
} from 'Core/components/Maps/@types/yandexMap.type'

@Component({ components: { yandexMap, ymapMarker } })
class YandexMap extends Vue {
  @Prop()
  lat: string
  @Prop()
  long: string
  /** Через этот атрибут можно прокинуть свой css класс для карт, иначе применится дефолтный класс */
  @Prop({
    type: String,
    default: null,
  })
  customClass: string
  @Prop({
    type: Number,
    default: 12,
  })
  zoom: number

  settings = {
    apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
  }
  localCoords: Coordinate = []

  get defaultCoords(): Coordinate {
    return [43.23, 76.89] // almaty
  }

  get parentCoords(): Coordinate {
    if (!this.lat || !this.long) return []
    return [this.parseFloatSafe(this.lat), this.parseFloatSafe(this.long)]
  }

  get widgets(): YandexMapWidgets[] {
    return [YandexMapWidgets.FULLSCREEN, YandexMapWidgets.GEOLOCATION]
  }

  @Watch('localCoords')
  onLocalCoordsChange(coords: Coordinate) {
    if (!coords?.length) return
    this.$emit('input', coords)
    this.$emit('onCoordsChange', {
      lat: coords[0]?.toFixed(6),
      long: coords[1]?.toFixed(6),
    })
  }

  @Watch('parentCoords')
  onParentCoordsChange(coords: Coordinate) {
    if (!coords.length) return
    // если координаты некорректные, откатываемся к дефолтным
    if (!this.isCoordinateValid(coords)) {
      this.localCoords = this.defaultCoords
      return
    }
    this.localCoords = this.parentCoords
  }

  onClick(e) {
    this.localCoords = e.get('coords')
  }

  isCoordinateValid(coords: Coordinate) {
    if (!coords.length) return false
    if (coords[0] > MAP_RANGES.MAX_LAT || coords[0] < MAP_RANGES.MIN_LAT)
      return false
    if (coords[1] > MAP_RANGES.MAX_LONG || coords[1] < MAP_RANGES.MIN_LONG)
      return false
    return true
  }

  parseFloatSafe(floatString): number {
    if (typeof floatString == 'number') return floatString
    return parseFloat(floatString.replace(',', '.').replace(' ', ''))
  }
}

export default YandexMap
