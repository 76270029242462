<template>
  <div>
    <a-spin :spinning="isFetching">
      <div class="settings-wrap">
        <h2>Редактирование QR</h2>
        <a-button @click="() => $router.push({ name: 'QRcodeList' })">
          {{ $t('common.back') }}
        </a-button>
      </div>
      <a-row type="flex" justify="center">
        <a-col span="16">
          <a-form-model
            ref="form"
            :model="formState"
            :rules="rules"
            @submit="onValidate"
          >
            <a-form-model-item label="ID">
              <a-input v-model="formState._id" disabled />
            </a-form-model-item>
            <a-form-model-item prop="abbrev" label="Наименование (Слаг)">
              <a-input v-model="formState.abbrev" @focusout="toUppercase" />
            </a-form-model-item>

            <a-form-model-item prop="description" label="Описание">
              <a-input v-model="formState.description" />
            </a-form-model-item>

            <a-form-model-item prop="link" label="Ссылка">
              <a-input v-model="formState.link" />
            </a-form-model-item>
            <a-form-model-item>
              <img :src="`data:image/png;base64,${formState.data}`" />
            </a-form-model-item>

            <a-button type="primary" html-type="submit">Сохранить</a-button>
          </a-form-model>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from './constants'

export default {
  name: 'QRcodeEdit',
  data() {
    return {
      formState,
      rules,
      isFetching: true,
    }
  },
  computed: {
    QR_code_id() {
      return this.$route.params.id
    },
  },
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        await this.getQRcodeById()
        this.isFetching = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    toUppercase() {
      this.formState.abbrev = this.formState.abbrev.toUpperCase()
    },
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        this.isFetching = true
        await this.$refs.form.validate()
        await this.$API.QRCode.updateQRcodeById(this.QR_code_id, this.formState)
        await this.getQRcodeById()
        showSuccessMsg('Удачно обновлен!')
        this.isFetching = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getQRcodeById() {
      this.formState = await this.$API.QRCode.getQRcodeById(this.QR_code_id)
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
