<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование способа оплаты</h2>
      <router-link :to="{ name: 'ConfigPaymentList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isPaymentTypeLoading">
      <a-form-model
        v-if="paymentTypeForm"
        ref="form"
        :model="paymentTypeForm"
        :rules="paymentTypeFormRules"
        @submit="updatePaymentType"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="paymentTypeForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="icon_url" label="Иконка способа оплаты">
          <upload-files
            @getImageParams="getImageParams"
            :img-url="paymentTypeForm.icon_url"
            placeholder-text="Загрузить иконку"
          />
        </a-form-model-item>
        <a-form-model-item prop="partial_delivery" label="Частичная поставка">
          <a-radio-group v-model="paymentTypeForm.partial_delivery">
            <a-radio :value="true">Да</a-radio>
            <a-radio :value="false">Нет</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="dividable" label="Делимость">
          <a-radio-group v-model="paymentTypeForm.dividable">
            <a-radio :value="true">Делимый</a-radio>
            <a-radio :value="false">Неделимый</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="is_active" label="Статус">
          <a-checkbox v-model="paymentTypeForm.is_active">
            {{ paymentTypeForm.is_active ? 'Активный' : 'Неактивный' }}
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item prop="type" label="Тип оплаты">
          <a-select
            v-model="paymentTypeForm.type"
            placeholder="Выберите тип оплаты"
          >
            <a-select-option
              v-for="(type, index) in paymentTypes"
              :key="index"
              :value="type.value"
            >
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="weight" label="Приоритет в списке">
          <a-input-number v-model="paymentTypeForm.weight" :min="1" />
        </a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          :loading="isUpdateLoading"
          v-can="{
            service: 'banner',
            permissions: ['banner-update'],
          }"
          >Сохранить
        </a-button>
        <a-button
          type="danger"
          class="form__button form__button--danger"
          :loading="isDeleteLoading"
          @click="deletePaymentType"
          v-can="{
            service: 'banner',
            permissions: ['banner-delete'],
          }"
          >Удалить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { paymentTypeFormRules, paymentTypes } from './constants'

export default {
  components: {
    UploadFiles,
  },
  computed: {
    paymentID() {
      return this.$route.params.payment_id
    },
  },
  data() {
    return {
      paymentTypeFormRules,
      paymentTypes,
      isPaymentTypeLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
      paymentTypeForm: null,
    }
  },
  created() {
    this.getPaymentTypeById()
  },
  methods: {
    getImageParams(params) {
      this.paymentTypeForm.icon_url = params
    },
    async getPaymentTypeById() {
      try {
        this.isPaymentTypeLoading = true
        this.paymentTypeForm =
          await this.$API.ConfigDiscovery.getPaymentInfoById(this.paymentID)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isPaymentTypeLoading = false
      }
    },
    async updatePaymentType(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isUpdateLoading = true
            await this.$API.ConfigDiscovery.updatePayment(
              this.paymentID,
              this.paymentTypeForm,
            )
            showSuccessMsg('Тип оплаты успешно обновлен')
            await this.$router.push({ name: 'ConfigPaymentList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isUpdateLoading = true
          }
        }
      })
    },
    async deletePaymentType() {
      try {
        this.isDeleteLoading = true
        await this.$API.ConfigDiscovery.deletePayment(this.paymentID)
        showSuccessMsg('Тип оплаты успешно удален')
        await this.$router.push({ name: 'ConfigPaymentList' })
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isDeleteLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;

  &--danger {
    margin-left: 20px;
  }
}
</style>
