<template>
  <div>
    <div class="settings-wrap">
      <h2>Список документов</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'DocumentsCreate',
            })
        "
      >
        Создать новый
      </a-button>
    </div>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/document/${id}`">
          <code>{{ id | shortID(4) }}</code>
        </router-link>
      </div>
      <div slot="agreeTitle" slot-scope="agreeTitle, record">
        <h3>{{ agreeTitle }}</h3>
        <p class="info">{{ record.description }}</p>
      </div>
      <div slot="verificationRequired" slot-scope="verificationRequired">
        <a-tag class="tag-label" v-if="verificationRequired" color="green">
          <a-icon type="check-circle" />
          Да
        </a-tag>
        <a-tag class="tag-label" v-else color="darkorange">
          <a-icon type="stop" />
          Нет
        </a-tag>
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        {{ updatedAt | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { columnNames } from './constants'

export default {
  name: 'DocumentsList',
  data: () => ({
    dataSource: [],
    isLoading: false,
    columnNames,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      page: 1,
      limit: 10,
    },
  }),
  methods: {
    initialize() {
      try {
        this.isLoading = true
        this.getAgreeDocumentLinkList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getAgreeDocumentLinkList() {
      const { specifications, count } =
        await this.$API.Agreement.getAgreeDocumentsList(this.filter)
      this.dataSource = specifications
      this.pagination.total = count
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getAgreeDocumentLinkList()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped>
.tag-label {
  padding: 7px 16px;
  font-weight: bold;
}
</style>
