<template>
  <div>
    <a-tag
      v-for="(partner, index) in selectedPartners"
      :key="partner.id"
      @close="removeSelectedPartner(partner, index)"
      closable
    >
      {{ partner.company_name }}
    </a-tag>
    <a-button @click="isVisible = true">
      <a-icon type="plus" />
      Добавить партнера
    </a-button>
    <a-drawer
      title="Выбрать партнера из списка"
      width="40%"
      :visible="isVisible"
      :closable="false"
    >
      <a-form-model
        layout="inline"
        @submit="searchPartners"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input
            v-model="params.company_name"
            placeholder="Поиск по названию компании"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button-group>
            <a-button type="primary" html-type="submit"> Поиск</a-button>
          </a-button-group>
        </a-form-model-item>
      </a-form-model>

      <div class="brand-list">
        <a-list
          class="merchant__list"
          :loading="isLoading"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          item-layout="horizontal"
          :data-source="partners"
          :pagination="{ ...pagination, onChange: onPageChange }"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a slot="actions" @click="onSelectPartner(item)">выбрать</a>
            <a-list-item-meta>
              <p slot="title">
                {{ item.company_info.ownership }}
                {{ item.company_info.company_name }}
              </p>
            </a-list-item-meta>
            <div>{{ item.company_info.payment_requisites.bin }}</div>
          </a-list-item>
        </a-list>
      </div>
      <div class="button-absolute-box">
        <a-button @click="onClose"> Отмена</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { showWarningMsg } from 'Core/utils'

export default {
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      partners: [],
      isLoading: false,
      isVisible: false,
      selectedPartners: [],
      pagination: {
        current: 1,
        total: 0,
        limit: 10,
      },
      params: {
        page: 1,
        limit: 10,
        company_name: null,
      },
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedPartners = newValue || []
      },
      immediate: true,
    },
  },
  methods: {
    async onPageChange(page) {
      this.params.page = this.pagination.current = page
      await this.searchPartners()
    },
    async searchPartners() {
      try {
        this.isLoading = true
        const { count, data } = await this.$API.MerchantProfile.getPartnerList(
          this.params,
        )
        this.pagination.total = count
        this.partners = data
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onClose() {
      this.isVisible = false
    },
    removeSelectedPartner(partner, idx) {
      this.selectedPartners.splice(idx, 1)
      this.$emit('input', this.selectedPartners)
    },
    onSelectPartner(partner) {
      const searchForExistanceResult = this.selectedPartners.find(
        (merchant) => partner.id == merchant.id,
      )
      if (searchForExistanceResult) {
        showWarningMsg(
          `Вы уже выбирали данного партнера "${partner.company_info.company_name}"`,
        )
        return
      }
      this.selectedPartners.push({
        id: partner.id,
        company_name: partner.company_info.company_name,
      })
      this.$emit('input', this.selectedPartners)
      this.isVisible = false
      this.resetAll()
    },
    resetAll() {
      this.params = {
        page: 1,
        limit: 10,
        company_name: null,
      }
      this.pagination = {
        current: 1,
        total: 0,
      }
      this.partners = []
    },
  },
}
</script>

<style lang="scss" scoped>
.merchant__list {
  margin-top: 30px;
}
</style>
