<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col span="16">
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-col>
            <h2>Создание сервисной точки</h2>
          </a-col>
          <a-col>
            <a-button @click="() => $router.go(-1)">
              {{ $t('common.back') }}
            </a-button>
          </a-col>
        </a-row>
        <a-form-model
          ref="form"
          :model="formState"
          :rules="createRules"
          @submit="createPickupPointHandler"
        >
          <a-divider>Основные</a-divider>
          <a-form-model-item prop="store_id" label="ID">
            <a-input v-model="formState.store_id" />
          </a-form-model-item>
          <a-form-model-item prop="name" label="Название">
            <a-input v-model="formState.name" />
          </a-form-model-item>
          <a-form-model-item prop="priority" label="Приоритет">
            <a-input-number v-model="formState.priority" :min="1" />
          </a-form-model-item>
          <a-form-model-item prop="contact.info" label="Имя контакта">
            <a-input v-model="formState.contact.info" />
          </a-form-model-item>
          <phone-input
            :label="'Номер контакта'"
            :prop="'contact.phone'"
            v-model="formState.contact.phone"
          />

          <a-divider>Адрес</a-divider>
          <a-form-model-item label="Город">
            <a-select v-model="formState.address.city_id">
              <a-select-option v-for="{ id, title } in cityList" :key="id">
                {{ title.ru }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="address.street" label="Улица">
            <a-input v-model="formState.address.street" />
          </a-form-model-item>
          <div class="grid-box">
            <a-form-model-item prop="address.house" label="Дом">
              <a-input v-model="formState.address.house" />
            </a-form-model-item>
            <a-form-model-item prop="address.apartment" label="Квартира">
              <a-input v-model="formState.address.apartment" />
            </a-form-model-item>
            <a-form-model-item prop="address.floor" label="Этаж">
              <a-input-number v-model="formState.address.floor" :min="0" />
            </a-form-model-item>
            <a-form-model-item prop="address.zipcode" label="Зипкод">
              <a-input v-model="formState.address.zipcode" />
            </a-form-model-item>
          </div>
          <div class="grid-box mb-24">
            <a-form-model-item prop="address.geo.latitude" label="Широта">
              <a-input v-model="formState.address.geo.latitude" />
            </a-form-model-item>
            <a-form-model-item prop="address.geo.longitude" label="Долгота">
              <a-input v-model="formState.address.geo.longitude" />
            </a-form-model-item>
          </div>
          <yandex-map
            :lat="formState.address.geo.latitude"
            :long="formState.address.geo.longitude"
            :zoom="
              formState.address.geo.latitude && formState.address.geo.longitude
                ? 15
                : 12
            "
            @onCoordsChange="onCoordsChange"
          />
          <a-divider>График работы</a-divider>
          <schedule-day
            v-for="day in formState.schedule"
            :key="day.weekday"
            :day-prop="day"
            :is-multiple="false"
            class="schedule__day"
            @scheduleChanged="onScheduleChanged(day.weekday, $event)"
          >
          </schedule-day>

          <a-form-model-item>
            <a-button type="primary" html-type="submit" class="mt--2">
              Создать
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { createRules } from 'Partner/pages/PickupPoint/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { MASK } from 'Core/constants'
import ScheduleDay from 'Core/components/Selector/WorkHoursRangeSelector.vue'
import YandexMap from 'Core/components/Maps/YandexMaps/YandexMap'

export default {
  name: 'PickupPointCreate',
  components: {
    ScheduleDay,
    YandexMap,
  },
  data: () => ({
    formState: {
      store_id: null,
      address: {
        apartment: null,
        city_id: '750000000', //Almaty
        floor: 0,
        geo: {
          latitude: '',
          longitude: '',
        },
        house: null,
        street: null,
        zipcode: null,
      },
      contact: {
        info: null,
        phone: null,
      },
      name: null,
      priority: 1,
      schedule: [
        { weekday: 1, time_ranges: [] },
        { weekday: 2, time_ranges: [] },
        { weekday: 3, time_ranges: [] },
        { weekday: 4, time_ranges: [] },
        { weekday: 5, time_ranges: [] },
        { weekday: 6, time_ranges: [] },
        { weekday: 7, time_ranges: [] },
      ],
    },
    createRules,
    MASK,
  }),
  methods: {
    async validateFields() {
      try {
        await this.createPickupPointHandler()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createPickupPointHandler(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            const { store_id } =
              await this.$API.MerchantProfile.createPickupPoint(
                this.merchantID,
                this.formState,
              )
            await this.$router.push(
              `/pickup-point/merchant=${this.merchantID}/id=${store_id}`,
            )
            showSuccessMsg('Сервисная точка успешно создана!')
            window.scrollTo(0, 0)
          } catch (error) {
            showErrorMsg(error.message)
          }
        }
      })
    },
    onScheduleChanged(weekday, newSchedule) {
      this.formState.schedule = this.formState.schedule.map((day) =>
        day.weekday == weekday ? newSchedule : day,
      )
    },
    onCoordsChange({ lat, long }) {
      this.formState.address.geo.latitude = lat
      this.formState.address.geo.longitude = long
    },
  },
  computed: {
    cityList() {
      return this.$store.getters['core/sortedCities']
    },
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>

<style scoped lang="scss">
.grid-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: baseline;
  gap: 20px;
}

::v-deep {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.flex-box {
  display: flex;
  align-items: center;

  & label {
    margin-left: 17px;
  }
}

.schedule {
  &__day {
    border-bottom: 1px solid #d2d2ec;
    padding-top: 13px;
  }
}
</style>
