export const PLATFORM_TYPES = {
  web: {
    value: 'web',
    label: 'WEB платформа',
    icon: 'chrome',
  },
  ios: {
    value: 'ios',
    label: 'IOS платформа',
    icon: 'apple',
  },
  android: {
    value: 'android',
    label: 'Andriod платформа',
    icon: 'android',
  },
}
