export const orderInfo = {
  id: null,
  status: null,
  total_price: null,
  dividable: false,
  products: [
    {
      sku: null,
      title: { kz: '', ru: '', en: '' },
      merchant_title: { kz: '', ru: '', en: '' },
      status: '',
      quantity: 1,
      price: '',
      total_price: '',
    },
  ],
  customer: { full_name: '', email: '', phone: '' },
  payment_type: '',
  address: '',
  recipient: { full_name: '', phone: '' },
}
export const columnNames = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Тип хранения',
    dataIndex: 'storage_type',
    key: 'storage_type',
    scopedSlots: { customRender: 'storage_type' },
  },
  {
    title: 'Название продавца',
    dataIndex: 'merchant_title',
    key: 'merchant_title',
    scopedSlots: { customRender: 'merchant_title' },
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    scopedSlots: { customRender: 'pickup_point_id' },
  },
  {
    title: 'Колличество',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Цена за еденицу',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: 'Общая цена',
    dataIndex: 'total_price',
    key: 'total_price',
    scopedSlots: { customRender: 'total_price' },
  },
  {
    title: 'Статус товара',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
]
export const solutionColumnsName = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Количество',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' },
  },
]
