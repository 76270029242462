<template>
  <div>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isFetching"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <code>{{ id | shortID(4) }}</code>
      </div>
      <div slot="version" slot-scope="version">
        <div v-if="version === pagination.total">
          {{ version }} <a-tag color="darkgreen"> актуальная версия </a-tag>
        </div>
        <code v-else>{{ version }}</code>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        <code>{{ createdAt | formatDate }}</code>
      </div>
      <div slot="viewAction" slot-scope="id, platform">
        <a-button type="primary" @click="showPreview(platform.body)">
          <a-icon type="eye" />
        </a-button>
      </div>
    </a-table>
    <a-drawer
      title="Просмотр шаблона"
      :width="'80%'"
      :visible="isTemplatePreviewVisible"
      :closable="false"
      @close="showPreview"
    >
      <div v-html="currentHTML"></div>
      <div class="button-absolute-box">
        <a-button @click="showPreview"> Закрыть</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { columnNames } from './constants'

export default {
  name: 'DocumentVersions',
  data() {
    return {
      columnNames,
      dataSource: [],
      isFetching: true,
      isTemplatePreviewVisible: false,
      currentHTML: 'empty',
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      filter: {
        page: 1,
        limit: 10,
      },
    }
  },
  computed: {
    documentId() {
      return this.$route.params.id
    },
  },
  methods: {
    showPreview(htmlCode) {
      this.isTemplatePreviewVisible = !this.isTemplatePreviewVisible
      this.currentHTML = htmlCode
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getOldVersions()
    },
    async getOldVersions() {
      const { agreements, count } =
        await this.$API.Agreement.getOldAgreeDocumentList(
          this.documentId,
          this.filter,
        )
      this.dataSource = agreements
      this.pagination.total = count
    },
    async initialize() {
      try {
        this.isFetching = true
        await this.getOldVersions()
        this.isFetching = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style scoped></style>
