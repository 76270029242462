import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {
  IContentDownloaderParams,
  IContentDownloaderResponse,
} from 'Core/modules/API/@types/ContentDownloader.type'

/**
 *  Сервис массового создания базовых продуктов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/content-downloader/swagger/index.html} */
export class ContentDownloaderService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'content-downloader',
    })
  }

  @Api
  async parseCSV(formData) {
    const response = await this.POST(`/v1/products/parse/csv`, formData)
    return response
  }

  @Api
  async getUploadsQueue(
    params?: IContentDownloaderParams,
  ): Promise<IContentDownloaderResponse> {
    const response = (await this.GET(`/v1/products/queue`, params)) as any
    return response
  }

  @Api
  async getFailedProducts(
    params?: IContentDownloaderParams,
  ): Promise<IContentDownloaderResponse> {
    const response = (await this.GET(`/v1/products/errors`, params)) as any
    return response
  }

  @Api
  async getCreatedProducts(
    params?: IContentDownloaderParams,
  ): Promise<IContentDownloaderResponse> {
    const response = (await this.GET(`/v1/products/finished`, params)) as any
    return response
  }
}
