import { IProductReview } from 'Core/modules/API/@types/Feedback.type'

export const REVIEW: IProductReview = {
  advantages: '',
  created_at: '',
  disadvantages: '',
  dislikes: {},
  dislikes_count: null as any,
  id: null as any,
  images_urls: [],
  likes: {},
  likes_count: null as any,
  merchant_id: '',
  merchant_name: '',
  merchant_sku: '',
  moderator_name: '',
  moderator_tdid: '',
  order_completed_at: '',
  order_id: '',
  product_sku: '',
  product_title: {
    kz: '',
    ru: '',
  },
  rating: null as any,
  status: '',
  tdid: '',
  text: '',
  updated_at: '',
  user_name: '',
  viewed_by_merchant: false,
}
