import { DELETE, GET, POST } from 'Main/api/REST'
import cookie from 'js-cookie'

const baseUrl = process.env.VUE_APP_SSO_API_URL

export const login = async (params) => {
  const response = await POST(`${baseUrl}/auth/signin/phone`, params)
  if (!response.success) {
    throw new Error('Неверный номер телефона или пароль')
  }

  const { access_token: accessToken, refresh_token: refreshToken } =
    response.data
  cookie.set('accessToken', accessToken)
  cookie.set('refreshToken', refreshToken)
}

export const logout = async () => {
  await DELETE(`${baseUrl}/auth/signout`)
  cookie.remove('accessToken')
  cookie.remove('refreshToken')
}

export const refreshAccessToken = async () => {
  const response = await POST(`${baseUrl}/auth/refresh`, {
    refresh_token: cookie.get('refreshToken'),
  })

  if (!response.success) {
    throw new Error('В доступе отказано')
  }

  const { access_token: newAccessToken } = response.data
  cookie.set('accessToken', newAccessToken)

  return newAccessToken
}

export const requestProfile = async () => {
  const response = await GET(`${baseUrl}/users/info`)

  if (!response.success) {
    throw new Error('В доступе отказано')
  }

  return response.data
}
