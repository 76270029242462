export const colorFormRules = {
  'title.ru': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'title.kz': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  hex: [
    {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
}
