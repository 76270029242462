import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис маппинга товаров с Технодом
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/td-stealer/swagger/index.html} */
export class TDStealerService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'td-stealer',
    })
  }

  /**
   * @param fileName
   * @param file
   */
  @Api
  async uploadFileTD(fileName: string, file) {
    const response = await this.POST(
      `/v1/td-stealer/market-file/${fileName}`,
      file,
    )
    return response
  }
}
