export const installmentFormRules = {
  'title.RU': {
    type: 'string',
    fullField: 'Заголовок на русском',
    required: true,
    max: 50,
  },
  'title.KZ': {
    type: 'string',
    fullField: 'Заголовок на казахском',
    required: true,
    max: 50,
  },
  commission: [
    {
      type: 'number',
      fullField: 'Комиссионные',
      required: true,
      max: 100,
    },
  ],
  duration: {
    type: 'number',
    fullField: 'Продолжительность',
    required: true,
    min: 3,
    max: 99,
  },
  end_at: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  note_data: {
    type: 'date',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  background_color: [
    {
      type: 'string',
      message: 'Выберите цвет',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
  text_color: [
    {
      type: 'string',
      message: 'Выберите цвет',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
}
