<template>
  <div>
    <div class="settings-wrap">
      <h2>Категории 3 уровня</h2>
      <router-link :to="{ name: 'ComissionView' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-table
      :columns="columnNames"
      :data-source="mappedList"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      bordered
    >
      <div slot="rate" slot-scope="rate, record">
        <a-tag color="green" class="rate-box">
          <span>{{ rate }}</span>
          %
        </a-tag>
        <a-icon
          @click="editOrSetHandler(record)"
          class="edit-box"
          type="edit"
        />
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        <template v-if="updatedAt">{{ updatedAt | formatDate }}</template>
        <template v-else>Еще не назначали.</template>
      </div>
    </a-table>

    <a-modal
      v-model="modalIsShown"
      title="Укажите значение от 0-100%"
      cancel-text="Отмена"
      @ok="handleUpdateCategoryRate"
    >
      <h2 v-if="currentCat.category_title">
        {{ currentCat.category_title.ru }}
      </h2>
      <a-form-model-item label="Сумма комиссии">
        <a-input-number
          v-model="rate"
          :min="0"
          block
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          :max="100"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'ComissionNew',
  data() {
    return {
      isLoading: true,
      modalIsShown: false,
      currentCat: {},
      categoryIII: [],
      rate: 0,
      columnNames: [
        {
          title: 'Категория',
          dataIndex: 'category_title.ru',
          key: 'category_title.ru',
          scopedSlots: { customRender: 'title' },
          align: 'right',
        },
        {
          title: 'Наша комиссия',
          dataIndex: 'value',
          key: 'value',
          scopedSlots: { customRender: 'rate' },
          align: 'right',
          width: '140px',
        },
        {
          title: 'Дата обновления',
          dataIndex: 'updated_at',
          key: 'updated_at',
          scopedSlots: { customRender: 'updatedAt' },
          align: 'right',
          width: '170px',
        },
      ],
      dataSource: [],
      mappedList: [],
    }
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.getCommission()
        this.categoryIII = await this.$API.Storefront.getCategoryList({
          level: 3,
        })
        this.setCategoryComissions()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getCommission() {
      try {
        const { commissions } = await this.$API.Commissions.getCommissionsList({
          page: 1,
          limit: 500,
        })
        this.dataSource = commissions
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    setCategoryComissions() {
      this.mappedList = this.categoryIII.map((item) => {
        const isSetted = this.getSetedCommissionInfo(item.title.ru)
        if (isSetted) {
          return isSetted
        }
        return {
          category_title: {
            ru: item.title.ru,
          },
          slug: item.slug,
          updated_at: null,
          value: 5,
        }
      })
    },
    getSetedCommissionInfo(category_name) {
      return this.dataSource.find(
        (item) => item.category_title.ru === category_name,
      )
    },
    editOrSetHandler(cat) {
      this.modalIsShown = true
      this.currentCat = cat
      this.rate = cat.value
    },
    async handleUpdateCategoryRate() {
      if (this.currentCat.updated_at) {
        const params = {
          value: this.rate ? this.rate : 0,
          category_title: {
            ru: this.currentCat.category_title.ru,
            kz: this.currentCat.category_title.kz,
          },
          category: this.currentCat.category,
          merchant_id: null,
        }
        await this.$API.Commissions.updateCommission(this.currentCat.id, params)

        this.modalIsShown = false
        showSuccessMsg('Успешно обновили комиссию')
        await this.initialize()
      } else {
        const params = {
          value: this.rate ? this.rate : 0,
          category_title: {
            ru: this.currentCat.category_title.ru,
            kz: this.currentCat.category_title.ru,
          },
          category: this.currentCat.slug,
        }
        await this.$API.Commissions.createCustomCommission(params)
        this.modalIsShown = false
        showSuccessMsg('Вы добавили глобальную комиссию')
        await this.initialize()
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
