export const createMap = (key, list) =>
  list.reduce((map, item) => {
    let copy = { ...map }
    copy = {
      ...copy,
      [item[key]]: item,
    }

    return copy
  }, {})
