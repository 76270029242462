export interface IFSM {
  value: string
  label: string
  variant?: string
  icon?: string
}

/**
 * Создание strongly typed FSM списка
 * @param FSMList [key: string]: IFSM
 */
export function createFSMList<K extends PropertyKey>(FSMList: Record<K, IFSM>) {
  return FSMList
}
