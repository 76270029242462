import { GET } from 'Main/api/REST'

const auditUrl = `${process.env.VUE_APP_API_URL}/audit/api/v1`

export const getLinkLogsAPI = async (params) => {
  const { data, success } = await GET(`${auditUrl}/admin/events`, params)
  if (!success) {
    throw new Error(
      `Ошибка при запросе списка партнеров: ${data?.error?.message}`,
    )
  }

  return data
}
