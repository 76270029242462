export const formState = {
  title: {
    kz: null,
    ru: null,
  },
  slug: null,
  weight: 0,
}

export const rules = {
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
  },
  weight: {
    type: 'number',
    message: 'Поле обязательно для заполнения(Только кириллица)',
    required: true,
  },
}
