var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список брокеров-партнеров")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:({
        service: 'banner',
        permissions: ['banner-create'],
      }),expression:"{\n        service: 'banner',\n        permissions: ['banner-create'],\n      }"}],attrs:{"to":{ name: 'ConfigBrokerPartnersCreate' }}},[_c('a-button',[_vm._v("Создать")])],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.colors,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var value = ref.value;

            return value;
},"loading":_vm.isLoading,"bordered":""},scopedSlots:_vm._u([{key:"value",fn:function(value){return [_c('router-link',{attrs:{"to":{
          name: 'ConfigBrokerPartnersEdit',
          params: { id: value },
        }}},[_vm._v(" "+_vm._s(_vm._f("shortID")(value,5))+" ")])]}},{key:"icon",fn:function(icon){return [(icon)?_c('img',{staticClass:"img-prev",attrs:{"src":icon,"width":"100"}}):_c('p',[_vm._v("Иконка не указана")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }