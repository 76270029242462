<template>
  <a-modal
    :visible="isVisible"
    title="Редактирование роли сотрудника"
    width="45vw"
    :closable="false"
  >
    <a-form-model
      ref="editEmployeeRoleForm"
      :rules="connectEmployeeFormRules"
      :model="editEmployeeRoleForm"
    >
      <div class="user-info__list">
        <div class="user-info__item">
          <p class="user-info__label">ID пользователя:</p>
          <p class="user-info__text" v-if="editEmployeeRoleForm.tdid">
            {{ editEmployeeRoleForm.tdid }}
          </p>
          <p class="user-info__text" v-else>Не указан</p>
        </div>
        <div class="user-info__item">
          <p class="user-info__label">ФИО:</p>
          <p
            class="user-info__text"
            v-if="
              editEmployeeRoleForm.surname ||
              editEmployeeRoleForm.name ||
              editEmployeeRoleForm.patronymic
            "
          >
            {{ editEmployeeRoleForm.surname }} {{ editEmployeeRoleForm.name }}
            {{ editEmployeeRoleForm.patronymic }}
          </p>
          <p class="user-info__text" v-else>Не указан</p>
        </div>
        <div class="user-info__item">
          <p class="user-info__label">Номер телефона:</p>
          <p class="user-info__text" v-if="editEmployeeRoleForm.phone_number">
            {{ editEmployeeRoleForm.phone_number }}
          </p>
          <p class="user-info__text" v-else>Не указан</p>
        </div>
      </div>
      <div class="user-info__item">
        <p class="user-info__label">Роли сотрудника</p>
        <div class="user-info__text">
          <a-form-model-item prop="roles">
            <a-select
              mode="multiple"
              v-model="editEmployeeRoleForm.roles"
              placeholder="Выберите роли для сотрудника"
            >
              <a-select-option
                v-for="role in employeeRoles"
                :key="role.value"
                :value="role.value"
              >
                {{ role.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
    <template slot="footer">
      <a-button key="back" @click="fail"> Отмена</a-button>
      <a-button type="primary" :loading="isEditLoading" @click="onEditEmployee">
        Сохранить
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import {
  connectEmployeeFormRules,
  employeeRoles,
} from 'Partner/pages/Partner/PartnerInfo/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    selectedEmployee: {
      type: Object,
      default: null,
    },
    success: {
      type: Function,
      required: true,
    },
    fail: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      employeeRoles,
      connectEmployeeFormRules,
      editEmployeeRoleForm: {
        tdid: null,
        roles: [],
      },
      isEditLoading: false,
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  watch: {
    selectedEmployee(newValue) {
      if (newValue) {
        const userInfoCopy = JSON.parse(JSON.stringify(newValue))
        userInfoCopy.roles = userInfoCopy.roles.map((role) => role.id)
        this.editEmployeeRoleForm = userInfoCopy
      }
    },
  },
  methods: {
    async onEditEmployee() {
      this.$refs.editEmployeeRoleForm.validate(async (result) => {
        if (result) {
          try {
            this.isEditLoading = true
            await this.$API.MerchantProfile.updateEmployeeToMerchant(
              this.merchantID,
              this.editEmployeeRoleForm.tdid,
              this.editEmployeeRoleForm,
            )
            showSuccessMsg('У сотрудника роль успешна обновлена!')
            this.success()
          } catch (error) {
            showErrorMsg(error.message)
            this.fail()
          } finally {
            this.isEditLoading = false
            this.editEmployeeRoleForm = {
              tdid: null,
              roles: [],
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-info {
  &__item {
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 15px;
  }

  &__label {
    font-weight: 600;
  }
}
</style>
