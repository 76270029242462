<template>
  <div>
    <div class="settings-wrap">
      <h2>Список транзакции</h2>
    </div>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onFilter">
      <div class="settings-wrap">
        <a-radio-group
          :default-value="null"
          button-style="solid"
          v-model="filter.type"
          @change="onFilter"
        >
          <a-radio-button :value="null">Все</a-radio-button>
          <a-radio-button
            v-for="(item, index) in TRANSACTION_STATUSES"
            :value="item.value"
            :key="index"
          >
            <a-icon :type="item.icon" />
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
        <a-form-model-item label="UUID транзакции">
          <a-input v-model="filter.search" />
        </a-form-model-item>

        <a-form-model-item label="Дата транзакции:">
          <a-range-picker
            :locale="locale"
            :format="'DD MMM YYYY'"
            @change="onDateRangeChange"
            :placeholder="['С:', 'По:']"
            :value="[filter.from, filter.to]"
          ></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="Сумма транзакции:">
          <a-input-group compact>
            <a-input-number
              style="width: 170px; text-align: center"
              :formatter="
                (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
              v-model="filter.price_from"
              placeholder="Минимум"
            />
            <a-input class="range-picker" placeholder="~" disabled />
            <a-input-number
              :formatter="
                (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
              v-model="filter.price_to"
              style="width: 170px; text-align: center; border-left: 0"
              placeholder="Максимум"
            />
          </a-input-group>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить </a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ sku }) => sku"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      :expand-row-by-click="true"
      :expanded-row-keys.sync="currentExpanded"
      bordered
    >
      <div slot="initiator" slot-scope="initiator">
        <a-tag :color="INITIATOR_STATUSES[initiator.type].variant">
          <a-icon :type="INITIATOR_STATUSES[initiator.type].icon" />
          {{ `${INITIATOR_STATUSES[initiator.type].label} — ${initiator.id}` }}
        </a-tag>
      </div>
      <div slot="recipient" slot-scope="recipient">
        <a-tag :color="INITIATOR_STATUSES[recipient.type].variant">
          <a-icon :type="INITIATOR_STATUSES[recipient.type].icon" />
          {{ `${INITIATOR_STATUSES[recipient.type].label} — ${recipient.id}` }}
        </a-tag>
      </div>
      <div slot="reason" slot-scope="reason">
        <a-tag
          style="text-transform: uppercase"
          :color="REASON_STATUSES[reason].variant"
          >{{ REASON_STATUSES[reason].label }}
        </a-tag>
      </div>
      <div slot="type" slot-scope="type">
        <div v-if="type">
          <a-tag
            style="text-transform: uppercase"
            :color="TRANSACTION_STATUSES[type].variant"
          >
            <a-icon :type="TRANSACTION_STATUSES[type].icon" />
            {{ TRANSACTION_STATUSES[type].label }}
          </a-tag>
        </div>
        <div v-else>Не указан</div>
      </div>
      <div slot="amount" slot-scope="amount">
        {{ amount | currency }}
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <div v-if="record.from.type === 'merchant'">
          <profile-description
            :profile-info="merchantProfileInfo[record.from.id]"
          />
        </div>
        <div v-if="record.to.type === 'merchant'">
          <profile-description
            :profile-info="merchantProfileInfo[record.to.id]"
          />
        </div>
        <div
          v-show="
            record.from.type !== 'merchant' && record.to.type !== 'merchant'
          "
        >
          Данные отсутствуют
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'

import { columnNames } from './constants'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import moment from 'moment'

import ProfileDescription from './ProfileDescription'

import {
  TRANSACTION_STATUSES,
  INITIATOR_STATUSES,
  REASON_STATUSES,
} from 'Core/types/transaction'

export default {
  name: 'FinanceList',
  components: {
    ProfileDescription,
  },
  data() {
    return {
      columnNames,
      locale,
      TRANSACTION_STATUSES,
      INITIATOR_STATUSES,
      REASON_STATUSES,
      currentExpanded: [],
      isLoading: true,
      dataSource: [],
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      filter: {
        search: null,
        from: null,
        to: null,
        price_from: null,
        price_to: null,
        type: null,
        page: 1,
        limit: 10,
      },
    }
  },
  watch: {
    currentExpanded(newVal) {
      this.expandEventHandler(newVal)
    },
  },
  computed: {
    merchantProfileInfo() {
      return this.$store.getters['core/merchantProfileInfoList']
    },
  },
  methods: {
    initialize() {
      this.isLoading = true
      try {
        this.getTransactionList()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getTransactionList() {
      const { transactions, count } =
        await this.$API.Accounter.getTransactionList(this.filter)
      this.pagination.total = count
      this.dataSource = transactions
    },
    onDateRangeChange(date) {
      this.filter.from = moment(date[0]).format('YYYY-MM-DDT00:00:00Z')
      this.filter.to = moment(date[1]).format('YYYY-MM-DDT23:59:59Z')
    },
    onFilter(event) {
      event.preventDefault()
      this.filterHandler()
    },
    async filterHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getTransactionList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.initialize()
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        search: null,
        page: 1,
        limit: 10,
      }
      this.filter = {
        search: null,
        from: null,
        to: null,
        price_from: null,
        price_to: null,
        type: null,
        page: 1,
        limit: 10,
      }
      this.initialize()
    },
    expandEventHandler(newVal) {
      if (newVal.length) {
        newVal.map((item) => {
          const transaction = this.dataSource[item]
          if (transaction.from.type === 'merchant') {
            if (!this.merchantProfileInfo[transaction.from.id]) {
              this.$store.dispatch(
                'core/getMerchantProfileInfo',
                this.dataSource[item].from.id,
              )
            }
          }
          if (transaction.to.type === 'merchant') {
            if (!this.merchantProfileInfo[transaction.to.id]) {
              this.$store.dispatch(
                'core/getMerchantProfileInfo',
                transaction.to.id,
              )
            }
          }
        })
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.range-picker {
  width: 30px !important;
  border-left: 0;
  pointer-events: none;
  background-color: #fff;
}
</style>
