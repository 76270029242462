export const contactFormRules = {
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  value: [
    {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  ],
  type: {
    type: 'string',
    message: 'Выберите тип связи',
    required: true,
  },
}
