<template>
  <section class="login">
    <div class="login__wrapper">
      <fieldset class="login__fieldset">
        <legend class="login__legend">
          <a-icon type="crown" theme="twoTone" />
          <h1 class="login__title">
            Добро пожаловать в <code>Airba-arm</code>
          </h1>
        </legend>
        <a-form-model
          ref="form"
          :model="formState"
          :rules="rules"
          @submit="onFormSubmit"
        >
          <phone-input
            style="width: 320px"
            prop="phone"
            v-model="formState.phone"
            icon="user"
          />

          <a-form-model-item prop="password">
            <a-input
              style="width: 320px"
              v-model="formState.password"
              type="password"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-model-item>

          <a-form-item>
            <a-button
              class="login__button"
              :loading="isLoading"
              type="primary"
              html-type="submit"
            >
              Войти
            </a-button>
          </a-form-item>
        </a-form-model>
      </fieldset>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      formState: {
        phone: null,
        password: null,
      },
      rules: {
        phone: {
          type: 'string',
          fullField: 'Номер',
          required: true,
          max: 50,
        },
        password: {
          type: 'string',
          fullField: 'Пароль',
          required: true,
          max: 50,
        },
      },
      isLoading: false,
    }
  },
  methods: {
    async onFormSubmit(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            await this.$API.SSO.LogIn(this.formState)
            // await login(this.formState)
            await this.$store.dispatch('user/updateProfile')
            await this.$store.dispatch('user/updatePermissions')
            await this.$store.dispatch('core/updateCities')
            await this.$router.push({ name: 'Notifier' })
          } catch (error) {
            this.$notification.error({
              message: 'Ошибка',
              description: error.message,
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import 'login';
</style>
