<template>
  <div>
    <div class="settings-wrap">
      <h2>
        Список FAQ - <i>{{ faqBelong }}</i>
      </h2>
      <a-button @click="gotoCreatePage(root_id)">
        Создать новый раздел
      </a-button>
    </div>
    <a-spin :spinning="isLoading">
      <div class="settings-wrap" v-if="faqType === 'user'">
        <a-radio-group
          :default-value="null"
          button-style="solid"
          v-model="platform"
          @change="platformChangeHandler"
        >
          <a-radio-button
            v-for="(item, index) in PLATFORM_TYPES"
            :value="item.value"
            :key="index"
          >
            <a-icon :type="item.icon" />
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <a-tree v-if="nestedFaqList">
        <a-icon slot="icon" type="carry-out" />
        <a-tree-node
          v-for="baseParent in sortList(nestedFaqList)"
          :key="baseParent.id"
          :selectable="false"
        >
          <template v-slot:title>
            <span class="tree-title" @click="gotoEditPage(baseParent.slug)">
              {{ baseParent.title.ru }}
            </span>
            <!--            <a-icon-->
            <!--              slot="icon"-->
            <!--              type="plus-circle-o"-->
            <!--              @click="gotoCreatePage(baseParent.id)"-->
            <!--            />-->
          </template>
          <template v-if="baseParent.page">
            <a-tree-node
              v-for="secondaryParent in sortList(baseParent.page)"
              :selectable="false"
              :key="secondaryParent.id"
            >
              <template v-slot:title>
                <span
                  class="tree-title"
                  @click="gotoEditPage(secondaryParent.slug)"
                >
                  {{ secondaryParent.title.ru }}
                </span>
                <!--                <a-icon-->
                <!--                  slot="icon"-->
                <!--                  type="plus-circle-o"-->
                <!--                  @click="gotoCreatePage(secondaryParent.id)"-->
                <!--                />-->
              </template>
              <template v-if="secondaryParent.page">
                <a-icon slot="icon" type="carry-out" />
                <a-tree-node
                  v-for="tertiary in sortList(secondaryParent.page)"
                  :key="tertiary.id"
                >
                  <template v-slot:title>
                    <span
                      class="tree-title"
                      @click="gotoEditPage(tertiary.slug)"
                    >
                      {{ tertiary.title.ru }}
                    </span>
                  </template>
                </a-tree-node>
              </template>
            </a-tree-node>
          </template>
        </a-tree-node>
      </a-tree>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { PLATFORM_TYPES } from './constants'

export default {
  name: 'FaqList',
  data() {
    return {
      PLATFORM_TYPES,
      isLoading: true,
      nestedFaqList: null,
      root_id: null,
      platform: 'web',
    }
  },
  watch: {
    faqType() {
      this.initialize()
    },
  },
  computed: {
    faqType() {
      return this.$route.params.type
    },
    faqBelong() {
      return this.$route.params.type === 'user' ? 'Клиентов' : 'Партнеров'
    },
  },
  methods: {
    initialize() {
      this.isLoading = true
      try {
        this.getFaqList()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    sortList(list) {
      return list
    },
    platformChangeHandler() {
      this.initialize()
    },
    async getFaqList() {
      const params = {
        depth: 3,
        root: true,
        faqType: this.faqType,
        platform: this.faqType === 'user' ? this.platform : 'web',
      }
      const { page, id } = await this.$API.ConfigDiscovery.getFaqList(
        this.faqType,
        params,
      )
      this.nestedFaqList = page
      this.root_id = id
    },
    gotoCreatePage(id) {
      this.$router.push({
        name: 'FaqCreate',
        query: {
          type: this.faqType,
          parent_id: id,
        },
      })
    },
    gotoEditPage(slug) {
      this.$router.push({
        name: 'FaqEdit',
        query: {
          type: this.faqType,
          slug: slug,
        },
      })
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style scoped></style>
