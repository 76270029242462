var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.isFetching}},[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v(" Список быстрых ссылок: "),_c('b',[_c('code',[_vm._v(_vm._s(_vm.fastLinkID))])])]),_c('a-button',{on:{"click":function () { return _vm.$router.push({ name: 'FastLinkList' }); }}},[_vm._v(" "+_vm._s(_vm.$t('common.back'))+" ")])],1),_c('div',{staticClass:"settings-wrap"},[_c('a-button',{on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Показать список ссылок' : 'Редактировать')+" ")]),_c('a-button',{on:{"click":function () { return _vm.$router.push({
              name: 'LinkCreate',
              params: { fast_link_id: _vm.fastLinkID },
            }); }}},[_vm._v("Создать ссылку ")])],1),(_vm.isEditing)?_c('a-form-model',{ref:"form",attrs:{"model":_vm.formState,"rules":_vm.rules},on:{"submit":_vm.onValidate}},[_c('a-form-model-item',{attrs:{"prop":"id","label":"ID"}},[_c('a-input',{model:{value:(_vm.formState.id),callback:function ($$v) {_vm.$set(_vm.formState, "id", $$v)},expression:"formState.id"}})],1),_c('a-form-model-item',{attrs:{"prop":"name","label":"Название"}},[_c('a-input',{model:{value:(_vm.formState.name),callback:function ($$v) {_vm.$set(_vm.formState, "name", $$v)},expression:"formState.name"}})],1),_c('a-form-model-item',{attrs:{"prop":"title.ru","label":"Заголовок(рус)"}},[_c('a-input',{model:{value:(_vm.formState.title.ru),callback:function ($$v) {_vm.$set(_vm.formState.title, "ru", $$v)},expression:"formState.title.ru"}})],1),_c('a-form-model-item',{attrs:{"label":"Заголовок(каз)"}},[_c('a-input',{model:{value:(_vm.formState.title.kz),callback:function ($$v) {_vm.$set(_vm.formState.title, "kz", $$v)},expression:"formState.title.kz"}})],1),_c('a-form-model-item',{attrs:{"label":"Заголовок(англ)"}},[_c('a-input',{model:{value:(_vm.formState.title.en),callback:function ($$v) {_vm.$set(_vm.formState.title, "en", $$v)},expression:"formState.title.en"}})],1),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Сохранить")])],1):_c('a-table',{attrs:{"columns":_vm.columnNames,"data-source":_vm.formState.links,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
                        var store_id = ref.store_id;

                        return store_id;
},"loading":_vm.isLoading,"bordered":""},scopedSlots:_vm._u([{key:"id",fn:function(id){return _c('div',{},[_c('router-link',{attrs:{"to":("/fast_link/" + _vm.fastLinkID + "/link_id=" + id)}},[_c('code',[_vm._v(_vm._s(_vm._f("shortID")(id,4)))])])],1)}},{key:"imageUrl",fn:function(imageUrl){return _c('div',{},[_c('img',{staticClass:"img-prev",attrs:{"src":imageUrl,"alt":imageUrl}})])}},{key:"isActive",fn:function(isActive, record){return _c('div',{},[(isActive)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.statusToggleHandler(record.id)}}},[_c('a-icon',{attrs:{"type":"check-circle"}})],1):_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.statusToggleHandler(record.id)}}},[_c('a-icon',{attrs:{"type":"stop"}})],1)],1)}},{key:"devices",fn:function(devices){return _c('div',{},[_c('div',{staticClass:"icons-list"},[(_vm.checkDevices(devices, 'ios'))?_c('a-icon',{attrs:{"type":"apple"}}):_vm._e(),(_vm.checkDevices(devices, 'android'))?_c('a-icon',{attrs:{"type":"android"}}):_vm._e(),(_vm.checkDevices(devices, 'web'))?_c('a-icon',{attrs:{"type":"chrome"}}):_vm._e()],1)])}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }