<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание промокода</h2>
      <router-link :to="{ name: 'PromocodeList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isLoading">
      <a-form-model
        v-if="promotionForm"
        ref="form"
        :model="promotionForm"
        :rules="promotionFormRules"
        @submit="createPromotion"
      >
        <a-form-model-item prop="title.ru" label="Заголовок на русском">
          <a-input v-model="promotionForm.title.ru" />
        </a-form-model-item>
        <a-form-model-item prop="title.kz" label="Заголовок на казахском">
          <a-input v-model="promotionForm.title.kz" />
        </a-form-model-item>
        <a-form-model-item prop="title.en" label="Заголовок на английском">
          <a-input v-model="promotionForm.title.en" />
        </a-form-model-item>
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="promotionForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="description" label="Описание для клиента">
          <a-textarea v-model="promotionForm.description" />
        </a-form-model-item>
        <a-form-model-item prop="code" label="Промокод">
          <a-radio-group v-model="promocodeType">
            <a-radio value="generated">Сгенерированный</a-radio>
            <a-radio value="manual">Вручную</a-radio>
          </a-radio-group>
          <a-input
            v-model="promotionForm.code"
            v-if="promocodeType === 'manual'"
          />
        </a-form-model-item>
        <a-form-model-item prop="discount" label="Тип дисконта">
          <a-radio-group v-model="promotionForm.discount.type">
            <a-radio value="percent">Процент</a-radio>
            <a-radio value="fix">Зафиксированный</a-radio>
          </a-radio-group>
          <br />
          <a-input-number
            v-model="promotionForm.discount.amount"
            block
            :min="1"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
            :max="100"
            v-if="promotionForm.discount.type == 'percent'"
          />
          <a-input-number
            v-model="promotionForm.discount.amount"
            v-else
            block
            :min="1"
          />
        </a-form-model-item>
        <a-form-model-item prop="target" label="Зона действии промокода">
          <a-radio-group
            :value="promotionForm.target.type"
            @change="onChangeTargetType($event.target.value)"
          >
            <a-radio value="product">Товары</a-radio>
            <a-radio value="cart">Корзина</a-radio>
          </a-radio-group>
          <br />
          <template v-if="promotionForm.target.type == 'product'">
            <product-selector
              v-model="promotionForm.target.items"
            ></product-selector>
          </template>
        </a-form-model-item>
        <a-form-model-item prop="payment_types" label="Тип оплаты">
          <a-select
            mode="multiple"
            :default-value="['cash']"
            v-model="promotionForm.payment_types"
            placeholder="Выберите устройства"
          >
            <a-select-option
              v-for="(type, index) in paymentTypes"
              :key="index"
              :value="type.value"
            >
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          prop="max_usage_limit"
          label="Макс. количество промокодов"
        >
          <a-input-number
            v-model="promotionForm.max_usage_limit"
            block
            :min="0"
          />
        </a-form-model-item>
        <a-form-model-item
          prop="user_user_limit"
          label="Макс. количество использания на пользователя"
        >
          <a-input-number
            v-model="promotionForm.user_user_limit"
            block
            :min="1"
          />
        </a-form-model-item>
        <a-form-model-item
          prop="lock_sec_count"
          label="Время блокировки после активации промокода(в секундах, по умолчанию 20 мин)"
        >
          <a-input-number
            v-model="promotionForm.lock_sec_count"
            block
            :min="1"
          />
        </a-form-model-item>
        <a-form-model-item prop="ends_at" label="Даты">
          <a-range-picker
            @change="onRangeDatepickerChange"
            :disabled-date="disabledDate"
            :locale="locale"
            format="DD-MM-YYYY"
            :value="[
              promotionForm.starts_at
                ? moment(promotionForm.starts_at)
                : promotionForm.starts_at,
              promotionForm.ends_at
                ? moment(promotionForm.ends_at)
                : promotionForm.ends_at,
            ]"
          />
        </a-form-model-item>
        <a-form-model-item prop="is_active" label="Статус">
          <a-checkbox v-model="promotionForm.is_active">
            {{ promotionForm.is_active ? 'Активный' : 'Неактивный' }}
          </a-checkbox>
        </a-form-model-item>
        <a-collapse :bordered="false">
          <a-collapse-panel key="1" header="Дополнительная форма валидации">
            <!-- <a-form-model-item label="Бренд">
              <template v-if="promotionForm.cart_rules.brands">
                <a-tag
                  v-for="(brand, index) in promotionForm.cart_rules.brands"
                  :key="brand.id"
                  @close="removeSelectedBrand(index)"
                  closable
                >
                  <span>{{ brand.title && brand.title.ru }}</span>
                </a-tag>
              </template>
              <p v-else>Не выбрано</p>
              <BrandSelector @setBrandHandler="setBrandHandler" />
            </a-form-model-item> -->

            <!-- <a-form-model-item label="Города">
              <a-select
                mode="multiple"
                v-model="promotionForm.cart_rules.city_ids"
                placeholder="Выберите города"
              >
                <a-select-option
                  v-for="(city, id) in cityList"
                  :key="id"
                  :value="id"
                >
                  {{ city.title.ru }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->

            <!-- <a-form-model-item label="Выбор продуктов">
              <ProductSelector
                v-model="promotionForm.cart_rules.products"
              ></ProductSelector>
            </a-form-model-item> -->

            <a-form-model-item
              prop="max_cart_size"
              label="Макс. количество товаров"
            >
              <a-input-number
                v-model="promotionForm.cart_rules.max_cart_size"
                block
                :min="0"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="min_cart_size"
              label="Мин. количество товаров"
            >
              <a-input-number
                v-model="promotionForm.cart_rules.min_cart_size"
                block
                :min="0"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="max_cart_sum"
              label="Макс. сумма товаров в корзине"
            >
              <a-input-number
                v-model="promotionForm.cart_rules.max_cart_sum"
                block
                :min="0"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="min_cart_sum"
              label="Мин. сумма товаров в корзине"
            >
              <a-input-number
                v-model="promotionForm.cart_rules.min_cart_sum"
                block
                :min="0"
              />
            </a-form-model-item>
          </a-collapse-panel>
        </a-collapse>
        <a-button type="primary" html-type="submit" class="form__button">
          Сохранить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import ProductSelector from 'Core/components/Selector/ProductSelector.vue'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { addEditPromotionMixin } from '../mixins/addEditPromotionMixin'
import moment from 'moment'

export default {
  components: {
    ProductSelector,
    // BrandSelector,
  },
  data() {
    return {
      isLoading: false,
      promotions: null,
      promocodeType: 'manual',
    }
  },
  watch: {
    promocodeType: {
      handler(newValue) {
        if (newValue === 'generated') {
          this.promotionForm.code = null
        }
      },
    },
  },
  mixins: [addEditPromotionMixin('create')],
  methods: {
    moment,
    disabledDate(current) {
      return current && current < moment().startOf('day')
    },
    async createPromotion(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            await this.$API.PromoCode.createPromoCode(this.promotionForm)
            showSuccessMsg('Промокод успешно создан!')
            await this.$router.push({ name: 'PromocodeList' })
          } catch (error) {
            showErrorMsg(error.message)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-input-number {
  width: 200px;
}

.form__button {
  margin-top: 30px !important;
}

::v-deep {
  .ant-form-item-label {
    font-weight: 600 !important;
  }
}
</style>
