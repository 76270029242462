import { FILE_LENGTH, FILE_SAVED } from 'Core/modules/validators/file'

export const mediaFormRules = {
  images: [
    {
      validator: FILE_LENGTH,
      fullField: 'Медиа контент',
      min: 1,
      // max: 8,
    },
    {
      validator: FILE_SAVED,
      fullField: 'Медиа контент',
    },
  ],
  //   videoUrl: {
  //     type: 'string',
  //     message: 'Поле обязательно для заполнения',
  //     required: true,
  //   },
}
