import { FILE_LENGTH } from 'Core/modules/validators/file'
import { IUploadFile } from 'Core/types/file'

export const rules = {
  categories: {
    message: 'Обязательное поле',
    required: true,
  },
  model_id: {
    message: 'Обязательное поле',
    required: true,
  },
  files: {
    message: 'Необходимо выбрать CSV файл',
    validator: FILE_LENGTH,
    min: 1,
    required: true,
  },
}

export const formState: {
  categories: any
  model_id: any
  files: IUploadFile[]
} = {
  categories: null,
  model_id: null,
  files: [],
}
