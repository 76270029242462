var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter__form"},[_c('a-form-model',{ref:"form",attrs:{"layout":"inline","model":_vm.params},on:{"submit":_vm.searchHandler}},[_c('a-form-model-item',{attrs:{"label":"Название продукта"}},[_c('a-input',{model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1),_c('a-form-item',[_c('a-button-group',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Поиск")]),_c('a-button',{attrs:{"type":"dashed"},on:{"click":_vm.dropFilter}},[_vm._v(" сбросить")])],1)],1),_c('a-form-item',[_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
            service: 'storefront',
            permissions: ['merchant-product-update'],
          }),expression:"{\n            service: 'storefront',\n            permissions: ['merchant-product-update'],\n          }"}],attrs:{"type":"primary"},on:{"click":_vm.gotoBindMerchantProduct}},[_vm._v(" Создать новые привязки ")])],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.unRecognizedTablecolumns,"data-source":_vm.merchantProducts,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
                    var sku = ref.products.sku;

                    return sku;
},"loading":_vm.isLoading,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"model",fn:function(products){return _c('div',{},[_vm._v(" "+_vm._s(products.model || 'Не указан')+" ")])}},{key:"price",fn:function(products){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("currency")(products.price && products.price))+" ")])}},{key:"created_at",fn:function(created_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at && created_at))+" ")])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }