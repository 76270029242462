<template>
  <a-table
    :columns="columnNames"
    :loading="loading"
    :data-source="dataSource"
    :scroll="{ x: true }"
    :locale="{ emptyText: 'Данные не найдены ):' }"
    :row-key="({ id }) => id"
    :pagination="pagination"
    @change="onPageChange"
    bordered
  >
    <div slot="id" slot-scope="id">
      <a @click="onRedirect(id)">{{ id }}</a>
    </div>
    <div slot="status" slot-scope="status">
      <status-view
        :status-list="PAYMENT_STATUSES"
        :status="status"
      ></status-view>
    </div>
    <div slot="payment_type" slot-scope="payment_type">
      <template v-if="PaymentTypes[payment_type]">
        <a-icon :type="PaymentTypes[payment_type].icon"></a-icon>
        {{ PaymentTypes[payment_type].label }}
      </template>
      <template v-else>
        Неизвестный способ оплаты [{{ payment_type }}]
      </template>
    </div>
    <div slot="created_at" slot-scope="created_at">
      {{ created_at | formatDate }}
    </div>
    <div slot="end_at" slot-scope="end_at">
      <template v-if="end_at">
        {{ end_at | formatDate }}
      </template>
      <template v-else> - </template>
    </div>
  </a-table>
</template>

<script>
import { columnNames } from 'CRM/pages/constants'
import { ORDER_STATUSES } from 'Core/types/order'
import { PaymentTypes } from 'Core/constants'

/**
 * dumb component для отображения заказов мерчанта/пользователя. Для получения нужных себе данных
 * эмиттит событие "fetchOrdersList", передав параметры пагинации
 */
export default {
  name: 'OrderList',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    dataSource: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number, // на случай, если будет фильтрация
    },
  },
  data: () => ({
    columnNames,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    PaymentTypes,
    PAYMENT_STATUSES: ORDER_STATUSES,
  }),
  watch: {
    totalCount(total) {
      this.pagination.total = total
    },
    page(page) {
      this.pagination.current = page
    },
  },
  methods: {
    async getMerchantOrders() {
      this.$emit('fetchOrdersList', {
        id: this.id,
        page: this.pagination.current,
        limit: this.pagination.defaultPageSize,
      })
    },
    onPageChange({ current }) {
      this.pagination.current = current
      this.getMerchantOrders()
    },
    onRedirect(orderId) {
      // eslint-disable-next-line no-unused-vars
      const [_, postfix] = orderId.split('-', 2)
      if (postfix) this.$router.push(`/partner/${this.id}/order/${orderId}`)
      else this.$router.push(`/order/${orderId}`)
    },
  },
  mounted() {
    this.getMerchantOrders()
  },
}
</script>

<style></style>
