import { mapStateToFSM } from 'Core/modules/helpers'

export default {
  namespaced: true,
  state: {
    colors: null,
    isLoading: false,
  },
  mutations: {
    SET_COLORS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_COLORS(state, colors) {
      state.colors = colors
    },
  },
  actions: {
    async fetchColors(context) {
      let data = null
      try {
        context.commit('SET_COLORS_LOADING', true)
        data = await this._vm.$API.ConfigDiscovery.getColorList()
      } finally {
        context.commit('SET_COLORS_LOADING', false)
        context.commit('SET_COLORS', data)
      }
    },
  },
  getters: {
    getColors: (state) =>
      mapStateToFSM(state.colors, {
        label: 'title',
        value: 'id',
        variant: 'hex',
      }),
    isLoading: (state) => state.isLoading,
  },
}
