import * as Sentry from '@sentry/vue'

const Acl = class {
  listIsSetted = false

  constructor(permissionsList = []) {
    this.list = permissionsList
    this.listIsSetted = false
  }

  set list(newList) {
    this._list = newList
  }

  // getters
  get list() {
    return this._list
  }

  get isActive() {
    return this.listIsSetted
  }

  setList(newList) {
    this._list = newList
    this.listIsSetted = true
  }

  // methods
  can(payload) {
    const currentPermission = this.list.find(
      (perm) => perm.service === payload.service,
    )
    if (currentPermission) {
      const permissions = currentPermission.permissions
      for (let i = 0; i < payload.permissions.length; i++) {
        const perm = payload.permissions[i]
        const isExists = permissions.includes(perm)

        if (!isExists) {
          if (process.env.NODE_ENV !== 'production' && this.isActive) {
            console.warn(
              `Отсутствует правило ${perm}. Отсутствует в системе либо у пользователя не хватает прав. `,
            )
            Sentry.addBreadcrumb({
              type: 'Permission Exception',
              level: Sentry.Severity.Warning,
              category: 'ACL_ERROR',
              message: `Отсутствует правило ${perm}. Отсутствует в системе либо у пользователя не хватает прав. `,
              data: {
                service: payload.service,
                permissions: payload.permissions,
              },
            })
          }
          return false
        }
      }
      return true
    }
    if (process.env.NODE_ENV !== 'production' && this.isActive) {
      console.warn(
        `Сервис ${payload.service} не найден. Отсутствует в системе либо у пользователя не хватает прав.  `,
      )
      Sentry.addBreadcrumb({
        type: 'Permission Exception',
        level: Sentry.Severity.Warning,
        category: 'ACL_ERROR',
        message: `Сервис ${payload.service} не найден. Отсутствует в системе либо у пользователя не хватает прав.  `,
        data: {
          service: payload.service,
        },
      })
    }
    return false
  }

  notCan(payload) {
    return !this.can(payload)
  }

  showNavigation(payload) {
    const availableNavigation = []
    payload.forEach((route) => {
      if (route.subMenu) {
        const subMenuList = route.subMenu.filter((item) =>
          this.can(item.permission),
        )
        if (subMenuList.length) {
          availableNavigation.push({
            name: route.name,
            icon: route.icon,
            subMenu: subMenuList,
          })
        }
      } else {
        if (this.can(route.permission)) {
          availableNavigation.push(route)
        }
      }
    })
    return availableNavigation
  }
}

const AccessControlList = {
  install: (Vue, permissions) => {
    Vue.directive('can', (el, binding) => {
      const localPermissions = binding.value
      const check = Vue.prototype.$acl.can(localPermissions)

      if (check) {
        el.classList.add('v-can')
      } else {
        el.style.display = 'none'
      }
    })
    Vue.prototype.$acl = new Acl(permissions)
  },
}

export default AccessControlList
