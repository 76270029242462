import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис создания и управления главным меню сайта
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/menu/swagger/index.html} */
export class MenuService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'menu',
    })
  }

  @Api
  async getNestedMenuList(params) {
    const response = await this.GET(`/v1/admin/menu`, params)
    return response
  }

  @Api
  async getNestedMenuById(id, payload = {}) {
    const response = await this.GET(`/v1/admin/menu/${id}`, payload)
    return response
  }

  @Api
  async getNestedMenuLevel(id) {
    const response = await this.GET(`/v1/admin/menu/${id}/level`)
    return response
  }

  @Api
  async updateNestedMenuById(id, payload) {
    const response = await this.PUT(`/v1/admin/menu/${id}`, payload)
    return response
  }

  @Api
  async addNestedMenu(payload) {
    const response = await this.POST(`/v1/admin/menu`, payload)
    return response
  }

  @Api
  async removeNestedMenu(id) {
    const response = await this.DELETE(`/v1/admin/menu/${id}`)
    return response
  }

  @Api
  async getFastLinkList(payload) {
    const response = await this.GET(`/v1/admin/mini_menus`, payload)
    return response
  }

  @Api
  async getFastLinkById(id) {
    const response = await this.GET(`/v1/admin/mini_menus/${id}`)
    return response
  }

  @Api
  async updateFastLinkById(id, params) {
    const response = await this.PUT(`/v1/admin/mini_menus/${id}`, params)
    return response
  }

  @Api
  async createFastLink(params) {
    const response = await this.POST(`/v1/admin/mini_menus`, params)
    return response
  }

  @Api
  async getFastLinkTypes(params) {
    const response = await this.GET(`/v1/admin/mini_menus/links/types`)
    return response
  }

  @Api
  async createLink(fastLinkId, payload) {
    const response = await this.POST(
      `/v1/admin/mini_menus/${fastLinkId}/links`,
      payload,
    )
    return response
  }

  @Api
  async getLinkInfoById(fastLinkId, linkId) {
    const response = await this.GET(
      `/v1/admin/mini_menus/${fastLinkId}/links/${linkId}`,
    )
    return response
  }

  @Api
  async updateLinkById(fastLinkId, linkId, payload) {
    const response = await this.PUT(
      `/v1/admin/mini_menus/${fastLinkId}/links/${linkId}`,
      payload,
    )
    return response
  }
}
