<template>
  <div class="ant-upload-list ant-upload-list-picture-card">
    <div
      class="
        ant-upload-list-item
        ant-upload-list-item-done
        ant-upload-list-item-list-type-picture-card
      "
    >
      <div class="ant-upload-list-item-info">
        <span>
          <a class="ant-upload-list-item-thumbnail">
            <img :src="url" alt="4.jpg" class="ant-upload-list-item-image" />
          </a>
        </span>
      </div>
      <span class="ant-upload-list-item-actions">
        <a-icon
          type="eye"
          :style="{ color: 'white', marginLeft: '10px' }"
          @click="previewVisible = true"
        ></a-icon>
      </span>
    </div>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img alt="example" style="width: 100%" :src="url" />
    </a-modal>
  </div>
</template>

<script>
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }

export default {
  name: 'ShowImage',
  props: {
    url: {
      type: String,
    },
  },
  data: () => ({ previewVisible: false }),
}
</script>

<style></style>
