// bytes / (1024*1024)
import { format, ByteToMB } from 'Core/modules/helpers'

/**
 * Функция-аналог array.prototype.find, за исключением того, что
 * на выходе возвращает индекс и значение элемента.
 *
 * Полезно, когда необходимо в один обход получить индекс и значение.
 * Эквивалентно вызову [array.prototype.indexOf(), array.prototype.find()]
 * @param {*} arr - массив
 * @param {*} cb - функция-предикат
 * @returns {arr} [index, value]
 */
const findItemBy = (arr, cb) => {
  if (!arr || !Array.isArray(arr) || typeof cb !== 'function') return undefined

  for (let i = 0; i < arr.length; i++) {
    if (cb(arr[i])) return [i, arr[i]]
  }

  return [undefined, undefined]
}

/**
 * Возвращает width и height изображения
 */
const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.naturalHeight,
        width: img.naturalWidth,
      })
    }
    img.src = dataURL
  })

export const FILE_SIZE = function (rule, value, callback, source, options) {
  const [fileIndex, overflowedFile] = findItemBy(
    value,
    (file) => ByteToMB(file.size) > rule.size,
  )

  if (!overflowedFile || value === '' || value === null) {
    return true
  } else {
    return callback(
      format(
        options.messages[rule.validator.name],
        rule.fullField,
        overflowedFile.name,
        fileIndex + 1,
        rule.size,
      ),
    )
  }
}

export const FILE_FORMAT = function (rule, value, callback, source, options) {
  const [fileIndex, errorFile] = findItemBy(
    value,
    (file) => !rule.formats.includes(file.type),
  )

  if (!errorFile || value === '' || value === null) {
    return true
  } else {
    return callback(
      format(
        options.messages[rule.validator.name],
        rule.fullField,
        errorFile.name,
        fileIndex + 1,
        rule.formats,
      ),
    )
  }
}

export const FILE_LENGTH = function (rule, value, callback, source, options) {
  const msg = rule.max
    ? options.messages[`${rule.validator.name}_RANGE`]
    : options.messages[rule.validator.name]

  if (
    value?.length >= rule.min &&
    (rule.max ? value.length <= rule.max : true)
  ) {
    return true
  } else {
    return callback(format(msg, rule.fullField, rule.min, rule.max))
  }
}

export const FILE_SAVED = function (rule, value, callback, source, options) {
  const msg =
    value?.length > 1
      ? options.messages[`${rule.validator.name}_MULTIPLE`]
      : options.messages[rule.validator.name]
  // eslint-disable-next-line no-unused-vars
  const [_, errorFile] = findItemBy(value, (file) => !file.url)
  if (!errorFile || value === '' || value === null) {
    return true
  } else {
    return callback(format(msg, rule.fullField))
  }
}

export const FILE_WIDTH_HEIGHT = async function (
  rule,
  files,
  callback,
  source,
  options,
) {
  let [fileIndex, errorFile] = [undefined, undefined]

  for await (let [i, file] of files?.entries()) {
    if (!file?.type?.includes('image')) continue

    const fileAsDataURL = window.URL.createObjectURL(file?.originFileObj)
    const demension = await getHeightAndWidthFromDataUrl(fileAsDataURL)
    if (
      rule.width
        ? demension.width > rule.width
        : true && rule.height
        ? demension.height > rule.height
        : true
    ) {
      errorFile = file
      fileIndex = i
      break
    }
  }

  if (
    !errorFile ||
    files === '' ||
    files === null ||
    (!rule.width && !rule.height)
  ) {
    return true
  } else {
    const msg =
      options.messages[
        rule.width && rule.height
          ? rule.validator.name
          : rule.height
          ? 'FILE_HEIGHT'
          : 'FILE_WIDTH'
      ]

    return callback(
      format(
        msg,
        rule.fullField,
        errorFile.name,
        fileIndex + 1,
        rule.width ? rule.width : rule.height || '',
        rule.height,
      ),
    )
  }
}
