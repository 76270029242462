export const paymentTypesColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'payment_id',
    width: '250px',
    scopedSlots: { customRender: 'payment_id' },
  },
  {
    title: 'Иконка',
    dataIndex: 'icon_url',
    key: 'payment_icon',
    width: '250px',
    scopedSlots: { customRender: 'payment_icon' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'payment_name',
    scopedSlots: { customRender: 'payment_name' },
  },
  {
    title: 'Статус',
    dataIndex: 'is_active',
    key: 'payment_status',
    width: '50px',
    scopedSlots: { customRender: 'payment_status' },
  },
  {
    title: 'Приоритет в списке',
    dataIndex: 'weight',
    key: 'payment_weight',
    width: '250px',
    scopedSlots: { customRender: 'payment_weight' },
  },
]
