export const formState = {
  id: null,
  name: null,
  title: {
    ru: null,
    kz: null,
    en: null,
  },
  links: [],
  is_active: false,
}

export const rules = {
  id: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
}

export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Картинка',
    dataIndex: 'image_url',
    key: 'image_url',
    scopedSlots: { customRender: 'imageUrl' },
    align: 'center',
    width: '100px',
  },
  {
    title: 'Приоритет',
    dataIndex: 'order_num',
    key: 'order_num',
    align: 'center',
  },
  {
    title: 'Статус',
    dataIndex: 'is_active',
    key: 'is_active',
    scopedSlots: { customRender: 'isActive' },
    align: 'center',
  },
  {
    title: 'Устройства',
    dataIndex: 'devices',
    key: 'devices',
    scopedSlots: { customRender: 'devices' },
    align: 'center',
  },
]
