<template>
  <div>
    <div class="settings-wrap">
      <h2>Базовые продукты</h2>
      <a-button
        v-can="{
          service: 'storefront',
          permissions: ['base-product-create'],
        }"
        @click="() => $router.push({ name: 'BaseProductCreate' })"
        >Создать новый
      </a-button>
    </div>
    <p class="info">
      ( Всего найдено: <code>{{ pagination.total }}</code>
      )
    </p>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onSearch">
      <div class="settings-wrap">
        <a-form-model-item label="Поиск">
          <a-input v-model="filter.search" />
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
      <div class="expanded-filter">
        <a-collapse>
          <a-collapse-panel key="1" header="Расширенные фильтры">
            <div class="expanded-filter-box">
              <a-form-model-item label="Статус">
                <a-radio-group
                  :default-value="null"
                  button-style="solid"
                  v-model="filter.is_enabled"
                  @change="searchHandler"
                >
                  <a-radio-button :value="null"> Все</a-radio-button>
                  <a-radio-button :value="true">
                    Только активные
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Только выключенные
                  </a-radio-button>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="Картинки">
                <a-radio-group
                  :default-value="null"
                  button-style="solid"
                  v-model="filter.without_media"
                  @change="searchHandler"
                >
                  <a-radio-button :value="null"> Все</a-radio-button>
                  <a-radio-button :value="true"> Без картинок</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="ВГХ">
                <a-radio-group
                  :default-value="null"
                  button-style="solid"
                  v-model="filter.without_dimensions"
                  @change="searchHandler"
                >
                  <a-radio-button :value="null"> Все</a-radio-button>
                  <a-radio-button :value="true">
                    ВГХ не указан(хотя бы одно поле равно 1)
                  </a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </div>
            <div class="expanded-filter-box">
              <a-form-model-item label="ID модели">
                <a-input-number
                  style="width: 220px"
                  v-model="filter.model_id"
                />
              </a-form-model-item>
              <a-form-model-item
                label="ID группы(0 для поиска по продуктам без группы)"
              >
                <a-input-number
                  style="width: 220px"
                  v-model="filter.group_id"
                />
              </a-form-model-item>
              <!--            <a-form-model-item-->
              <!--              label="Не включать эти БП(укажите id через запятую, можно с пробелом и без)"-->
              <!--            >-->
              <!--              <a-textarea-->
              <!--                allow-clear-->
              <!--                v-model="filter.exclude"-->
              <!--                :auto-size="{ minRows: 2, maxRows: 4 }"-->
              <!--              />-->
              <!--            </a-form-model-item>-->
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ sku }) => sku"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="sku" slot-scope="sku">
        <router-link :to="`/base-product/${sku}`">
          <code>{{ sku }}</code>
        </router-link>
      </div>
      <div slot="title" slot-scope="title, sku">
        <template v-if="sku">
          <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
            {{ title.ru }}
            <a-icon type="link" />
          </a>
        </template>
      </div>
      <div slot="imageUrl" slot-scope="imageUrl">
        <div
          class="img-wrap"
          :style="{ backgroundImage: `url(${imageUrl})` }"
        />
      </div>
      <div slot="categories" slot-scope="categories">
        <a-badge
          v-for="item in categories"
          :key="item.slug"
          class="category-item"
          status="processing"
          :text="item.title.ru"
        />
      </div>
      <div slot="isEnabled" slot-scope="isEnabled, record">
        <a-button
          v-if="isEnabled"
          type="primary"
          @click="statusToggleHandler(record, isEnabled)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="statusToggleHandler(record, isEnabled)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { columnNames } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'BaseProductList',
  data: () => ({
    columnNames,
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      search: null,
      page: 1,
      limit: 10,
      exclude: null,
      model_id: null,
      group_id: null,
      is_enabled: null,
      without_media: null,
      without_dimensions: null,
    },
    showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
  }),
  methods: {
    onSearch(event) {
      event.preventDefault()
      this.searchHandler()
    },
    async searchHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getBaseProductList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getBaseProductList()
    },
    initialize() {
      try {
        this.isLoading = true
        this.getBaseProductList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getBaseProductList() {
      const { products, total } = await this.$API.Storefront.getBaseProductList(
        this.filter,
      )
      this.pagination.total = total
      this.dataSource = products
      this.isLoading = false
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        search: null,
        page: 1,
        limit: 10,
        exclude: null,
        model_id: null,
        group_id: null,
        is_enabled: null,
        without_media: null,
        without_dimensions: null,
      }
      this.getBaseProductList()
    },
    async statusToggleHandler(record, isActive) {
      try {
        await this.$API.Storefront.toggleBaseProductStatus(
          record.sku,
          !isActive,
        )
        showSuccessMsg('Удачно обновлен!')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    if (this.$route.query.sku) {
      this.filter.search = this.$route.query.sku
    }
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.img-wrap {
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
  border-radius: 4px;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  animation: loadingPlaceholder 1s infinite;
  background-position: center center;
}

@keyframes loadingPlaceholder {
  from {
    background-color: #e8e8e8;
  }
  to {
    background-color: #e0e0e0;
  }
}

.expanded-filter {
  margin-bottom: 20px;

  &-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
  }
}
.category-item {
  margin-right: 8px;
}
</style>
