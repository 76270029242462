export const templateFormRules = {
  body: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
    trigger: 'change',
  },
  channel: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
    trigger: 'change',
  },
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  readable_id: [
    {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    {
      pattern: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/,
      message: 'Невалидный семантический URL',
    },
  ],
  receiver: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
    trigger: 'change',
  },
  subject: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}

export const channelTypes = [
  {
    id: 'sms',
    title: 'СМС',
  },
  {
    id: 'email',
    title: 'Эл. почта',
  },
  {
    id: 'push',
    title: 'Push уведомление',
  },
]

export const receiverTypes = [
  {
    id: 'admin',
    title: 'Админ',
  },
  {
    id: 'client',
    title: 'Клиент',
  },
  {
    id: 'merchant',
    title: 'Партнер',
  },
]

export const shortcuts = ['bin', 'birth_date', 'fio']
