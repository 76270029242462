<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col span="16">
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-col>
            <h2>Созадние Модели</h2>
          </a-col>
          <a-col>
            <a-button @click="() => $router.push({ name: 'ModelList' })">
              {{ $t('common.back') }}
            </a-button>
          </a-col>
        </a-row>
        <a-form-model
          ref="form"
          :model="formState"
          :rules="rules"
          @submit="onCreate"
        >
          <a-divider>Название</a-divider>
          <a-form-model-item prop="title.ru" label="RU(на русском)">
            <a-input v-model="formState.title.ru" />
          </a-form-model-item>
          <a-form-model-item prop="title.kz" label="KZ(на казахском)">
            <a-input v-model="formState.title.kz" />
          </a-form-model-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">Создать</a-button>
          </a-form-item>
        </a-form-model>
      </a-col>
      <p class="info">Атрибуты создаются внутри уже созданной модели.</p>
    </a-row>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'ModelCreate',
  data: () => ({
    formState: { ...formState },
    rules,
  }),
  methods: {
    onCreate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createModelHandler()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createModelHandler() {
      const { id } = await this.$API.Storefront.createModel(this.formState)
      await this.$router.push(`/model-edit/${id}`)
    },
  },
}
</script>

<style scoped></style>
