/**
 * Виджеты для Яндекс Карты
 * @see https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/reference/control.Manager.html#method_detail__add-param-control
 */
export enum YandexMapWidgets {
  /**
   * кнопка разворачивания карты на весь экран
   */
  FULLSCREEN = 'fullscreenControl',
  /**
   * кнопка определения местоположения пользователя
   */
  GEOLOCATION = 'geolocationControl',
  /**
   * кнопка включения и отключения поведения "линейка"
   */
  RULER = 'rulerControl',
  /**
   * поисковая строка
   */
  SEARCH = 'searchControl',
  /**
   * панель пробок
   */
  TRAFFIC = 'trafficControl',
  /**
   * панель переключения типа карты
   */
  TYPE_SELECTOR = 'typeSelector',
  /**
   * ползунок масштаба
   */
  ZOOM = 'zoomControl',
  /**
   * панель для построения маршрутов
   */
  ROUTE_BUTTON = 'routeButtonControl',
  /**
   * панель маршрутизации
   */
  ROUTE_PANEL = 'routePanelControl',
}

interface IMapOptions {
  autoFitToViewport?: 'none' | 'ifNull' | 'always'
  avoidFractionalZoom?: boolean
  exitFullscreenByEsc?: boolean
  fullscreenZIndex?: number
  mapAutoFocus?: boolean
  maxAnimationZoomDifference?: number
  maxZoom?: number
  minZoom?: number
  nativeFullscreen?: boolean
  restrictMapArea?: boolean
  suppressMapOpenBlock?: boolean
  suppressObsoleteBrowserNotifier?: boolean
  yandexMapAutoSwitch?: boolean
  yandexMapDisablePoiInteractivity?: boolean

  [index: string]: any
}

export type Coordinate = [number, number] | []

export const MAP_RANGES = {
  MAX_LAT: 90,
  MIN_LAT: -90,
  MAX_LONG: 180,
  MIN_LONG: -180,
}
