export const formState = {
  description: {
    kz: null,
    ru: null,
  },
  group_id: null,
  has_options: true,
  is_collection: true,
  is_description: true,
  is_required: true,
  is_variable: true,
  slug: null,
  title: {
    kz: null,
    ru: null,
  },
  type: 'StringType',
  weight: 0,
}

export const optionsState = [
  {
    description: null,
    value: {
      ru: null,
      kz: null,
    },
  },
]

export const rules = {
  weight: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  group_id: {
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  description: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
  },
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
  },
}
