export const platformsColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'platform_id',
    width: '250px',
    scopedSlots: { customRender: 'platform_id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'platform_name',
    width: '250px',
    scopedSlots: { customRender: 'platform_name' },
  },
  {
    title: 'Операции',
    dataIndex: 'id',
    key: 'platform_actions',
    width: '250px',
    scopedSlots: { customRender: 'platform_actions' },
  },
]

export const platformFormRules = {
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}
