import FinanceList from 'Finances/pages/FinanceList/FinanceList'

export default [
  {
    path: '/finances',
    name: 'FinanceList',
    component: FinanceList,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Финансы',
        icon: 'dollar',
      },
      failPath: '/censor',
    },
  },
]
