<template>
  <a-layout-header theme="light" class="header">
    <a-icon
      class="header__trigger"
      :type="isCollapsed ? 'menu-unfold' : 'menu-fold'"
      @click="onTriggerClick"
    />
    <div class="header__menu">
      <account />
      <logger />
    </div>
  </a-layout-header>
</template>

<script>
import Account from '@/modules/User/components/Account'
import './header.scss'
import Logger from 'Core/components/Logger/Logger'

export default {
  name: 'AppHeader',
  components: {
    Account,
    Logger,
  },
  props: {
    isCollapsed: Boolean,
    onTriggerClick: Function,
  },
  methods: {
    async onLogout() {
      await this.$API.SSO.LogOut()
    },
  },
}
</script>
