import OrderList from 'Order/pages/OrderList/OrderList.vue'
import OrderInfo from 'Order/pages/OrderInfo/OrderInfo.vue'

import PurchaseReturnList from 'Order/pages/PurchaseReturn/PurchaseReturnList/PurchaseReturnList'
import PurchaseReturnInfo from 'Order/pages/PurchaseReturn/PurchaseReturnInfo/PurchaseReturnInfo'

export default [
  {
    path: '/orders',
    name: 'OrdersList',
    component: OrderList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      search: {
        name: 'Все заказы',
        icon: 'shopping',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/order/:id',
    name: 'OrderInfo',
    component: OrderInfo,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['buyer-order-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/purchase_returns',
    name: 'PurchaseReturnList',
    component: PurchaseReturnList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      search: {
        name: 'Заявки на возврат',
        icon: 'fall',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/purchase_returns/:id',
    name: 'PurchaseReturnInfo',
    component: PurchaseReturnInfo,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      failPath: '/censor',
    },
  },
]
