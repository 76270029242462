<template>
  <a-spin :spinning="isLoading" v-if="profile">
    <div v-if="profile">
      <router-link :to="`/partner/${mid}`">
        <a>{{ profile.store_name }} #{{ mid }}</a>
      </router-link>

      <show-image :url="profile.logo_url" class="mb--4" />

      <a-descriptions>
        <a-descriptions-item label="Статус">
          <status-view
            :status-list="PARTNER_STATUSES"
            :status="profile.status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Дата создания">
          {{ profile.created | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Дата последнего обновления">
          {{ profile.updated | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ profile.contact_info.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер телефона">
          {{ profile.contact_info.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер счета в банке">
          {{ paymentRequisites.account_bank_number }}
        </a-descriptions-item>
        <a-descriptions-item label="БИК">
          {{ paymentRequisites.bik }}
        </a-descriptions-item>
        <a-descriptions-item label="БИН">
          {{ paymentRequisites.bin }}
        </a-descriptions-item>
        <a-descriptions-item label="КБЕ">
          {{ paymentRequisites.kbe }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </a-spin>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { PARTNER_STATUSES } from 'Core/types/partner'

import ShowImage from 'Core/components/Images/ShowImage.vue'

export default {
  name: 'PartnerProfile',
  components: { ShowImage },
  props: {
    mid: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    profile: null,
    PARTNER_STATUSES,
  }),
  mounted() {
    this.getProfile()
  },
  methods: {
    async getProfile() {
      try {
        this.isLoading = true
        this.profile = await this.$API.MerchantProfile.getPartnerById(this.mid)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
  },
  computed: {
    paymentRequisites() {
      return this.profile?.company_info?.payment_requisites
    },
  },
}
</script>

<style scoped>
a {
  font-size: 20px;
}
</style>
