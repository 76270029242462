export const SEARCH_FIELDS = {
  phone: {
    value: 'phone',
    label: 'Номер телефона',
    variant: 'blue',
    icon: 'phone',
  },
  email: {
    value: 'email',
    label: 'Email',
    variant: 'orange',
    icon: 'mail',
  },
  fio: {
    value: 'fio',
    label: 'ФИО',
    variant: 'red',
    icon: 'user',
  },
}

export const CLIENTS_LIST_COLUMNS = [
  {
    title: 'ID клиента',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'right',
  },
  {
    title: 'ФИО',
    dataIndex: 'fio',
    key: 'lastname',
    scopedSlots: { customRender: 'fio' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Верифицирован в Pay',
    dataIndex: 'is_phone_verified',
    key: 'is_phone_verified',
    scopedSlots: { customRender: 'is_phone_verified' },
    align: 'center',
  },
]

export interface IFilter {
  current: number
  total: number
  defaultPageSize: number
  field?: string
  search?: any
  phone?: string
  email?: string
  first_name?: string
  last_name?: string
  patronymic?: string
}

export const FILTER_DEFAULT = {
  current: 1,
  total: 0,
  defaultPageSize: 10,
  field: '',
  search: '',
}
