export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Заголовок',
    dataIndex: 'agreement.title',
    key: 'agreement',
    scopedSlots: { customRender: 'agreeTitle' },
  },

  {
    title: 'Код',
    dataIndex: 'code',
    key: 'code',
    width: '100px',
  },
  {
    title: 'Обязательная верификация',
    dataIndex: 'verification_required',
    key: 'verification_required',
    width: '150px',
    scopedSlots: { customRender: 'verificationRequired' },
    align: 'center',
  },
  {
    title: 'Последнее обновление',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updatedAt' },
  },
  {
    title: 'Версия документа',
    dataIndex: 'agreement.version',
    key: 'agreement.version',
    align: 'center',
    width: '120px',
  },
]
