export const config = {
  cities: 'Города',
  city: 'Город',
  guidCity: 'GUID города',
  cityNameRu: 'Название города (Ru)',
  cityNameKz: 'Название города (Kz)',
  cityNameEn: 'Название города (En)',
  cityTitle: 'Название транслитом',
  postIndex: 'Почтовый индекс',
  cityUpdate: 'Обновление города',
  cityCreate: 'Создание города ',
  enterCityNameRu: 'Введите название города на русском',
  enterCityNameEn: 'Введите название города на английском',
  enterCityNameKz: 'Введите название города казахском',
  enterCityTitle: 'Введите название транслитом',
  enterCityGuid: 'Введите GUID города',
  chooseCountry: 'Выберите страну',
  searchByCityName: 'Поиск по названию города',
}
