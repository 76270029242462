<template>
  <div>
    <div class="settings-wrap">
      <h2>Список типов платформ</h2>
      <a-button @click="openModal">Создать</a-button>
    </div>
    <a-table
      :columns="platformsColumns"
      :data-source="platforms"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      bordered
    >
      <div slot="platform_id" slot-scope="id">
        {{ id | shortID(5) }}
      </div>
      <div slot="platform_name" slot-scope="platform_name">
        {{ platform_name }}
      </div>
      <div
        slot="platform_actions"
        slot-scope="id, platform"
        class="platform__actions"
      >
        <a-button type="primary" @click="openModal(platform)">
          <a-icon type="edit" />
        </a-button>

        <a-popconfirm
          title="Вы уверены?"
          ok-text="Да"
          cancel-text="Отмена"
          @confirm="deletePlatform(id)"
        >
          <a-button type="danger">
            <a-icon type="delete" />
          </a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal
      :visible="createEditVisible"
      :footer="null"
      @cancel="closeModal"
      :title="
        selectedPlatform.id
          ? 'Редактирование типа платформы'
          : 'Создание типа платформы'
      "
    >
      <a-form-model
        ref="form"
        :model="selectedPlatform"
        :rules="platformFormRules"
        @submit="onSubmit"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="selectedPlatform.name" />
        </a-form-model-item>

        <a-button type="primary" html-type="submit">Сохранить</a-button>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { platformsColumns, platformFormRules } from './constants'

export default {
  data() {
    return {
      platformsColumns,
      platformFormRules,
      createEditVisible: false,
      selectedPlatform: { id: null, name: null },
    }
  },
  computed: {
    platforms() {
      return this.$store.getters['config/platforms/platforms']
    },
    isLoading() {
      return this.$store.getters['config/platforms/isLoading']
    },
  },

  methods: {
    openModal(platform) {
      if (platform.id) this.selectedPlatform = platform
      this.createEditVisible = true
    },
    closeModal() {
      this.selectedPlatform = { id: null, name: null }
      this.$refs.form.clearValidate()
      this.createEditVisible = false
    },
    async onSubmit() {
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isSubmitLoading = true
            this.selectedPlatform.id
              ? await this.$API.ConfigDiscovery.updatePlatform(
                  this.selectedPlatform.id,
                  this.selectedPlatform,
                )
              : await this.$API.ConfigDiscovery.createPlatform(
                  this.selectedPlatform,
                )
            await this.$store.dispatch('config/platforms/fetchPlatforms')
            this.closeModal()
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isSubmitLoading = false
          }
        }
      })
    },
    async deletePlatform(id) {
      try {
        this.isDeleteLoading = true
        await this.$API.ConfigDiscovery.deletePlatform(id)
        await this.$store.dispatch('fetchConfigPlatforms')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isDeleteLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.platform {
  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
