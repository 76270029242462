<template>
  <a-form-model-item :label="label" :prop="prop">
    <the-mask
      @input="handleInput"
      class="ant-input"
      :v-facade="mask"
      v-model="localValue"
      :disabled="disabled"
    />
  </a-form-model-item>
</template>

<script>
export default {
  name: 'MaskedInput',
  props: {
    label: {
      type: String,
    },
    mask: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prop: {
      type: String,
    },
    value: null,
  },
  data() {
    return {
      localValue: this.value,
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localValue = newValue
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.localValue)
    },
  },
}
</script>

<style scoped></style>
