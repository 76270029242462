import UserList from 'User/pages/Users/UserList/UserList'
import UserEdit from 'User/pages/Users/UserEdit/UserEdit'

export default [
  {
    path: '/users',
    name: 'UserList',
    component: UserList,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      search: {
        name: 'Пользователи админки',
        icon: 'team',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/user/:id',
    name: 'UserEdit',
    component: UserEdit,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      failPath: '/censor',
    },
  },
]
