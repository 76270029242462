




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { clientProfile } from 'Client/pages/ClientInfo/constants'
import { showErrorMsg } from '@/Core/utils'

@Component
class ClientProfile extends Vue {
  @Prop({ required: true, type: String })
  id: any
  @Prop()
  requisites: any

  isLoading = false
  profile = clientProfile
  $API: any

  async getUserProfile() {
    try {
      this.isLoading = true
      this.profile = await this.$API.SSO.getUserByTdId(this.id)
    } catch (error) {
      showErrorMsg(error.msg)
    } finally {
      this.isLoading = false
    }
  }

  get isClientPage() {
    return this.$route.name === 'ClientInfo'
  }

  mounted() {
    this.getUserProfile()
  }
}

export default ClientProfile
