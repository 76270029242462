<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание способа оплаты</h2>
      <router-link :to="{ name: 'ConfigPaymentList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-form-model
      ref="form"
      :model="paymentTypeForm"
      :rules="paymentTypeFormRules"
      @submit="createPaymentType"
    >
      <a-form-model-item prop="name" label="Название">
        <a-input v-model="paymentTypeForm.name" />
      </a-form-model-item>
      <a-form-model-item prop="icon_url" label="Иконка способа оплаты">
        <upload-files
          @getImageParams="getImageParams"
          :img-url="paymentTypeForm.icon_url"
          placeholder-text="Загрузить иконку"
        />
      </a-form-model-item>
      <a-form-model-item prop="partial_delivery" label="Частичная поставка">
        <a-radio-group v-model="paymentTypeForm.partial_delivery">
          <a-radio :value="true">Да</a-radio>
          <a-radio :value="false">Нет</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="dividable" label="Делимость">
        <a-radio-group v-model="paymentTypeForm.dividable">
          <a-radio :value="true">Делимый</a-radio>
          <a-radio :value="false">Неделимый</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="is_active" label="Статус">
        <a-checkbox v-model="paymentTypeForm.is_active">
          {{ paymentTypeForm.is_active ? 'Активный' : 'Неактивный' }}
        </a-checkbox>
      </a-form-model-item>
      <a-form-model-item prop="type" label="Тип оплаты">
        <a-select
          v-model="paymentTypeForm.type"
          placeholder="Выберите тип оплаты"
        >
          <a-select-option
            v-for="(type, index) in paymentTypes"
            :key="index"
            :value="type.value"
          >
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="weight" label="Приоритет в списке">
        <a-input-number v-model="paymentTypeForm.weight" :min="1" />
      </a-form-model-item>
      <a-button
        type="primary"
        html-type="submit"
        class="form__button"
        :loading="isCreateLoading"
        >Сохранить
      </a-button>
    </a-form-model>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import {
  paymentTypeFormRules,
  paymentTypes,
} from '../ConfigPaymentEdit/constants'

export default {
  components: {
    UploadFiles,
  },
  data() {
    return {
      paymentTypeFormRules,
      paymentTypes,
      isCreateLoading: false,
      paymentTypeForm: {
        dividable: false,
        icon_url: null,
        is_active: true,
        name: null,
        partial_delivery: false,
        type: null,
        weight: 1,
      },
    }
  },
  methods: {
    getImageParams(params) {
      this.paymentTypeForm.icon_url = params
    },

    async createPaymentType(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isCreateLoading = true
            await this.$API.ConfigDiscovery.createPayment(this.paymentTypeForm)
            showSuccessMsg('Тип оплаты успешно создан!')
            await this.$router.push({ name: 'ConfigPaymentList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isCreateLoading = true
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;

  &--danger {
    margin-left: 20px;
  }
}
</style>
