import { GET, POST, PUT, DELETE } from 'Main/api/REST'

const baseUrl = `${process.env.VUE_APP_API_URL}/roles/api/v1`
const SSO_baseUrl = `${process.env.VUE_APP_API_URL}/sso/api/v1`

export const getEmployeeListAPI = async (params) => {
  const { data, success } = await GET(`${baseUrl}/employees`, params)
  if (!success) {
    throw new Error(
      `Ошибка при запросе списка сотрудников: ${data?.error?.message}`,
    )
  }
  return data
}

export const getEmployeeByIdAPI = async (id) => {
  const { data, success } = await GET(`${baseUrl}/employees/${id}`)
  if (!success) {
    throw new Error(
      `Ошибка при запросе Информации сотрудника: ${data?.error?.message}`,
    )
  }
  return data
}

export const getAllowedRoleListAPI = async (params) => {
  const { data, success } = await GET(
    `${baseUrl}/employees/roles/allowed`,
    params,
  )
  if (!success) {
    throw new Error(
      `Ошибка при запросе списка сотрудников: ${data?.error?.message}`,
    )
  }
  return data
}

export const searchUserAPI = async (phone) => {
  const { data, success } = await GET(`${SSO_baseUrl}/users`, { phone: phone })
  if (!success) {
    throw new Error(
      `Ошибка при запросе списка сотрудников: ${data?.error?.message}`,
    )
  }
  return data
}

export const getUserByIdAPI = async (id) => {
  const { data, success } = await GET(`${SSO_baseUrl}/users/${id}`)
  if (!success) {
    throw new Error(
      `Ошибка при получении пользователя по ID: ${data?.error?.message}`,
    )
  }
  return data
}

export const createUserByPhoneAPI = async (phone) => {
  const { data, success } = await POST(`${SSO_baseUrl}/users`, { phone })
  if (!success) {
    throw new Error(
      `Ошибка при регистрации пользователя: ${data?.error?.message}`,
    )
  }
  return data
}

export const createEmployeeAPI = async (params) => {
  const { data, success } = await POST(`${baseUrl}/employees`, params)
  if (!success) {
    throw new Error(`Ошибка при создании сотрудника: ${data?.error?.message}`)
  }
  return data
}

export const updateEmployeeByIdAPI = async (id, params) => {
  const { data, success } = await PUT(`${baseUrl}/employees/${id}`, params)
  if (!success) {
    throw new Error(`Ошибка при создании сотрудника: ${data?.error?.message}`)
  }
  return data
}

export const deactivateEmployeeByIdAPI = async (id) => {
  const { data, success } = await POST(`${baseUrl}/employees/deactivated`, [id])
  if (!success) {
    throw new Error(`Ошибка при создании сотрудника: ${data?.error?.message}`)
  }
  return data
}

export const deleteEmployeeByIdAPI = async (id) => {
  const { data, success } = await DELETE(`${baseUrl}/employees/${id}`)
  if (!success) {
    throw new Error(`Ошибка при удалении сотрудника: ${data?.error?.message}`)
  }
  return data
}
