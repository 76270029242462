<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание шаблона</h2>
      <router-link :to="{ name: 'MailingTemplateList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-form-model
      ref="form"
      :model="templateForm"
      :rules="templateFormRules"
      @submit="createTemplate"
    >
      <a-form-model-item prop="name" label="Название">
        <a-input v-model="templateForm.name" />
      </a-form-model-item>
      <a-form-model-item prop="readable_id" label="Слаг рассылки">
        <a-input v-model="templateForm.readable_id">
          <a-tooltip
            slot="suffix"
            title="Слаг должен состоят из латинских букв, и слова должны быть прописаны через тире(-)"
          >
            <a-icon type="info-circle" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="receiver" label="Получатель">
        <a-select
          placeholder="Выберите получателя"
          style="width: 100%"
          v-model="templateForm.receiver"
        >
          <a-select-option
            v-for="type in receiverTypes"
            :key="type.id"
            :value="type.id"
          >
            {{ type.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="channel" label="Канал доставки">
        <a-select
          placeholder="Выберите канал доставки"
          style="width: 100%"
          :value="templateForm.channel"
          @select="onSelectChannel"
        >
          <a-select-option
            v-for="type in channelTypes"
            :key="type.id"
            :value="type.id"
          >
            {{ type.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <template v-if="templateForm.channel === 'email'">
        <a-form-model-item prop="subject" label="Тема письма">
          <span>Шорткаты: </span>
          <a-tag
            v-for="shortcut in shortcuts"
            :key="shortcut"
            @click="addShortcut('subject', shortcut)"
            >{{ shortcut }}
          </a-tag>
          <a-input v-model="templateForm.subject" />
        </a-form-model-item>
        <a-form-model-item prop="body" label="Тело шаблона">
          <!-- <a-textarea
            :autoSize="{ minRows: 12, maxRows: 20 }"
            class="promo-page__html"
            v-model="templateForm.body"
          /> -->
          <code-editor v-model="templateForm.body" :options="cmOptions" />

          <a-button size="small" @click="onOpenPreview">
            Посмотреть шаблон
          </a-button>
          <a-drawer
            title="Просмотр шаблона"
            :width="templateForm.body ? '60%' : '45%'"
            :visible="isTemplatePreviewVisible"
            :closable="false"
          >
            <div v-html="templateForm.body" v-if="templateForm.body"></div>
            <p v-else>Шаблон пустой. Пожалуйста внесите html разметку</p>
            <div class="button-absolute-box">
              <a-button @click="onClosePreview"> Закрыть</a-button>
            </div>
          </a-drawer>
        </a-form-model-item>
      </template>
      <template v-else>
        <a-form-model-item prop="body" label="Тело шаблона">
          <a-tag
            v-for="shortcut in shortcuts"
            :key="shortcut"
            @click="addShortcut('body', shortcut)"
            >{{ shortcut }}
          </a-tag>
          <a-textarea
            placeholder="Заполните тело сообщения"
            v-model="templateForm.body"
            :auto-size="{ minRows: 6, maxRows: 12 }"
          />
        </a-form-model-item>
      </template>

      <a-button
        type="primary"
        html-type="submit"
        class="form__button"
        :loading="isCreateLoading"
        v-can="{
          service: 'jam',
          permissions: ['template-create'],
        }"
        >Сохранить
      </a-button>
    </a-form-model>
  </div>
</template>

<script>
import { codemirror as CodeEditor } from 'vue-codemirror'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import {
  templateFormRules,
  channelTypes,
  receiverTypes,
  shortcuts,
} from '../MailingTemplateEdit/constants'

import 'codemirror/mode/htmlembedded/htmlembedded.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/theme/material.css'

export default {
  components: {
    CodeEditor,
  },
  data() {
    return {
      shortcuts,
      templateFormRules,
      isTemplatePreviewVisible: false,
      templateForm: {
        body: null,
        channel: 'email',
        name: null,
        readable_id: null,
        receiver: null,
        subject: null,
      },
      channelTypes,
      receiverTypes,
      isCreateLoading: false,
      cmOptions: {
        tabSize: 4,
        mode: 'text/html',
        theme: 'material',
        lineNumbers: true,
        line: true,
      },
    }
  },
  methods: {
    async createTemplate(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isCreateLoading = true
            await this.$API.Jam.createTemplate(this.templateForm)
            showSuccessMsg('Шаблон удачно создан!')
            this.$router.push({ name: 'MailingTemplateList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isCreateLoading = false
          }
        }
      })
    },
    onOpenPreview() {
      this.isTemplatePreviewVisible = true
    },
    onClosePreview() {
      this.isTemplatePreviewVisible = false
    },
    addShortcut(objKey, shortcut) {
      this.templateForm[objKey] = `${
        this.templateForm[objKey] ? this.templateForm[objKey] : ''
      } {{${shortcut}}}`
    },
    onSelectChannel(value) {
      this.templateForm.channel = value
      this.templateForm.subject = null
      this.templateForm.body = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~codemirror/lib/codemirror.css';

.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;
}
</style>
