import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {
  IProductReview,
  IProductReviewParams,
} from 'Core/modules/API/@types/Feedback.type'

export class FeedbackService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'feedback',
    })
  }

  @Api
  async getProductReviews(
    params?: IProductReviewParams,
  ): Promise<{ limit: number; reviews: IProductReview[]; total: number }> {
    const response = (await this.GET(
      `/v1/admin/reviews/products`,
      params,
    )) as any
    return response
  }

  @Api
  async getProductReviewById(id): Promise<IProductReview> {
    const response = (await this.GET(`/v1/admin/reviews/products/${id}`)) as any
    return response
  }

  @Api
  async confirmReview(id) {
    const response = await this.PUT(`/v1/admin/reviews/products/${id}/confirm`)
    return response
  }

  @Api
  async rejectReview(id) {
    const response = await this.PUT(`/v1/admin/reviews/products/${id}/reject`)
    return response
  }
}
