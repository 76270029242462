var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Редактирование группы базовых продуктов")]),_c('a-button',{on:{"click":function () { return _vm.$router.push({ name: 'BaseProductGroupList' }); }}},[_vm._v(" Назад ")])],1),_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.groupForm,"rules":_vm.groupFormRules},on:{"submit":_vm.updateProductGroup}},[_c('a-form-model-item',{attrs:{"prop":"name","label":"Название"}},[_c('a-input',{model:{value:(_vm.groupForm.name),callback:function ($$v) {_vm.$set(_vm.groupForm, "name", $$v)},expression:"groupForm.name"}})],1),(_vm.modelInfo)?_c('a-form-model-item',{attrs:{"prop":"model_id","label":"Модель"}},[_c('a-input',{staticClass:"form__input",attrs:{"disabled":""},model:{value:(_vm.modelInfo.title.ru),callback:function ($$v) {_vm.$set(_vm.modelInfo.title, "ru", $$v)},expression:"modelInfo.title.ru"}})],1):_vm._e(),_c('a-form-model-item',[_c('a-button-group',[_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
              service: 'storefront',
              permissions: ['group-update'],
            }),expression:"{\n              service: 'storefront',\n              permissions: ['group-update'],\n            }"}],staticClass:"form__button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Сохранить ")]),_c('a-popconfirm',{attrs:{"title":"Удалить группу？","ok-text":"Да, удалить","cancel-text":"Нет"},on:{"confirm":_vm.deleteBaseProductsGroup}},[_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
                service: 'storefront',
                permissions: ['group-delete'],
              }),expression:"{\n                service: 'storefront',\n                permissions: ['group-delete'],\n              }"}],staticClass:"form__button",attrs:{"type":"danger"}},[_vm._v("Удалить ")])],1)],1)],1)],1),(_vm.groupForm.model_id)?[(_vm.groupProducts)?_c('a-form-model-item',{attrs:{"prop":"skus","label":"Список базовых продуктов"}},[_c('div',{staticClass:"product-wrap"},_vm._l((_vm.groupProducts),function(product){return _c('div',{key:product.sku},[_c('product-item',{attrs:{"product":product},on:{"deleteProduct":_vm.deleteProduct}})],1)}),0),_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
            service: 'storefront',
            permissions: ['group-update'],
          }),expression:"{\n            service: 'storefront',\n            permissions: ['group-update'],\n          }"}],attrs:{"type":"primary"},on:{"click":_vm.addBaseProduct}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Добавить базовый продукт ")],1)],1):_vm._e()]:_vm._e(),_c('bind-product-modal',{attrs:{"is-visible":_vm.isModalVisible,"request-params":_vm.params,"action-type":"bindToGroup"},on:{"closeModal":function($event){return _vm.onCloseModal($event)},"addProduct":_vm.onAddProduct}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }