<template>
  <div>
    <a-form-model-item>
      <a-button @click="onOpen">{{ buttonTitle }}</a-button>
    </a-form-model-item>
    <a-drawer
      :title="drawerTitle"
      :width="drawerWidth"
      :placement="drawerPlacement"
      :mask-closable="maskClosable"
      :keyboard="keyboardAction"
      @close="onClose"
      :destroy-on-close="destroyOnClose"
      :visible="visible"
    >
      <div class="button-absolute-box">
        <a-button-group>
          <a-button @click="onClose">
            <a-icon type="rollback" />
            Отмена
          </a-button>
        </a-button-group>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'Selector',
  props: {
    buttonTitle: {
      type: String,
      default: 'Выбрать',
    },
    drawerTitle: {
      type: String,
      default: 'Укажите тут заголовок',
    },
    drawerWidth: {
      type: String,
      // could be px or %
      default: '80%',
    },
    drawerPlacement: {
      type: String,
      default: 'right',
    },
    maskClosable: {
      // close by clicking outside
      type: Boolean,
      default: true,
    },
    keyboardAction: {
      // listener for keyboard click(esc for close drawer)
      type: Boolean,
      default: true,
    },
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    onOpen() {
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
  },
}
</script>
