import exceptions from '@/locales/exceptions/exceptions.json'
export class BusinessLogicException extends Error {
  readonly errorsList: object
  constructor(message: string) {
    if (!message) {
      console.error(
        '%c [Business Logic Exception] - Вызван без параметра. ',
        'color: #ffffff; background: red;',
      )
      super('Не передан текст ошибки.')
    } else {
      super(message)
    }
    Object.setPrototypeOf(this, BusinessLogicException.prototype)
    this.errorsList = exceptions
  }
  transformErrorMessage() {
    return this.errorsList[this.message]
      ? this.errorsList[this.message]
      : `Неизвестная ошибка ${this.message}`
  }
}
