



















































import { Component } from 'vue-property-decorator'
import CategorySelector from 'Product/components/CategorySelector.vue'
import ModelFinder from 'Product/components/ModelFinder.vue'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { rules, formState } from './constants'
import { FormModel } from 'ant-design-vue'
import MultipleBPCreationMixin from 'Content/mixins/MultipleBPCreation.mixin'
import UploadedFileInfo from 'Content/pages/MultipleBaseProductCreate/components/UploadedFileInfo.vue'

@Component({ components: { CategorySelector, ModelFinder, UploadedFileInfo } })
class UploadCSV extends MultipleBPCreationMixin {
  formState = formState
  rules = rules
  categoryParent: any[] = []
  categoryState: any[] = []
  modelState: string = ''
  isLoading = false
  $API: any
  fileUuid: null | string = null

  $refs: {
    form: FormModel
  }

  async initialize() {
    try {
      this.isLoading = true
      this.categoryParent = await this.$API.Storefront.getCategoryList({
        level: 1,
      })
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  onCreate() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        showErrorMsg('Заполните все поля')
        return
      }

      try {
        this.isLoading = true
        const formData = new FormData()
        const file = this.formState.files[0].originFileObj
        formData.append('file', file, file?.name)
        formData.append('model_id', this.formState.model_id)
        this.categoryState.forEach((state) => {
          formData.append('category', state.slug)
        })
        const res = await this.$API.ContentDownloader.parseCSV(formData)
        if (res?.uuid) this.fileUuid = res.uuid
        showSuccessMsg('Массовое создание БП началось')
        this.clearForm()
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    })
  }

  setCategory(category) {
    this.categoryState = category
    this.formState.categories = `${category[0].title.ru} > ${category[1].title.ru} > ${category[2].title.ru}`
  }

  onSetModel(selectedModel) {
    this.formState.model_id = selectedModel.id
    this.modelState = selectedModel.title.ru
  }

  handleChange(info) {
    this.formState.files = info.fileList
  }

  previewFile() {
    return false
  }

  onRemoveFile() {
    this.formState.files = []
    return true
  }

  clearForm() {
    this.formState = { categories: null, model_id: null, files: [] }
    this.categoryParent = []
    this.categoryState = []
    this.modelState = ''
  }

  mounted() {
    this.initialize()
  }
}

export default UploadCSV
