<template>
  <div>
    <div class="settings-wrap">
      <h2>Список вложенных категорий</h2>
      <a-button @click="gotoCreatePage(root_id)">
        Создать новую категорию
      </a-button>
    </div>
    <a-spin :spinning="isLoading">
      <a-tree v-if="nestedMenuList" draggable>
        <a-icon slot="icon" type="carry-out" />
        <a-tree-node
          v-for="baseParent in sortList(nestedMenuList)"
          :key="baseParent.id"
          :selectable="false"
        >
          <template v-slot:title>
            <span class="tree-title" @click="gotoUpdatePage(baseParent.id)">
              {{ baseParent.title.ru }}
              <a-icon v-if="!baseParent.is_active" type="eye-invisible" />
            </span>
            <a-icon
              slot="icon"
              type="plus-circle-o"
              @click="gotoCreatePage(baseParent.id)"
            />
          </template>
          <template v-if="baseParent.children">
            <a-tree-node
              v-for="secondaryParent in sortList(baseParent.children)"
              :selectable="false"
              :key="secondaryParent.id"
            >
              <template v-slot:title>
                <span
                  class="tree-title"
                  @click="gotoUpdatePage(secondaryParent.id)"
                >
                  {{ secondaryParent.title.ru }}
                  <a-icon
                    v-if="!secondaryParent.is_active"
                    type="eye-invisible"
                  />
                </span>
                <a-icon
                  slot="icon"
                  type="plus-circle-o"
                  @click="gotoCreatePage(secondaryParent.id)"
                />
              </template>
              <template v-if="secondaryParent.children">
                <a-icon slot="icon" type="carry-out" />
                <a-tree-node
                  v-for="tertiary in sortList(secondaryParent.children)"
                  :key="tertiary.id"
                >
                  <template v-slot:title>
                    <span
                      class="tree-title"
                      @click="gotoUpdatePage(tertiary.id)"
                    >
                      {{ tertiary.title.ru }}
                      <a-icon v-if="!tertiary.is_active" type="eye-invisible" />
                    </span>
                  </template>
                </a-tree-node>
              </template>
            </a-tree-node>
          </template>
        </a-tree-node>
      </a-tree>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'

export default {
  data() {
    return {
      nestedMenuList: null,
      isLoading: false,
      root_id: null,
      params: {
        depth: 3,
      },
    }
  },
  created() {
    this.getNestedMenuList()
  },
  methods: {
    async getNestedMenuList() {
      try {
        this.isLoading = true
        const { children, id } = await this.$API.Menu.getNestedMenuList(
          this.params,
        )
        this.root_id = id
        this.nestedMenuList = children
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    gotoUpdatePage(menu_id) {
      this.$router.push({
        name: 'NestedMenuEdit',
        params: {
          menu_id,
        },
      })
    },
    sortList(arr) {
      const newArr = [...arr]
      return newArr.sort((a, b) =>
        a.order_num > b.order_num ? 1 : b.order_num > a.order_num ? -1 : 0,
      )
    },
    gotoCreatePage(parentId) {
      this.$router.push({
        name: 'NestedMenuCreate',
        query: {
          parent_id: parentId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-title {
  margin-right: 15px;
}

.operation-popup {
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90px;
}
</style>
