<template>
  <div>
    <client-profile :id="tdId" :requisites="requisites" />
    <a-tabs default-active-key="0" type="card">
      <a-tab-pane key="0" tab="Основная информация">
        <client-requisites :id="tdId" :requisites="requisites" />
      </a-tab-pane>
      <a-tab-pane key="1" tab="Заказы">
        <client-orders
          :show-filter="false"
          :id="tdId"
          :data-source="orders"
          :total-count="ordersTotalCount"
          :loading="isOrdersLoading"
          @fetchOrdersList="getUserOrders"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'

import ClientProfile from 'CRM/components/Client/ClientProfile.vue'
import ClientRequisites from 'CRM/components/Client/ClientRequisites.vue'
import ClientOrders from 'CRM/components/Common/OrderList.vue'
import { clientRequisites } from 'CRM/pages/constants'

export default {
  name: 'ClientView',
  components: { ClientOrders, ClientProfile, ClientRequisites },
  data: () => ({
    isOrdersLoading: false,
    orders: [],
    ordersTotalCount: 0,
    requisites: clientRequisites,
  }),
  computed: {
    tdId() {
      return this.$route.params.tdId
    },
  },
  methods: {
    async getUserOrders({ id: tdid, ...otherParams }) {
      try {
        this.isOrdersLoading = true
        const { orders, total_count } = await this.$API.OMS.getOrderList({
          tdid,
          ...otherParams,
        })
        this.orders = orders
        this.ordersTotalCount = total_count
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isOrdersLoading = false
      }
    },
    async getUserRequisites() {
      try {
        this.isLoading = true
        this.requisites = await this.$API.UserProfile.searchUser({
          field: 'tdid',
          value: this.tdId,
        })
      } catch (error) {
        showErrorMsg(error.msg)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.getUserRequisites()
  },
}
</script>

<style></style>
