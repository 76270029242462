<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание баннера</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'BannerList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="bannerForm"
      :rules="bannerFormRules"
      @submit="createBanner"
    >
      <a-form-model-item prop="info.title" label="Заголовок">
        <a-input v-model="bannerForm.info.title" />
      </a-form-model-item>
      <a-form-model-item prop="info.text" label="Подзаголовок">
        <a-input v-model="bannerForm.info.text" />
      </a-form-model-item>
      <a-form-model-item prop="color.background" label="Цвет фона">
        <a-input v-model="bannerForm.color.background" class="color__input">
          <template slot="addonBefore">
            <div
              :style="{ backgroundColor: bannerForm.color.background }"
              class="color__preview"
            ></div>
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="color.text" label="Цвет текста">
        <a-input v-model="bannerForm.color.text" class="color__input">
          <template slot="addonBefore">
            <div
              :style="{ backgroundColor: bannerForm.color.text }"
              class="color__preview"
            ></div>
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="image" label="Картинка баннера">
        <upload-files
          @getImageParams="getImageParams"
          :img-url="bannerForm.image"
          placeholder-text="Загрузить картинку"
        />
      </a-form-model-item>
      <a-form-model-item prop="url" label="Ссылка">
        <a-input v-model="bannerForm.url" />
      </a-form-model-item>

      <a-form-model-item prop="switch_text" label="Текст замена">
        <a-input v-model="bannerForm.switch_text" />
      </a-form-model-item>

      <a-form-model-item prop="alt" label="Описание картинки">
        <a-input v-model="bannerForm.alt" />
      </a-form-model-item>

      <a-form-model-item prop="weight" label="Приоритет в списке">
        <a-input-number v-model="bannerForm.weight" :min="1" />
      </a-form-model-item>

      <a-form-model-item prop="position" label="Позиция">
        <a-select v-model="bannerForm.position" placeholder="Выберите позицию">
          <a-select-option
            v-for="(position, index) in positionTypes"
            :key="index"
            :value="position"
          >
            {{ position }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="platforms" label="Устройства">
        <a-select
          mode="multiple"
          :default-value="['web']"
          v-model="bannerForm.platforms"
          placeholder="Выберите устройства"
        >
          <a-select-option
            v-for="(device, index) in deviceList"
            :key="index"
            :value="device"
          >
            {{ device }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="active" label="Статус">
        <a-switch v-model="bannerForm.active" />
      </a-form-model-item>

      <a-button type="primary" html-type="submit">Создать</a-button>
    </a-form-model>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { bannerFormRules } from './constants'

export default {
  components: {
    UploadFiles,
  },
  data() {
    return {
      bannerFormRules,
      deviceList: ['WEB', 'IOS', 'ANDROID', 'WEB_MOBILE'],
      positionTypes: ['top', 'bottom', 'middle', 'left', 'right'],
      bannerForm: {
        active: true,
        alt: null,
        image: null,
        info: {
          text: null,
          title: null,
        },
        color: {
          background: null,
          text: null,
        },
        position: [],
        switch_text: null,
        platforms: ['WEB', 'IOS', 'ANDROID', 'WEB_MOBILE'],
        url: null,
        weight: 1,
      },
      isLoading: false,
    }
  },
  methods: {
    async createBanner(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            await this.$API.Banner.createBanner(this.bannerForm)
            showSuccessMsg('Баннер успешно создан!')
            await this.$router.push({
              name: 'BannerList',
            })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    getImageParams(params) {
      this.bannerForm.image = params
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-btn {
    margin-top: 30px;
  }
}

.color {
  &__preview {
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }
}
</style>
