<template>
  <div>
    <div class="settings-wrap">
      <h2>Группы быстрых ссылок</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'FastLinkCreate',
            })
        "
      >
        Создать новый
      </a-button>
    </div>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/fast_link/${id}`">
          <code>{{ id }}</code>
        </router-link>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { columnNames } from './constants'

export default {
  name: 'FastLinkList',
  data: () => ({
    dataSource: [],
    isLoading: false,
    columnNames,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      page: 1,
      limit: 10,
    },
  }),
  methods: {
    initialize() {
      try {
        this.isLoading = true
        this.getFastLinkList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getFastLinkList() {
      const { mini_menus, count } = await this.$API.Menu.getFastLinkList(
        this.filter,
      )
      this.dataSource = mini_menus
      this.pagination.total = count
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getFastLinkList()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
