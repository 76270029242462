import { render, staticRenderFns } from "./AttributeCreate.vue?vue&type=template&id=4821a054&scoped=true&"
import script from "./AttributeCreate.vue?vue&type=script&lang=js&"
export * from "./AttributeCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4821a054",
  null
  
)

export default component.exports