<template>
  <div>
    <div class="settings-wrap">
      <h2>Роли с доступами</h2>
    </div>
    <a-spin :spinning="isLoading">
      <a-tabs default-active-key="1" tab-position="right">
        <a-tab-pane
          v-for="(role, index) in dataSource"
          :key="index + 1"
          :tab="getRoleName(role.name)"
        >
          <div v-for="(domain, index) in role.domains" :key="index">
            <h3>
              Сервис: <b>{{ domain.service }}</b>
            </h3>
            <div class="tag-box">
              <div v-for="(perm, index) in domain.permissions" :key="index">
                <a-tag color="cyan">{{ perm }}</a-tag>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import { getRoleListAPI } from 'Config/api/role'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'RoleList',
  data: () => ({
    dataSource: [],
    isLoading: false,
  }),
  methods: {
    initialize() {
      try {
        this.isLoading = true
        this.getRoleList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getRoleList() {
      this.dataSource = await getRoleListAPI()
    },
    getRoleName(name) {
      switch (name) {
        case 'head_account_manager': {
          return 'Главный аккаунт менеджер'
        }
        case 'account_manager': {
          return 'Аккаунт менеджер'
        }
        case 'head_content_manager': {
          return 'Главный контент менеджер'
        }
        case 'content_manager': {
          return 'Контент менеджер'
        }
        case 'head_support_manager': {
          return 'Руководитель Службы Поддержки'
        }
        case 'support_manager': {
          return 'Сотрудник Службы поддержки'
        }
        case 'head_marketing_manager': {
          return 'Руководитель маркет менеджмента'
        }
        case 'marketing_manager': {
          return 'Маркет менеджер'
        }
        default: {
          return name
        }
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.tag-box {
  display: flex;
  flex-wrap: wrap;

  & span {
    margin: 0 5px 5px 0;
    padding: 5px 12px;
  }
}
</style>
