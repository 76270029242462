<template>
  <div class="list-def-wrap">
    <div>{{ title }}</div>
    <div v-if="hasChild">
      <a-icon type="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListDefault',
  props: {
    hasChild: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.list-def-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid blanchedalmond;

  &:hover {
    color: #73be6f;
  }
}
</style>
