import { AxiosInstance } from 'axios'
import { BusinessLogicException } from 'Core/modules/API/@types/BusinessLogicException'
import * as Sentry from '@sentry/vue'

export class ApiServiceClass {
  protected axios: AxiosInstance
  protected serviceUrl: string
  constructor(config) {
    this.axios = config.axios
    this.serviceUrl = config.serviceUrl
  }
  protected async GET<T>(url, params = {}, headers = {}): Promise<T> {
    try {
      const response = await this.axios({
        url: `${this.serviceUrl}/api${url}`,
        method: 'GET',
        params,
        headers,
      })
      if (response.status >= 200 && response.status < 300) {
        // @ts-ignore
        return {
          success: true,
          data: response.data,
        }
      } else {
        // @ts-ignore
        return {
          success: false,
          data: response.data,
        }
      }
    } catch (error) {
      // @ts-ignore
      return {
        success: false,
        data: error,
      }
    }
  }
  protected async POST<T>(
    url,
    data = {},
    headers = {},
    params = {},
  ): Promise<T> {
    try {
      const response = await this.axios({
        url: `${this.serviceUrl}/api${url}`,
        method: 'POST',
        data,
        headers,
        params,
      })
      if (response.status >= 200 && response.status < 300) {
        // @ts-ignore
        return {
          success: true,
          data: response.data,
        }
      } else {
        // @ts-ignore
        return {
          success: false,
          data: response.data,
        }
      }
    } catch (error) {
      // @ts-ignore
      return {
        success: false,
        data: error,
      }
    }
  }
  protected async PUT<T>(
    url,
    data = {},
    headers = {},
    params = {},
  ): Promise<T> {
    try {
      const response = await this.axios({
        url: `${this.serviceUrl}/api${url}`,
        method: 'PUT',
        data,
        headers,
        params,
      })
      if (response.status >= 200 && response.status < 300) {
        // @ts-ignore
        return {
          success: true,
          data: response.data,
        }
      } else {
        // @ts-ignore
        return {
          success: false,
          data: response.data,
        }
      }
    } catch (error) {
      // @ts-ignore
      return {
        success: false,
        data: error,
      }
    }
  }
  protected async PATCH<T>(
    url,
    data = {},
    headers = {},
    params = {},
  ): Promise<T> {
    try {
      const response = await this.axios({
        url: `${this.serviceUrl}/api${url}`,
        method: 'PATCH',
        data,
        headers,
        params,
      })
      if (response.status >= 200 && response.status < 300) {
        // @ts-ignore
        return {
          success: true,
          data: response.data,
        }
      } else {
        // @ts-ignore
        return {
          success: false,
          data: response.data,
        }
      }
    } catch (error) {
      // @ts-ignore
      return {
        success: false,
        data: error,
      }
    }
  }
  protected async DELETE<T>(url, headers = {}): Promise<T> {
    try {
      const response = await this.axios({
        url: `${this.serviceUrl}/api${url}`,
        method: 'DELETE',
        headers,
      })
      if (response.status >= 200 && response.status < 300) {
        // @ts-ignore
        return {
          success: true,
          data: response.data,
        }
      } else {
        // @ts-ignore
        return {
          success: false,
          data: response.data,
        }
      }
    } catch (error) {
      // @ts-ignore
      return {
        success: false,
        data: error,
      }
    }
  }
  public CaptureException(
    originalMethod: PropertyDescriptor,
    params: any[],
    error: Error | BusinessLogicException,
  ) {
    // eslint-disable-next-line no-console
    console.table({
      // @ts-ignore
      method: originalMethod.name,
      // @ts-ignore
      params: JSON.stringify(...params),
      error:
        error instanceof BusinessLogicException
          ? error.transformErrorMessage()
          : error.message,
      originalError: error.message,
    })

    Sentry.addBreadcrumb({
      type: 'Request Exception',
      level: Sentry.Severity.Warning,
      category: 'REQUEST_ERROR',
      message:
        error instanceof BusinessLogicException
          ? error.transformErrorMessage()
          : error.message,
      data: {
        // @ts-ignore
        params: JSON.stringify(...params),
        originalError: error.message,
        // @ts-ignore
        method: originalMethod.name,
      },
    })
  }
}
