export const columnNames = [
  {
    title: 'TDID',
    dataIndex: 'tdid',
    key: 'id',
    scopedSlots: { customRender: 'tdid' },
  },
  {
    title: 'ФИО',
    dataIndex: 'first_name',
    key: 'tdid',
    scopedSlots: { customRender: 'fullName' },
  },
  {
    title: 'Роли',
    dataIndex: 'roles',
    key: 'roles',
    scopedSlots: { customRender: 'roles' },
  },
  {
    title: 'Статус',
    dataIndex: 'is_active',
    key: 'is_active',
    scopedSlots: { customRender: 'isActive' },
  },
  {
    title: 'Номер',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
    width: '155px',
    align: 'center',
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updatedAt' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
]
