<template>
  <a-spin :spinning="isLoading">
    <a-divider>Адреса</a-divider>
    <a-table
      :columns="addressesColumnNames"
      :loading="isLoading"
      :data-source="requisites.addresses"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      bordered
    >
      <template v-slot:is_default="is_default">
        {{ is_default ? 'Да' : '-' }}
      </template>
    </a-table>

    <a-divider>Получатели</a-divider>
    <a-table
      :columns="receiversColumnNames"
      :loading="isLoading"
      :data-source="requisites.receivers"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      bordered
    >
      <template v-slot:phone="phone">
        {{ phone | phoneFormat }}
      </template>
      <template v-slot:created_at="created_at">
        {{ created_at | formatDate }}
      </template>
      <template v-slot:is_default="is_default">
        {{ is_default ? 'Да' : '-' }}
      </template>
    </a-table>
  </a-spin>
</template>

<script>
import { addressesColumnNames, receiversColumnNames } from 'CRM/pages/constants'

export default {
  name: 'ClientRequisites',
  props: {
    id: {
      type: String,
      required: true,
    },
    requisites: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    addressesColumnNames,
    receiversColumnNames,
    isLoading: false,
  }),
}
</script>

<style></style>
