































































import { Component, Vue } from 'vue-property-decorator'
import { SEARCH_FIELDS } from 'Client/pages/ClientList/constants'

@Component
class ClientListFilter extends Vue {
  SEARCH_FIELDS = SEARCH_FIELDS

  fio = {
    lastname: null,
    firstname: null,
    patronymic: null,
  }

  filter: any = { search: '', field: SEARCH_FIELDS.phone.value }

  onSearch() {
    const { field, search } = this.filter
    if (this.filter.field === 'fio') {
      this.$emit('onSearch', { value: { ...this.fio } })
    } else {
      this.$emit('onSearch', { value: { [field]: search } })
    }
  }

  dropFilter() {
    this.filter = { search: '', field: SEARCH_FIELDS.phone.value }
    this.fio = {
      lastname: null,
      firstname: null,
      patronymic: null,
    }
    this.$emit('onSearch', this.filter)
  }

  beforeDestroy() {
    this.dropFilter()
  }
}

export default ClientListFilter
