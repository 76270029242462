<template>
  <a-form-model-item :prop="prop" :label="label">
    <a-input
      @input="handleInput"
      v-model="localValue"
      @focus.native="setInitialValue"
      v-facade="'+7 (###) ###-##-##'"
      :placeholder="placeholder"
      :disabled="disabled"
    >
      <a-icon v-if="icon" slot="prefix" :type="icon" />
    </a-input>
  </a-form-model-item>
</template>

<script>
export default {
  name: 'PhoneInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (newValue !== this.rawValue(oldValue)) {
        this.localValue = newValue
      }
    },
  },
  methods: {
    rawValue(value) {
      if (value !== null && value !== '') {
        return value.replace(/\D/g, '')
      } else {
        return value
      }
    },
    setInitialValue() {
      this.localValue === '' || this.localValue === null
        ? (this.localValue = '+7')
        : null
    },
    handleInput() {
      let trimmedValue = this.localValue.replace(/\D/g, '')
      this.$emit('input', trimmedValue)
    },
  },
}
</script>

<style lang="scss" scoped></style>
