var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список способов оплаты")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:({
        service: 'banner',
        permissions: ['banner-create'],
      }),expression:"{\n        service: 'banner',\n        permissions: ['banner-create'],\n      }"}],attrs:{"to":{ name: 'ConfigPaymentCreate' }}},[_c('a-button',[_vm._v("Создать")])],1)],1),_c('a-table',{attrs:{"columns":_vm.paymentTypesColumns,"data-source":_vm.paymentTypes,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var id = ref.id;

            return id;
},"loading":_vm.isLoading,"bordered":""},scopedSlots:_vm._u([{key:"payment_id",fn:function(payment_id){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'ConfigPaymentEdit',
          params: { payment_id: payment_id },
        }}},[_vm._v(" "+_vm._s(_vm._f("shortID")(payment_id,5))+" ")])],1)}},{key:"payment_icon",fn:function(payment_icon){return _c('div',{},[(payment_icon)?_c('img',{staticClass:"img-prev",attrs:{"src":payment_icon,"width":"170"}}):_c('p',[_vm._v("Иконка не указана")])])}},{key:"payment_status",fn:function(payment_status, record, index){return _c('div',{},[(payment_status)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.statusToggleHandler(record, payment_status, index)}}},[_c('a-icon',{attrs:{"type":"check-circle"}})],1):_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.statusToggleHandler(record, payment_status, index)}}},[_c('a-icon',{attrs:{"type":"stop"}})],1)],1)}},{key:"payment_weight",fn:function(weight, paymentType){return _c('div',{},[_c('a-input-number',{attrs:{"id":paymentType.id,"value":weight,"min":0},on:{"change":function (value) { return _vm.onPriorityChange(value, paymentType); }}}),_c('a-button',{staticClass:"form__button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.confirmPriorityChange(paymentType)}}},[_c('a-icon',{attrs:{"type":"save"}})],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }