<template>
  <div>
    <div class="settings-wrap">
      <h2>
        Модели
        <code class="settings-wrap__info">
          (Всего: {{ pagination.total }})
        </code>
      </h2>
      <a-button
        v-can="{
          service: 'storefront',
          permissions: ['model-create'],
        }"
        @click="
          () =>
            $router.push({
              name: 'ModelCreate',
            })
        "
      >
        Создать новый
      </a-button>
    </div>
    <div class="settings-wrap">
      <a-form-model
        layout="inline"
        ref="form"
        :model="filter"
        @submit="onSearch"
      >
        <a-form-model-item label="Название модели">
          <a-input v-model="filter.search" />
        </a-form-model-item>
        <a-form-model-item label="Стаутус">
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="filter.is_enabled"
            @change="searchHandler"
          >
            <a-radio-button :value="null"> Все</a-radio-button>
            <a-radio-button :value="true"> Активные</a-radio-button>
            <a-radio-button :value="false"> Выключенные</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </a-form-model>
    </div>
    <a-table
      :columns="columnNames"
      :loading="isLoading"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/model-edit/${id}`">
          <code>{{ id }}</code>
        </router-link>
      </div>
      <div slot="isActive" slot-scope="isActive, record">
        <a-button
          v-if="isActive"
          type="primary"
          :disabled="record.base_product_count > 0"
          @click="statusToggleHandler(record, isActive)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          :disabled="record.base_product_count > 0"
          v-else
          type="danger"
          @click="statusToggleHandler(record, isActive)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        {{ updatedAt | formatDate }}
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { columnNames } from './constants'

export default {
  name: 'ModelList',
  data: () => ({
    columnNames,
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 20,
    },
    filter: {
      search: null,
      is_enabled: null,
      page: 1,
      limit: 20,
    },
  }),
  methods: {
    onSearch(event) {
      event.preventDefault()
      this.searchHandler()
    },
    async searchHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getModelList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        search: null,
        is_enabled: null,
        page: 1,
        limit: 20,
      }
      this.getModelList()
    },
    initialize() {
      try {
        this.isLoading = true
        this.getModelList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getModelList()
    },
    async getModelList() {
      this.isLoading = true
      const { models, count } = await this.$API.Storefront.getModelList(
        this.filter,
      )
      this.pagination.total = count
      this.dataSource = models
      this.isLoading = false
    },
    async statusToggleHandler(record, isActive) {
      try {
        await this.$API.Storefront.toggleModelStatus(record.id, !isActive)
        showSuccessMsg('Удачно обновлен!')
        await this.getModelList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped>
.settings-wrap__info {
  font-size: 14px;
}
</style>
