export const groupFormRules = {
  model_id: {
    type: 'number',
    message: 'Обязательное поле',
    required: true,
  },
  name: {
    type: 'string',
    message: 'Обязательное поле',
    required: true,
  },
}
