<template>
  <div>
    <div class="notification">
      <h3 class="notification__title">Правила загрузки медиа контента</h3>
      <ul class="notification__list">
        <li>Загрузка изображений: Формат JPG, PNG;</li>
        <li>Размер от 800 до 2 000 px;</li>
        <li>Вес не более 1 мб;</li>
        <li>
          Загружайте изображения только с изделием, которое будет выставлено на
          продажу;
        </li>
        <li>
          Изделие должно находиться в центре изображения и занимать большую
          часть пространства (80%)
        </li>
        <li>
          Чем больше изображение, тем легче покупателю рассмотреть товар;
          Основное изображение должно быть на белом или светлом фоне, без
          водяных знаков;
        </li>
        <li>
          Товар должен быть в фокусе, без размытых деталей, с хорошим
          освещением;
        </li>
        <li>
          Товары на изображениях могут быть представлены с разных ракурсов
        </li>
        <li>
          Если продаете набор или комплект – загрузите изображения, где будут
          видны все товары;
        </li>
      </ul>
    </div>
    <a-form-model
      @submit="onSubmit"
      ref="form"
      :rules="mediaFormRules"
      :model="mediaForm"
    >
      <upload-files
        v-model="mediaForm.images"
        multiple
        prop="images"
        placeholder-text="Загрузить картину"
        draggable
      />

      <a-form-model-item label="Загрузить из JSON">
        <input type="file" @change="previewFiles" />
      </a-form-model-item>
      <a-form-model-item prop="videoUrl" label="Ссылка на видео">
        <a-input v-model="mediaForm.videoUrl" />
      </a-form-model-item>
      <a-button type="primary" html-type="submit">Сохранить</a-button>
    </a-form-model>
  </div>
</template>

<script>
import { mediaFormRules } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { uploadJSONAPI } from 'Partner/api/merchant'
import { RU_LOCALE } from 'Core/modules/validator'

import UploadFiles from 'Core/components/Files/UploadFiles.vue'

export default {
  components: {
    UploadFiles,
  },
  props: {
    media: {
      type: Array,
    },
  },
  data() {
    return {
      RU_LOCALE,
      mediaFormRules,
      mediaForm: {
        images: [],
        videoUrl: null,
      },
    }
  },
  watch: {
    media: {
      handler(newValue) {
        const videoTypeItem = newValue.find((media) => media.type === 'video')
        this.mediaForm.videoUrl = videoTypeItem && videoTypeItem.url
        this.mediaForm.images = newValue
          .filter((media) => media.type !== 'video')
          .map((image, index) => ({
            uid: this.getUniqueKey(),
            name: `Картинка ${index}`,
            status: 'done',
            url: image.url,
          }))
      },
      immediate: true,
    },
  },
  computed: {
    productID() {
      return this.$route.params.id
    },
  },
  methods: {
    getUniqueKey() {
      return (Math.random() + 1).toString(36).substring(7)
    },
    async onSubmit(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            const normalizePayload = this.mediaForm.images.map((image) => ({
              type: 'image',
              url: image.url,
            }))
            if (this.mediaForm.videoUrl) {
              normalizePayload.push({
                type: 'video',
                url: this.mediaForm.videoUrl,
              })
            }
            await this.$API.Storefront.updateBaseProductMediaById(
              this.productID,
              normalizePayload,
            )
            showSuccessMsg('Медиа файлы успешно загружены!')
          } catch (error) {
            showErrorMsg(error.message)
          }
        }
      })
    },
    previewFiles(event) {
      const file = event.target.files[0]
      let reader = new FileReader()
      const _this = this
      reader.readAsText(file)
      reader.onload = function () {
        _this.loadFromJSON(JSON.parse(reader.result))
      }
    },
    async loadFromJSON(json) {
      try {
        const { urls } = await uploadJSONAPI(json)
        urls.forEach((item) => {
          this.mediaForm.images.push({
            status: 'done',
            url: item,
            uid: item.split('/')[item.split('/').length - 1],
            name: item.split('/')[item.split('/').length - 1],
          })
        })
        this.$emit('input', this.fileList)
        this.isLoading = false
      } catch (error) {
        showErrorMsg(
          `Не удалось прикрепить картинку. Обратитесь в службу поддержки`,
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.notification {
  padding: 15px 15px 15px 20px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #ffcdcd;
  font-size: 16px;
  margin-bottom: 30px;

  &__list {
    padding-left: 15px;
  }
}
</style>
