<template>
  <a-dropdown>
    <span>
      <a-avatar src="images/user/profile.png" />
      {{ fullName }}
    </span>
    <a-menu slot="overlay">
      <a-menu-divider />
      <a-menu-item>
        <router-link :to="{ name: 'ProfileInfo' }">
          <a-icon type="user" />
          <span> Мой профиль</span>
        </router-link>
      </a-menu-item>
      <a-menu-item @click="onLogout">
        <a-icon type="setting" />
        <span> Выйти</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
export default {
  name: 'Account',
  computed: {
    fullName() {
      return this.$store.getters['user/fullName']
    },
  },
  methods: {
    async onLogout() {
      await this.$API.SSO.LogOut()
      await this.$router.push('/login')
    },
  },
}
</script>
