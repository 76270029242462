<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование рассрочки</h2>
      <router-link :to="{ name: 'InstallmentList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isLoading">
      <a-form-model
        v-if="installmentForm"
        ref="form"
        :model="installmentForm"
        :rules="installmentFormRules"
        @submit="editInstallment"
      >
        <a-form-model-item prop="title.RU" label="Заголовок на русском">
          <a-input v-model="installmentForm.title.RU" />
        </a-form-model-item>
        <a-form-model-item prop="title.KZ" label="Заголовок на казахском">
          <a-input v-model="installmentForm.title.KZ" />
        </a-form-model-item>
        <a-form-model-item prop="categories" label="Зона действия рассрочки">
          <a-radio-group v-model="installmentForm.type">
            <a-radio value="category">Категорий</a-radio>
            <a-radio value="merchant" disabled>Партнеры</a-radio>
          </a-radio-group>
          <a-input v-model="installmentForm.categories" disabled />
          <template v-if="installmentForm.type === 'category'">
            <category-selector v-model="installmentForm.categories" />
          </template>
          <template v-else>
            <merchant-selector
              v-model="installmentForm.merchant_ids"
            ></merchant-selector>
          </template>
        </a-form-model-item>
        <a-form-model-item prop="commission" label="Комиссионные">
          <a-input-number
            v-model="installmentForm.commission"
            block
            :min="0"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
            :max="100"
          />
        </a-form-model-item>
        <a-form-model-item prop="duration" label="Продолжительность ( месяцы )">
          <a-input-number
            v-model="installmentForm.duration"
            block
            :min="3"
            :max="99"
          />
        </a-form-model-item>
        <a-form-model-item
          prop="min_cart_sum"
          :min="0"
          label="Макс. сумма товаров"
        >
          <a-input-number v-model="installmentForm.min_cart_sum" block />
        </a-form-model-item>
        <a-form-model-item prop="end_at" label="Даты с-по">
          <a-range-picker
            :disabled-date="disabledDate"
            @change="onRangeDatepickerChange"
            :locale="locale"
            :value="[
              installmentForm.starts_at
                ? moment(installmentForm.start_at)
                : installmentForm.start_at,
              installmentForm.ends_at
                ? moment(installmentForm.end_at)
                : installmentForm.end_at,
            ]"
          />
        </a-form-model-item>
        <a-form-model-item prop="note_data" label="Время уведомления">
          <a-date-picker
            :locale="locale"
            :show-time="{ format: 'HH:mm' }"
            v-model="installmentForm.note_data"
            :disabled-date="disabledDate"
          />
        </a-form-model-item>
        <a-form-model-item prop="background_color" label="Цвет фона">
          <a-input
            v-model="installmentForm.background_color"
            class="color__input"
          >
            <template slot="addonBefore">
              <div
                :style="{ backgroundColor: installmentForm.background_color }"
                class="color__preview"
              ></div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="text_color" label="Цвет текста">
          <a-input v-model="installmentForm.text_color" class="color__input">
            <template slot="addonBefore">
              <div
                :style="{ backgroundColor: installmentForm.text_color }"
                class="color__preview"
              ></div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="Превью рассрочки">
          <div
            class="installment__content"
            :style="{ backgroundColor: installmentForm.background_color }"
          >
            <p
              class="installment__text"
              :style="{ color: installmentForm.text_color }"
            >
              {{ installmentForm.title.RU }}
            </p>
          </div>
        </a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          v-can="{
            service: 'payplan',
            permissions: ['installment-update'],
          }"
          >Сохранить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import MerchantSelector from 'Core/components/Selector/MerchantSelector.vue'
import CategorySelector from 'Core/components/Selector/InstallmentCategorySelector'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import { installmentFormRules } from './constants'

import moment from 'moment'
import 'moment/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'

export default {
  components: {
    MerchantSelector,
    CategorySelector,
  },
  computed: {
    installmentID() {
      return this.$route.params.installment_id
    },
  },
  watch: {
    'installmentForm.type': {
      handler(newValue) {
        if (newValue === 'category') {
          this.installmentForm.merchant_ids = []
        } else {
          this.installmentForm.categories = []
        }
      },
    },
  },
  data() {
    return {
      locale,
      installmentFormRules,
      isLoading: false,
      installmentForm: null,
    }
  },
  created() {
    this.getInstallmentById()
  },
  methods: {
    moment,
    disabledDate(current) {
      return current && current < moment().startOf('day')
    },
    async getInstallmentById() {
      try {
        this.isLoading = true
        this.installmentForm = await this.$API.Payplan.getInstallmentById(
          this.installmentID,
        )
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onRangeDatepickerChange(datesMoment) {
      const [start_date, end_date] = datesMoment
      this.installmentForm.start_at = moment(start_date).toISOString()
      this.installmentForm.end_at = moment(end_date).toISOString()
    },
    async editInstallment(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            await this.$API.Payplan.updateInstallmentById(
              this.installmentID,
              this.installmentForm,
            )
            showSuccessMsg('Изменения сохранены.')
          } catch (error) {
            showErrorMsg(error.message)
          }
        }
      })
    },
    getPreviewStyle(color) {
      return {
        backgroundColor: color,
        width: '15px',
        height: '15px',
        border: '1px solid black',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-input-number {
  width: 200px;
}

.form__button {
  margin-top: 30px !important;
}

::v-deep {
  .ant-form-item-label {
    font-weight: 600 !important;
  }
}

.color {
  &__preview {
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.installment {
  &__content {
    padding: 5px 30px;
    width: fit-content;
    border-radius: 5px;
  }

  &__text {
    margin: 0;
    font-weight: 600;
    padding: 0;
  }
}
</style>
