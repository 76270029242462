/* eslint-disable */
import {format} from 'Core/modules/helpers'

export const IBAN = function (rule, value, callback, source, options) {
    let regExp = /^(kz|Kz|kZ|KZ)([a-zA-Z0-9]){18,18}$/g
    if (regExp.test(value) || value === '') {
        return true
    } else {
        return callback(
            format(options.messages[rule.validator.name], rule.fullField),
        )
    }
}
