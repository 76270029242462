import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис генерации qr кодов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/qr/swagger/index.html} */
export class QRCodeService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'qr',
    })
  }

  @Api
  async getQRcodeList(params): Promise<any> {
    const response = await this.GET('/v1/', params)
    return response
  }

  @Api
  async getQRcodeById(id) {
    const response = await this.GET(`/v1/${id}`)
    return response
  }

  @Api
  async updateQRcodeById(id, params) {
    const response = await this.PATCH(`/v1/${id}`, params)
    return response
  }

  @Api
  async createQRcodeList(params) {
    const response = await this.POST(`/v1/`, params)
    return response
  }
}
