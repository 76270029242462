export const TRANSACTION_STATUSES = {
  accrual: {
    value: 'accrual',
    label: 'Начисление',
    variant: 'green',
    icon: 'rise',
  },
  retention: {
    value: 'retention',
    label: 'Удержание',
    variant: 'orange',
    icon: 'info-circle',
  },
  transfer: {
    value: 'transfer',
    label: 'Перевод',
    variant: 'blue',
    icon: 'swap',
  },
}
export const INITIATOR_STATUSES = {
  company: {
    value: 'company',
    label: 'Компания',
    variant: 'purple',
    icon: 'bank',
  },
  merchant: {
    value: 'merchant',
    label: 'Партнер',
    variant: 'pink',
    icon: 'file-protect',
  },
  client: {
    value: 'client',
    label: 'Клиент',
    variant: 'cyan',
    icon: 'smile',
  },
}

export const REASON_STATUSES = {
  order: {
    value: 'order',
    label: 'заказ',
    variant: 'green',
    icon: 'swap',
  },
  request: {
    value: 'request',
    label: 'запрос',
    variant: 'darkorange',
    icon: 'swap',
  },
  tax: {
    value: 'tax',
    label: 'налог',
    variant: 'red',
    icon: 'swap',
  },
  return: {
    value: 'return',
    label: 'возврат',
    variant: 'darkred',
    icon: 'swap',
  },
  delivery: {
    value: 'delivery',
    label: 'blue',
    variant: 'purple',
    icon: 'swap',
  },
  penalty: {
    value: 'penalty',
    label: 'штраф',
    variant: 'black',
    icon: 'swap',
  },
}
