import axiosConfig from './axiosConfig'
import { withAbort } from './apiHelper'
import Vue from 'vue'

const GET = async (url, params = {}, headers = {}) => {
  try {
    const restBody = {
      path: url,
      params: params,
      type: 'GET',
      date: new Date(),
    }
    Vue.prototype.$logger.addAction(restBody)
    const configs = { params, headers }
    const response = await withAbort(axiosConfig.get)(url, configs)

    if (response.status >= 200 && response.status < 300) {
      return {
        ...response,
        success: true,
      }
    }

    return {
      ...response,
      success: false,
    }
  } catch (error) {
    return {
      ...error?.response,
      success: false,
    }
  }
}

const POST = async (url, data = {}, headers = {}, params = {}) => {
  try {
    const restBody = {
      path: url,
      params: params,
      data: data,
      type: 'POST',
      date: new Date(),
    }
    Vue.prototype.$logger.addAction(restBody)
    const response = await axiosConfig({
      url,
      method: 'POST',
      data,
      headers,
      params,
    })

    if (response.status >= 200 && response.status < 300) {
      return {
        ...response,
        success: true,
      }
    }

    return {
      ...response,
      success: false,
    }
  } catch (error) {
    return {
      ...error?.response,
      success: false,
    }
  }
}

const PUT = async (url, data = {}, headers = {}, params = {}) => {
  try {
    const restBody = {
      path: url,
      params: params,
      data: data,
      type: 'PUT',
      date: new Date(),
    }
    Vue.prototype.$logger.addAction(restBody)
    const response = await axiosConfig({
      url,
      method: 'PUT',
      data,
      headers,
      params,
    })

    if (response.status >= 200 && response.status < 300) {
      return {
        ...response,
        success: true,
      }
    }

    return {
      ...response,
      success: false,
    }
  } catch (error) {
    return {
      ...error?.response,
      success: false,
    }
  }
}

const PATCH = async (url, data = {}, headers = {}, params = {}) => {
  try {
    const restBody = {
      path: url,
      params: params,
      data: data,
      type: 'PATCH',
      date: new Date(),
    }
    Vue.prototype.$logger.addAction(restBody)
    const response = await axiosConfig({
      url,
      method: 'PATCH',
      data,
      headers,
      params,
    })

    if (response.status >= 200 && response.status < 300) {
      return {
        ...response,
        success: true,
      }
    }

    return {
      ...response,
      success: false,
    }
  } catch (error) {
    return {
      ...error?.response,
      success: false,
    }
  }
}

const DELETE = async (url, headers = {}) => {
  try {
    const restBody = {
      path: url,
      type: 'DELETE',
      date: new Date(),
    }
    Vue.prototype.$logger.addAction(restBody)
    const response = await axiosConfig({
      url,
      method: 'DELETE',
      headers,
    })

    if (response.status >= 200 && response.status < 300) {
      return {
        ...response,
        success: true,
      }
    }

    return {
      ...response,
      success: false,
    }
  } catch (error) {
    return {
      ...error?.response,
      success: false,
    }
  }
}

export { GET, POST, PUT, PATCH, DELETE }
