<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование меню</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'NestedMenuList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="getMenuLoading">
      <a-form-model
        ref="form"
        :model="categoryForm"
        :rules="rules"
        @submit="onSubmit"
        v-if="!getMenuLoading && categoryForm"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="categoryForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="title.ru" label="Заголовок на русском">
          <a-input v-model="categoryForm.title.ru" />
        </a-form-model-item>
        <a-form-model-item prop="title.kz" label="Заголовок на казахском">
          <a-input v-model="categoryForm.title.kz" />
        </a-form-model-item>
        <a-form-model-item prop="title.en" label="Заголовок на английском">
          <a-input v-model="categoryForm.title.en" />
        </a-form-model-item>

        <a-form-model-item
          label="Группа быстрых ссылок"
          v-if="categoryForm.level === 1"
        >
          <a-select
            v-model="categoryForm.mini_menu_id"
            placeholder="Выберите устройства"
          >
            <a-select-option
              v-for="(link, index) in fastLinks"
              :key="index"
              :value="link.id"
            >
              {{ link.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="storefront_id" label="Зона действия меню">
          <a-radio-group
            v-model="categoryForm.type"
            @change="onChangeCategoryType"
          >
            <a-radio value="CATEGORY">Категория</a-radio>
            <a-radio value="BRAND">Бренд</a-radio>
          </a-radio-group>

          <a-input v-model="categoryForm.storefront_id" disabled />
          <template v-if="categoryForm.type === 'CATEGORY'">
            <category-selector
              :drawer-title="'Выберите категорию для меню'"
              :drawer-width="'85%'"
              v-model="categoryForm.storefront_id"
            />
          </template>
          <template v-else>
            <brand-selector @setBrandHandler="setBrandHandler"></brand-selector>
          </template>
        </a-form-model-item>

        <a-form-model-item prop="order_num" label="Порядок категорий">
          <a-input-number
            id="inputNumber"
            v-model="categoryForm.order_num"
            :min="1"
          />
        </a-form-model-item>

        <a-form-model-item
          v-if="categoryForm.level === 1"
          prop="image_url"
          label="Картинка категорий"
        >
          <upload-files
            @getImageParams="getImageParams"
            :img-url="categoryForm.image_url"
          />
        </a-form-model-item>

        <a-form-model-item prop="devices" label="Выберите устройства">
          <a-select
            mode="multiple"
            :default-value="['web']"
            v-model="categoryForm.devices"
            placeholder="Выберите устройства"
          >
            <a-select-option
              v-for="(device, index) in deviceList"
              :key="index"
              :value="device"
            >
              {{ device }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="is_active" label="Доступность">
          <a-checkbox v-model="categoryForm.is_active"> Активный </a-checkbox>
        </a-form-model-item>
        <div>
          <a-button type="primary" html-type="submit">Сохранить</a-button>
          <a-popconfirm
            title="Вы уверены?"
            ok-text="Да"
            cancel-text="Отмена"
            @confirm="removeCategory"
          >
            <a-icon slot="icon" type="question-circle-o" style="color: red" />
            <a-button type="danger"> Удалить</a-button>
          </a-popconfirm>
        </div>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'
import BrandSelector from 'Product/components/BrandSelector'
import CategorySelector from 'Core/components/Selector/CategorySelector'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { categoryFormRules } from 'Content/component/constants'

export default {
  components: {
    UploadFiles,
    BrandSelector,
    CategorySelector,
  },
  data() {
    return {
      getMenuLoading: false,
      updateCategoryLoading: false,
      deviceList: ['web', 'ios', 'android'],
      categoryForm: {
        children: [],
        devices: [],
        id: null,
        image_url: null,
        is_active: false,
        name: null,
        order_num: 1,
        parent_id: null,
        storefront_id: null,
        title: {},
        type: 'CATEGORY',
      },
      rules: categoryFormRules,
      fastLinks: [],
    }
  },
  watch: {
    'categoryForm.storefront_id': {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.form.validateField('storefront_id')
          })
        }
      },
    },
  },
  computed: {
    menuId() {
      return this.$route.params.menu_id
    },
  },
  async created() {
    if (this.menuId) await this.getNestedMenuById(this.menuId)
    const { mini_menus } = await this.$API.Menu.getFastLinkList({
      page: 1,
      limit: 100,
    })
    this.fastLinks = mini_menus
  },
  methods: {
    getImageParams(params) {
      this.categoryForm.image_url = params
    },
    async onSubmit(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            if (this.categoryForm.level > 1) {
              this.categoryForm.image_url = null
            }
            await this.$API.Menu.updateNestedMenuById(
              this.categoryForm.id,
              this.categoryForm,
            )
            showSuccessMsg('Меню успешно обновлен')
            this.gotoListPage()
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async removeCategory() {
      try {
        await this.$API.Menu.removeNestedMenu(this.categoryForm.id)
        showSuccessMsg('Категория удалена!')
        this.gotoListPage()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getNestedMenuById(menuId) {
      try {
        this.getMenuLoading = true
        this.categoryForm = await this.$API.Menu.getNestedMenuById(menuId)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.getMenuLoading = false
      }
    },
    removeSelectedBrand() {
      this.categoryForm.storefront_id = null
    },
    setBrandHandler(brand) {
      this.categoryForm.storefront_id = brand.slug
    },
    onChangeCategoryType() {
      this.categoryForm.storefront_id = null
    },
    gotoListPage() {
      this.$router.push({
        name: 'NestedMenuList',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.ant-btn-danger {
  margin: 20px 15px 0;
}

.category {
  &__image {
    width: 130px;
    margin-bottom: 10px;
  }
}
</style>
