import Vue from 'vue'
import Antd from 'ant-design-vue'
import VueRouter from 'vue-router'
import VueClipboard from 'vue-clipboard2'
import store from 'Core/store'
import App from './App.vue'
import { i18n } from './i18n.js'
import router from './router'
import AccessControlList from './acl'
import LogList from './logger/logger'
import InputFacade from 'vue-input-facade'

import FILTERS from 'Core/modules/filters/index'
import COMPONENTS from 'Core/components/index'

import { APIModule } from 'Core/modules/API'

Vue.use(APIModule, {
  baseUrl: process.env.VUE_APP_API_URL,
  privateBaseUrl: process.env.VUE_APP_API_PRIVATE_URL,
})
Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(VueRouter)
Vue.use(InputFacade)
Vue.use(AccessControlList)
Vue.use(LogList, {
  store: store,
  storePath: 'user/profile',
  historyMaxLength: 50,
})
Vue.use(VueClipboard)
Vue.use(require('vue-shortkey'))
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

for (const item in COMPONENTS) {
  Vue.component(item, COMPONENTS[item])
}

for (const item in FILTERS) {
  Vue.filter(item, FILTERS[item])
}

export const eventBus = new Vue()
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'arm.airba.kz',
        'arm.mp-test.airba.dev',
        /^(.*).test.airba.dev$/,
      ],
    }),
  ],
  release: process.env.VUE_APP_SENTRY_RELEASE,
  attachProps: true,
  attachStacktrace: true,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
})

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
