import { getUserPermissionListAPI } from 'Config/api/permission.js'
import router from '../../../router'

export const user = {
  namespaced: true,
  state: () => ({
    profile: {
      created: '',
      email: '',
      first_name: '',
      lang: 'ru',
      last_name: '',
      roles: [],
      phone: '',
      phones: [],
      receivers: [],
      sex: '',
      tdid: '',
      updated: '',
    },
    modules: [],
    permissions: [],
  }),
  mutations: {
    UPDATE_PROFILE: (state, profile) => {
      state.profile = profile
    },
    UPDATE_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
  },
  actions: {
    async updateProfile({ commit }) {
      const profile = await this._vm.$API.SSO.ProfileInfo()
      if (profile) commit('UPDATE_PROFILE', profile)
    },
    updatePermissions: async ({ commit }) => {
      const perm = await getUserPermissionListAPI()
      router.app.$acl.setList(perm)
      if (perm?.length) commit('UPDATE_PERMISSIONS', perm)
    },
  },
  getters: {
    profile: (state) => state.profile,
    permissions: (state) => state.permissions,
    roles: (state) => state.profile.roles,
    modules: (state) => state.modules,
    fullName: (state) =>
      `${state.profile.last_name} ${state.profile.first_name[0]}.`,
  },
}
