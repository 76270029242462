export const PARTNER_STATUSES = {
  new: {
    value: 'new',
    label: 'Новый',
    variant: 'blue',
    icon: 'thunderbolt',
  },
  'on-check': {
    value: 'on-check',
    label: 'На проверке',
    variant: 'orange',
    icon: 'security-scan',
  },
  active: {
    value: 'active',
    label: 'Активный',
    variant: 'green',
    icon: 'check',
  },
  disabled: {
    value: 'disabled',
    label: 'Неактивный',
    variant: 'red',
    icon: 'stop',
  },
}
