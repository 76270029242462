















import { Component, Vue } from 'vue-property-decorator'
import UploadCsv from 'Content/pages/MultipleBaseProductCreate/Tabs/UploadCSV/UploadCSV.vue'
import UploadSku from 'Content/pages/MultipleBaseProductCreate/Tabs/UploadSKU/UploadSKU.vue'

@Component({ components: { UploadCsv, UploadSku } })
class MultipleBaseProductCreate extends Vue {}

export default MultipleBaseProductCreate
