import { FILE_LENGTH } from 'Core/modules/validators/file'

export const rules = {
  files: {
    message: 'Необходимо выбрать CSV файл',
    validator: FILE_LENGTH,
    min: 1,
    required: true,
  },
}

export const formState: { files: File[] } = {
  files: [],
}
