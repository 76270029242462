<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-row type="flex" justify="center">
        <a-col span="16">
          <a-row class="mb-24" type="flex" justify="space-between">
            <a-col>
              <h2>Редактирование группы</h2>
            </a-col>
            <a-col>
              <a-button
                @click="
                  () =>
                    $router.push({
                      name: 'AttributeGroupList',
                      props: { modelId: modelId },
                    })
                "
              >
                {{ $t('common.back') }}
              </a-button>
            </a-col>
          </a-row>
          <a-form-model
            ref="form"
            :model="formState"
            :rules="rules"
            @submit="onEdit"
          >
            <a-form-model-item label="Slug(генерируется автоматически)">
              <a-input v-model="formState.slug" disabled />
            </a-form-model-item>
            <a-form-model-item prop="title.ru" label="RU(на русском)">
              <a-input v-model="formState.title.ru" />
            </a-form-model-item>
            <a-form-model-item prop="title.kz" label="KZ(на казахском)">
              <a-input v-model="formState.title.kz" />
            </a-form-model-item>
            <a-form-model-item prop="order_num" label="Приоритет">
              <a-input-number v-model="formState.weight" :min="0" />
            </a-form-model-item>
            <a-form-item>
              <a-button-group>
                <a-button type="primary" html-type="submit">Сохранить</a-button>
                <a-popconfirm
                  v-if="!isInUse"
                  title="Удалить безвозвратно?"
                  ok-text="Да, удалить"
                  cancel-text="отмена"
                  @confirm="() => onDelete()"
                >
                  <a-button type="danger">
                    <a-icon type="delete" />
                  </a-button>
                </a-popconfirm>
              </a-button-group>
            </a-form-item>
          </a-form-model>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'GroupEdit',
  data: () => ({
    formState,
    rules,
    isFetching: true,
    isInUse: false,
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        this.isInUse = !!(
          await this.$API.Storefront.getModelDependencyCount(this.modelId)
        ).count
        await this.getModelAttributeGroupById()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getModelAttributeGroupById() {
      this.formState = await this.$API.Storefront.getModelAttributeGroupById(
        this.modelId,
        this.groupId,
      )
    },
    onEdit(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        this.isFetching = true
        await this.updateModelAttributeGroupHandler()
        await this.getModelAttributeGroupById()
        showSuccessMsg('Удачно Сохранен!')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async updateModelAttributeGroupHandler() {
      await this.$API.Storefront.updateModelAttributeGroupById(
        this.modelId,
        this.groupId,
        this.formState,
      )
      this.isFetching = false
    },
    async onDelete() {
      await this.$API.Storefront.deleteModelGroupById(
        this.modelId,
        this.groupId,
      )
      showSuccessMsg('Группа удалена.')
      await this.$router.push({
        name: 'AttributeGroupList',
        props: { modelId: this.modelId },
      })
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    groupId() {
      return this.$route.params.id
    },
    modelId() {
      return this.$route.params.modelId
    },
  },
}
</script>

<style scoped></style>
