<template>
  <a-spin :spinning="isLoading">
    <h2>
      {{
        `${profile.last_name || ''} ${profile.first_name || ''} ${
          profile.patronymic || ''
        }`
      }}
    </h2>
    <a-descriptions>
      <a-descriptions-item label="Номер телефона">
        {{ profile.phone | phoneFormat }}
      </a-descriptions-item>
      <a-descriptions-item label="Email">
        {{ profile.email }}
      </a-descriptions-item>
      <a-descriptions-item label="Пол">
        {{ requisites.sex ? requisites.sex.ru : '' }}
      </a-descriptions-item>

      <a-descriptions-item label="Дата создания">
        {{ profile.created_at | formatDateRaw }}
      </a-descriptions-item>
      <a-descriptions-item label="Дата последнего обновления">
        {{ profile.updated_at | formatDateRaw }}
      </a-descriptions-item>
      <a-descriptions-item label="Дата рождения">
        {{ requisites.birth_date | formatDateISO }}
      </a-descriptions-item>
      <a-descriptions-item label="ИИН">
        {{ requisites.iin || requisites.financial_credits.iin }}
      </a-descriptions-item>
    </a-descriptions>
  </a-spin>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import {
  clientProfile,
  addressesColumnNames,
  receiversColumnNames,
} from 'CRM/pages/constants'

export default {
  name: 'ClientProfile',
  props: {
    id: {
      type: String,
      required: true,
    },
    requisites: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    addressesColumnNames,
    receiversColumnNames,
    isLoading: false,
    profile: clientProfile,
  }),
  methods: {
    async getUserProfile() {
      try {
        this.isLoading = true
        this.profile = await this.$API.SSO.getUserByTdId(this.id)
      } catch (error) {
        showErrorMsg(error.msg)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.getUserProfile()
  },
}
</script>

<style></style>
