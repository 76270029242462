import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис учёта денежных потоков/транзакций
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/accounter/swagger/index.html} */
export class AccounterService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'accounter',
    })
  }

  @Api
  async getTransactionList(params) {
    const response = await this.GET(`/v1/transactions/analytics/admin`, params)
    return response
  }
}
