























import { Component, Prop, Watch } from 'vue-property-decorator'
import MultipleBPCreationMixin from 'Content/mixins/MultipleBPCreation.mixin'
import { IContentDownloaderParams } from 'Core/modules/API/@types/ContentDownloader.type'
import { showErrorMsg } from '@/Core/utils'

@Component
class UploadedFileInfo extends MultipleBPCreationMixin {
  @Prop()
  uuid: string

  errorsCount = 0
  successCount = 0
  queuedCount = 0

  isLoading = false

  @Watch('uuid')
  onFileUUIDChange(uuid) {
    if (!uuid) return
    this.initialize()
  }

  get payload(): IContentDownloaderParams {
    return {
      file_uuid: this.uuid,
    }
  }

  get totalCount() {
    return this.successCount + this.errorsCount + this.queuedCount
  }

  async initialize() {
    try {
      this.isLoading = true
      const queue = await this.getUploadsQueue(this.payload)
      if (queue) this.queuedCount = queue.total

      const success = await this.getCreatedProducts(this.payload)
      if (success) this.successCount = success.total

      const error = await this.getFailedProducts(this.payload)
      if (error) this.errorsCount = error.total
    } catch (err) {
      showErrorMsg(err.message)
    } finally {
      this.isLoading = false
    }
  }
}

export default UploadedFileInfo
