import PartnerView from 'CRM/pages/Partner/PartnerView'
import ClientView from 'CRM/pages/Client/ClientView'

export default [
  {
    path: '/crm/partner/:tdId',
    name: 'PartnerProfile',
    component: PartnerView,
    meta: {
      permission: {
        service: 'merchant-profile', // crm
        permissions: ['merchant-view'], // 'partner-info-view'
      },
      failPath: '/censor',
    },
  },
  {
    path: '/crm/client/:tdId',
    name: 'ClientProfile',
    component: ClientView,
    meta: {
      permission: {
        service: 'merchant-profile', // crm
        permissions: ['merchant-view'], // 'client-info-view'
      },
      failPath: '/censor',
    },
  },
]
