import { mapStateToFSM } from 'Core/modules/helpers'

export default {
  namespaced: true,
  state: {
    payments: null,
    isLoading: false,
  },
  mutations: {
    SET_PAYMENTS(state, platforms) {
      state.payments = platforms
    },
    SET_PAYMENTS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async fetchPayments(context) {
      let data = null
      try {
        context.commit('SET_PAYMENTS_LOADING', true)
        data = await this._vm.$API.ConfigDiscovery.getPaymentList()
      } finally {
        context.commit('SET_PAYMENTS_LOADING', false)
        context.commit('SET_PAYMENTS', data)
      }
    },
  },
  getters: {
    /** оплаты в ФСМ формате. Будут использованы позже */
    paymentsFSM: (state) =>
      mapStateToFSM(state.payments, {
        label: 'name',
        value: 'id',
        variant: 'type',
        icon: 'icon_url',
      }),
    payments: (state) => state.payments,
    isLoading: (state) => state.isLoading,
  },
}
