export const formState = {
  description: {
    kz: null,
    ru: null,
  },
  group_id: null,
  has_options: false,
  is_collection: false,
  is_description: false,
  is_required: false,
  is_variable: false,
  title: {
    kz: null,
    ru: null,
  },
  type: 'string',
  weight: 0,
}
export const optionsState = [
  {
    description: null,
    value: {
      ru: null,
      kz: null,
    },
  },
]
export const rules = {
  weight: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  group_id: {
    required: true,
    message: 'Поле обязательно для заполнения',
  },
  description: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
  },
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения(Только кириллица)',
      required: true,
    },
  },
}
