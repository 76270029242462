<template>
  <div>
    <a-button @click="toggleModal(true)"> Добавить сотрудника</a-button>
    <a-modal
      :visible="isVisible"
      title="Добавление сотрудника"
      @ok="handleOk"
      cancel-text="Отмена"
      ok-text="Добавить"
      @cancel="toggleModal(false)"
      :ok-button-props="{
        props: { disabled: !checkedList.length },
      }"
    >
      <div>
        <a-form-model ref="form" :model="searchState" @submit="onSearch">
          <phone-input
            label="Поиск по номеру телефона"
            v-model="searchState.phone"
          />
          <a-button type="primary" html-type="submit">Поиск</a-button>
        </a-form-model>
      </div>
      <template v-if="fioInfo">
        <div class="user-wrap">
          <a-tag class="user-info" color="cyan"> {{ fioInfo }}</a-tag>

          <div>
            <a-checkbox-group v-model="checkedList">
              <a-row>
                <a-col v-for="(role, index) in allowedRoleList" :key="index">
                  <a-checkbox :value="role.id">
                    {{ role.title.ru }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { searchUserAPI, createEmployeeAPI } from 'User/api/users'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'SearchUserModal',
  props: {
    allowedRoleList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      newEmployee: {
        id: null,
        tdid: null,
      },
      checkedList: [],
      searchState: { phone: null },
    }
  },
  computed: {
    fioInfo() {
      if (this.newEmployee && this.newEmployee.id) {
        return `${this.newEmployee.last_name} ${this.newEmployee.first_name} ${this.newEmployee.patronymic}
        :${this.newEmployee.email}`
      }
      return ''
    },
  },
  methods: {
    toggleModal(state) {
      if (state === false) {
        this.checkedList = []
        if (this.newEmployee) {
          this.newEmployee.id = null
        }
      }
      this.isVisible = state
    },
    async onSearch(event) {
      event.preventDefault()
      this.newEmployee = {
        id: null,
        tdid: null,
      }
      try {
        const { Users } = await searchUserAPI(this.searchState.phone)
        this.newEmployee = Users[0]
      } catch (error) {
        showErrorMsg('Повторите позже')
      }
    },
    async handleOk() {
      try {
        const data = {
          is_active: true,
          role_ids: this.checkedList,
          tdid: this.newEmployee.id,
        }
        await createEmployeeAPI(data)
        this.isVisible = false
        showSuccessMsg('Пользователь добавлен.')
        this.$emit('updateUserList')
      } catch (e) {
        showErrorMsg('Упс, не удалось. Попробуйте позже')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.user-wrap {
  margin-top: 15px;
}

.user-info {
  padding: 7px 12px;
  margin-bottom: 20px;
  font-size: 13px;
}
</style>
