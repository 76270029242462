export const installmentColumns = [
  {
    title: 'Заголовок',
    dataIndex: 'title.ru',
    key: 'title.ru',
    scopedSlots: { customRender: 'naming' },
  },
  {
    title: 'Продолжительность',
    dataIndex: 'duration',
    key: 'duration',
    width: '200px',
    scopedSlots: { customRender: 'duration' },
  },
  {
    title: 'Комиссионные',
    dataIndex: 'commission',
    key: 'commission',
    width: '150px',
    scopedSlots: { customRender: 'commission' },
  },
  {
    title: 'Дата начала',
    dataIndex: 'start_at',
    key: 'start_at',
    scopedSlots: { customRender: 'start_at' },
  },
  {
    title: 'Дата завершения',
    dataIndex: 'end_at',
    key: 'end_at',
    scopedSlots: { customRender: 'end_at' },
  },
]
