import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис Хранение документов и пользователських подписей этих документов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/agree/swagger/index.html} */
export class AgreementService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'agree',
    })
  }

  @Api
  async getAgreeDocumentsList(params) {
    const response = await this.GET(`/v1/admin/specifications`, params)
    return response
  }

  @Api
  async getOldAgreeDocumentList(id, params) {
    const response = await this.GET(
      `/v1/admin/specifications/${id}/agreements`,
      params,
    )
    return response
  }

  @Api
  async getAgreeDocumentById(id) {
    const response = await this.GET(`/v1/admin/specifications/${id}`)
    return response
  }

  @Api
  async createAgreeDocument(payload) {
    const response = await this.POST(`/v1/admin/specifications/`, payload)
    return response
  }

  @Api
  async updateAgreeDocument(id, payload) {
    const response = await this.PUT(`/v1/admin/specifications/${id}`, payload)
    return response
  }
}
