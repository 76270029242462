import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления баннерами на сайте
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/banner/swagger/index.html} */
export class BannerService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'banner',
    })
  }

  @Api
  async getBannersList(params) {
    const response = await this.GET(`/v1/banners`, params)
    return response
  }

  @Api
  async getBannerById(id) {
    const response = await this.GET(`/v1/banners/${id}`)
    return response
  }

  @Api
  async createBanner(payload) {
    const response = await this.POST(`/v1/banners/`, payload)
    return response
  }

  @Api
  async updateBannerById(id, payload) {
    const response = await this.PUT(`/v1/banners/${id}`, payload)
    return response
  }

  @Api
  async updateBannerWeightById(id, payload) {
    const response = await this.PUT(`/v1/banners/${id}/weight`, payload)
    return response
  }

  @Api
  async deleteBannerById(id) {
    const response = await this.DELETE(`/v1/banners/${id}`)
    return response
  }
}
