import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'js-cookie'
import store from 'Core/store/index'

import NotFound from 'Core/pages/NotFound'
import Censor from 'Core/pages/Censor/Censor'

import Login from 'User/pages/Login'
import Notifier from '../modules/Notifier/Notifier'
import ProfileInfo from 'User/pages/Profile/ProfileInfo.vue'

Vue.use(VueRouter)

import ConfigRoutes from './config'
import PartnerRoutes from './partner'
import ContentRoutes from './content'
import ProductRoutes from './product'
import MarketingRoutes from './marketing'
import ReferenceRoutes from './references'
import OrderRoutes from './order'
import UserList from './user'
import CRMRoutes from './crm'
import FinanceRoutes from './finances'
import ClientRoutes from './clients'

export const routes = [
  ...UserList,
  ...ConfigRoutes,
  ...PartnerRoutes,
  ...ContentRoutes,
  ...ProductRoutes,
  ...MarketingRoutes,
  ...OrderRoutes,
  ...ReferenceRoutes,
  ...FinanceRoutes,
  ...ClientRoutes,
  ...CRMRoutes,
  {
    path: '/',
    name: 'Notifier',
    component: Notifier,
    meta: {
      permission: {
        //пока пусть будет но вроде скоро снесем этот модуль
        service: 'merchant-profile',
        permissions: ['merchant-view'],
      },
      failPath: '/сensor',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isOpen: true,
      name: 'Логин',
    },
  },
  {
    path: '/profile',
    name: 'ProfileInfo',
    component: ProfileInfo,
    meta: {
      permission: {
        service: 'merchant-profile',
        permissions: ['merchant-view'],
      },
      search: {
        name: 'Мой профиль',
        icon: 'user',
      },
      failPath: '/сensor',
    },
  },
  {
    path: '/censor',
    name: 'Censor',
    component: Censor,
    meta: {
      isOpen: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      isOpen: true,
      name: 'Ничего не найдено',
    },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach(async (to, from, next) => {
  router.app.$logger.addAction({ to: to.path, from: from.path })

  const isAuthenticated = Boolean(cookie.get('accessToken'))
  const isInnerPage = to.name !== 'Login'
  const shouldRedirectToLogin = isInnerPage && !isAuthenticated
  if (shouldRedirectToLogin) {
    return next({ name: 'Login' })
  }
  if (to.meta.isOpen) {
    return next()
  }
  if (!router.app.$acl.isActive) {
    await store.dispatch('user/updatePermissions')
  }

  const hasPermission = router.app.$acl.can(to.meta.permission)
  if (hasPermission) {
    return next()
  }
  next({ path: to.meta.failPath })
})

export default router
