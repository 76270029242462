<template>
  <div>
    <div class="settings-wrap">
      <h2>Заявки на возврат</h2>
    </div>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onFilter">
      <div class="settings-wrap">
        <phone-input
          label="Номер телефона клиента"
          v-model="filter.customer_phone"
          prop="customer_phone"
        />
        <a-form-model-item label="Номер заказа:">
          <a-input v-model="filter.order_id" />
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :loading="isLoading"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/purchase_returns/${id}`">
          <code>{{ id | shortID(5) }}</code>
        </router-link>
      </div>
      <div slot="order_id" slot-scope="order_id">
        <router-link :to="`/order/${order_id}`" target="_blank">
          <code>{{ order_id | shortID(5) }}</code>
        </router-link>
      </div>
      <div slot="refund_state" slot-scope="refund_state">
        <template v-if="returnStatus[refund_state]">
          <a-tag :color="returnStatus[refund_state].color">
            {{ returnStatus[refund_state].label }}
          </a-tag>
        </template>
        <template v-else>
          Неизвестный статус - <code>[{{ refund_state }}]</code>
        </template>
      </div>
      <div slot="reason" slot-scope="reason">
        {{ getReadableReason(reason) }}
      </div>
      <div slot="created_at" slot-scope="created_at">
        {{ created_at | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { columnNames } from './constants'
import { returnStatus } from 'Core/constants'

export default {
  name: 'PurchaseReturnList',
  data: () => ({
    columnNames,
    returnStatus,
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 20,
    },
    filter: {
      customer_phone: null,
      order_id: null,
      page: 1,
      limit: 20,
    },
  }),
  methods: {
    getReadableReason(reason) {
      switch (reason) {
        case 'changed_mind': {
          return 'Передумал покупать'
        }
        case 'defective': {
          return 'Брак'
        }
        default: {
          return 'Другое'
        }
      }
    },
    initialize() {
      try {
        this.isLoading = true
        this.getPurchaseReturnList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getPurchaseReturnList() {
      this.isLoading = true
      if (this.filter.customer_phone && this.filter.customer_phone !== '') {
        this.filter.customer_phone = this.filter.customer_phone.replace(
          /[^\d]/g,
          '',
        )
      }
      const { count, refunds } = await this.$API.RMS.getPurchaseReturnList(
        this.filter,
      )
      this.pagination.total = count
      this.dataSource = refunds
      this.isLoading = false
    },
    async onFilter(event) {
      event.preventDefault()
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getPurchaseReturnList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getPurchaseReturnList()
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        customer_phone: null,
        order_id: null,
        page: 1,
        limit: 20,
      }
      this.getPurchaseReturnList()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
