var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.value),function(product,index){return _c('a-tag',{key:product.sku ? product.sku : product.id,attrs:{"closable":""},on:{"close":function($event){return _vm.removeSelectedProduct(product, index)}}},[_vm._v(" "+_vm._s(product.title.ru)+" ")])}),_c('a-button',{staticStyle:{"background":"#fff","borderstyle":"dashed"},attrs:{"size":"small"},on:{"click":function($event){_vm.isVisible = true}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Добавить базовый продукт ")],1),_c('a-drawer',{attrs:{"title":"Выбрать продукт из списка","width":"35%","visible":_vm.isVisible,"keyboard":"","closable":false}},[_c('a-form-model',{attrs:{"layout":"inline"},on:{"submit":_vm.searchProducts},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"Поиск по названию компании"},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1),_c('a-form-model-item',[_c('a-button-group',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Поиск")])],1)],1)],1),(_vm.products.length)?_c('div',{staticClass:"product__list"},[_c('a-table',{attrs:{"columns":_vm.productColumns,"data-source":_vm.products,"scroll":{ x: true },"row-selection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onSelect: _vm.onSelectChange,
          hideSelectAllCheckbox: true,
        },"row-key":function (ref) {
                var sku = ref.sku;

                return sku;
},"locale":{ emptyText: 'Данные не найдены ):' },"loading":_vm.isLoading,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"product_title",fn:function(product_title){return _c('div',{},[_vm._v(" "+_vm._s((product_title && product_title.ru) || 'Не указан')+" ")])}}],null,false,920553679)})],1):_vm._e(),_c('div',{staticClass:"button-absolute-box"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onSave}},[_vm._v(" Выбрать")]),_c('a-button',{on:{"click":_vm.onClose}},[_vm._v(" Отмена")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }