import { createFSMList } from 'Core/types/FCM'

export const MODERATION_STATUSES = createFSMList({
  moderation_required: {
    value: 'moderation_required',
    label: 'Ожидает модерации',
    variant: 'blue',
    icon: 'clock-circle',
  },
  confirmed: {
    value: 'confirmed',
    label: 'Подтвержден',
    variant: 'green',
    icon: 'check',
  },
  rejected: {
    value: 'rejected',
    label: 'Отклонен',
    variant: 'red',
    icon: 'stop',
  },
})
