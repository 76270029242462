<template>
  <a-drawer
    title="Необходимо дозаполнить"
    width="35%"
    placement="right"
    :mask-closable="true"
    :keyboard="true"
    @close="onClose"
    :destroy-on-close="true"
    :visible="showToFixList"
  >
    <div>
      <p class="info">
        Чтобы сделать обязательным, необходимо дозаполнить во всех ниже
        указанных Базовых продуктах
      </p>
      <h3>После попробуйте снова!</h3>
    </div>
    <div class="box-wrap">
      <a
        target="_blank"
        class="box-wrap__item"
        v-for="(id, index) in BaseProductList"
        :href="`#/base-product/${id}?toSection=attr-slug-${attrSlug}`"
        :key="index"
        >{{ id }}
      </a>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: 'ToFixBaseProductList',
  props: {
    BaseProductList: {
      type: Array,
    },
    showToFixList: {
      type: Boolean,
    },
    attrSlug: {
      type: String,
      required: true,
    },
  },
  watch: {},
  methods: {
    onClose() {
      this.$emit('hideToFixList')
    },
  },
}
</script>

<style scoped lang="scss">
.box-wrap {
  display: flex;
  flex-wrap: wrap;
  &__item {
    cursor: pointer;
    padding: 2px 9px;
    margin: 0 10px 10px 0;
    transition: 0.3s;
    border: 1px solid black;
    border-radius: 7px;
    &:hover {
      color: white;
      background-color: black;
    }
  }
}
</style>
