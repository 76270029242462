import { ALPHA } from 'Core/modules/validators/alpha'

export const formState = {
  body: {
    kz: '',
    ru: '',
  },
  weight: 0,
  description: '',
  //icon_url: '',
  parent_id: '',
  is_available: true,
  slug: '',
  platforms: ['web', 'ios', 'android'],
  title: {
    kz: '',
    ru: '',
  },
  faqType: 'merchant' | 'user',
}

export const rules = {
  slug: [
    {
      required: true,
      fullField: 'Слаг',
      max: 50,
    },
    {
      fullField: 'Слаг',
      validator: ALPHA,
      langs: ['en'],
      specials: ['-', '_'],
    },
  ],
  weight: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  platforms: {
    type: 'array',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'body.ru': {
    type: 'string',
    required: true,
    message: 'Поле обязательно для заполнения',
  },
  'title.ru': {
    type: 'string',
    required: true,
    message: 'Поле обязательно для заполнения',
  },
}
