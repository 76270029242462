import { ApiServiceClass } from './ApiServiceClass'
import { BusinessLogicException } from 'Core/modules/API/@types/BusinessLogicException'
import * as Sentry from '@sentry/vue'

export function Api(
  target: ApiServiceClass,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) {
  const originalMethod = descriptor.value
  descriptor.value = async function (...args: any[]) {
    try {
      const { data, success } = await originalMethod.apply(this, args)
      if (!success) {
        throw new BusinessLogicException(
          data?.error?.message ? data?.error?.message : originalMethod.name,
        )
      }
      return data
    } catch (error) {
      target.CaptureException(originalMethod, args, error)
      if (error instanceof BusinessLogicException) {
        throw new Error(error.transformErrorMessage())
      } else {
        throw new Error(
          `Произошла ошибка при отправке запроса. ${originalMethod.name}`,
        )
      }
    }
  }
}
