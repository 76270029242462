<template>
  <div>
    <a-form-model-item label="Выберите атрибут" :prop="attributeInfo.slug">
      <a-select
        mode="multiple"
        v-if="attributeInfo.is_collection"
        placeholder="Выберите из списка (можно несколько выбрать)"
        style="width: 100%"
        @change="handleInput"
        :filter-option="filterOption"
        v-model="selectedOption"
      >
        <a-select-option
          v-for="(option, index) in optionsList"
          :key="option.slug"
          :value="index"
        >
          {{ option.value.ru }}
        </a-select-option>
      </a-select>
      <a-select
        v-else
        mode="default"
        :allow-clear="true"
        show-search
        :filter-option="filterOption"
        @change="handleInput"
        v-model="selectedOption"
      >
        <a-select-option
          v-for="(item, index) in optionsList"
          :key="(index + 9).toString(36) + index"
          :value="index"
        >
          {{ item.value.ru }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>

<script>
import { filterOption } from 'Core/utils'

export default {
  name: 'AttributeSelector',
  props: {
    attributeInfo: {
      type: Object,
      required: true,
    },
    optionsList: {
      type: Array,
      required: true,
    },
    modelId: {
      type: Number,
    },
    value: [],
  },
  data() {
    return {
      selectedOption: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.selectedOption =
        newValue && newValue[0] !== undefined && newValue[0] !== null
          ? newValue
          : []
    },
  },
  methods: {
    filterOption,
    handleInput() {
      const localArray = this.attributeInfo.is_collection
        ? this.selectedOption
        : [this.selectedOption]
      this.$emit(
        'input',
        localArray[0] !== undefined && localArray[0] !== null ? localArray : [],
      )
    },
  },
}
</script>

<style scoped></style>
