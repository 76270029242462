export const formState = {
  css: null,
  html: null,
  js: null,
  meta: {
    description: null,
    keywords: null,
    title: null,
  },
  title: null,
  slug: null,
  is_active: false,
}

export const rules = {
  title: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  sluh: {
    type: 'string',
    required: true,
    message: 'Поле обязательно для заполнения',
  },
  html: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  css: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}
