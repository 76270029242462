import * as Sentry from '@sentry/vue'
import { IUserContext, IRequestParams, IRouteHistory } from './interfaces'

const agentContext: IUserContext = {
  browserName: detectBrowserName(navigator.userAgent),
  cookieEnabled: navigator.cookieEnabled,
  language: navigator.language,
  platform: navigator.platform,
  userAgent: navigator.userAgent,
}

function detectBrowserName(userAgent) {
  let browserName: string = 'No browser detection'
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Google Chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge'
  }
  return browserName
}

class Logger {
  userContext: object
  actionList: Array<IRouteHistory | IRequestParams>
  historyMaxLength: number

  constructor(config, agent) {
    this.userContext = {
      ...config.store.getters[config.storePath],
      ...agent,
    }
    Sentry.setUser(config.store.getters[config.storePath])
    this.actionList = []
    this.historyMaxLength = config.historyMaxLength
  }

  addAction(newTrigger: IRouteHistory | IRequestParams): void {
    if (this.actionList.length === this.historyMaxLength)
      this.actionList.shift()
    this.actionList.push(newTrigger)
  }
}

export const LogList = {
  install(Vue, config) {
    Vue.prototype.$logger = new Logger(config, agentContext)
  },
}

export default LogList
