import axios from 'axios'

const didAbort = (error) => axios.isCancel(error)

const getCancelSource = () => axios.CancelToken.source()

export const withAbort =
  (fn) =>
  async (...args) => {
    const originalConfig = args[args.length - 1]
    let { abort, ...config } = originalConfig

    if (typeof abort === 'function') {
      const { cancel, token } = getCancelSource()
      config.cancelToken = token
      abort(cancel)
    }

    try {
      return await fn(...args.slice(0, args.length - 1), config)
    } catch (error) {
      didAbort(error) && (error.aborted = true)
      throw error
    }
  }
