<template>
  <div>
    <h2>Партнеры</h2>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onSearch">
      <div class="settings-wrap">
        <div>
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="filter.status"
            @change="searchHandler"
          >
            <a-radio-button :value="null"> Все</a-radio-button>
            <a-radio-button
              v-for="(item, index) in PARTNER_STATUSES"
              :value="item.value"
              :key="index"
            >
              <a-icon :type="item.icon" />
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
        </div>
        <div>
          <a-form-item>
            <a-button
              type="primary"
              @click.prevent.stop="addPartner"
              v-can="{
                service: 'merchant-profile',
                permissions: ['merchant-create'],
              }"
            >
              <a-icon type="user-add" />
              Зарегистрировать партнера
            </a-button>
          </a-form-item>
        </div>
      </div>
      <div class="settings-wrap">
        <a-form-model-item label="Название юр.лица">
          <a-input v-model="filter.company_name" />
        </a-form-model-item>
        <a-form-model-item label="Бин">
          <a-input v-model="filter.bin" />
        </a-form-model-item>
        <phone-input
          label="Номер партнера"
          v-model="filter.phone"
          prop="phone"
        />
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">
              <a-icon type="search" />
              Поиск
            </a-button>
            <a-button type="dashed" @click="dropFilter">
              <a-icon type="close" />
              Сбросить
            </a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/partner/${id}`">
          <code>{{ id | shortID(5) }}</code>
        </router-link>
      </div>
      <div slot="fullName" slot-scope="fullName">
        {{ fullName.first_name | fio(fullName.last_name, fullName.patronymic) }}
      </div>
      <div slot="status" slot-scope="status">
        <status-view
          :status-list="PARTNER_STATUSES"
          :status="status"
        ></status-view>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
    </a-table>
    <!--    <a-divider>Создание партнера вручную</a-divider>-->
    <!--    <a-button-->
    <!--      type="primary"-->
    <!--      block-->
    <!--      @click="$router.push({ name: 'MerchantCreate' })"-->
    <!--    >-->
    <!--      Создать-->
    <!--    </a-button>-->
  </div>
</template>

<script>
import { columnNames } from './constants'
import { showErrorMsg } from 'Core/utils'
import { PARTNER_STATUSES } from 'Core/types/partner'

export default {
  name: 'PartnersList',
  data: () => ({
    PARTNER_STATUSES,
    columnNames,
    searchLabel: '',
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      bin: null,
      status: null,
      company_name: null,
      phone: null,
      page: 1,
      limit: 10,
    },
  }),
  methods: {
    addPartner() {
      this.$router.push({ name: 'PartnerCreate' })
    },
    onSearch(event) {
      event.preventDefault()
      this.searchHandler()
    },
    async searchHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getPartnerList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getPartnerList()
    },
    initialize() {
      try {
        this.isLoading = true
        this.getPartnerList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getPartnerList() {
      const { data, count } = await this.$API.MerchantProfile.getPartnerList(
        this.filter,
      )
      this.pagination.total = count
      this.dataSource = data
      this.isLoading = false
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        bin: null,
        status: null,
        company_name: null,
        phone: null,
        page: 1,
        limit: 10,
      }
      this.getPartnerList()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.active {
  background-color: red;
}
</style>
