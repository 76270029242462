import { render, staticRenderFns } from "./MailingTemplateCreate.vue?vue&type=template&id=48f10eb8&scoped=true&"
import script from "./MailingTemplateCreate.vue?vue&type=script&lang=js&"
export * from "./MailingTemplateCreate.vue?vue&type=script&lang=js&"
import style0 from "./MailingTemplateCreate.vue?vue&type=style&index=0&id=48f10eb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f10eb8",
  null
  
)

export default component.exports