export const paymentTypeFormRules = {
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  type: {
    type: 'string',
    message: 'Выберите тип оплаты',
    required: true,
  },
}
export const paymentTypes = [
  {
    name: 'Наличными',
    value: 'cash',
  },
  {
    name: 'Картой курьеру',
    value: 'card_offline',
  },
  {
    name: 'Онлайн картой',
    value: 'card_online',
  },
  {
    name: 'Кредит',
    value: 'loan',
  },
  {
    name: 'Рассрочка',
    value: 'installment',
  },
]
