export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название группы',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: 'Дата последнего обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updatedAt' },
  },
]
