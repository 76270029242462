import axios from 'axios'
import cookie from 'js-cookie'
import { refreshAccessToken } from '@/modules/User/api/sso'

const axiosConfig = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosConfig.interceptors.request.use(
  async (config) => {
    config.headers['Authorization'] = `Bearer ${cookie.get('accessToken')}`
    return config
  },
  (error) => Promise.reject(error),
)

axiosConfig.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalRequest = err.config

    if (err.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const newAccessToken = await refreshAccessToken()
      axiosConfig.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`
      return axiosConfig(originalRequest)
    }

    return Promise.reject(err)
  },
)

export default axiosConfig
