<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-form-model
        ref="companyInfoForm"
        :model="formState"
        :rules="companyInfoStateRules"
        @submit="saveCompanyInfoHandler"
      >
        <a-form-model-item prop="ownership" label="Тип компании">
          <a-radio-group v-model="formState.ownership">
            <a-radio-button value="ТОО"> ТОО</a-radio-button>
            <a-radio-button value="ИП"> ИП</a-radio-button>
            <a-radio-button value="АО"> АО</a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item
          prop="company_name"
          label="Юридическое название компании"
        >
          <a-input v-model="formState.company_name" />
        </a-form-model-item>

        <a-form-model-item prop="first_name_ceo" label="Имя директора">
          <a-input
            v-model="formState.first_name_ceo"
            @focusout="capitalize('first_name_ceo')"
          />
        </a-form-model-item>
        <a-form-model-item prop="last_name_ceo" label="Фамилия директора">
          <a-input
            v-model="formState.last_name_ceo"
            @focusout="capitalize('last_name_ceo')"
          />
        </a-form-model-item>
        <a-form-model-item prop="patronymic_ceo" label="Отчество директора">
          <a-input
            v-model="formState.patronymic_ceo"
            @focusout="capitalize('patronymic_ceo')"
          />
        </a-form-model-item>
        <a-form-model-item prop="email" label="Электронная почта(email)">
          <a-input v-model="formState.email" />
        </a-form-model-item>
        <phone-input
          prop="phone_number"
          label="Контактный номер"
          v-model="formState.phone_number"
        />
        <a-form-model-item label="Ссылка на сайт (Пример: www.airba.kz)">
          <a-input v-model="formState.site_url" />
        </a-form-model-item>
        <a-button type="primary" html-type="submit">
          Сохранить изменения о Компании
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { companyInfoStateRules } from 'Partner/pages/Partner/PartnerInfo/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { capitalize } from 'Core/modules/helpers'

export default {
  name: 'PartnerAboutInfo',
  props: {
    aboutInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      companyInfoStateRules,
      formState: this.aboutInfo,
      isFetching: false,
    }
  },
  methods: {
    capitalize(str1) {
      if (this.formState && this.formState[str1]) {
        this.formState[str1] = capitalize(this.formState[str1])
      }
    },
    async saveCompanyInfoHandler(event) {
      event.preventDefault()
      this.$refs.companyInfoForm.validate(async (result) => {
        if (result) {
          try {
            this.isFetching = true
            const companyInfoPayload = {
              company_info: this.formState,
            }
            await this.$API.MerchantProfile.updateProfile(
              this.merchantID,
              companyInfoPayload,
            )
            showSuccessMsg('Удачно обновлен!')
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isFetching = false
          }
        }
      })
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped></style>
