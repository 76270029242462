import axios, { AxiosInstance } from 'axios'
import Qs from 'qs'
import { SSOService } from 'Core/modules/API/services/SSO.service'
import cookie from 'js-cookie'
import { PromoCodeService } from 'Core/modules/API/services/PromoCode.service'
import { PayplanService } from 'Core/modules/API/services/Payplan.service'
import { StorefrontService } from 'Core/modules/API/services/Storefront.service'
import { QRCodeService } from 'Core/modules/API/services/QRCode.service'
import { JamService } from 'Core/modules/API/services/Jam.service'
import { OMSService } from 'Core/modules/API/services/OMS.service'
import { RMSService } from 'Core/modules/API/services/RMS.service'
import { MerchantProfileService } from 'Core/modules/API/services/MerchantProfile.service'
import { ConfigDiscoveryService } from 'Core/modules/API/services/ConfigDiscovery.service'
import { MerchantGatewayService } from 'Core/modules/API//services/MerchantGateway.service'
import { CommissionsService } from 'Core/modules/API/services/Commissions.service'
import { MenuService } from 'Core/modules/API/services/Menu.service'
import { BannerService } from 'Core/modules/API/services/Banner.service'
import { AccounterService } from 'Core/modules/API/services/Accounter.service'
import { AgreementService } from 'Core/modules/API/services/Agreement.service'
import { ContentDownloaderService } from 'Core/modules/API/services/ContentDownloader.service'
import { PPS } from 'Core/modules/API/services/PPS.service'
import { TDStealerService } from 'Core/modules/API/services/stealers/TDStealer.service'
import { OzonStealerService } from 'Core/modules/API/services/stealers/OzonStealer.service'
import { KaspiStealerService } from 'Core/modules/API/services/stealers/KaspiStealer.service'
import { UserProfileService } from 'Core/modules/API/services/UserProfile'
import { FeedbackService } from 'Core/modules/API/services/Feedback.service'

export class API {
  protected axios: AxiosInstance
  protected axiosPrivate: AxiosInstance

  public SSO: SSOService
  public PromoCode: PromoCodeService
  public Storefront: StorefrontService
  public Commissions: CommissionsService
  public Payplan: PayplanService
  public QRCode: QRCodeService
  public Jam: JamService
  public OMS: OMSService
  public RMS: RMSService
  public MerchantProfile: MerchantProfileService
  public ConfigDiscovery: ConfigDiscoveryService
  public UserProfile: UserProfileService
  public MerchantGateway: MerchantGatewayService
  public Menu: MenuService
  public Banner: BannerService
  public Accounter: AccounterService
  public Agreement: AgreementService
  public ContentDownloader: ContentDownloaderService
  public TDStealer: TDStealerService
  public OzonStealer: OzonStealerService
  public KaspiStealer: KaspiStealerService
  public PPS: PPS
  public Feedback: FeedbackService
  constructor(config) {
    this.axios = axios.create({
      baseURL: config.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: (params) => {
        return Qs.stringify(params, {
          arrayFormat: 'comma',
          skipNulls: true,
        })
      },
    })
    this.axios.interceptors.request.use(
      async (config) => {
        config.headers['Authorization'] = `Bearer ${cookie.get('accessToken')}`
        return config
      },
      (error) => Promise.reject(error),
    )

    this.axios.interceptors.response.use(
      (response) => response,
      async (err) => {
        const originalRequest = err.config

        if (err.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          const { data } = await this.axios.post('/sso/api/v1/auth/refresh', {
            refresh_token: cookie.get('refreshToken'),
          })
          cookie.set('accessToken', data.access_token)
          this.axios.defaults.headers[
            'Authorization'
          ] = `Bearer ${data.access_token}`
          return this.axios(originalRequest)
        }
        return err.response
      },
    )
    // TEMP
    this.axiosPrivate = axios.create({
      baseURL: config.privateBaseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: (params) =>
        Qs.stringify(params, { arrayFormat: 'comma', skipNulls: true }),
    })
    this.axiosPrivate.interceptors.request.use(
      async (config) => {
        config.headers['Authorization'] = `Bearer ${cookie.get('accessToken')}`
        return config
      },
      (error) => Promise.reject(error),
    )

    this.axiosPrivate.interceptors.response.use(
      (response) => response,
      async (err) => {
        const originalRequest = err.config

        if (err.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          const { data } = await this.axios.post('/sso/api/v1/auth/refresh', {
            refresh_token: cookie.get('refreshToken'),
          })
          cookie.set('accessToken', data.access_token)
          this.axios.defaults.headers[
            'Authorization'
          ] = `Bearer ${data.access_token}`
          return this.axios(originalRequest)
        }
        return Promise.reject(err)
      },
    )

    this.SSO = new SSOService(this.axios)
    this.PromoCode = new PromoCodeService(this.axios)
    this.Storefront = new StorefrontService(this.axios)
    this.Payplan = new PayplanService(this.axios)
    this.QRCode = new QRCodeService(this.axios)
    this.Jam = new JamService(this.axios)
    this.OMS = new OMSService(this.axios)
    this.RMS = new RMSService(this.axios)
    this.MerchantProfile = new MerchantProfileService(this.axios)
    this.UserProfile = new UserProfileService(this.axios)
    this.ConfigDiscovery = new ConfigDiscoveryService(this.axios)
    this.MerchantGateway = new MerchantGatewayService(this.axios)
    this.Commissions = new CommissionsService(this.axios)
    this.Menu = new MenuService(this.axios)
    this.Banner = new BannerService(this.axios)
    this.Accounter = new AccounterService(this.axios)
    this.Agreement = new AgreementService(this.axios)
    this.ContentDownloader = new ContentDownloaderService(this.axiosPrivate)
    this.TDStealer = new TDStealerService(this.axios)
    this.OzonStealer = new OzonStealerService(this.axios)
    this.KaspiStealer = new KaspiStealerService(this.axios)
    this.PPS = new PPS(this.axios)
    this.Feedback = new FeedbackService(this.axios)
  }
}

export const APIModule = {
  install: (Vue, config) => {
    Vue.prototype.$API = new API(config)
  },
}
