export const formState = {
  sku: null,
  title: {
    kz: null,
    ru: null,
  },
  about: {
    kz: null,
    ru: null,
  },
  about_page: {
    kz: null,
    ru: null,
  },
  brand: {
    title: {
      ru: 'Updating...',
    },
  },
  media: [],
  model_id: null,
  color: null,
  price: {
    minimum: 100,
  },
  type: 'REGULAR',
  categories: [],
  dimensions: [
    {
      package: {
        depth: 1,
        height: 1,
        width: 1,
      },
      weight: 1,
    },
  ],
}

export const rules = {
  title: {
    ru: {
      type: 'string',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
  color: {
    type: 'string',
    message: 'Обязательное поле',
    required: true,
  },
  price: {
    minimum: {
      type: 'number',
      message: 'Обязательное поле',
      required: true,
    },
  },
  brand: {
    message: 'Обязательное поле',
    required: true,
  },
  dimensions: [
    {
      package: {
        depth: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
        height: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
        width: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
      },
      weight: {
        type: 'number',
        message: 'Поле обязательно для заполнения',
        required: true,
      },
    },
  ],
}
