export const brokerPartnersFormRules = {
  title: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  partner_code: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}
