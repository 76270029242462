/* eslint-disable */
import {format} from 'Core/modules/helpers'

export const EMAIL = function (rule, value, callback, source, options) {
    let regExp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (regExp.test(value) || value === '') {
        return true
    } else {
        return callback(
            format(options.messages[rule.validator.name], rule.fullField),
        )
    }
}

//
