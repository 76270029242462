import { render, staticRenderFns } from "./AttributeSelector.vue?vue&type=template&id=98b23a28&scoped=true&"
import script from "./AttributeSelector.vue?vue&type=script&lang=js&"
export * from "./AttributeSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98b23a28",
  null
  
)

export default component.exports