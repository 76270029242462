import { createFSMList } from 'Core/types/FCM'

export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название товара на русском якыке',
    dataIndex: 'product_title.ru',
    key: 'product_title.ru',
    scopedSlots: { customRender: 'product_title.ru' },
  },
  {
    title: 'Название товара на казахском якыке',
    dataIndex: 'product_title.kz',
    key: 'product_title.kz',
    scopedSlots: { customRender: 'product_title.kz' },
  },
  {
    title: 'Дата создания отзыва',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Оценка',
    dataIndex: 'rating',
    key: 'rating',
    scopedSlots: { customRender: 'rating' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Количество лайков',
    dataIndex: 'likes_count',
    key: 'likes_count',
    scopedSlots: { customRender: 'likes_count' },
  },
  {
    title: 'Количество дизлайков',
    dataIndex: 'dislikes_count',
    key: 'dislikes_count',
    scopedSlots: { customRender: 'dislikes_count' },
  },
  {
    title: 'Статус модерации',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Имя модератора',
    dataIndex: 'moderator_name',
    key: 'moderator_name',
    scopedSlots: { customRender: 'moderator_name' },
  },
]

export const REVIEW_SEARCHABLE_FIELDS = createFSMList({
  merchant_name: {
    value: 'merchant_name',
    label: 'Название партнера',
    icon: 'user',
  },
  product_title: {
    value: 'product_title',
    label: 'Название товара',
    icon: 'font-colors',
  },
  order_id: {
    value: 'order_id',
    label: 'Номер заказа',
    icon: 'ordered-list',
  },
  ratings: {
    value: 'ratings',
    label: 'Оценка',
    icon: 'star',
  },
})

export const REVIEW_STATUSES = createFSMList({
  confirmed: {
    value: 'confirmed',
    label: 'Подтвержденные отзывы',
    icon: 'check',
  },
  rejected: {
    value: 'rejected',
    label: 'Отклоненные отзывы',
    icon: 'stop',
  },
  moderation_required: {
    value: 'moderation_required',
    label: 'Ожидает модерции',
    icon: 'clock-circle',
  },
})

export const REVIEW_RATINGS: number[] = [1, 2, 3, 4, 5]
