/* eslint-disable */
const KEYBOARD_TABLE = {
    й: 'q',
    ц: 'w',
    у: 'e',
    к: 'r',
    е: 't',
    н: 'y',
    г: 'u',
    ш: 'i',
    щ: 'o',
    з: 'p',
    ф: 'a',
    ы: 's',
    в: 'd',
    а: 'f',
    п: 'g',
    р: 'h',
    о: 'j',
    л: 'k',
    д: 'l',
    я: 'z',
    ч: 'x',
    с: 'c',
    м: 'v',
    и: 'b',
    т: 'n',
    ь: 'm',
    б: '',
    э: '',
}
const TRANSLITERATION_TABLE = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'j',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'sh',
    ъ: ' ',
    ы: 'y',
    ь: ' ',
    э: 'e',
    ю: 'yu',
    я: 'ya',
}
const SPACE = ' '

const keyboardTransliteration = function (originalName) {
    let tmpName = ''
    for (let i = 0; i < originalName.length; i++) {
        if (/[а-яё]/.test(originalName.charAt(i))) {
            // заменяем символы на русском
            tmpName += KEYBOARD_TABLE[originalName.charAt(i)]
        } else if (/[a-z0-9]/.test(originalName.charAt(i))) {
            // символы на анг. оставляем как есть
            tmpName += originalName.charAt(i)
        } else {
            if (tmpName.slice(-1) !== SPACE) {
                tmpName += SPACE
            } // прочие символы заменяем на space
        }
    }
    return tmpName
}

const englishTransliteration = function (originalName) {
    let tmpName = ''
    for (let i = 0; i < originalName.length; i++) {
        if (/[а-яё]/.test(originalName.charAt(i))) {
            // заменяем символы на русском
            tmpName += TRANSLITERATION_TABLE[originalName.charAt(i)]
        } else if (/[a-z0-9]/.test(originalName.charAt(i))) {
            // символы на анг. оставляем как есть
            tmpName += originalName.charAt(i)
        } else {
            if (tmpName.slice(-1) !== SPACE) {
                tmpName += SPACE
            } // прочие символы заменяем на space
        }
    }
    return tmpName
}

export {keyboardTransliteration, englishTransliteration}
