<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование способа связи</h2>
      <router-link :to="{ name: 'ConfigContactList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isFormLoading">
      <a-form-model
        v-if="contactForm"
        ref="form"
        :model="contactForm"
        :rules="contactFormRules"
        @submit="updateContactById"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="contactForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="comment" label="Комментарий">
          <a-textarea
            allow-clear
            v-model="contactForm.comment"
            :auto-size="{ minRows: 2, maxRows: 4 }"
          />
        </a-form-model-item>
        <a-form-model-item prop="user_type" label="Сервис">
          <a-select
            @select="onSelectService"
            :value="contactForm.user_type"
            placeholder="Выберите сервис"
          >
            <a-select-option
              v-for="(item, index) in serviceTypes"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="type" label="Тип способа связи">
          <a-select
            @select="onSelectType"
            :value="contactForm.type"
            placeholder="Выберите тип способа связи"
          >
            <a-select-option
              v-for="type in contactTypes"
              :key="type"
              :value="type"
            >
              {{ getReadableType(type) }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="value" :label="getValueLabel">
          <a-input v-model="contactForm.value" />
        </a-form-model-item>
        <a-form-model-item prop="icon_url" label="Иконка">
          <upload-files
            @getImageParams="getImageParams"
            :img-url="contactForm.icon_url"
            placeholder-text="Загрузить иконку"
          />
        </a-form-model-item>
        <a-form-model-item prop="priority" label="Приоритет в списке">
          <a-input-number v-model="contactForm.priority" :min="1" />
        </a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          :loading="isUpdateLoading"
          v-can="{
            service: 'banner',
            permissions: ['banner-update'],
          }"
          >Сохранить
        </a-button>
        <a-button
          type="danger"
          class="form__button form__button--danger"
          :loading="isDeleteLoading"
          @click="deleteContact"
          v-can="{
            service: 'banner',
            permissions: ['banner-delete'],
          }"
          >Удалить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { contactFormRules } from './constants'
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

export default {
  components: {
    UploadFiles,
  },
  data() {
    return {
      contactFormRules,
      isUpdateLoading: false,
      isFormLoading: false,
      isDeleteLoading: false,
      contactForm: false,
      contactTypes: null,
      serviceTypes: [
        {
          value: 'user',
          label: 'Клиентский',
        },
        {
          value: 'merchant',
          label: 'Партнерский',
        },
      ],
    }
  },
  computed: {
    contactID() {
      return this.$route.params.contact_id
    },
  },
  async created() {
    this.isFormLoading = true
    await this.getContactTypes()
    await this.getContactById()
    this.isFormLoading = false
  },
  methods: {
    onSelectService(service) {
      this.contactForm.user_type = service
    },
    onSelectType(type) {
      this.contactForm.type = type
      if (this.contactForm.type == 'email') {
        this.contactFormRules.value.push({
          type: 'email',
          message: 'Введите валидный email адрес!',
        })
      } else {
        this.contactFormRules.value = this.contactFormRules.value.filter(
          (rule) => rule.type !== 'email',
        )
      }
    },
    getValueLabel() {
      switch (this.contactForm.type) {
        case 'email':
          return 'Адрес эл.почты'
        case 'phone':
          return 'Номер телефона'
        case 'social_network':
          return 'Ссылка'
        case 'link':
          return 'Ссылка'
        default:
          return 'Значение'
      }
    },
    getReadableType(type) {
      switch (type) {
        case 'email':
          return 'Эл. почта'
        case 'phone':
          return 'Телефон'
        case 'social_network':
          return 'Соц.сеть'
        case 'link':
          return 'Ссылка'
        case 'button':
          return 'Кнопка'
        default:
          return type
      }
    },
    async getContactTypes() {
      try {
        const { types } = await this.$API.ConfigDiscovery.getContactTypes()
        this.contactTypes = types
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getContactById() {
      try {
        this.contactForm = await this.$API.ConfigDiscovery.getContactInfoById(
          this.contactID,
        )
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    getImageParams(params) {
      this.contactForm.icon_url = params
    },
    async updateContactById(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isUpdateLoading = true
            await this.$API.ConfigDiscovery.updateContact(
              this.contactID,
              this.contactForm,
            )
            showSuccessMsg('Способ связи успешно обновлен!')
            this.$router.push({ name: 'ConfigContactList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isUpdateLoading = false
          }
        }
      })
    },
    async deleteContact() {
      try {
        this.isDeleteLoading = true
        await this.$API.ConfigDiscovery.deleteContact(this.contactID)
        showSuccessMsg('Способ связи успешно удален!')
        this.$router.push({
          name: 'ConfigContactList',
        })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;

  &--danger {
    margin-left: 20px;
  }
}
</style>
