import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg } from 'Core/utils'
import { IContentDownloaderParams } from 'Core/modules/API/@types/ContentDownloader.type'

@Component
class MultipleBPCreationMixin extends Vue {
  async getFailedProducts(params?: IContentDownloaderParams) {
    try {
      const response = await this.$API.ContentDownloader.getFailedProducts(
        params,
      )
      return response
    } catch (err) {
      showErrorMsg(err.message)
    }
  }

  async getUploadsQueue(params?: IContentDownloaderParams) {
    try {
      const response = await this.$API.ContentDownloader.getUploadsQueue(params)
      return response
    } catch (err) {
      showErrorMsg(err.message)
    }
  }

  async getCreatedProducts(params?: IContentDownloaderParams) {
    try {
      const response = await this.$API.ContentDownloader.getCreatedProducts(
        params,
      )
      return response
    } catch (err) {
      showErrorMsg(err.message)
    }
  }
}

export default MultipleBPCreationMixin
