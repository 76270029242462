import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import { BusinessLogicException } from 'Core/modules/API/@types/BusinessLogicException'

/**
 *  Сервис управления витриной и базовыми продуктами
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/storefront/swagger/index.html} */
export class StorefrontService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'storefront',
    })
  }

  @Api
  async disableMerchantProduct(id, payload) {
    const response = await this.PUT(
      `/v1/merchants/${id}/products/disableMany`,
      payload,
    )
    return response
  }

  @Api
  async disableMerchantProductById(id, sku) {
    const response = await this.PUT(
      `/v1/merchants/${id}/products/${sku}/disable`,
    )
    return response
  }

  @Api
  async enableMerchantProduct(id, payload) {
    const response = await this.PUT(
      `/v1/merchants/${id}/products/enableMany`,
      payload,
    )
    return response
  }

  @Api
  async enableMerchantProductById(id, sku) {
    const response = await this.PUT(
      `/v1/merchants/${id}/products/${sku}/enable`,
    )
    return response
  }

  @Api
  async searchUnconnectedBaseProduct(id, params) {
    const response = await this.GET(
      `/v1/merchants/${id}/unconnected-base-products`,
      params,
    )
    return response
  }

  @Api
  async getModelAttributeList(id, params) {
    const response = await this.GET(`/v1/models/${id}/attributes`, params)
    return response
  }

  @Api
  async createAttribute(id, params) {
    const response = await this.POST(`/v1/models/${id}/attributes`, params)
    return response
  }

  async updateAttribute(modelId, id, params) {
    const response = await this.PATCH<{ data: any; success: boolean }>(
      `/v1/models/${modelId}/attributes/${id}`,
      params,
    )
    if (response.success) {
      return response
    } else if (
      response?.data?.error?.message.includes(
        'base products with empty attribute',
      )
    ) {
      return {
        data: Object.keys(response.data.validation),
        success: false,
      }
    } else {
      throw new BusinessLogicException(
        response?.data.error?.message
          ? response?.data.error?.message
          : 'updateAttribute',
      )
    }
  }

  @Api
  async getAttributeById(modelId, id) {
    const response = await this.GET(`/v1/models/${modelId}/attributes/${id}`)
    return response
  }

  @Api
  async getModelAttributeGroupList(modelId) {
    const response = await this.GET(`/v1/models/${modelId}/attributes/groups`)
    return response
  }

  @Api
  async createModelAttribute(modelId, params) {
    const response = await this.POST(
      `/v1/models/${modelId}/attributes/groups`,
      params,
    )
    return response
  }

  @Api
  async enlargeModelAttributeOption(modelId, attrID, params) {
    const response = await this.POST(
      `/v1/models/${modelId}/attributes/${attrID}/options`,
      params,
    )
    return response
  }

  @Api
  async getModelAttributeGroupById(modeId, id) {
    const response = await this.GET(
      `/v1/models/${modeId}/attributes/groups/${id}`,
    )
    return response
  }

  @Api
  async getAttributeOptions(modeId, attrId, params) {
    const response = await this.GET(
      `/v1/models/${modeId}/attributes/${attrId}/options`,
      params,
    )
    return response
  }

  @Api
  async updateModelAttributeGroupById(modelId, groupID, params) {
    const response = await this.PUT(
      `/v1/models/${modelId}/attributes/groups/${groupID}`,
      params,
    )
    return response
  }

  @Api
  async deleteModelGroupById(modelId, groupId) {
    const response = await this.DELETE(
      `/v1/models/${modelId}/attributes/groups/${groupId}`,
    )
    return response
  }

  @Api
  async deleteModelAttributeById(modelId, attributeId) {
    const response = await this.DELETE(
      `/v1/models/${modelId}/attributes/${attributeId}`,
    )
    return response
  }

  @Api
  async getModelList(params) {
    const response = await this.GET(`/v1/models`, params)
    return response
  }

  @Api
  async createModel(params) {
    const response = await this.POST(`/v1/models`, params)
    return response
  }

  @Api
  async getModelById(id) {
    const response = await this.GET(`/v1/models/${id}`)
    return response
  }

  @Api
  async updateModelById(id, params) {
    const response = await this.PUT(`/v1/models/${id}`, params)
    return response
  }

  @Api
  async toggleModelStatus(id, newStatus) {
    const response = await this.PUT(`/v1/models/${id}/enabled`, {
      is_enabled: newStatus,
    })
    return response
  }

  @Api
  async getModelDependencyCount(id) {
    const response = await this.GET(`/v1/models/${id}/base-product-count`)
    return response
  }

  @Api
  async duplicateModel(id) {
    const response = await this.POST(`/v1/models/${id}/copy`)
    return response
  }

  @Api
  async getProductGroupList(params) {
    const response = await this.GET(`/v1/groups/`, params)
    return response
  }

  @Api
  async createProductGroup(payload) {
    const response = await this.POST(`/v1/groups`, payload)
    return response
  }

  @Api
  async getProductGroupsById(id) {
    const response = await this.GET(`/v1/groups/${id}`)
    return response
  }

  @Api
  async updateProductGroupsById(id, payload) {
    const response = await this.PUT(`/v1/groups/${id}`, payload)
    return response
  }

  @Api
  async addBaseProductToGroup(sku, group_id) {
    const response = await this.PUT(`/v1/baseproducts/${sku}/group/${group_id}`)
    return response
  }

  @Api
  async deleteBaseProductFromGroup(sku) {
    const response = await this.DELETE(`/v1/baseproducts/${sku}/group`)
    return response
  }

  @Api
  async deleteProductGroupsById(id) {
    const response = await this.DELETE(`/v1/groups/${id}`)
    return response
  }

  @Api
  async getGroupBaseProductsList(id) {
    const response = await this.GET(`/v1/groups/${id}/products`)
    return response
  }

  @Api
  async getBaseProductList(params) {
    const response = await this.GET(`/v1/baseproducts`, params)
    return response
  }

  @Api
  async createBaseProduct(params) {
    const response = await this.POST(`/v1/baseproducts/`, params)
    return response
  }

  @Api
  async getBaseProductById(sku) {
    const response = await this.GET(`/v1/baseproducts/${sku}`)
    return response
  }

  @Api
  async updateBaseProductById(sku, params) {
    const response = await this.PATCH(`/v1/baseproducts/${sku}`, params)
    return response
  }

  @Api
  async updateBaseProductMediaById(sku, payload) {
    const response = await this.PUT(`/v1/baseproducts/${sku}/media`, payload)
    return response
  }

  @Api
  async toggleBaseProductStatus(sku, status) {
    const response = await this.PUT(`/v1/baseproducts/${sku}/enabled`, {
      is_enabled: status,
    })
    return response
  }

  @Api
  async searchBaseProduct(params) {
    const response = await this.GET(`/v1/baseproducts/`, params)
    return response
  }

  @Api
  async createDuplicateBaseProduct(sku) {
    const response = await this.POST(`/v1/baseproducts/${sku}/copy`)
    return response
  }

  @Api
  async getCategoryList(params) {
    const response = await this.GET(`/v1/categories`, params)
    return response
  }

  @Api
  async getCategoryTree(params) {
    const response = await this.GET(`/v1/categories/tree`, params)
    return response
  }

  @Api
  async getBrandList(params) {
    const response = await this.GET(`/v1/brands`, params)
    return response
  }

  @Api
  async getRecognizedMerchantProductsList(params) {
    const response = await this.GET(
      `/v1/merchants/${params.merchant_id}/products-info`,
      params,
    )
    return response
  }
}
