<template>
  <div>
    <a-spin :spinning="isFetching">
      <div class="settings-wrap">
        <h2>Создание меню</h2>
        <a-button
          @click="
            () =>
              $router.push({
                name: 'NestedMenuList',
              })
          "
        >
          {{ $t('common.back') }}
        </a-button>
      </div>
      <a-form-model
        ref="form"
        :model="categoryForm"
        :rules="rules"
        @submit="onSubmit"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="categoryForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="title.ru" label="Заголовок на русском">
          <a-input v-model="categoryForm.title.ru" />
        </a-form-model-item>
        <a-form-model-item prop="title.kz" label="Заголовок на казахском">
          <a-input v-model="categoryForm.title.kz" />
        </a-form-model-item>
        <a-form-model-item prop="title.en" label="Заголовок на английском">
          <a-input v-model="categoryForm.title.en" />
        </a-form-model-item>

        <a-form-model-item
          label="Группа быстрых ссылок"
          v-if="fastLinks.length"
        >
          <a-select v-model="categoryForm.mini_menu_id">
            <a-select-option
              v-for="(link, index) in fastLinks"
              :key="index"
              :value="link.id"
            >
              {{ link.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="storefront_id" label="Зона действия меню">
          <a-radio-group
            v-model="categoryForm.type"
            @change="onChangeCategoryType"
          >
            <a-radio value="CATEGORY">Категория</a-radio>
            <a-radio value="BRAND">Бренд</a-radio>
          </a-radio-group>

          <a-input v-model="categoryForm.storefront_id" disabled />
          <template v-if="categoryForm.type === 'CATEGORY'">
            <category-selector
              :drawer-title="'Выберите категорию для меню'"
              :drawer-width="'85%'"
              v-model="categoryForm.storefront_id"
            />
          </template>
          <template v-else>
            <brand-selector @setBrandHandler="setBrandHandler"></brand-selector>
          </template>
        </a-form-model-item>

        <a-form-model-item prop="order_num" label="Порядок категорий">
          <a-input-number
            id="inputNumber"
            v-model="categoryForm.order_num"
            :min="1"
          />
        </a-form-model-item>

        <a-form-model-item
          v-if="level === 0"
          prop="image_url"
          label="Картинка категорий"
        >
          <upload-files
            @getImageParams="getImageParams"
            :img-url="categoryForm.image_url"
            placeholder-text="Загрузить картинку"
          />
        </a-form-model-item>

        <a-form-model-item prop="devices" label="Выберите устройства">
          <a-select
            mode="multiple"
            :default-value="['web']"
            v-model="categoryForm.devices"
            placeholder="Выберите устройства"
          >
            <a-select-option
              v-for="(device, index) in deviceList"
              :key="index"
              :value="device"
            >
              {{ device }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="is_active" label="Доступность">
          <a-checkbox v-model="categoryForm.is_active"> Активный</a-checkbox>
        </a-form-model-item>
        <div>
          <a-button type="primary" html-type="submit">Сохранить</a-button>
        </div>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'
import BrandSelector from 'Product/components/BrandSelector'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { categoryFormRules } from 'Content/component/constants'

import CategorySelector from 'Core/components/Selector/CategorySelector'

export default {
  components: {
    CategorySelector,
    UploadFiles,
    BrandSelector,
  },
  data() {
    return {
      isFetching: false,
      saveCategoryLoading: false,
      deviceList: ['web', 'ios', 'android'],
      categoryForm: {
        children: [],
        devices: ['web', 'ios', 'android'],
        id: null,
        image_url: null,
        is_active: false,
        name: null,
        order_num: 1,
        parent_id: null,
        storefront_id: null,
        title: {},
        type: 'CATEGORY',
      },
      rules: categoryFormRules,
      fastLinks: [],
      level: 0,
    }
  },
  computed: {
    parentId() {
      return this.$route.query.parent_id
    },
  },
  watch: {
    'categoryForm.storefront_id': {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.form.validateField('storefront_id')
          })
        }
      },
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    getImageParams(params) {
      this.categoryForm.image_url = params
    },
    setBrandHandler(brand) {
      this.categoryForm.storefront_id = brand.slug
    },
    removeSelectedBrand() {
      this.categoryForm.storefront_id = null
    },
    onChangeCategoryType() {
      this.categoryForm.storefront_id = null
    },
    async onSubmit(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.saveCategoryLoading = true
            await this.$API.Menu.addNestedMenu(this.categoryForm)
            showSuccessMsg('Меню успешно создан')
            this.$router.push({
              name: 'NestedMenuList',
            })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.saveCategoryLoading = false
          }
        }
      })
    },
    async initialize() {
      this.categoryForm.parent_id = this.parentId
      try {
        this.isFetching = true
        this.level = await this.$API.Menu.getNestedMenuLevel(this.parentId)
        if (this.level === 0) {
          const { mini_menus } = await this.$API.Menu.getFastLinkList({
            page: 1,
            limit: 100,
          })
          this.fastLinks = mini_menus
        }
        this.isFetching = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.ant-btn-primary {
  margin: 20px 0;
}

.category {
  &__image {
    width: 130px;
    margin-bottom: 10px;
  }
}
</style>
