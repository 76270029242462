export const tableColumns = [
  {
    title: 'id',
    dataIndex: '_id',
    key: '_id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название',
    dataIndex: 'abbrev',
    key: 'abbrev',
    scopedSlots: { customRender: 'info' },
  },
  {
    title: 'Ссылка',
    dataIndex: 'link',
    key: 'link',
    scopedSlots: { customRender: 'link' },
  },
  {
    title: 'QR',
    dataIndex: 'data',
    key: 'data',
    scopedSlots: { customRender: 'dataSrc' },
  },
]
