<template>
  <div>
    <div class="settings-wrap">
      <h3>Список документов партнера</h3>
      <a-button size="small" class="ml--1" @click="onOpenModal(null)">
        Добавить документ
      </a-button>
    </div>
    <a-table
      :columns="documentsColumns"
      :data-source="documents"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="false"
      :loading="isLoading"
      bordered
    >
      <div slot="id" slot-scope="id">
        {{ id | shortID(5) }}
      </div>
      <div slot="operations" slot-scope="link, rowInfo, idx">
        <a :href="rowInfo.link" target="blank">
          <a-button>
            <a-icon type="link" />
          </a-button>
        </a>
        <a-button type="primary" @click="onOpenModal(rowInfo)" class="ml--1">
          <a-icon type="edit" />
        </a-button>
        <a-popconfirm
          title="Вы уверены?"
          ok-text="Да"
          cancel-text="Отмена"
          @confirm="deleteDocument(rowInfo, idx)"
        >
          <a-button type="danger" class="ml--1" :loading="isDeleteLoading">
            <a-icon type="delete" />
          </a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <merchant-document-modal
      :is-visible="isModalVisible"
      :selected-document-prop="selectedDocument"
      @closeModal="onCloseModal($event)"
    ></merchant-document-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { documentsColumns } from 'Partner/pages/Partner/PartnerInfo/constants'
import MerchantDocumentModal from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/DocumentsModal/DocumentModal'

export default {
  components: {
    MerchantDocumentModal,
  },
  data() {
    return {
      documents: [],
      isModalVisible: false,
      isLoading: false,
      isDeleteLoading: false,
      documentsColumns,
      selectedDocument: null,
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  created() {
    this.getEmployeeDocuments()
  },
  methods: {
    async getEmployeeDocuments() {
      try {
        this.isLoading = true
        this.documents =
          await this.$API.MerchantProfile.getEmployeeDocumentList(
            this.merchantID,
          )
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onOpenModal(document) {
      this.isModalVisible = true
      this.selectedDocument = document
    },
    async deleteDocument(document, idx) {
      try {
        await this.$API.MerchantProfile.deleteEmployeeDocumentById(
          this.merchantID,
          document.id,
        )
        showSuccessMsg('Документ успешно удален!')
        this.documents.splice(idx, 1)
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onCloseModal(refetch) {
      if (refetch) {
        this.getEmployeeDocuments()
      }
      this.isModalVisible = false
      this.selectedDocument = { id: null, name: '', link: '' }
    },
  },
}
</script>

<style lang="scss" scoped></style>
