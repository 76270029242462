<template>
  <div>
    <div class="settings-wrap">
      <h2>
        Заявка на возврат <code>#{{ returnID }}</code>
      </h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'PurchaseReturnList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <div class="settings-wrap" v-if="returnInfo.closed_at">
      <a-tag class="return-tag" color="red">
        Дата закрытия заявки
        <code>{{ returnInfo.closed_at | formatDate }}</code>
      </a-tag>
    </div>
    <a-spin :spinning="isFetching">
      <a-descriptions
        :column="5"
        class="description-padding"
        title="Информация о заявке"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="Номер возврата">
          {{ returnInfo.id }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер заказа">
          <router-link :to="`/order/${returnInfo.order_id}`" target="_blank">
            <code>{{ returnInfo.order_id }}</code>
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="Статус заказа">
          <template v-if="returnStatus[returnInfo.refund_state]">
            <a-tag :color="returnStatus[returnInfo.refund_state].color">
              {{ returnStatus[returnInfo.refund_state].label }}
            </a-tag>
          </template>
          <template v-else>
            Неизвестный статус - <code>[{{ returnInfo.refund_state }}]</code>
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Причина возврата">
          {{ getReadableReason(returnInfo.reason) }}
        </a-descriptions-item>
        <a-descriptions-item label="Общая сумма заказа">
          {{ returnInfo.total_price | currency }}
        </a-descriptions-item>
        <a-descriptions-item label="Комментарий">
          {{
            returnInfo.comment
              ? returnInfo.comment
              : 'Оформлен без комментариев'
          }}
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions
        :column="5"
        class="description-padding"
        title="Информация о заказе"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="ID клиента">
          {{ returnInfo.buyer.id }}
        </a-descriptions-item>
        <a-descriptions-item label="Email клиента">
          {{ returnInfo.buyer.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер клиента">
          {{ returnInfo.buyer.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="ID точки выдачи">
          {{ returnInfo.pickup_point }}
        </a-descriptions-item>
        <a-descriptions-item label="Дата создания заяки">
          {{ returnInfo.created_at | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Имя клиента">
          {{ returnInfo.buyer.last_name }}
          {{ returnInfo.buyer.first_name }}
          {{ returnInfo.buyer.patronymic }}
        </a-descriptions-item>
      </a-descriptions>

      <a-table
        v-if="isDivisible"
        class="description-padding"
        :columns="columnNames"
        :data-source="returnInfo.items"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="({ sku }) => sku"
        :pagination="false"
        :row-selection="rowSelection"
        bordered
      >
        <div slot="sku" slot-scope="sku">
          <p>
            <router-link :to="`/base-product/${sku}`" target="_blank">
              {{ sku }}
            </router-link>
          </p>
          <p>
            <a :href="`${showcaseUrl}product/${sku}`" target="_blank">
              Посмотреть на сайте
              <a-icon type="link" />
            </a>
          </p>
        </div>
        <div slot="title" slot-scope="title">
          {{ title.ru }}
        </div>
        <div slot="price" slot-scope="price">
          {{ price | currency }}
        </div>
        <div slot="total_price" slot-scope="total_price">
          {{ total_price | currency }}
        </div>
      </a-table>
      <a-table
        v-else
        class="description-padding"
        :columns="columnNames"
        :data-source="returnInfo.items"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="({ sku }) => sku"
        :pagination="false"
        bordered
      >
        <div slot="sku" slot-scope="sku">
          <p>
            <router-link :to="`/base-product/${sku}`" target="_blank">
              {{ sku }}
            </router-link>
          </p>
          <p>
            <a :href="`${showcaseUrl}product/${sku}`" target="_blank">
              Посмотреть на сайте
              <a-icon type="link" />
            </a>
          </p>
        </div>
        <div slot="title" slot-scope="title">
          {{ title.ru }}
        </div>
        <div slot="price" slot-scope="price">
          {{ price | currency }}
        </div>
        <div slot="total_price" slot-scope="total_price">
          {{ total_price | currency }}
        </div>
      </a-table>
      <div class="description-padding">
        <a-button
          type="danger"
          @click="cancelReturnHandler"
          v-if="returnInfo.refund_state === 'created'"
        >
          Отменить заявку на возврат
        </a-button>
      </div>
    </a-spin>

    <a-modal
      v-model="cancelReturnModal"
      title="Отмена заявки на возврат"
      cancel-text="Отмена"
      ok-text="Отменить возврат"
      @ok="handleOnCancelReturn"
    >
      <div class="return-modal">
        <a-form-model
          ref="form"
          :model="cancelReturnForm"
          @submit="handleOnCancelReturn"
        >
          <a-form-model-item label="Причина">
            <a-radio-group v-model="cancelReturnForm.state">
              <a-radio value="cancelled">
                Отменен по инициативе клиента
              </a-radio>
              <a-radio value="rejected_by_support">
                Возврат отклонен СП
              </a-radio>
              <a-radio value="rejected_by_merchant">
                Возврат отклонен Мерчантом
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="name" label="Коментарий">
            <a-textarea
              allow-clear
              v-model="cancelReturnForm.comment"
              :auto-size="{ minRows: 2, maxRows: 4 }"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { returnStatus } from 'Core/constants'

export default {
  name: 'PurchaseReturnInfo',
  data: () => ({
    isFetching: true,
    returnInfo: {
      buyer: {
        id: '',
        phone: '',
        email: '',
        first_name: '',
        last_name: '',
        patronymic: '',
      },
      created_at: null,
      reason: null,
    },
    returnStatus,
    columnNames: [
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        scopedSlots: { customRender: 'sku' },
      },
      {
        title: 'Количество',
        dataIndex: 'quantity',
        key: 'quantity',
        scopedSlots: { customRender: 'quantity' },
      },
      {
        title: 'Сумма',
        dataIndex: 'total_price',
        key: 'total_price',
        scopedSlots: { customRender: 'total_price' },
      },
    ],
    showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
    cancelReturnModal: false,
    cancelReturnForm: {
      comment: null,
      refund_id: null,
      state: 'cancelled',
    },
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        this.returnInfo = await this.$API.RMS.getPurchaseReturnById(
          this.returnID,
        )
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    getReadableReason(reason) {
      switch (reason) {
        case 'changed_mind': {
          return 'Передумал покупать'
        }
        case 'defective': {
          return 'Брак'
        }
        default: {
          return 'Другое'
        }
      }
    },
    cancelReturnHandler() {
      this.cancelReturnModal = true
    },
    async handleOnCancelReturn() {
      try {
        this.isFetching = true
        this.cancelReturnForm.refund_id = this.returnID
        await this.$API.RMS.cancelPurchaseReturn(
          this.returnID,
          this.cancelReturnForm,
        )
        showSuccessMsg('Вы отменили заявку!')
        await this.initialize()
        this.cancelReturnModal = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    returnID() {
      return this.$route.params.id
    },
    isDivisible() {
      //TODO post MVP сделать частичную отмену возврата
      // return (
      //   this.returnInfo.payment_type === 'card_online' ||
      //   this.returnInfo.payment_type === 'card_offline'
      // )
      return false
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.cancelReturnForm = selectedRows
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.description-padding {
  margin-top: 32px;
}

.return-tag {
  padding: 7px 12px;
}
</style>
