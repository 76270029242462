


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { addressesColumnNames } from 'Client/pages/ClientInfo/constants'

@Component
class ClientRequisites extends Vue {
  @Prop({ type: String, required: true })
  id
  @Prop({ type: Object, required: true })
  requisites

  addressesColumnNames = addressesColumnNames
  isLoading = false
}

export default ClientRequisites
