<template>
  <div>
    <a-spin :spinning="isFetching">
      <div class="settings-wrap">
        <h2>Редактирование ссылки</h2>
        <a-button
          @click="
            () =>
              $router.push({ name: 'FastLinkInfo', params: { id: fastLinkId } })
          "
        >
          {{ $t('common.back') }}
        </a-button>
      </div>
      <a-row type="flex" justify="center">
        <a-col span="16">
          <a-form-model
            ref="form"
            :model="formState"
            :rules="rules"
            @submit="onSave"
          >
            <a-form-model-item label="ID">
              <a-input v-model="formState.id" disabled />
            </a-form-model-item>
            <a-form-model-item label="Статус">
              <a-switch v-model="formState.is_active" />
            </a-form-model-item>
            <a-form-model-item prop="name" label="Название">
              <a-input v-model="formState.name" />
            </a-form-model-item>
            <a-form-model-item prop="order_num" label="Приоритет">
              <a-input-number v-model="formState.order_num" block :min="1" />
            </a-form-model-item>
            <a-form-model-item prop="title.ru" label="Заголовок(рус)">
              <a-input v-model="formState.title.ru" />
            </a-form-model-item>

            <a-form-model-item label="Заголовок(каз)">
              <a-input v-model="formState.title.kz" />
            </a-form-model-item>

            <a-form-model-item label="Заголовок(англ)">
              <a-input v-model="formState.title.en" />
            </a-form-model-item>

            <a-form-model-item prop="target_id" label="Адрес ссылки">
              <a-radio-group v-model="formState.type" @change="dropTargetId">
                <a-radio-button value="CATEGORY">Категория</a-radio-button>
                <a-radio-button value="BRAND">Бренд</a-radio-button>
              </a-radio-group>

              <a-input v-model="formState.target_id" disabled />
              <template v-if="formState.type === 'CATEGORY'">
                <category-selector
                  :drawer-title="'Выберите категорию для ссылки'"
                  :drawer-width="'85%'"
                  v-model="formState.target_id"
                />
              </template>
              <template v-else>
                <brand-selector
                  @setBrandHandler="setBrandHandler"
                ></brand-selector>
              </template>
            </a-form-model-item>

            <a-form-model-item prop="image_url" label="Картинка">
              <upload-files
                @getImageParams="getImageParams"
                :img-url="formState.image_url"
                placeholder-text="Загрузить картинку"
              />
            </a-form-model-item>
            <a-form-model-item prop="devices" label="Устройства">
              <a-select
                mode="multiple"
                :default-value="['web']"
                v-model="formState.devices"
                placeholder="Выберите устройства"
              >
                <a-select-option
                  v-for="(device, index) in deviceList"
                  :key="index"
                  :value="device"
                >
                  {{ device }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-button type="primary" html-type="submit">Сохранить</a-button>
          </a-form-model>
          <a-divider>История изменении</a-divider>
          <action-logs
            entity-type="menu.minimenu"
            event-type="menu.change"
            :entity-id="linkId"
          />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import CategorySelector from 'Core/components/Selector/CategorySelector'
import UploadFiles from 'Core/components/Files/UploadFiles.vue'
import BrandSelector from 'Product/components/BrandSelector'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from './constants'
import ActionLogs from '../../../component/ActionLogs'

export default {
  name: 'LinkEdit',
  data: () => ({
    formState,
    rules,
    isFetching: true,
    deviceList: ['web', 'ios', 'android'],
    fastLinkTypeList: [],
  }),
  watch: {
    'formState.target_id': {
      handler() {
        this.$refs.form.validateField('target_id')
      },
    },
    'formState.image_url': {
      handler() {
        this.$refs.form.validateField('image_url')
      },
    },
  },
  methods: {
    getImageParams(params) {
      this.formState.image_url = params
    },
    setBrandHandler(brand) {
      this.formState.target_id = brand.slug
    },
    dropTargetId() {
      this.formState.target_id = null
    },
    onSave(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.updateLinkById()
        showSuccessMsg('Удачно обновлен!')
        await this.getLinkInfoById()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async updateLinkById() {
      // поля на бэк-е обязательные
      this.formState.title.kz = this.formState.title.kz
        ? this.formState.title.kz
        : this.formState.title.ru
      this.formState.title.en = this.formState.title.en
        ? this.formState.title.en
        : this.formState.title.ru
      await this.$API.Menu.updateLinkById(
        this.fastLinkId,
        this.linkId,
        this.formState,
      )
    },
    async initialize() {
      try {
        this.isFetching = true
        await this.getLinkInfoById()
        await this.getFastLinkTypes()
        this.$refs.form.clearValidate()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getLinkInfoById() {
      // eventBus.$emit('logs:update')
      this.formState = await this.$API.Menu.getLinkInfoById(
        this.fastLinkId,
        this.linkId,
      )
    },
    async getFastLinkTypes() {
      this.fastLinkTypeList = await this.$API.Menu.getFastLinkTypes()
    },
  },
  computed: {
    fastLinkId() {
      return this.$route.params.fast_link_id
    },
    linkId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.initialize()
  },
  components: {
    ActionLogs,
    UploadFiles,
    CategorySelector,
    BrandSelector,
  },
}
</script>

<style scoped>
.icons-list {
  margin-right: 6px;
  font-size: 24px;
}
</style>
