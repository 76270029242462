export const formState = {
  id: null,
  name: null,
  order_num: null,
  type: null,
  target_id: null,
  image_url: null,
  is_active: false,
  title: {
    ru: null,
    kz: null,
    en: null,
  },
  links: [],
  devices: [],
}

export const rules = {
  id: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  order_num: {
    required: true,
    type: 'number',
    fullField: 'Приоритет',
    min: 1,
  },
  target_id: {
    message: 'Выберите категорию или Бренд для ссылки',
    required: true,
  },
  image_url: {
    type: 'string',
    message: 'Загрузите картинку',
    required: true,
  },
  devices: {
    type: 'array',
    message: 'Выберите как минимум одно устройство',
    required: true,
  },
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
}
