<template>
  <div>
    <div class="settings-wrap">
      <h2>Список цветов</h2>
      <router-link
        :to="{ name: 'ConfigColorsCreate' }"
        v-can="{
          service: 'banner',
          permissions: ['banner-create'],
        }"
      >
        <a-button>Создать</a-button>
      </router-link>
    </div>

    <a-table
      :columns="columns"
      :data-source="colors"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ value }) => value"
      :loading="isLoading"
      bordered
    >
      <template v-slot:value="value">
        <router-link
          :to="{
            name: 'ConfigColorsEdit',
            params: { color_id: value },
          }"
        >
          {{ value | shortID(5) }}
        </router-link>
      </template>

      <template v-slot:variant="variant">
        <div class="color__info">
          <div :style="{ backgroundColor: variant }" class="color__label"></div>
          {{ variant }}
        </div>
      </template>

      <template v-slot:label_ru="label_ru">
        {{ label_ru }}
      </template>

      <template v-slot:label_kz="label_kz">
        {{ label_kz }}
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'value',
          width: '250px',
          scopedSlots: { customRender: 'value' },
        },
        {
          title: 'Цвет',
          dataIndex: 'variant',
          width: '250px',
          scopedSlots: { customRender: 'variant' },
        },
        {
          title: 'Название на русском',
          dataIndex: 'label.ru',
          width: '250px',
          scopedSlots: { customRender: 'label_ru' },
        },
        {
          title: 'Название на казахском',
          dataIndex: 'label.kz',
          width: '250px',
          scopedSlots: { customRender: 'label_kz' },
        },
      ],
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['config/colors/isLoading']
    },
    colors() {
      return this.$store.getters['config/colors/getColors']
    },
  },
}
</script>

<style lang="scss" scoped>
.color {
  &__label {
    width: 15px;
    height: 15px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
