<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание цвета</h2>
      <router-link :to="{ name: 'ConfigColorsList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isColorGetLoading">
      <a-form-model
        ref="form"
        :model="colorForm"
        :rules="colorFormRules"
        @submit="createColorConfig"
      >
        <a-form-model-item prop="title.ru" label="Заголовок на русском">
          <a-input v-model="colorForm.title.ru" />
        </a-form-model-item>
        <a-form-model-item prop="title.kz" label="Заголовок на казахском">
          <a-input v-model="colorForm.title.kz" />
        </a-form-model-item>
        <a-form-model-item prop="hex" label="Цвет (в hex, например #ffffff)">
          <a-input v-model="colorForm.hex" class="color__input">
            <template slot="addonBefore">
              <div
                :style="{ backgroundColor: colorForm.hex }"
                class="color__preview"
              ></div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-button type="primary" html-type="submit" class="form__button">
          Сохранить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { colorFormRules } from '../ConfigColorsEdit/constants'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  data() {
    return {
      colorFormRules,
      isColorGetLoading: false,
      colorForm: {
        title: {
          ru: null,
          kz: null,
        },
        hex: null,
      },
    }
  },
  methods: {
    async createColorConfig(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isColorUpdateLoading = true
            await this.$API.ConfigDiscovery.createColor(this.colorForm)
            await this.$store.dispatch('fetchConfigColors')
            showSuccessMsg('Успешно создан цвет в справочниках')
            this.$router.push({ name: 'ConfigColorsList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isColorUpdateLoading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;
}

.color__preview {
  width: 15px;
  height: 15px;
  border: 1px solid black;
}
</style>
