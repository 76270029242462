export const columnNames = [
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
    scopedSlots: { customRender: 'slug' },
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
  // {
  //   title: 'Баннер',
  //   dataIndex: 'banner',
  //   key: 'banner',
  // },
  {
    title: 'Последнее обновление',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updatedAt' },
  },
  {
    title: 'Статус',
    dataIndex: 'is_enabled',
    key: 'is_enabled',
    scopedSlots: { customRender: 'isEnabled' },
  },
]
