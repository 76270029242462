<template>
  <div>
    <a-result title="Тут будет какая-то статистика">
      <template #icon>
        <a-icon type="smile" theme="twoTone" />
      </template>
    </a-result>
    <p class="info" style="text-align: center">coming soon...</p>
  </div>
</template>

<script>
export default {
  name: 'Notifier',
}
</script>
