export const partnerProductsColumnNames = [
  {
    title: 'Название товара партнера',
    dataIndex: 'products',
    key: 'model',
    scopedSlots: { customRender: 'model' },
  },
]
export const baseProductsColumnNames = [
  {
    title: 'Название базового продукта',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'REES SKU',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
]
