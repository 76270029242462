























import { Component, Vue } from 'vue-property-decorator'
import { clientRequisites } from 'Client/pages/ClientInfo/constants'
import ClientOrders from 'Client/components/Orders/OrderList.vue'
import ClientProfile from 'Client/components/ClientInfo/ClientProfile.vue'
import ClientRequisites from 'Client/components/ClientInfo/ClientRequisites.vue'
import { showErrorMsg } from '@/Core/utils'
import ClientReceivers from 'Client/components/ClientInfo/ClientReceivers.vue'

@Component({
  components: {
    ClientOrders,
    ClientProfile,
    ClientRequisites,
    ClientReceivers,
  },
})
class ClientInfo extends Vue {
  isOrdersLoading = false
  orders = []
  ordersTotalCount = 0
  requisites = clientRequisites
  $API: any

  get tdId() {
    return this.$route.params.tdId
  }

  async getUserOrders(params) {
    try {
      this.isOrdersLoading = true
      const { orders, total_count } = await this.$API.OMS.getOrderList({
        tdid: this.tdId,
        ...params,
      })
      this.orders = orders
      this.ordersTotalCount = total_count
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.isOrdersLoading = false
    }
  }
  async getUserRequisites() {
    try {
      this.requisites = await this.$API.UserProfile.searchUser({
        field: 'tdid',
        value: this.tdId,
      })
    } catch (error) {
      showErrorMsg(error.msg || 'Профиль пользователя не найден')
    }
  }
  mounted() {
    this.getUserRequisites()
  }
}

export default ClientInfo
