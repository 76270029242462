<template>
  <div>
    <div class="settings-wrap">
      <h2>Список способов связи</h2>
      <router-link
        :to="{ name: 'ConfigContactCreate' }"
        v-can="{
          service: 'banner',
          permissions: ['banner-create'],
        }"
      >
        <a-button>Создать</a-button>
      </router-link>
    </div>
    <div class="settings-wrap">
      <div>
        <a-radio-group
          :default-value="null"
          button-style="solid"
          v-model="filter.type"
          @change="updateContactsList"
        >
          <a-radio-button :value="''">Все</a-radio-button>
          <a-radio-button :value="'merchant'">Партнер</a-radio-button>
          <a-radio-button :value="'user'">Клиент</a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <a-table
      :columns="contactColumns"
      :data-source="contacts"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      bordered
    >
      <div slot="contact_id" slot-scope="contact_id">
        <router-link
          :to="{
            name: 'ConfigContactEdit',
            params: { contact_id: contact_id },
          }"
        >
          {{ contact_id }}
        </router-link>
      </div>
      <div slot="contact_name" slot-scope="contact_name">
        {{ contact_name }}
      </div>
      <div slot="value" slot-scope="value">
        {{ value }}
      </div>
      <div slot="comment" slot-scope="comment">
        {{ comment }}
      </div>
      <div slot="type" slot-scope="type">
        {{ getReadableType(type) }}
      </div>
      <div slot="priority" slot-scope="priority, contact">
        <a-input-number
          :id="contact.id"
          :value="priority"
          :min="0"
          @change="(value) => onPriorityChange(value, contact)"
        />
        <a-button
          type="primary"
          @click="confirmPriorityChange(contact)"
          class="form__button"
        >
          <a-icon type="save" />
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { contactColumns } from './constants'

export default {
  data() {
    return {
      contactColumns,
      contactTypes: null,
      filter: {
        type: 'user',
      },
    }
  },
  computed: {
    contacts() {
      return this.$store.getters['config/contacts/contacts']
    },
    isLoading() {
      return this.$store.getters['config/contacts/isLoading']
    },
  },
  methods: {
    getReadableType(type) {
      switch (type) {
        case 'email':
          return 'Эл. почта'
        case 'phone':
          return 'Телефон'
        case 'social_network':
          return 'Соц.сеть'
        default:
          return type
      }
    },
    async onPriorityChange(value, contact) {
      if (value && typeof value === 'number') {
        this.contacts.map((item) => {
          if (item.id == contact.id) contact.priority = value
          return contact
        })
      }
    },
    async confirmPriorityChange(contact) {
      try {
        await this.$API.ConfigDiscovery.updateContact(contact.id, contact)
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    updateContactsList() {
      this.$store.dispatch('config/contacts/fetchContacts', this.filter.type)
    },
    mounted() {
      this.updateContactsList()
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__button {
    margin-left: 10px;
  }
}
</style>
