export const bannersColumns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Заголовок',
    dataIndex: 'info.title',
    key: 'bannerTitle',
    scopedSlots: { customRender: 'info' },
  },
  {
    title: 'Позиция',
    dataIndex: 'position',
    key: 'position',
    scopedSlots: { customRender: 'position' },
  },
  {
    title: 'Картинка',
    dataIndex: 'image',
    key: 'image',
    width: '200px',
    scopedSlots: { customRender: 'image' },
  },
  {
    title: 'Устройства',
    dataIndex: 'platforms',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Статус',
    dataIndex: 'active',
    key: 'active',
    width: '100px',
    scopedSlots: { customRender: 'active' },
  },
  {
    title: 'Ссылка',
    dataIndex: 'url',
    key: 'url',
    scopedSlots: { customRender: 'url' },
  },
  {
    title: 'Приоритет',
    dataIndex: 'weight',
    key: 'weight',
    width: '200px',
    scopedSlots: { customRender: 'weight' },
  },
]
