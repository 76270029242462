import { GET } from 'Main/api/REST'

const BASE_URL = process.env.VUE_APP_API_URL

export const getUserPermissionListAPI = async () => {
  const { data, success } = await GET(
    `${BASE_URL}/cabinet/accesser/api/v1/roles/permissions`,
  )

  if (!success) {
    throw new Error('Ошибка при запросе списка доступов: ' + data.error.message)
  }

  if (!data.length) {
    throw new Error('Ошибка при запросе списка доступов: у вас нет доступа')
  }

  return data
}
