








































































































































import { showErrorMsg } from 'Core/utils'
import { Component, Vue } from 'vue-property-decorator'
import { IProductReview } from 'Core/modules/API/@types/Feedback.type'
import { REVIEW } from 'Content/pages/Reviews/ProductReviews/ProductReviewInfo/constants'
import ShowImages from 'Core/components/Images/ShowImages.vue'
import StatusView from 'Core/components/StatusView/StatusView.vue'
import { MODERATION_STATUSES } from 'Core/types/review'

@Component({ components: { ShowImages, StatusView } })
class ProductReviewInfo extends Vue {
  MODERATION_STATUSES = MODERATION_STATUSES
  isLoading = false
  review: IProductReview = REVIEW
  showcaseUrl = process.env.VUE_APP_SHOWCASE_URL

  get id() {
    return this.$route.params.id
  }

  get isDisabled() {
    return ['rejected', 'confirmed'].includes(this.review?.status) ?? true
  }

  async getProductReview() {
    try {
      this.isLoading = true
      this.review = await this.$API.Feedback.getProductReviewById(this.id)
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async onDecisionMade(isConfirmed: boolean) {
    try {
      this.isLoading = true
      if (isConfirmed) {
        await this.$API.Feedback.confirmReview(this.id)
      } else {
        await this.$API.Feedback.rejectReview(this.id)
      }
      await this.$router.push({ name: 'Reviews' })
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.getProductReview()
  }
}

export default ProductReviewInfo
