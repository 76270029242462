export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Заголовок',
    dataIndex: 'title.ru',
    key: 'title.ru',
  },
  {
    title: 'Статус',
    dataIndex: 'is_enabled',
    key: 'is_enabled',
    scopedSlots: { customRender: 'isActive' },
  },
  {
    title: 'Количество заведенных продуктов',
    dataIndex: 'base_product_count',
    key: 'base_product_count',
    align: 'right',
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updatedAt' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
]
