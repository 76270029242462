<template>
  <div>
    <div class="settings-wrap">
      <h2>Список брокеров-партнеров</h2>
      <router-link
        :to="{ name: 'ConfigBrokerPartnersCreate' }"
        v-can="{
          service: 'banner',
          permissions: ['banner-create'],
        }"
      >
        <a-button>Создать</a-button>
      </router-link>
    </div>

    <a-table
      :columns="columns"
      :data-source="colors"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ value }) => value"
      :loading="isLoading"
      bordered
    >
      <template v-slot:value="value">
        <router-link
          :to="{
            name: 'ConfigBrokerPartnersEdit',
            params: { id: value },
          }"
        >
          {{ value | shortID(5) }}
        </router-link>
      </template>

      <template v-slot:icon="icon">
        <img class="img-prev" :src="icon" width="100" v-if="icon" />
        <p v-else>Иконка не указана</p>
      </template>
    </a-table>
  </div>
</template>

<script>
import { brokerColumns } from 'Reference/pages/ConfigBrokerPartners/ConfigBrokerPartnersList/constants'

export default {
  data() {
    return {
      columns: brokerColumns,
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['config/brokers/isLoading']
    },
    colors() {
      return this.$store.getters['config/brokers/getBrokers']
    },
  },
}
</script>

<style lang="scss" scoped>
.color {
  &__label {
    width: 15px;
    height: 15px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
