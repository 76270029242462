<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col span="16">
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-col>
            <h2>Созадние Группы для Атрибута</h2>
          </a-col>
          <a-col>
            <a-button
              @click="
                () =>
                  $router.push({
                    name: 'AttributeGroupList',
                    params: { modelId: modelId },
                  })
              "
            >
              {{ $t('common.back') }}
            </a-button>
          </a-col>
        </a-row>
        <a-form-model
          ref="form"
          :model="formState"
          :rules="rules"
          @submit="onCreate"
        >
          <a-form-model-item prop="title.ru" label="RU(на русском)">
            <a-input v-model="formState.title.ru" />
          </a-form-model-item>
          <a-form-model-item prop="title.kz" label="KZ(на казахском)">
            <a-input v-model="formState.title.kz" />
          </a-form-model-item>
          <a-form-model-item prop="order_num" label="Приоритет">
            <a-input-number v-model="formState.weight" :min="0" block />
          </a-form-model-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">Создать</a-button>
          </a-form-item>
        </a-form-model>
      </a-col>
      <p class="info">
        После создания вы можете использовать группу в Атрибутах
      </p>
    </a-row>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'GroupCreate',
  data: () => ({
    formState,
    rules,
    isFetching: true,
  }),
  methods: {
    onCreate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createModelAttributeGroupHandler()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createModelAttributeGroupHandler() {
      const { id } = await this.$API.Storefront.createModelAttribute(
        this.modelId,
        this.formState,
      )
      await this.$router.push(`/model/${this.modelId}/attribute/group=${id}`)
    },
  },
  computed: {
    modelId() {
      return this.$route.params.id
    },
  },
}
</script>
