import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления и создания шаблонов нотификаций
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/jam/swagger/index.html} */
export class JamService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'jam',
    })
  }

  @Api
  async getTemplatesList(params) {
    const response = await this.GET(`/v1/templates`, params)
    return response
  }

  @Api
  async createTemplate(payload) {
    const response = await this.POST(`/v1/templates`, payload)
    return response
  }

  @Api
  async updateTemplateById(id, payload) {
    const response = await this.PUT(`/v1/templates/${id}`, payload)
    return response
  }

  @Api
  async getTemplateById(id) {
    const response = await this.GET(`/v1/templates/${id}`)
    return response
  }

  @Api
  async deleteTemplateById(id) {
    const response = await this.DELETE(`/v1/templates/${id}`)
    return response
  }
}
