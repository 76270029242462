<template>
  <div>
    <div class="settings-wrap">
      <a-button @click="openModal('add')">Добавить сотрудника</a-button>
    </div>
    <a-table
      :columns="employeesColumns"
      :data-source="employees"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ tdid }) => tdid"
      :pagination="false"
      :loading="isLoading"
      bordered
    >
      <div slot="tdid" slot-scope="id">
        {{ id | shortID(5) }}
      </div>
      <div slot="fio" slot-scope="fio">
        <span v-if="fio.surname || fio.name || fio.patronymic">
          {{ fio.surname }} {{ fio.name }} {{ fio.patronymic }}
        </span>
        <span v-else>Не указан</span>
      </div>
      <div slot="phone_number" slot-scope="phone_number">
        {{ phone_number }}
      </div>
      <div slot="roles" slot-scope="roles">
        <a-tag
          v-for="(value, key) in roles"
          :key="key"
          :color="value.id === 'merchant_owner' ? '#f50' : ''"
        >
          {{ value.role }}
        </a-tag>
      </div>
      <div slot="action" slot-scope="user, rowinfo, index">
        <a-button
          type="primary"
          @click="openModal('edit', user)"
          :disabled="isMerchantOwner(user.roles)"
        >
          <a-icon type="edit" />
        </a-button>
        <a-popconfirm
          title="Удалить сотрудника？"
          ok-text="Да, удалить"
          cancel-text="Нет"
          @confirm="deleteEmployee(user, index)"
        >
          <a-button
            type="danger"
            class="ml--1"
            :loading="isLoading"
            :disabled="isMerchantOwner(user.roles)"
          >
            <a-icon type="delete" />
          </a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <employee-edit
      :is-visible="modals.edit"
      :selected-employee="selectedEmployee"
      :success="modalSuccessCallback"
      :fail="modalFailCallback"
    ></employee-edit>
    <employee-create
      :is-visible="modals.add"
      :success="modalSuccessCallback"
      :fail="modalFailCallback"
    ></employee-create>
  </div>
</template>

<script>
import { employeesColumns } from 'Partner/pages/Partner/PartnerInfo/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import EmployeeEdit from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/EmployeeModals/EmployeeEdit'
import EmployeeCreate from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/EmployeeModals/EmployeeCreate'

export default {
  name: 'PartnerEmployeeInfo',
  components: {
    EmployeeEdit,
    EmployeeCreate,
  },
  data() {
    return {
      employeesColumns,
      employees: [],
      isLoading: true,
      modals: {
        edit: false,
        add: false,
      },
      selectedEmployee: null,
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  methods: {
    openModal(name, user) {
      this.modals[name] = true
      this.selectedEmployee = user
    },
    modalSuccessCallback() {
      this.modals.edit = false
      this.modals.add = false
      this.selectedEmployee = null
      this.getMerchantEmployees()
    },
    modalFailCallback() {
      this.modals.edit = false
      this.modals.add = false
      this.selectedEmployee = null
    },

    isMerchantOwner(roles) {
      return !!roles.merchant_owner
    },
    async deleteEmployee(user) {
      try {
        this.isLoading = true
        await this.$API.MerchantProfile.deleteMerchantEmployee(
          this.merchantID,
          user.tdid,
        )
        showSuccessMsg(`Сотрудник успешно удален из списка!`)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        await this.getMerchantEmployees()
        this.isLoading = false
      }
    },
    async getMerchantEmployees() {
      try {
        this.isLoading = true
        this.employees =
          await this.$API.MerchantProfile.getMerchantEmployeesList(
            this.merchantID,
            this.params,
          )
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
  },
  created() {
    this.getMerchantEmployees()
  },
}
</script>

<style lang="scss" scoped></style>
