<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование цвета</h2>
      <router-link :to="{ name: 'ConfigColorsList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-spin :spinning="isColorGetLoading">
      <a-form-model
        v-if="colorForm"
        ref="form"
        :model="colorForm"
        :rules="colorFormRules"
        @submit="editColorConfig"
      >
        <a-form-model-item prop="title.ru" label="Заголовок на русском">
          <a-input v-model="colorForm.title.ru" />
        </a-form-model-item>
        <a-form-model-item prop="title.kz" label="Заголовок на казахском">
          <a-input v-model="colorForm.title.kz" />
        </a-form-model-item>
        <a-form-model-item prop="hex" label="Цвет (в hex, например #ffffff)">
          <a-input v-model="colorForm.hex" class="color__input">
            <template slot="addonBefore">
              <div
                :style="{ backgroundColor: colorForm.hex }"
                class="color__preview"
              ></div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          v-can="{
            service: 'banner',
            permissions: ['banner-update'],
          }"
          >Сохранить
        </a-button>
        <a-button
          type="danger"
          class="form__button form__button--danger"
          @click="deleteColorConfig"
          v-can="{
            service: 'banner',
            permissions: ['banner-delete'],
          }"
          >Удалить
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { colorFormRules } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  computed: {
    colorID() {
      return this.$route.params.color_id
    },
    previewStyle() {
      return {
        backgroundColor: this.value,
        width: '15px',
        height: '15px',
        border: '1px solid black',
      }
    },
  },
  data() {
    return {
      colorFormRules,
      isColorGetLoading: false,
      colorForm: null,
    }
  },
  created() {
    this.getColorById()
  },
  methods: {
    async getColorById() {
      try {
        this.isColorGetLoading = true
        this.colorForm = await this.$API.ConfigDiscovery.getColorInfoById(
          this.colorID,
        )
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isColorGetLoading = false
      }
    },
    async editColorConfig(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isColorUpdateLoading = true
            await this.$API.ConfigDiscovery.updateColor(
              this.colorID,
              this.colorForm,
            )
            await this.$store.dispatch('fetchConfigColors')
            showSuccessMsg('Успешно обновлен цвет')
            this.$router.push({ name: 'ConfigColorsList' })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isColorUpdateLoading = false
          }
        }
      })
    },
    async deleteColorConfig() {
      try {
        await this.$API.ConfigDiscovery.deleteColor(this.colorID)
        showSuccessMsg('Цвет успешно удален!')
        await this.$store.dispatch('fetchConfigColors')
        this.$router.push({ name: 'ConfigColorsList' })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.form__button {
  margin-top: 30px !important;

  &--danger {
    margin-left: 20px;
  }
}

.color__preview {
  width: 15px;
  height: 15px;
  border: 1px solid black;
}
</style>
