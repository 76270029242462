<template>
  <div>
    <div class="settings-wrap">
      <h2>Группа атрибутов</h2>
      <a-button
        @click="
          () => $router.push({ name: 'ModelEdit', params: { id: modelId } })
        "
      >
        {{ $t('common.back') }} к модели
      </a-button>
    </div>

    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="false"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/model/${modelId}/attribute/group=${id}`">
          <code>{{ id }}</code>
        </router-link>
      </div>
      <div slot="fullName" slot-scope="fullName">
        {{ fullName }}
      </div>
      <div slot="status" slot-scope="status">
        <a-tag v-if="status === 'active'" color="green">
          Зарегистрирован
        </a-tag>
        <a-tag v-else-if="status === 'disabled'" color="red"> Отклонен</a-tag>
        <a-tag v-else-if="status === 'on-check'" color="orange">
          На проверке
        </a-tag>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm
          v-if="dataSource.length"
          :disabled="isInUse"
          title="Удалить безвозвратно?"
          ok-text="Да, удалить"
          cancel-text="отмена"
          @confirm="() => onDelete(record.id)"
        >
          <a-button :disabled="isInUse" type="danger">
            <a-icon type="delete" />
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-row type="flex" justify="center">
      <a-col span="16">
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-divider>Создание новой группы</a-divider>
          <a-button type="primary" block @click="goToCreate()">
            Создать
          </a-button>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { columnNames } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'AttributeGroupList',
  data: () => ({
    columnNames,
    dataSource: [],
    isLoading: true,
    isInUse: false,
  }),
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        this.isInUse = !!(
          await this.$API.Storefront.getModelDependencyCount(this.modelId)
        ).count
        await this.getModelAttributeGroupList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getModelAttributeGroupList() {
      this.dataSource = await this.$API.Storefront.getModelAttributeGroupList(
        this.modelId,
      )
      this.isLoading = false
    },
    async onDelete(id) {
      await this.$API.Storefront.deleteModelGroupById(this.modelId, id)
      showSuccessMsg('Группа удалена.')
      await this.initialize()
    },
    goToCreate() {
      this.$router.push({
        name: 'AttributeGroupCreate',
        params: { id: this.modelId },
      })
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    modelId() {
      return this.$route.params.modelId
    },
  },
}
</script>

<style scoped></style>
