export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Заголовок',
    dataIndex: 'title.ru',
    key: 'contact.phone',
  },
]
