<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование FAQ категории для {{ faqBelong }}</h2>
      <a-button
        @click="
          () =>
            $router.push({
              path: faqType === 'user' ? '/faq/user' : '/faq/merchant',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="isLoading">
      <a-form-model
        ref="form"
        :model="formState"
        :rules="rules"
        @submit="onValidate"
      >
        <a-form-model-item prop="title.ru" label="Заголовок">
          <a-input v-model="formState.title.ru" />
        </a-form-model-item>

        <a-form-model-item prop="slug" label="Наименование (Слаг)">
          <a-input v-model="formState.slug" disabled />
        </a-form-model-item>

        <a-form-model-item prop="weight" label="Вес">
          <a-input-number v-model="formState.weight" />
        </a-form-model-item>

        <a-form-model-item prop="" label="Описание">
          <a-input v-model="formState.description" />
        </a-form-model-item>

        <a-form-model-item
          v-if="selectionIsActive"
          label=" Выберите раздел (Оставьте пустым, чтобы создать Новый раздел)"
        >
          <a-auto-complete
            :style="{ width: '100%' }"
            v-model="selectedOption"
            @search="onSearch"
            option-label-prop="value"
          >
            <template slot="dataSource">
              <a-select-option
                v-for="{ id, title } in searchResult"
                @click="onSelect(id)"
                :key="id"
                :value="title"
              >
                {{ title }}
              </a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>

        <a-form-model-item
          prop="platforms"
          label="Показывать на выбранных платформах"
          v-if="faqType === 'user'"
        >
          <a-select
            mode="multiple"
            :default-value="['web']"
            v-model="formState.platforms"
            placeholder="Выберите устройства"
          >
            <a-select-option
              v-for="(platform, index) in PLATFORM_TYPES"
              :key="index"
              :value="platform.value"
            >
              <a-icon :type="platform.icon" />
              {{ platform.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Статус видимости">
          <a-switch
            checked-children="включен"
            un-checked-children="выключен"
            v-model="formState.is_available"
          />
        </a-form-model-item>

        <a-form-model-item prop="body.ru" label="HTML" v-if="!isLoading">
          <a-textarea v-model="formState.body.ru"></a-textarea>
          <quill-input v-model="formState.body.ru" />
        </a-form-model-item>
        <a-button-group>
          <a-button type="primary" html-type="submit">Сохранить</a-button>
          <a-popconfirm
            title="Вы уверены?"
            ok-text="Да"
            cancel-text="Отмена"
            @confirm="deleteFaq()"
          >
            <a-button type="danger">
              <a-icon type="delete" />
            </a-button>
          </a-popconfirm>
        </a-button-group>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from 'Content/pages/Faq/FaqCreate/constants'
import { PLATFORM_TYPES } from 'Content/pages/Faq/FaqList/constants'
import Fuse from 'fuse.js'
import {
  englishTransliteration,
  keyboardTransliteration,
} from 'Core/modules/translator'
import QuillInput from 'Core/components/QuillInput/QuillInput'

export default {
  name: 'FaqEdit',
  components: {
    QuillInput,
  },
  data() {
    return {
      PLATFORM_TYPES,
      formState,
      rules,
      isLoading: true,
      selectedOption: '',
      selectOptions: [],
      searchResult: [],
      fuse: null,
      selectionIsActive: false,
      searchConfig: {
        isCaseSensitive: false,
        keys: ['name', 'path', 'keywords'],
      },
    }
  },
  computed: {
    faqSlug() {
      return this.$route.query.slug
    },
    faqBelong() {
      return this.$route.query.type === 'user' ? 'Клиентов' : 'Партнеров'
    },
    faqType() {
      return this.$route.query.type
    },
  },
  methods: {
    initialize() {
      try {
        this.isLoading = true
        this.getFaqInfo()
        this.getFaqList()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getFaqInfo() {
      this.formState = await this.$API.ConfigDiscovery.getFaqInfo(
        this.faqType,
        this.faqSlug,
      )
    },
    async getFaqList() {
      const params = {
        depth: 2,
        root: false,
        faqType: this.faqType,
      }
      const { page } = await this.$API.ConfigDiscovery.getFaqList(
        this.faqType,
        params,
      )
      // Только 3 уровень вложенности можно менять и перекидывать в другой подраздел без потерь
      const isLastNode = true
      if (isLastNode) {
        this.selectOptions = this.flatArrayHandler(page)
        const option = this.selectOptions.find(
          (item) => item.id === this.formState.parent_id,
        )?.title
        this.selectedOption = option ? option : ''
        this.searchResult = this.selectOptions
        this.fuse = new Fuse(this.selectOptions, this.searchConfig)
        this.selectionIsActive = true
      }
    },
    flatArrayHandler(list) {
      let tempArr = []
      list.forEach((item) => {
        tempArr.push({
          title: item.title.ru,
          id: item.id,
          slug: item.slug,
          keywords: [
            item.title.ru,
            item.slug,
            keyboardTransliteration(item.title.ru),
            englishTransliteration(item.title.ru),
          ].join(', '),
        })
      })
      return tempArr
    },
    onSearch(value) {
      if (value) {
        this.searchResult = this.fuse.search(value).map((item) => {
          return item.item
        })
      } else {
        this.searchResult = this.selectOptions
      }
    },
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    onSelect(id) {
      this.formState.parent_id = id
    },
    async validateFields() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            await this.editFaqHandler()
          } catch (error) {
            showErrorMsg(error.message)
          }
        } else {
          showErrorMsg('Заполните все обязательные поля')
          return false
        }
      })
    },
    async editFaqHandler() {
      this.formState.faqType = this.faqType
      if (!this.formState.parent_id) {
        this.formState.parent_id = this.parentId ? this.parentId : null
      }
      this.formState.title.kz = this.formState.title.ru
      this.formState.body.kz = this.formState.title.ru
      await this.$API.ConfigDiscovery.updateFaq(
        this.faqType,
        this.formState.id,
        this.formState,
      )
      showSuccessMsg('Вы обновили FAQ!')
    },
    async deleteFaq() {
      try {
        await this.deleteFaqHandler()
        showSuccessMsg('Вы удалили FAQ!')
        await this.$router.push({
          path: this.faqType === 'user' ? '/faq/user' : '/faq/merchant',
        })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async deleteFaqHandler() {
      await this.$API.ConfigDiscovery.deleteFaq(this.faqType, this.formState.id)
    },
  },
  created() {
    this.initialize()
  },
}
</script>
