<template>
  <div>
    <div class="settings-wrap">
      <h2>Группы базовых продуктов</h2>
      <a-button
        v-can="{
          service: 'storefront',
          permissions: ['group-create'],
        }"
        @click="() => $router.push({ name: 'BaseProductGroupCreate' })"
        >Создать новую группу
      </a-button>
    </div>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onSearch">
      <div class="settings-wrap">
        <a-form-model-item label="Поиск">
          <a-input v-model="filter.search" />
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ sku }) => sku"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link
          :to="{
            name: 'BaseProductGroupEdit',
            params: {
              group_id: id,
            },
          }"
        >
          {{ id }}
        </router-link>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        {{ updatedAt | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { columnNames } from './constants'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'BaseProductGroupList',
  data: () => ({
    columnNames,
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      search: null,
      page: 1,
      limit: 10,
    },
  }),
  methods: {
    onSearch(event) {
      event.preventDefault()
      this.searchHandler()
    },
    async searchHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getProductGroupList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getProductGroupList()
    },
    initialize() {
      try {
        this.isLoading = true
        this.getProductGroupList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getProductGroupList() {
      const { groups, total } = await this.$API.Storefront.getProductGroupList(
        this.filter,
      )
      this.pagination.total = total
      this.dataSource = groups
      this.isLoading = false
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        search: null,
        page: 1,
      }
      this.getProductGroupList()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
