<template>
  <div>
    <div class="settings-wrap">
      <h2>Информация о пользователе</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'UserList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="isFetching">
      <a-form-model ref="form" :model="userState" @submit="updateHandler">
        <a-form-model-item label="Текущий статус:">
          <a-switch
            v-model="userState.is_active"
            checked-children="Активен"
            un-checked-children="Неактивен"
            default-checked
          />
        </a-form-model-item>
        <a-form-model-item label="TDID:">
          <a-input v-model="userState.tdid" disabled />
        </a-form-model-item>
        <a-form-model-item label="Имя:">
          <a-input v-model="userState.first_name" disabled />
        </a-form-model-item>
        <!--        <a-form-model-item label="Фамилия:">-->
        <!--          <a-input v-model="userState.last_name" disabled />-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item label="Отчество:">-->
        <!--          <a-input v-model="userState.patronymic" disabled />-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label="Почта:">
          <a-input v-model="userState.email" disabled />
        </a-form-model-item>
        <phone-input
          label="Номер телефона"
          v-model="userState.phone"
          :disabled="true"
        />
        <a-form-model-item label="Текущие роли:">
          <a-tag
            class="tag-label"
            v-for="role in userState.roles"
            :key="role.id"
          >
            {{ role.title.ru }}
          </a-tag>
        </a-form-model-item>
        <a-divider></a-divider>
        <a-form-model-item label="Список ролей которые можно добавить:">
          <a-checkbox-group v-model="checkedList">
            <a-row>
              <a-col v-for="(role, index) in allowedRoleList" :key="index">
                <a-checkbox :value="role.id" :disabled="role.disabled === true">
                  {{ role.title.ru }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-col>
            <p>
              Дата создания:
              <code>
                {{ userState.created_at | formatDate }}
              </code>
            </p>
          </a-col>
          <a-col>
            <p>
              Дата последнего обновления:
              <code>
                {{ userState.updated_at | formatDate }}
              </code>
            </p>
          </a-col>
        </a-row>
        <a-button-group>
          <a-button type="primary" html-type="submit">Сохранить</a-button>
          <a-popconfirm
            title="Удалить сотрудника и лишить его доступа к админ панели?"
            ok-text="Да"
            cancel-text="Отмена"
            @confirm="deleteUserHandler"
          >
            <a-button type="danger">
              <a-icon type="delete" />
              Удалить
            </a-button>
          </a-popconfirm>
        </a-button-group>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import {
  getAllowedRoleListAPI,
  getEmployeeByIdAPI,
  updateEmployeeByIdAPI,
  deleteEmployeeByIdAPI,
} from 'User/api/users'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'UserEdit',
  data: () => ({
    userState: {},
    isFetching: true,
    allowedRoleList: [],
    checkedList: [],
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        this.userState = await getEmployeeByIdAPI(this.userId)
        const { roles } = await getAllowedRoleListAPI()
        const roleList = this.userState.roles.filter((role) => {
          let tempVal = null
          if (roles.findIndex((x) => x.id === role.id) === -1) {
            tempVal = role
            tempVal.disabled = true
            return tempVal
          }
        })
        this.allowedRoleList = [...roles, ...roleList]
        this.checkedList = this.userState.roles.map((role) => role.id)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async updateHandler() {
      try {
        this.isFetching = true
        const curObj = {
          is_active: this.userState.is_active,
          role_ids: this.checkedList.filter((role) => role.disabled !== true),
        }
        await updateEmployeeByIdAPI(this.userId, curObj)
        showSuccessMsg('Обновлен.')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async deleteUserHandler() {
      try {
        await deleteEmployeeByIdAPI(this.userId)
        showSuccessMsg('Вы удалили пользователя.')
        await this.$router.push({
          name: 'UserList',
        })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  created() {
    this.initialize()
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
  },
}
</script>

<style scoped lang="scss">
.tag-label {
  padding: 3px 5px;
  margin: 2px;
  color: #2c3e50;
}
</style>
