export const orderInfo = {
  id: null,
  status: null,
  payment_status: null,
  total_price: null,
  comment: null,
  products: [
    {
      sku: null,
      title: { kz: '', ru: '', en: '' },
      merchant_title: { kz: '', ru: '', en: '' },
      status: '',
      quantity: 1,
      price: '',
      total_price: '',
    },
  ],
  customer: { full_name: '', email: '', phone: '' },
  payment_type: '',
  address: '',
  recipient: { full_name: '', phone: '' },
}
export const columnNames = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Тип хранения',
    dataIndex: 'storage_type',
    key: 'storage_type',
    scopedSlots: { customRender: 'storage_type' },
  },
  {
    title: 'Название продавца',
    dataIndex: 'merchant_title',
    key: 'merchant_title',
    scopedSlots: { customRender: 'merchant_title' },
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    scopedSlots: { customRender: 'pickup_point_id' },
  },
  {
    title: 'Колличество',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Цена за еденицу',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: 'Общая цена',
    dataIndex: 'total_price',
    key: 'total_price',
    scopedSlots: { customRender: 'total_price' },
  },
  {
    title: 'Статус товара',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Причина',
    dataIndex: 'cancel_reason',
    key: 'cancel_reason',
    scopedSlots: { customRender: 'cancel_reason' },
  },
]
export const returnColumnNames = [
  {
    title: 'Название',
    dataIndex: 'title.ru',
    key: 'title.ru',
  },
  {
    title: 'Колличество к возврату',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' },
  },
  {
    title: 'Колличество в заказе',
    dataIndex: 'max_amount',
    key: 'max_amount',
  },
]
export const returnForm = {
  comment: null,
  reason: 'other',
  items: [],
  order_id: null,
}

export const CANCEL_REASONS = {
  incorrectSpecs: {
    value: 'incorrectSpecs',
    label: 'не та характеристика товара',
  },
  incorrectSize: {
    value: 'incorrectSize',
    label: 'не тот размер / габариты',
  },
  changedDecision: {
    value: 'changedDecision',
    label: 'передумал / пропала потребность',
  },
  foundCheaper: {
    value: 'foundCheaper',
    label: 'нашёл дешевле',
  },
  longDelivery: {
    value: 'longDelivery',
    label: 'долгая доставка',
  },
}
export const returnFormRules = {}
