/* eslint-disable */

export const RU_LOCALE = {
  default: 'Ошибка валидации',
  required: 'Поле %s обязательно для заполнение',
  enum: 'Поле %s должно быть одним из набора %s',
  whitespace: 'Поле "%s" не может быть пустым',
  date: {
    format: '%s date %s is invalid for format %s',
    parse: '%s date could not be parsed, %s is invalid ',
    invalid: '%s date %s is invalid',
  },
  types: {
    string: 'Поле %s должно быть строкой',
    method: 'Поле %s должно быть методом',
    array: 'Поле %s должно быть массивом',
    object: 'Поле %s должно быть объектом %s',
    number: 'Поле %s должно быть числом',
    date: 'Поле %s должно быть датой',
    boolean: 'Поле %s должно быть boolean',
    integer: 'Поле %s должно быть целым числом',
    float: 'Поле %s должно быть дробным числом',
    regexp: 'Поле %s должно быть регулярным выражением',
    email: 'Поле %s должно быть email адресо',
    url: 'Поле %s должно быть ссылкой',
    hex: 'Поле %s должно быть hex-кодом цвета',
  },
  string: {
    len: 'Длина поля %s должна быть %s',
    min: 'Минимальная длина поля %s должна быть %s',
    max: 'Максимальная длина поля %s должна быть %s',
    range: 'Длина поля %s должна быть больше %s и меньше %s символов',
  },
  number: {
    len: 'Поле %s должно быть равно %s',
    min: 'Поле %s не может быть меньше %s',
    max: 'Поле %s не может быть больше %s',
    range: 'Поле %s должно быть в диапозоне от %s до %s',
  },
  array: {
    len: 'Поле %s должно состоять из %s элементов',
    min: 'Поле %s должно состоять минимум из %s элементов',
    max: 'Поле %s должно состоять максимум из %s элементов',
    range: 'В поле А',
  },
  files: {
    min: 'Поле %s должно состоять минимум из %s файлов',
  },
  pattern: {
    mismatch: 'Поле %s value %s does not match pattern %s',
  },
  ALPHA:
    'Поле "%s" должно состоять из символов русского,казахского или английского алфавита',
  ALPHA_SPACE:
    'Поле "%s" должно состоять из символов русского,казахского или английского алфавита и пробела',
  ALPHA_LATIN: 'Поле "%s" должно состоять из символов латинского алфавита',
  ALPHA_LATIN_SPACE:
    'Поле "%s" должно состоять из символов латинского алфавита и пробела',
  ALPHA_CYRILLIC: 'Поле "%s" должно состоять из символов русского алфавита',
  ALPHA_CYRILLIC_SPACE:
    'Поле "%s" должно состоять из символов русского алфавита и пробела',
  ALPHA_CYRILLIC_KAZ:
    'Поле "%s" должно состоять из символово русского или казахского алфавита и пробела',
  ALPHA_CYRILLIC_KAZ_SPACE:
    'Поле "%s" должно состоять из символово русского или казахского алфавита ',
  ALPHA_NUMBER:
    'Поле "%s" должно состоять из символов русского или английского алфавита, пробела а так же символов . , \' " : ',
  ALPHA_NUMBER_SPECIALS:
    'Поле "%s" должно состоять из символов русского или английского алфавита, пробела а так же символов . , \' " : » «',
  ALPHA_NUMBER_LATIN:
    'Поле "%s" должно состоять из цифр и символов латинского алфавита ',
  ALPHA_NUMBER_LATIN_SPACE:
    'Поле "%s" должно состоять из цифр, символов латинского алфавита и пробела',
  ALPHA_NUMBER_CYRILLIC:
    'Поле "%s" должно состоять из цифры и символов русского алфавита',
  ALPHA_NUMBER_CYRILLIC_SPACE:
    'Поле "%s" должно состоять из цифр, символов русского алфавита и пробелов',
  ALPHA_NUMBER_CYRILLIC_KAZ:
    'Поле "%s" должно состоять из цифр и символово русского или казахского алфавита',
  ALPHA_NUMBER_CYRILLIC_KAZ_SPACE:
    'Поле "%s" должно состоять из цифры, символово русского или казахского алфавита и пробела',
  ALPHA_NUMBER_LATIN_UNDERSCORE:
    'Поле "%s" может состоять из символов латинского алфавита, чисел и нижнего пробела',
  ALPHA_NUMBER_LATIN_UNDERSCORE_SPACE:
    'Поле "%s" может состоять из символов латинского алфавита, чисел, нижнего пробела и пробела',
  IBAN: 'Не валидный номер счета',
  URL: 'Ссылка не валидна',
  EMAIL: 'Email не валиден',
  PHONE: 'Номер телефона должен соответсвовать формату +7 (###)###-##-##',
  INTEGER: 'Поле "%s" должно состоять только из цифр',
  DECIMAL: 'Поле "%s" должно состоять только из цифр и символа "."',
  FILE_LENGTH: 'Поле %s должно состоять минимум из %s файлов',
  FILE_LENGTH_RANGE:
    'Количество файлов в поле %s должно быть в диапозоне от %s до %s',
  FILE_SIZE:
    'В поле %s файл %s(файл №%s в списке) превышает ограничение в %s мб.',
  FILE_FORMAT:
    'В поле %s файл %s(файл №%s в списке) не соответствует требуемым форматам: %s',
  FILE_SAVED: 'Файл в поле %s не сохранен',
  FILE_SAVED_MULTIPLE: 'Файлы в поле %s не сохранены',
  FILE_WIDTH:
    'В поле %s файл %s(файл №%s в списке) превышает максимально допустимую ширину:  %spx',
  FILE_HEIGHT:
    'В поле %s файл %s(файл №%s в списке) превышает максимально допустимую высоту:  %spx',
  FILE_WIDTH_HEIGHT:
    'В поле %s файл %s(файл №%s в списке) превышает максимально допустимые размеры(ШхВ):  %sX%s',
}
