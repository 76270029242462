<template>
  <a-spin :spinning="isMerchantInfoLoading">
    <a-tabs type="card" default-active-key="1">
      <a-tab-pane v-for="(shop, index) in shops" :key="index + 1">
        <span slot="tab">
          <img :src="shop.logo_url" alt="" class="tab-logo" />
          {{ shop.store_name }}
        </span>

        <partner-profile :mid="shop.mid" />
        <partner-orders
          :id="shop.mid"
          :data-source="dataSources[shop.mid].orders"
          :total-count="dataSources[shop.mid].totalCount"
          :loading="dataSources[shop.mid].isLoading"
          @fetchOrdersList="getMerchantOrders"
        />
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>

<script>
import { showErrorMsg } from 'Core/utils'

import PartnerProfile from 'CRM/components/Partner/PartnerProfile.vue'
import PartnerOrders from 'CRM/components/Common/OrderList.vue'

export default {
  name: 'PartnerView',
  components: { PartnerProfile, PartnerOrders },
  data: () => ({
    shops: [],
    isMerchantInfoLoading: false,
    /* данные по заказам будем хранить в объекте в нормализованном виде, чтобы:
       1. У каждого таба был свой список заказов со своим state
       2. Каждый раз не запрашивать список заказов при смене таба */
    dataSources: {},
  }),
  async mounted() {
    await this.getMerchant()
  },
  computed: {
    tdId() {
      return this.$route.params.tdId
    },
  },
  methods: {
    async getMerchant() {
      try {
        this.isMerchantInfoLoading = true
        const { data } = await this.$API.MerchantProfile.getMerchantsByTdId(
          this.tdId,
        )
        this.shops = data
        for (const shop of this.shops) {
          /**
         при первичной загрузке списка магазинов, задаём соответствующие dataSources, 
         чтобы позже можно было записывать заказы и т.д по каждому отдельному магазину
         **/
          this.$set(this.dataSources, shop.mid, {
            orders: [],
            totalCount: 0,
            isLoading: false,
          })
        }
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isMerchantInfoLoading = false
      }
    },
    async getMerchantOrders({ id: merchant_id, ...otherParams }) {
      try {
        this.dataSources[merchant_id].isLoading = true
        const { orders, total_count } =
          await this.$API.OMS.getMerchantOrderList({
            merchant_id,
            ...otherParams,
          })
        this.dataSources[merchant_id].orders = orders
        this.dataSources[merchant_id].totalCount = total_count
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.dataSources[merchant_id].isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.tab-logo {
  max-width: 20px;
  max-height: 20px;
}
</style>
