export const columnNames = [
  {
    title: 'ID менеджера',
    dataIndex: 'tdid',
    key: 'tdid',
    scopedSlots: { customRender: 'tdid' },
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
]

export const categoryFormRules = {
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'title.ru': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'title.kz': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'title.en': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  image_url: {
    type: 'string',
    message: 'Выберите картинку',
    required: true,
  },
  storefront_id: {
    required: true,
    fullField: 'Зона действия меню',
    type: 'string',
  },
  devices: {
    type: 'array',
    message: 'Выберите как минимум одно устройство',
    required: true,
  },
}

export const categoryFormState = {
  children: [],
  devices: [],
  id: null,
  image_url: null,
  is_active: false,
  name: null,
  order_num: 1,
  parent_id: null,
  storefront_id: null,
  title: {},
  type: 'CATEGORY',
}
