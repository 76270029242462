export const product = {
  condition: 'Состояние',
  showcase: 'Витрина',
  type: 'Тип товара',
  regular: 'Обычный',
  digital: 'Цифровой',
  service: 'Услуга',
  mto: 'МТО',
  stock: 'Остаток',
  stocks: 'Остатки',
  servicePoint: 'Точка обслуживания',
  fieldIsRequired: 'Поле обязательно для заполнения!',
  filter: 'Фильтр',
  SKU: 'СКУ',
  city: 'Город',
  search: 'Поиск',
  clear: 'Сбросить',
  notFound: 'Ничего не найдено!',
  listReceived: 'Список получен!',
}
