<template>
  <section>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="profileStateRules"
      @submit="saveProfileHandler"
    >
      <upload-files
        @getImageParams="getImageParams"
        :img-url="formState.logo_url"
        prop="fileList"
        v-model="formState.fileList"
      />
      <a-form-model-item prop="store_name" label="Название компании">
        <a-input v-model="formState.store_name" />
      </a-form-model-item>
      <a-form-model-item prop="contact_info.first_name" label="Имя">
        <a-input
          v-model="formState.contact_info.first_name"
          @focusout="capitalize('contact_info', 'first_name')"
        />
      </a-form-model-item>
      <a-form-model-item prop="contact_info.last_name" label="Фамилия">
        <a-input
          v-model="formState.contact_info.last_name"
          @focusout="capitalize('contact_info', 'last_name')"
        />
      </a-form-model-item>
      <a-form-model-item prop="contact_info.patronymic" label="Отчество">
        <a-input
          v-model="formState.contact_info.patronymic"
          @focusout="capitalize('contact_info', 'patronymic')"
        />
      </a-form-model-item>
      <a-form-model-item prop="contact_info.email" label="Email">
        <a-input v-model="formState.contact_info.email" />
      </a-form-model-item>
      <phone-input
        :prop="'contact_info.phone'"
        label="Номер телефона"
        v-model="formState.contact_info.phone"
      />
      <a-button type="primary" html-type="submit">
        Сохранить основную информацию
      </a-button>
    </a-form-model>
  </section>
</template>

<script>
import { profileStateRules } from 'Partner/pages/Partner/PartnerInfo/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

import { capitalize } from 'Core/modules/helpers'

export default {
  name: 'PartnerGeneralInfo',
  components: {
    UploadFiles,
  },
  props: {
    generalInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileStateRules,
      formState: { ...this.generalInfo, fileList: [] },
    }
  },
  methods: {
    capitalize(str1, str2) {
      if (
        this.formState &&
        this.formState[str1] &&
        this.formState[str1][str2]
      ) {
        this.formState[str1][str2] = capitalize(this.formState[str1][str2])
      }
    },
    getImageParams(imgUrl) {
      this.formState.logo_url = imgUrl
    },
    async saveProfileHandler(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isFetching = true
            const profileObj = {
              profile: {
                ...this.formState.contact_info,
                logo_url: this.formState.logo_url,
                store_name: this.formState.store_name,
              },
            }
            await this.$API.MerchantProfile.updateProfile(
              this.merchantID,
              profileObj,
            )
            showSuccessMsg('Удачно обновлен!')
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isFetching = false
          }
        }
      })
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped></style>
