<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание новой группы базовых продуктов</h2>
      <a-button @click="() => $router.push({ name: 'BaseProductGroupList' })">
        Назад
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="groupForm"
      :rules="groupFormRules"
      @submit="createProductGroup"
    >
      <a-form-model-item prop="name" label="Название">
        <a-input v-model="groupForm.name" />
      </a-form-model-item>
      <a-form-model-item prop="model_id" label="Модель">
        <a-input
          class="form__input"
          v-model="selectedModel.title.ru"
          disabled
        />
        <model-finder @setModel="onSetModel"></model-finder>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" html-type="submit">Создать</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import ModelFinder from 'Product/components/ModelFinder'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { groupFormRules } from './contants'

export default {
  components: {
    ModelFinder,
  },
  data() {
    return {
      groupFormRules,
      groupForm: {
        model_id: null,
        name: null,
      },
      isModalVisible: false,
      selectedModel: {
        title: {
          ru: null,
        },
      },
      selectedProducts: [],
    }
  },
  methods: {
    onSetModel(selectedModel) {
      this.selectedModel = selectedModel
      this.groupForm.model_id = selectedModel.id
    },
    async createProductGroup(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.submitIsLoading = true
            const { id } = await this.$API.Storefront.createProductGroup(
              this.groupForm,
            )
            showSuccessMsg('Группа базовых продуктов успешно создана')
            await this.$router.push({
              name: 'BaseProductGroupEdit',
              params: { group_id: id },
            })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.submitIsLoading = false
          }
        }
      })
    },
    onCloseModal() {
      this.isModalVisible = false
    },
  },
  watch: {
    'groupForm.model_id': {
      handler() {
        this.$refs.form.validateField('model_id')
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__input {
    margin-right: 20px;
  }
}

::v-deep {
  .ant-form-item-children {
    display: flex;
    align-items: initial;
  }
}
</style>
