<template>
  <div>
    <div class="settings-wrap">
      <h2>Список шаблонов</h2>
      <router-link
        :to="{ name: 'MailingTemplateCreate' }"
        v-can="{
          service: 'jam',
          permissions: ['template-create'],
        }"
      >
        <a-button>Создать новый шаблон</a-button>
      </router-link>
    </div>
    <div class="filter__form">
      <a-form-model
        layout="inline"
        ref="form"
        :model="params"
        @submit="searchHandler"
      >
        <a-form-model-item label="Канал доставки">
          <a-select
            placeholder="Выберите канал доставки"
            style="min-width: 220px"
            v-model="params.channel"
          >
            <a-select-option
              v-for="type in channelTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Получатель">
          <a-select
            placeholder="Выберите получателя"
            style="min-width: 220px"
            v-model="params.receiver"
          >
            <a-select-option
              v-for="type in receiverTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </a-form-model>
    </div>
    <a-table
      :columns="templateColumns"
      :data-source="templates"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      :loading="isLoading"
      @change="onPageChange"
      bordered
    >
      <div slot="readable_id" slot-scope="readable_id, template">
        <router-link
          :to="{
            name: 'MailingTemplateEdit',
            params: { template_id: template.id },
          }"
        >
          {{ readable_id }}
        </router-link>
      </div>
      <div slot="created_at" slot-scope="created_at">
        {{ created_at && created_at | formatDate }}
      </div>
      <div slot="receiver" slot-scope="receiver">
        {{ getValue('receiverTypes', receiver) }}
      </div>
      <div slot="channel" slot-scope="channel">
        {{ getValue('channelTypes', channel) }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { templateColumns } from './constants'

import { channelTypes, receiverTypes } from '../MailingTemplateEdit/constants'

export default {
  data() {
    return {
      channelTypes,
      receiverTypes,
      templateColumns,
      templates: [],
      isLoading: false,
      params: {
        limit: 10,
        page: 1,
        channel: null,
        receiver: null,
        sort_key: null,
        sortVal: null,
      },
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
        limit: 10,
      },
    }
  },
  created() {
    this.getTemplatesList()
  },
  methods: {
    async getTemplatesList() {
      try {
        this.isLoading = true
        const { templates, count } = await this.$API.Jam.getTemplatesList(
          this.params,
        )
        this.templates = templates
        this.pagination.total = count
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async onPageChange({ current }, filter, sorter) {
      if (sorter['columnKey']) {
        this.params.sort_key = sorter.columnKey
        this.params.sort_val = sorter.order === 'descend' ? -1 : 1
        this.params.page = this.pagination.current = 1
      } else {
        this.params.page = this.pagination.current = current
      }
      await this.getTemplatesList()
    },
    async searchHandler(event) {
      event.preventDefault()
      try {
        this.pagination.current = this.params.page = 1
        await this.getTemplatesList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async dropFilter() {
      this.pagination.current = 1
      this.params = {
        limit: 10,
        page: 1,
        channel: null,
        receiver: null,
      }
      await this.getTemplatesList()
    },
    getValue(fieldName, receiver) {
      const findValue = this[fieldName].find((temp) => temp.id == receiver)
      return findValue ? findValue.title : ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
