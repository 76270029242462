import { GET, PUT } from 'Main/api/REST'

const baseUrl = `${process.env.VUE_APP_API_URL}/cabinet/accesser/api/v1`

export const getRoleListAPI = async () => {
  const { data, success } = await GET(`${baseUrl}/roles`)
  if (!success) {
    throw new Error(`Ошибка при запросе списка Ролей: ${data?.error?.message}`)
  }
  return data
}

export const getRoleByIdAPI = async (id) => {
  const { data, success } = await GET(`${baseUrl}/roles/${id}`)
  if (!success) {
    throw new Error(
      `Ошибка при запросе списка пермишенов: ${data?.error?.message}`,
    )
  }
  return data
}

export const updateAttributeAPI = async (modelId, id, params) => {
  const { data, success } = await PUT(
    `${baseUrl}/models/${modelId}/attributes/${id}`,
    params,
  )
  if (!success) {
    throw new Error(`Ошибка при сохранении: ${data?.error?.message}`)
  }
}
