<template>
  <div>
    <a-modal
      :visible="isVisible"
      :title="
        documentForm.id
          ? 'Редактирование документа'
          : 'Добавление нового документа'
      "
      width="45vw"
      :closable="false"
    >
      <a-form-model ref="form" :rules="documentFormRules" :model="documentForm">
        <a-form-model-item prop="name" label="Название документа">
          <a-input v-model="documentForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="link" label="Ссылка на документ">
          <a-input
            v-model="documentForm.link"
            placeholder="Пример: https://www.google.com/"
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="$emit('closeModal', false)">
          Отмена
        </a-button>
        <a-button type="primary" :loading="isLoading" @click="onSubmit">
          Сохранить
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { documentFormRules } from 'Partner/pages/Partner/PartnerInfo/constants'

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    selectedDocumentProp: {
      type: Object,
    },
  },
  data() {
    return {
      documentFormRules,
      isLoading: false,
      documentForm: {
        id: null,
        name: '',
        link: '',
      },
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  watch: {
    selectedDocumentProp: {
      handler(newValue) {
        if (newValue) {
          this.documentForm = newValue
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            if (this.documentForm.id) {
              await this.$API.MerchantProfile.updateEmployeeDocuments(
                this.merchantID,
                this.documentForm.id,
                this.documentForm,
              )
              showSuccessMsg('Документ успешно обновлен!')
            } else {
              await this.$API.MerchantProfile.addEmployeeDocuments(
                this.merchantID,
                this.documentForm,
              )
              showSuccessMsg('Документ успешно добавлен!')
            }
            this.$emit('closeModal', true)
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
