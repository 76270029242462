import { format } from 'Core/modules/helpers'

export const INTEGER = function (rule, value, callback, source, options) {
  let regExp = new RegExp(/[0-9]+$/g)
  if (regExp.test(value) || value === '' || value === null) {
    return true
  } else {
    return callback(
      format(options.messages[rule.validator.name], rule.fullField),
    )
  }
}
export const DECIMAL = function (rule, value, callback, source, options) {
  let regExp = new RegExp(/^[+-]?\d+(\.\d+)?$/g)
  if (regExp.test(value) || value === '' || value === null) {
    return true
  } else {
    return callback(
      format(options.messages[rule.validator.name], rule.fullField),
    )
  }
}
