<template>
  <div>
    <a-form-model-item>
      <a-button @click="visible = true">выбрать Категорию</a-button>
    </a-form-model-item>
    <a-drawer
      title="Выберите категорию товара"
      width="95%"
      placement="right"
      :mask-closable="true"
      :keyboard="true"
      @close="onClose"
      :destroy-on-close="true"
      :visible="visible"
    >
      <div class="catalog-info">
        <div v-if="parentState">{{ parentState.title.ru }}</div>
        <template v-if="uncleState">
          <div class="catalog-info__arrow">
            <a-icon type="right" />
          </div>
          <div>{{ uncleState.title.ru }}</div>
        </template>
        <template v-if="childState">
          <div class="catalog-info__arrow">
            <a-icon type="right" />
          </div>
          <div>{{ childState.title.ru }}</div>
        </template>
      </div>

      <div class="category-wrap">
        <div class="category-menu">
          <div v-for="(item, index) in categoryParent" :key="index">
            <div>
              <list-default
                :title="item.title.ru"
                :has-child="true"
                @click.native="getCategoryChild(2, item)"
              />
            </div>
          </div>
          <div class="manual-wrap">
            <a-button type="link" @click="showModalForm(1)">
              Добавить новую
            </a-button>
          </div>
        </div>
        <div class="category-menu">
          <div v-for="(item, index) in categoryUncle" :key="index">
            <div>
              <list-default
                :title="item.title.ru"
                :has-child="true"
                @click.native="getCategoryChild(3, item)"
              />
            </div>
          </div>
          <div class="manual-wrap">
            <a-button type="link" v-if="parentState" @click="showModalForm(2)">
              Добавить новую
            </a-button>
          </div>
        </div>
        <div class="category-menu">
          <div v-for="(item, index) in categoryChild" :key="index">
            <list-default
              :title="item.title.ru"
              @click.native="setChild(item)"
            />
          </div>
          <div class="manual-wrap">
            <a-button type="link" v-if="uncleState" @click="showModalForm(3)">
              Добавить новую
            </a-button>
          </div>
        </div>
      </div>
      <div class="button-absolute-box">
        <a-button-group>
          <a-button type="primary" v-if="childState" @click="saveCategory">
            Сохранить
          </a-button>
          <a-button @click="onClose"> Отмена</a-button>
        </a-button-group>
      </div>
      <a-modal
        v-model="modalIsShown"
        title="Добавить категорию"
        cancel-text="Отмена"
        @ok="handleSaveCategory"
        :ok-button-props="{
          props: { disabled: !(ru && kz) },
        }"
      >
        <a-form-model-item label="Название категории (RU)">
          <a-input v-model="ru" />
        </a-form-model-item>
        <a-form-model-item label="Название категории (KZ)">
          <a-input v-model="kz" />
        </a-form-model-item>
      </a-modal>
    </a-drawer>
  </div>
</template>

<script>
import ListDefault from 'Core/components/ListDefault/ListDefault'

export default {
  name: 'CategorySelector',
  props: {
    categoryParent: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    visible: false,
    categoryUncle: [],
    categoryChild: [],
    parentState: null,
    uncleState: null,
    childState: null,
    modalIsShown: false,
    ru: null,
    kz: null,
  }),
  methods: {
    onClose() {
      this.visible = false
    },
    showModalForm(step) {
      this.manualStep = step
      this.modalIsShown = true
      this.ru = null
      this.kz = null
    },
    handleSaveCategory() {
      switch (this.manualStep) {
        case 1: {
          this.parentState = {
            title: {
              ru: this.ru,
              kz: this.kz,
            },
          }
          this.uncleState = null
          this.childState = null
          this.categoryUncle = []
          this.categoryChild = []
          break
        }
        case 2: {
          this.uncleState = {
            title: {
              ru: this.ru,
              kz: this.kz,
            },
          }
          this.childState = null
          this.categoryChild = []
          break
        }
        case 3: {
          this.childState = {
            title: {
              ru: this.ru,
              kz: this.kz,
            },
          }
          break
        }
      }

      this.modalIsShown = false
    },
    async getCategoryChild(step, item) {
      const data = await this.$API.Storefront.getCategoryList({
        level: step,
        parent: item.slug,
      })
      if (step === 2) {
        this.categoryUncle = data
        this.categoryChild = []
        this.uncleState = null
        this.childState = null
        this.parentState = item
      } else {
        this.categoryChild = data
        this.uncleState = item
        this.childState = null
      }
    },
    setChild(item) {
      this.childState = item
      const category = [this.parentState, this.uncleState, this.childState]
      this.$emit('setCategory', category)
      this.visible = false
    },
    saveCategory() {
      const category = [this.parentState, this.uncleState, this.childState]
      this.$emit('setCategory', category)
      this.visible = false
    },
  },
  components: {
    ListDefault,
  },
}
</script>

<style scoped lang="scss">
.category {
  &-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &-menu {
    border-right: 2px dashed lightgray;
    max-height: 75vh;
    overflow: auto;
  }
}

.catalog-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  min-height: 36px;
  font-weight: bold;
  color: #73be6f;

  &__arrow {
    font-size: 1.5rem;
    margin: 0 15px;
  }
}

.manual-wrap {
  padding: 7px 20px;
  margin: 10px;
}
</style>
