import { format, joinWith } from 'Core/modules/helpers'

const HELPERS = {
  getLangName: function (lang) {
    switch (lang) {
      case 'ru':
        return 'русского'
      case 'kz':
        return 'казахского'
      case 'en':
        return 'английского'
      default:
        return ''
    }
  },
  getSpecialName: function (symbol) {
    switch (symbol) {
      case 'space':
        return 'пробела'
      case '_':
        return 'нижнего пробела'
      default:
        return symbol
    }
  },
}

const MESSAGES = {
  getLangsMessage: function (langs) {
    const lines = langs.map((lang) => HELPERS.getLangName(lang))
    return `символов ${joinWith(lines, ' или ')} алфавита`
  },
  getNumbersMessage: function () {
    return 'чисел'
  },
  getSpecialsMessage: function (specials) {
    // разделяем символы на 2 типа: именованные(пробел, нижний пробел и т.д) и обычные
    const namedSpecials = []
    const simpleSpecials = []

    specials.forEach((special) => {
      const name = HELPERS.getSpecialName(special)
      if (name.length > 1) namedSpecials.push(name)
      else simpleSpecials.push(name)
    })

    return joinWith(
      [namedSpecials.join(', '), simpleSpecials.join(' ')],
      `${namedSpecials.length && simpleSpecials.length ? ',' : ''} ${
        simpleSpecials.length ? 'а так же символов ' : ''
      } `,
    )
  },
}

const PATTERNS = {
  isOneOfNextLanguages: function (langs) {
    let langRegex = ''
    if (langs?.includes('en')) langRegex += 'a-zA-Z'
    if (langs?.includes('ru')) langRegex += 'а-яА-ЯёЁ'
    if (langs?.includes('kz')) langRegex += 'а-яА-ЯёЁӘәҒғҚқҢңӨөҰұҮүҺһІі'

    return langRegex
  },
  isOneOfNextSpecials: function (specials) {
    let regex = []
    specials?.forEach((special) => {
      switch (special) {
        case 'space':
          regex.push(`\\s`)
          break

        default:
          regex.push(`\\${special}`)
          break
      }
    })

    return regex.join('')
  },
}

const generateRegexMesssage = (rules) => {
  let baseMessage = format('Поле "%s" должно состоять из ', rules.fullField)
  const validateMessages = []
  if (rules.langs?.length)
    validateMessages.push(MESSAGES.getLangsMessage(rules.langs))
  if (rules.numeric)
    validateMessages.push(MESSAGES.getNumbersMessage(rules.numeric))
  if (rules.specials)
    validateMessages.push(MESSAGES.getSpecialsMessage(rules.specials))

  baseMessage += joinWith(
    validateMessages,
    rules.specials?.length > 1 ? ', ' : ' и ',
  )

  return baseMessage
}

const generateRegexPattern = (rules) => {
  const langsRegex = PATTERNS.isOneOfNextLanguages(rules.langs)
  const specialsRegex = PATTERNS.isOneOfNextSpecials(rules.specials)
  const numericRegex = rules.numeric ? `\\d` : ''

  return new RegExp(`^[${langsRegex}${numericRegex}${specialsRegex}]+$`, 'g')
}

export { generateRegexMesssage, generateRegexPattern }
