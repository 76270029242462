<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание Промо страницы</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'PromoPageList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="rules"
      @submit="onValidate"
    >
      <a-form-model-item label="Статаус">
        <a-switch
          checked-children="Активен"
          un-checked-children="Выключен"
          v-model="formState.is_active"
        />
      </a-form-model-item>
      <a-form-model-item prop="title" label="Загаловок">
        <a-input v-model="formState.title" />
      </a-form-model-item>
      <a-form-model-item prop="slug" label="Slug">
        <a-input v-model="formState.slug" />
      </a-form-model-item>
      <a-form-model-item label="Описание">
        <a-input v-model="formState.meta.description" />
      </a-form-model-item>
      <a-form-model-item label="Заголовок(meta)">
        <a-input v-model="formState.meta.title" />
      </a-form-model-item>
      <a-form-model-item label="Ключевые слова(meta)">
        <a-input v-model="formState.meta.keywords" />
      </a-form-model-item>

      <a-form-model-item prop="html" label="HTML код">
        <code-editor v-model="formState.html" :options="cmOptions" />
      </a-form-model-item>
      <a-form-model-item prop="css" label="CSS код">
        <code-editor v-model="formState.css" :options="cmOptions" />
      </a-form-model-item>
      <a-form-model-item label="JS код">
        <code-editor v-model="formState.js" :options="cmOptions" />
      </a-form-model-item>

      <a-form-item>
        <a-button type="primary" html-type="submit">Создать</a-button>
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { codemirror as CodeEditor } from 'vue-codemirror'

import 'codemirror/mode/htmlembedded/htmlembedded.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/theme/material.css'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'PromoPageCreate',
  components: {
    CodeEditor,
  },
  data() {
    return {
      formState,
      rules,
      cmOptions: {
        tabSize: 2,
        mode: 'text/html',
        theme: 'material',
        lineNumbers: true,
        line: true,
      },
    }
  },
  methods: {
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createPromoPage()
        showSuccessMsg('Страница создана!')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createPromoPage() {
      const { slug } = await this.$API.PPS.createPromoPage(this.formState)
      await this.$router.push({ name: 'PromoPageEdit', params: { id: slug } })
    },
  },
}
</script>

<style scoped></style>
