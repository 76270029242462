<template>
  <div class="logo-box">
    <a-spin :spinning="isLoading">
      <div :class="{ upload__wrapper: draggable }">
        <div
          class="ant-upload-list ant-upload-list-picture-card"
          v-if="draggable"
        >
          <draggable
            class="syllable_ul"
            element="ul"
            :list="fileList"
            :options="{ group: 'title', animation: 150 }"
            :no-transition-on-drag="true"
          >
            <transition-group type="transition" :css="true">
              <div
                class="
                  ant-upload-list-item
                  ant-upload-list-item-done
                  ant-upload-list-item-list-type-picture-card
                "
                v-for="(item, index) in fileList"
                :key="`key-${index}`"
              >
                <div class="ant-upload-list-item-info">
                  <span>
                    <a
                      @click="handlePreview(item)"
                      class="ant-upload-list-item-thumbnail"
                    >
                      <img
                        :src="item.url || getBase64FromFile(item)"
                        alt="4.jpg"
                        class="ant-upload-list-item-image"
                      />
                    </a>
                  </span>
                </div>
                <span class="ant-upload-list-item-actions">
                  <a-icon
                    type="eye"
                    :style="{ color: 'white', marginLeft: '10px' }"
                    @click="handlePreview(item)"
                  ></a-icon>
                  <a-icon type="delete" @click="handleDelete(item)"></a-icon>
                </span>
              </div>
            </transition-group>
          </draggable>
        </div>

        <div class="upload-container">
          <a-form-model-item
            :label="label"
            :prop="prop"
            ref="uploadFormModelRef"
            validate-on-rule-change
          >
            <a-upload
              v-if="formModel"
              :class="{
                'error-form': hasError,
              }"
              ref="AUploadRef"
              list-type="picture-card"
              :file-list="fileList"
              :before-upload="onSelectFile"
              @preview="handlePreview"
              @change="handleChange"
              accept="image/*"
              v-model="fileList"
              :multiple="multiple"
              :show-upload-list="!draggable"
            >
              <div v-if="multiple || (fileList && fileList.length < 1)">
                <a-icon type="plus" />
                <div class="ant-upload-text">{{ placeholderText }}</div>
              </div>
            </a-upload>
          </a-form-model-item>

          <a-button-group v-if="saveableFiles.length">
            <a-button
              type="primary"
              class="mr--4"
              @click="onSaveImg"
              :disabled="hasError"
            >
              Сохранить
            </a-button>
          </a-button-group>
        </div>
      </div>

      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { saveImageAPI } from 'Partner/api/merchant'
import { showErrorMsg } from 'Core/utils'
import { convertBlobToBase64, format } from 'Core/modules/helpers'
import { RU_LOCALE } from 'Core/modules/validator'
import draggable from 'vuedraggable'

export default {
  name: 'UploadFiles',
  components: {
    draggable,
  },
  data: () => ({
    isLoading: null,
    previewVisible: false,
    previewImage: '',
    fileList: [],
    formModel: null,
  }),
  props: {
    imgUrl: {
      type: String,
    },
    placeholderText: {
      type: String,
      default: 'Загрузить лого',
    },
    prop: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    imgUrl: {
      handler(newVal) {
        if (newVal) {
          this.fileList = [
            {
              uid: '-1',
              name: 'image',
              status: 'done',
              url: newVal,
            },
          ]
        }
      },
      immediate: true,
    },
    value: 'syncWithParentValue',
  },
  computed: {
    hasError() {
      /**
       * существует отдельная проверка на сохранение файла.
       * Если текущая ошибка валидации === файл не сохранен,
       * отключаем проверку, чтобы юзер мог сохранить.
       */
      const fieldName = this.formModel.getFilteredRule()?.[0]?.fullField
      const currentErrorMessage = this.formModel.validateMessage
      const fileNotSavedError = format(
        this.fileList?.length > 1
          ? RU_LOCALE.FILE_SAVED_MULTIPLE
          : RU_LOCALE.FILE_SAVED,
        fieldName,
      )
      const isErrorEqualsToFileNotSavedError =
        fileNotSavedError === currentErrorMessage

      return isErrorEqualsToFileNotSavedError ? false : !!currentErrorMessage
    },
    saveableFiles() {
      return this.fileList.filter((file) => !file.url)
    },
  },
  mounted() {
    this.formModel = this.$refs.uploadFormModelRef
    this.syncWithParentValue()
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },

    handleChange({ fileList }) {
      if (!fileList.length) {
        this.$emit('getImageParams', null)
        this.previewImage = ''
      }

      this.fileList = fileList
      this.$emit('input', fileList)
    },

    async handlePreview(file) {
      this.setPreviewImage(file)
      this.previewVisible = true
    },

    async setPreviewImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await convertBlobToBase64(file.originFileObj ?? file)
        this.$set(file, 'url', null)
      }
      this.previewImage = file.url || file.preview
    },

    onSelectFile(file) {
      this.setPreviewImage(file)
      return false
    },

    getBase64FromFile(file) {
      if (!file.url && file.originFileObj?.preview) {
        return file.originFileObj.preview
      }
      return ''
    },

    async onSaveImg() {
      try {
        this.isLoading = true
        for (const file of this.saveableFiles) {
          let data = new FormData()
          data.append('file', file.originFileObj, file.name)
          const { url } = await saveImageAPI(data)
          this.$emit('getImageParams', url)
          this.$set(file, 'url', url)
        }
        this.$emit('input', this.fileList)
        /**
         * @important
         * после того, как проставили url, обновляем состояние form-model-item вручную
         * на случай, если на форме есть валидация на сохранение файла.
         * ПРИЧИНА: свойство url является глубинным свойством элемента массива fileList.
         * Проблема в том, что валидатор от AntDv не триггерится при изменении глубинных
         * свойств(хотя аксессоры у поля url имеются). Поэтому необходимо дергать метод validate вручную
         */
        this.$refs.uploadFormModelRef.validate()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
        this.previewImage = ''
      }
    },

    handleDelete(file) {
      this.$refs.AUploadRef.handleRemove(file)
    },

    syncWithParentValue() {
      if (this.multiple) this.fileList = this.value
    },
  },
}
</script>

<style lang="scss">
.error-form {
  .ant-upload-list-item {
    border: 1px solid red !important;
  }
}
</style>

<style lang="scss" scoped>
ul {
  padding: 0;
}
.upload {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
