<template>
  <div>
    <div class="filter__form">
      <a-form-model
        layout="inline"
        ref="form"
        :model="params"
        @submit="searchHandler"
      >
        <a-form-model-item label="Название продукта">
          <a-input v-model="params.search" />
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
        <a-form-item>
          <a-button
            v-can="{
              service: 'storefront',
              permissions: ['merchant-product-update'],
            }"
            @click="gotoBindMerchantProduct"
            type="primary"
          >
            Создать новые привязки
          </a-button>
        </a-form-item>
      </a-form-model>
    </div>
    <a-table
      :columns="unRecognizedTablecolumns"
      :data-source="merchantProducts"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ products: { sku } }) => sku"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="model" slot-scope="products">
        {{ products.model || 'Не указан' }}
      </div>
      <div slot="price" slot-scope="products">
        {{ products.price && products.price | currency }}
      </div>

      <div slot="created_at" slot-scope="created_at">
        {{ created_at && created_at | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { productListMixin } from 'Partner/mixins/merchantProductListMixin'
import { unRecognizedTablecolumns } from 'Partner/components/constants'

export default {
  data() {
    return {
      unRecognizedTablecolumns,
      isModalVisible: false,
    }
  },
  mixins: [productListMixin('unrecognized')],
  methods: {
    gotoBindMerchantProduct() {
      this.$router.push({
        name: 'PartnerProductBind',
        params: {
          merchant_id: this.$route.params.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
