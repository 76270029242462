export const promotionColumns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Кол. активных промокодов',
    dataIndex: 'active_promo_codes_count',
    key: 'active_promo_codes_count',
    scopedSlots: { customRender: 'active_promo_codes_count' },
    width: '130px',
    align: 'right',
  },
  {
    title: 'Статус',
    dataIndex: 'is_active',
    key: 'is_active',
    width: '100px',
    scopedSlots: { customRender: 'is_active' },
  },
  {
    title: 'Дата начало',
    dataIndex: 'starts_at',
    key: 'starts_at',
    width: '200px',
    scopedSlots: { customRender: 'starts_at' },
  },
  {
    title: 'Дата окончания',
    dataIndex: 'ends_at',
    key: 'ends_at',
    width: '200px',
    scopedSlots: { customRender: 'ends_at' },
  },
]

export const statusTypes = [
  {
    name: 'Активные',
    value: 'true',
  },
  {
    name: 'Неактивные',
    value: 'false',
  },
]
