import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления возвратами заказов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/rms/swagger/index.html} */
export class RMSService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'rms',
    })
  }

  @Api
  async getPurchaseReturnList(params) {
    const response = await this.GET(`/v1/refunds`, params)
    return response
  }

  @Api
  async getPurchaseReturnById(id) {
    const response = await this.GET(`/v1/refund/${id}`)
    return response
  }

  @Api
  async purchaseReturn(params) {
    const response = await this.POST(`/v1/refund`, params)
    return response
  }

  @Api
  async cancelPurchaseReturn(id, params) {
    const response = await this.PATCH(`/v1/refund/${id}`, params)
    return response
  }
}
