export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'store_id',
    key: 'store_id',
    scopedSlots: { customRender: 'store_id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Номер телефона',
    dataIndex: 'contact.phone',
    key: 'contact.phone',
  },
  // {
  //   title: 'Название Юр. лица',
  //   dataIndex: 'name',
  //   key: 'name',
  // },
  {
    title: 'ИИН / Бин Юр. лица',
    dataIndex: 'company_info.payment_requisites.bin',
    key: 'company_info.payment_requisites.bin.bin',
  },
  {
    title: 'Статус',
    dataIndex: 'is_active',
    key: 'is_active',
    scopedSlots: { customRender: 'isActive' },
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
]

export const contactState = {
  info: null,
  phone: null,
}

export const contactRules = {
  info: {
    type: 'string',
    message: 'Поле обязательно для заполнения(только кириллица)',
    required: true,
  },
  phone: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}
