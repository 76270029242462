import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления коммисиями
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/commissions/swagger/index.html} */
export class CommissionsService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'commissions',
    })
  }

  @Api
  async getCommissionsList(params) {
    const response = await this.GET(`/v1/commissions`, params)
    return response
  }

  @Api
  async getPersonalCommissionList(id, params) {
    const response = await this.GET(`/v1/commissions/merchant/${id}`, params)
    return response
  }

  @Api
  async updateCommission(id, payload) {
    const response = await this.PUT(`/v1/commissions/${id}`, payload)
    return response
  }

  @Api
  async createCustomCommission(params) {
    // одноразово создаем когда нет id у комиссиии, для дальнейшего обновление кастомной комиссии используем updateCommission
    const response = await this.POST(`/v1/commissions/`, params)
    return response
  }
}
