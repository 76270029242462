export const formState = {
  id: null,
  name: null,
  title: {
    ru: null,
    kz: null,
    en: null,
  },
  links: [],
}

export const rules = {
  id: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
}
