






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { receiversColumnNames } from '../../pages/ClientInfo/constants'

@Component
class ClientReceivers extends Vue {
  @Prop({ type: Object, required: true })
  requisites

  receiversColumnNames = receiversColumnNames
}

export default ClientReceivers
