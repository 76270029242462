import { promotionFormRules, paymentTypes } from '../PromocodeEdit/constants'

import 'moment/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import moment from 'moment'

export const addEditPromotionMixin = () => ({
  data() {
    return {
      locale,
      paymentTypes,
      promotionFormRules,
      promotionForm: {
        code: null,
        description: null,
        ends_at: null,
        is_active: true,
        lock_sec_count: 1200,
        max_usage_limit: null,
        name: null,
        starts_at: null,
        title: {
          kz: null,
          ru: null,
          en: null,
        },
        user_user_limit: null,
        cart_rules: {
          max_cart_size: null,
          max_cart_sum: null,
          min_cart_size: null,
          min_cart_sum: null,
          // products: [],
          // brands: [],
          // city_ids: [],
          // categories: [],
        },
        discount: {
          amount: 1,
          type: 'percent',
        },
        payment_types: [],
        target: {
          items: [],
          type: 'product',
        },
      },
      productSearchParams: {
        search: null,
        limit: 50,
        offset: 1,
      },
      isModalVisible: false,
    }
  },
  computed: {
    promotionID() {
      return this.$route.params.promocode_id
    },
    // cityList() {
    //   return this.$store.getters['core/cities']
    // },
  },
  methods: {
    onRangeDatepickerChange(datesMoment) {
      const [start_date, end_date] = datesMoment
      this.promotionForm.starts_at = moment(start_date).toISOString()
      this.promotionForm.ends_at = moment(end_date).toISOString()
    },
    onChangeTargetType(type) {
      this.promotionForm.target.type = type
      if (type != 'product') {
        this.promotionForm.target.items = []
      }
    },
    // setBrandHandler({ slug, title }) {
    //   this.promotionForm.cart_rules.brands.push({ id: slug, title })
    // },
    // removeSelectedBrand(brandIndex) {
    //   this.promotionForm.cart_rules.brands.splice(brandIndex, 1)
    // },
  },
})
