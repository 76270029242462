import { capitalize } from '@/Core/modules/helpers'

export default {
  fio(name, surname, secondname, full = false) {
    if (name && surname) {
      if (full) {
        return `${capitalize(name)} ${surname} ${secondname ? secondname : ''}`
      } else {
        return (
          `${capitalize(name)} ${surname[0]}.` +
          (secondname ? ` ${secondname[0]}.` : '')
        )
      }
    } else {
      return 'Нет данных [name | surname]'
    }
  },
}
