<template>
  <div>
    <a-form-model
      ref="addressInfoForm"
      :model="formState"
      :rules="addressInfoStateRules"
      :validate-messages="RU_LOCALE"
      @submit="saveAddressInfoHandler"
    >
      <a-form-model-item prop="country" label="Страна">
        <a-input v-model="formState.country" disabled />
      </a-form-model-item>
      <a-form-model-item prop="city" label="Город">
        <a-select
          v-model="formState.city"
          show-search
          style="width: 100%"
          :filter-option="filterOption"
        >
          <a-select-option v-for="{ id, title } in cityList" :key="id">
            {{ title.ru }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="index" label="Индекс">
        <a-input v-model="formState.index" />
      </a-form-model-item>
      <a-form-model-item prop="street" label="Улица, дом, квартира">
        <a-input v-model="formState.street" />
      </a-form-model-item>
      <a-button type="primary" html-type="submit">
        Сохранить изменения по адресу
      </a-button>
    </a-form-model>
  </div>
</template>

<script>
import { addressInfoStateRules } from 'Partner/pages/Partner/PartnerInfo/constants'
import { filterOption, showErrorMsg, showSuccessMsg } from 'Core/utils'
import { RU_LOCALE } from 'Core/modules/validator'

export default {
  name: 'PartnerAddressInfo',
  props: {
    addressInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addressInfoStateRules,
      formState: this.addressInfo,
      isFetching: false,
      RU_LOCALE,
    }
  },
  methods: {
    filterOption,
    async saveAddressInfoHandler(event) {
      event.preventDefault()
      this.$refs.addressInfoForm.validate(async (result) => {
        if (result) {
          try {
            this.isFetching = true
            const addressObj = {
              address: this.formState,
            }
            await this.$API.MerchantProfile.updateProfile(
              this.merchantID,
              addressObj,
            )
            showSuccessMsg('Удачно обновлен!')
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isFetching = false
          }
        }
      })
    },
  },
  computed: {
    cityList() {
      return this.$store.getters['core/sortedCities']
    },
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped></style>
