<template>
  <div
    v-shortkey="{ en: ['ctrl', 'd'], ru: ['ctrl', 'в'] }"
    @shortkey="toggleModal"
  >
    <a-modal
      class="fast-search"
      :visible="visible"
      :mask-closable="true"
      :closable="false"
      :footer="null"
      :auto-focus-button="null"
      @cancel="toggleModal()"
    >
      <a-auto-complete
        :style="{ width: '100%' }"
        size="large"
        ref="search"
        v-model="search"
        @search="onSearch"
        @select="onSelect"
        option-label-prop="value"
      >
        <template slot="dataSource">
          <a-select-option
            v-for="item in searchResult"
            :key="item.name"
            :value="item.path"
          >
            <a-icon :type="item.icon" />
            {{ item.name }}
          </a-select-option>
        </template>
      </a-auto-complete>
    </a-modal>
  </div>
</template>

<script>
import { routes } from '@/router'
import {
  keyboardTransliteration,
  englishTransliteration,
} from 'Core/modules/translator'

import Fuse from 'fuse.js'

export default {
  name: 'FastNavModal',
  data() {
    return {
      visible: false,
      searchResult: [],
      fuse: null,
      search: '',
      searchConfig: {
        isCaseSensitive: false,
        keys: ['name', 'path', 'keywords'],
      },
    }
  },
  computed: {
    flattenRouterList() {
      let flatterArray = []
      routes.forEach((item) => {
        if (
          !item.meta.isOpen &&
          this.$acl.can(item.meta.permission) &&
          item.meta.search
        ) {
          flatterArray.push({
            name: item.meta.search.name,
            path: item.path,
            icon: item.meta.search.icon,
            keywords: [
              item.name,
              keyboardTransliteration(item.meta.search.name),
              englishTransliteration(item.meta.search.name),
            ].join(', '),
          })
        }
      })
      return flatterArray
    },
  },
  methods: {
    toggleModal() {
      this.visible = !this.visible
      if (this.visible) {
        setTimeout(() => {
          this.$refs.search.focus()
        })
      }
    },
    onSelect(value) {
      this.$router.push(value)
      this.search = ''
      this.toggleModal()
    },
    onSearch(value) {
      if (value) {
        this.searchResult = this.fuse.search(value).map((item) => {
          return item.item
        })
      } else {
        this.searchResult = this.flattenRouterList
      }
    },
  },
  mounted() {
    this.fuse = new Fuse(this.flattenRouterList, this.searchConfig)
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-modal-body {
    padding: 6px;
  }
}
</style>
