import ClientList from 'Client/pages/ClientList/ClientList.vue'
import ClientInfo from 'Client/pages/ClientInfo/ClientInfo.vue'

export default [
  {
    path: '/clients',
    name: 'ClientList',
    component: ClientList,
    meta: {
      permission: {
        service: 'clients',
        permissions: ['client-view'],
      },
      search: {
        name: 'Клиенты',
        icon: 'dollar',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/clients/:tdId',
    name: 'ClientInfo',
    component: ClientInfo,
    meta: {
      permission: {
        service: 'clients',
        permissions: ['client-view'],
      },
      failPath: '/censor',
    },
  },
]
