<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование группы базовых продуктов</h2>
      <a-button @click="() => $router.push({ name: 'BaseProductGroupList' })">
        Назад
      </a-button>
    </div>
    <a-spin :spinning="isLoading">
      <a-form-model
        ref="form"
        :model="groupForm"
        :rules="groupFormRules"
        @submit="updateProductGroup"
      >
        <a-form-model-item prop="name" label="Название">
          <a-input v-model="groupForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="model_id" label="Модель" v-if="modelInfo">
          <a-input class="form__input" v-model="modelInfo.title.ru" disabled />
        </a-form-model-item>

        <a-form-model-item>
          <a-button-group>
            <a-button
              v-can="{
                service: 'storefront',
                permissions: ['group-update'],
              }"
              type="primary"
              html-type="submit"
              class="form__button"
              >Сохранить
            </a-button>
            <a-popconfirm
              title="Удалить группу？"
              ok-text="Да, удалить"
              cancel-text="Нет"
              @confirm="deleteBaseProductsGroup"
            >
              <a-button
                v-can="{
                  service: 'storefront',
                  permissions: ['group-delete'],
                }"
                type="danger"
                class="form__button"
                >Удалить
              </a-button>
            </a-popconfirm>
          </a-button-group>
        </a-form-model-item>
      </a-form-model>

      <template v-if="groupForm.model_id">
        <a-form-model-item
          prop="skus"
          v-if="groupProducts"
          label="Список базовых продуктов"
        >
          <div class="product-wrap">
            <div v-for="product in groupProducts" :key="product.sku">
              <product-item :product="product" @deleteProduct="deleteProduct" />
            </div>
          </div>
          <a-button
            v-can="{
              service: 'storefront',
              permissions: ['group-update'],
            }"
            type="primary"
            @click="addBaseProduct"
          >
            <a-icon type="plus" />
            Добавить базовый продукт
          </a-button>
        </a-form-model-item>
      </template>

      <bind-product-modal
        :is-visible="isModalVisible"
        :request-params="params"
        @closeModal="onCloseModal($event)"
        @addProduct="onAddProduct"
        action-type="bindToGroup"
      ></bind-product-modal>
    </a-spin>
  </div>
</template>

<script>
import BindProductModal from 'Partner/pages/MerchantProducts/components/BindProductModal.vue'
import ProductItem from 'Product/components/ProductItem'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { groupFormRules } from 'Product/pages/BaseProductGroup/BaseProductGroupCreate/contants'

export default {
  components: {
    BindProductModal,
    ProductItem,
  },
  data() {
    return {
      groupFormRules,
      groupForm: {
        name: null,
        model_id: null,
      },
      params: {
        page: 1,
        search: null,
        limit: 50,
        is_enabled: true,
        group_id: 0,
        model_id: null,
      },
      isLoading: false,
      modelInfo: null,
      groupProducts: null,
      updateLoading: false,
      isModalVisible: false,
    }
  },
  computed: {
    groupID() {
      return this.$route.params.group_id
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    async deleteBaseProductsGroup() {
      try {
        await this.$API.Storefront.deleteProductGroupsById(this.groupID)
        showSuccessMsg('Группа продуктов успешно удалена')
        this.$router.push({ name: 'BaseProductGroupList' })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getProductGroupInfo() {
      try {
        this.groupForm = await this.$API.Storefront.getProductGroupsById(
          this.groupID,
        )
        await this.getModelById()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getGroupBaseProductsList() {
      try {
        this.groupProducts =
          await this.$API.Storefront.getGroupBaseProductsList(this.groupID)
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getModelById() {
      try {
        this.modelInfo = await this.$API.Storefront.getModelById(
          this.groupForm.model_id,
        )
        this.params.model_id = this.modelInfo.id
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async initialize() {
      try {
        this.isLoading = true
        await this.getProductGroupInfo()
        await this.getGroupBaseProductsList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async updateProductGroup(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.updateLoading = true
            await this.$API.Storefront.updateProductGroupsById(
              this.groupID,
              this.groupForm,
            )
            showSuccessMsg('Группа продуктов успешна обновлена')
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.updateLoading = false
          }
        }
      })
    },
    async onAddProduct(product) {
      try {
        this.isLoading = true
        await this.$API.Storefront.addBaseProductToGroup(
          product.sku,
          this.groupID,
        )
        await this.getGroupBaseProductsList()
        showSuccessMsg('Продукт добавлен!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async deleteProduct(sku) {
      try {
        this.isLoading = true
        await this.$API.Storefront.deleteBaseProductFromGroup(sku)
        await this.getGroupBaseProductsList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    addBaseProduct() {
      this.isModalVisible = true
    },
    onCloseModal() {
      this.isModalVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-btn-danger {
  margin-left: 20px;
}

.product-wrap {
  display: flex;
  flex-wrap: wrap;
}
</style>
