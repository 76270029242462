






































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
class ShowImages extends Vue {
  @Prop()
  images: string[]

  previewImage: string = ''
  previewVisible = false

  async handlePreview(imageUrl: string) {
    this.previewImage = imageUrl
    this.previewVisible = true
  }

  handleCancel() {
    this.previewVisible = false
  }
}

export default ShowImages
