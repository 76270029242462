<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание быстрых ссылок</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'FastLinkList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="rules"
      @submit="onValidate"
    >
      <a-form-model-item prop="id" label="ID">
        <a-input v-model="formState.id" />
      </a-form-model-item>

      <a-form-model-item prop="name" label="Название">
        <a-input v-model="formState.name" />
      </a-form-model-item>

      <a-form-model-item prop="title.ru" label="Заголовок(рус)">
        <a-input v-model="formState.title.ru" />
      </a-form-model-item>

      <a-form-model-item label="Заголовок(каз)">
        <a-input v-model="formState.title.kz" />
      </a-form-model-item>

      <a-form-model-item label="Заголовок(англ)">
        <a-input v-model="formState.title.en" />
      </a-form-model-item>
      <a-button type="primary" html-type="submit">Создать</a-button>
    </a-form-model>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { formState, rules } from './constants'

export default {
  name: 'FastLinkCreate',
  data: () => ({
    formState,
    rules,
  }),
  methods: {
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createFastLinkById()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createFastLinkById() {
      // поля на бэк-е обязательные
      this.formState.title.kz = this.formState.title.kz
        ? this.formState.title.kz
        : this.formState.title.ru
      this.formState.title.en = this.formState.title.en
        ? this.formState.title.en
        : this.formState.title.ru
      const { id } = await this.$API.Menu.createFastLink(this.formState)
      await this.$router.push({ name: 'FastLinkInfo', params: { id: id } })
    },
  },
}
</script>

<style scoped></style>
