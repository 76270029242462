import MaskedInput from './MaskedInput/MaskedInput.vue'
import StatusView from './StatusView/StatusView'
import PhoneInput from './PhoneInput/PhoneInput'
import FormModel from './FormModel/FormModel'

export default {
  MaskedInput,
  StatusView,
  PhoneInput,
  AFormModel: FormModel,
}
