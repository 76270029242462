import { reesApi } from './rees46'

export const getReesSearchAPI = async (query) => {
  if (!reesApi.initialized) {
    await reesApi.init()
  }
  return new Promise((resolve, reject) => {
    reesApi.request(
      'suggest',
      query,
      (success) => {
        resolve(success)
      },
      (err) => {
        reject(err)
      },
    )
  })
}
