<template>
  <div>
    <a-tag
      v-for="(product, index) in value"
      :key="product.sku ? product.sku : product.id"
      @close="removeSelectedProduct(product, index)"
      closable
    >
      {{ product.title.ru }}
    </a-tag>
    <a-button
      style="background: #fff; borderstyle: dashed"
      @click="isVisible = true"
      size="small"
    >
      <a-icon type="plus" />
      Добавить базовый продукт
    </a-button>
    <a-drawer
      title="Выбрать продукт из списка"
      width="35%"
      :visible="isVisible"
      keyboard
      :closable="false"
    >
      <a-form-model
        layout="inline"
        @submit="searchProducts"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input
            v-model="params.search"
            placeholder="Поиск по названию компании"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button-group>
            <a-button type="primary" html-type="submit"> Поиск</a-button>
          </a-button-group>
        </a-form-model-item>
      </a-form-model>

      <div class="product__list" v-if="products.length">
        <a-table
          :columns="productColumns"
          :data-source="products"
          :scroll="{ x: true }"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onSelect: onSelectChange,
            hideSelectAllCheckbox: true,
          }"
          :row-key="({ sku }) => sku"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          :loading="isLoading"
          :pagination="pagination"
          @change="onPageChange"
          bordered
        >
          <div slot="product_title" slot-scope="product_title">
            {{ (product_title && product_title.ru) || 'Не указан' }}
          </div>
        </a-table>
      </div>
      <div class="button-absolute-box">
        <a-button @click="onSave" type="primary"> Выбрать</a-button>
        <a-button @click="onClose"> Отмена</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
// import { productColumns } from '../../../modules/Main/components/common/constants'

export default {
  props: {
    value: {
      type: Array,
    },
    requestParams: {
      type: Object,
      default: () => ({ page: 1, limit: 10, search: null }),
    },
  },
  data() {
    return {
      productColumns: [
        {
          title: 'Название товара',
          dataIndex: 'title',
          key: 'product_title',
          scopedSlots: { customRender: 'product_title' },
        },
      ],
      products: [],
      isLoading: false,
      isVisible: false,
      selectedProducts: [],
      pagination: {
        current: 1,
        total: 0,
        limit: 10,
      },
      params: null,
      selectedRowKeys: [],
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue.length) {
          this.selectedRowKeys = newValue.map((product) => product.id)
          this.selectedProducts = [...newValue]
        }
      },
    },
    requestParams: {
      handler(newValue) {
        this.params = newValue
      },
      immediate: true,
    },
  },
  methods: {
    async onPageChange({ current }) {
      this.params.page = this.pagination.current = current
      await this.searchProducts()
    },
    async searchProducts() {
      try {
        this.isLoading = true
        const { total, products } =
          await this.$API.Storefront.getBaseProductList(this.params)
        this.pagination.total = total
        this.products = products
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onSave() {
      this.$emit('input', this.selectedProducts)
      this.onClose()
    },
    removeSelectedProduct(product, idx) {
      this.selectedProducts.splice(idx, 1)
      this.$emit('input', this.selectedProducts)
    },
    onClose() {
      this.isVisible = false
      this.params = {
        page: 1,
        limit: 10,
        search: null,
      }
      this.pagination.current = 1
      this.pagination.total = 0
      this.products = []
    },
    onSelectChange(record, selected) {
      if (selected) {
        this.selectedRowKeys.push(record.sku)
        this.selectedProducts.push({ id: record.sku, title: record.title })
      } else {
        this.selectedProducts = this.selectedProducts.filter(
          (pr) => pr.id != record.sku,
        )
        this.selectedRowKeys = this.selectedRowKeys.filter(
          (key) => key != record.sku,
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.product__list {
  margin-top: 30px;
}

::v-deep {
  .ant-table-thead {
    display: none !important;
  }
}
</style>
