export const brokerColumns = [
  {
    title: 'ID',
    dataIndex: 'value',
    key: 'value',
    width: '250px',
    scopedSlots: { customRender: 'value' },
  },
  {
    title: 'Иконка',
    dataIndex: 'icon',
    key: 'icon',
    width: '250px',
    scopedSlots: { customRender: 'icon' },
  },
  {
    title: 'Название',
    dataIndex: 'label',
    key: 'label',
    scopedSlots: { customRender: 'label' },
  },
  {
    title: 'Код',
    dataIndex: 'variant',
    key: 'variant',
    scopedSlots: { customRender: 'variant' },
  },
]
