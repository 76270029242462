export const columnNames = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название продукта',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Фото',
    dataIndex: 'image',
    key: 'image',
    scopedSlots: { customRender: 'imageUrl' },
    align: 'center',
    width: '140px',
  },
  {
    title: 'Древо категории',
    dataIndex: 'categories',
    key: 'categories',
    scopedSlots: { customRender: 'categories' },
  },
  {
    title: 'Бренд',
    dataIndex: 'brand.slug',
    key: 'brand.slug',
  },
  {
    title: 'Статус',
    dataIndex: 'is_enabled',
    key: 'is_enabled',
    scopedSlots: { customRender: 'isEnabled' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
]
