<template>
  <a-result
    status="500"
    title="Нет доступа"
    sub-title="Извинте, но похоже у вас нет доступа. Обратитесь к администратору"
  >
  </a-result>
</template>

<script>
export default {
  name: 'Censor',
}
</script>

<style scoped></style>
