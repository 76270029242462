/* eslint-disable */
import {format} from 'Core/modules/helpers'

export const URL = function (rule, value, callback, source, options) {
    const regExp =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    if (regExp.test(value) || value === '') {
        return true
    } else {
        return callback(
            format(options.messages[rule.validator.name], rule.fullField),
        )
    }
}
