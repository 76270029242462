import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

export class UserProfileService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'user-profile',
    })
  }

  @Api
  async searchUser(params) {
    const response = await this.GET(`/v1/users/search`, params)
    return response
  }
}
