export const columnNames = [
  {
    title: 'Инициатор',
    dataIndex: 'from',
    scopedSlots: { customRender: 'initiator' },
  },
  {
    title: 'Получатель',
    dataIndex: 'to',
    scopedSlots: { customRender: 'recipient' },
  },
  {
    title: 'Причина',
    dataIndex: 'reason.type',
    scopedSlots: { customRender: 'reason' },
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount_in_cents',
    scopedSlots: { customRender: 'amount' },
    width: '170px',
  },
  {
    title: 'Дата операции',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
]
