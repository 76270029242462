<template>
  <a-layout-sider
    class="navigation"
    v-model="
      /* eslint-disable-next-line vue/no-mutating-props */
      isCollapsed
    "
    theme="light"
    :trigger="null"
    collapsible
  >
    <div class="navigation__logo">
      <img src="images/logo.svg" />
    </div>
    <a-menu mode="vertical" :default-selected-keys="['Роли']">
      <template v-for="{ name, path, icon, subMenu } in routes">
        <a-menu-item v-if="!subMenu" :key="name">
          <router-link :to="path">
            <a-icon :type="icon" />
            <span>{{ name }}</span>
          </router-link>
        </a-menu-item>
        <a-sub-menu v-else :key="name">
          <span slot="title">
            <a-icon :type="icon" />
            <span>{{ name }}</span>
          </span>
          <a-menu-item v-for="{ name, path, icon } in subMenu" :key="name">
            <router-link :to="path">
              <a-icon :type="icon" />
              <span>{{ name }}</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { navRoutes } from './constants'
import './navigation.scss'

export default {
  name: 'Navigation',
  computed: {
    routes() {
      return this.$acl.showNavigation(navRoutes)
    },
  },
  props: {
    isCollapsed: Boolean,
  },
}
</script>
