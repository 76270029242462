var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('a-divider',[_vm._v("Адреса")]),_c('a-table',{attrs:{"columns":_vm.addressesColumnNames,"loading":_vm.isLoading,"data-source":_vm.requisites.addresses,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
	var id = ref.id;

	return id;
},"bordered":""},scopedSlots:_vm._u([{key:"is_default",fn:function(is_default){return [_vm._v(" "+_vm._s(is_default ? 'Да' : '-')+" ")]}}])}),_c('a-divider',[_vm._v("Получатели")]),_c('a-table',{attrs:{"columns":_vm.receiversColumnNames,"loading":_vm.isLoading,"data-source":_vm.requisites.receivers,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
	var id = ref.id;

	return id;
},"bordered":""},scopedSlots:_vm._u([{key:"phone",fn:function(phone){return [_vm._v(" "+_vm._s(_vm._f("phoneFormat")(phone))+" ")]}},{key:"created_at",fn:function(created_at){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at))+" ")]}},{key:"is_default",fn:function(is_default){return [_vm._v(" "+_vm._s(is_default ? 'Да' : '-')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }