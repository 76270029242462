import configColors from '../../modules/Reference/store/configColors'
import configPlatform from '../../modules/Reference/store/configPlatforms'
import configContacts from '../../modules/Reference/store/configContacts'
import configPayments from '../../modules/Reference/store/configPayments'
import configBrokers from 'Reference/store/configBrokers'

export const config = {
  namespaced: true,
  modules: {
    colors: configColors,
    platforms: configPlatform,
    contacts: configContacts,
    payments: configPayments,
    brokers: configBrokers,
  },
}
