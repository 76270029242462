var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Группы базовых продуктов")]),_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
        service: 'storefront',
        permissions: ['group-create'],
      }),expression:"{\n        service: 'storefront',\n        permissions: ['group-create'],\n      }"}],on:{"click":function () { return _vm.$router.push({ name: 'BaseProductGroupCreate' }); }}},[_vm._v("Создать новую группу ")])],1),_c('a-form-model',{ref:"form",attrs:{"layout":"inline","model":_vm.filter},on:{"submit":_vm.onSearch}},[_c('div',{staticClass:"settings-wrap"},[_c('a-form-model-item',{attrs:{"label":"Поиск"}},[_c('a-input',{model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('a-form-item',[_c('a-button-group',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Поиск")]),_c('a-button',{attrs:{"type":"dashed"},on:{"click":_vm.dropFilter}},[_vm._v(" сбросить")])],1)],1)],1)]),_c('a-table',{attrs:{"columns":_vm.columnNames,"data-source":_vm.dataSource,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var sku = ref.sku;

            return sku;
},"loading":_vm.isLoading,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"id",fn:function(id){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'BaseProductGroupEdit',
          params: {
            group_id: id,
          },
        }}},[_vm._v(" "+_vm._s(id)+" ")])],1)}},{key:"createdAt",fn:function(createdAt){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(createdAt))+" ")])}},{key:"updatedAt",fn:function(updatedAt){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(updatedAt))+" ")])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }