import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { user } from 'User/store/user'
import { core } from './core'
import { config } from 'Core/modules/configs'

Vue.use(Vuex)

const vuexPersistence = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user'],
})

const store = new Vuex.Store({
  modules: {
    user,
    core,
    config,
  },
  plugins: [vuexPersistence.plugin],
})

export default store
