import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис маппинга товаров с Kaspi
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/kaspi-stealer/swagger/index.html} */
export class KaspiStealerService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'kaspi-stealer',
    })
  }

  @Api
  async uploadFileKaspi(fileName: string, file) {
    const response = await this.POST(
      `/v1/kaspi-stealer/market-file/${fileName}`,
      file,
    )
    return response
  }

  @Api
  async uploadKaspiLogs(merchantId: string, fileName: string, file: FormData) {
    const response = await this.POST(
      `/v1/kaspi-stealer/merchant-logs/${fileName}`,
      file,
      undefined,
      { merchant_id: merchantId },
    )
    return response
  }
}
