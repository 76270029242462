<template>
  <div>
    <a-form-model
      v-if="showFilter"
      layout="inline"
      ref="form"
      :model="filter"
      @submit.prevent="onFilter"
    >
      <div class="settings-wrap">
        <a-form-model-item label="Номер заказа">
          <a-input v-model="filter.id" />
        </a-form-model-item>
        <a-form-model-item label="Статус заказа">
          <a-select
            class="select-width"
            :allow-clear="true"
            v-model="filter.status"
            :default-value="null"
            :options="orderStatusesList"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="Тип оплаты">
          <a-select
            class="select-width"
            :allow-clear="true"
            v-model="filter.paymentType"
            :default-value="null"
            :options="paymentTypesList"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="Дата заказа:">
          <a-range-picker
            :locale="locale"
            :format="'DD MMM YYYY'"
            @change="onDateRangeChange"
            :placeholder="['С:', 'По:']"
            :value="[filter.dateFrom, filter.dateTo]"
          ></a-range-picker>
        </a-form-model-item>
        <phone-input
          v-model="filter.customerPhone"
          label="Номер телефона клиента"
          prop="customerPhone"
        />
        <a-form-model-item label="Статусы товаров">
          <a-select
            class="multiselect-width"
            mode="tags"
            :allow-clear="true"
            v-model="filter.productStatuses"
            :default-value="null"
            :options="productStatusesList"
          ></a-select>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>

    <a-table
      :columns="columnNames"
      :loading="loading"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <a :href="generateRedirectLink(id)">{{ id }}</a>
      </div>
      <div slot="status" slot-scope="status">
        <status-view
          :status-list="ORDER_STATUSES"
          :status="status"
        ></status-view>
      </div>
      <div slot="payment_type" slot-scope="payment_type">
        <template v-if="PaymentTypes[payment_type]">
          <a-icon :type="PaymentTypes[payment_type].icon"></a-icon>
          {{ PaymentTypes[payment_type].label }}
        </template>
        <template v-else>
          Неизвестный способ оплаты [{{ payment_type }}]
        </template>
      </div>
      <div slot="customer" slot-scope="customer">
        <a :href="`/#/clients/${customer.tdid}`">{{ customer.full_name }}</a>
      </div>
      <div slot="payment_status" slot-scope="payment_status">
        <status-view
          :status-list="PAYMENT_STATUSES"
          :status="payment_status"
        ></status-view>
      </div>
      <div slot="created_at" slot-scope="created_at">
        {{ created_at | formatDate }}
      </div>
      <div slot="end_at" slot-scope="end_at">
        <template v-if="end_at">
          {{ end_at | formatDate }}
        </template>
        <template v-else> - </template>
      </div>
    </a-table>
  </div>
</template>

<script>
import { columnNames } from 'Client/pages/ClientInfo/constants'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import moment from 'moment'
import { PaymentTypes } from 'Core/constants'
import {
  ORDER_STATUSES,
  PAYMENT_STATUSES,
  PRODUCT_STATUSES,
} from 'Core/types/order'
import { showErrorMsg } from 'Core/utils'

/**
 * dumb component для отображения заказов мерчанта/пользователя. Для получения нужных себе данных
 * эмиттит событие "fetchOrdersList", передав параметры пагинации/фильтрации
 */
export default {
  name: 'OrderList',
  props: {
    id: {
      type: String,
      default: null,
    },
    dataSource: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number, // на случай, если будет фильтрация
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    columnNames,
    locale,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 50,
    },
    PaymentTypes,
    PAYMENT_STATUSES,
    PRODUCT_STATUSES,
    ORDER_STATUSES,
    filter: {
      id: null,
      status: null,
      paymentType: null,
      productStatuses: [],
      dateFrom: null,
      dateTo: null,
      customerPhone: null,
      limit: 50,
    },
  }),
  watch: {
    totalCount(total) {
      this.pagination.total = total
    },
    page(page) {
      this.pagination.current = page
    },
  },
  computed: {
    orderStatusesList() {
      return Object.values(this.ORDER_STATUSES)
    },
    paymentTypesList() {
      return Object.values(this.PaymentTypes)
    },
    paymentStatusesList() {
      return Object.values(this.PAYMENT_STATUSES)
    },
    productStatusesList() {
      return Object.values(this.PRODUCT_STATUSES)
    },
  },
  methods: {
    async getOrderList() {
      this.$emit('fetchOrdersList', {
        id: this.id,
        page: this.pagination.current,
        limit: this.pagination.defaultPageSize,
        ...this.filter,
      })
    },
    onDateRangeChange(date) {
      this.filter.dateFrom = moment(date[0]).format('YYYY-MM-DDT00:00:00Z')
      this.filter.dateTo = moment(date[1]).format('YYYY-MM-DDT23:59:59Z')
    },
    onPageChange({ current }) {
      this.pagination.current = current
      this.getOrderList()
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        id: null,
        status: null,
        paymentType: null,
        dateFrom: null,
        dateTo: null,
        customerPhone: null,
        productStatuses: [],
        page: 1,
        limit: 20,
      }
      this.getOrderList()
    },
    generateRedirectLink(orderId) {
      // eslint-disable-next-line no-unused-vars
      const [_, postfix] = orderId.split('-', 2)
      if (postfix) return `/#/partner/${this.id}/order/${orderId}`
      else return `/#/order/${orderId}`
    },
    async onFilter() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        // this.filter.page = 1
        await this.getOrderList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    this.getOrderList()
  },
}
</script>

<style lang="scss" scoped>
.multiselect-width {
  min-width: 220px;
  max-width: 600px;
  width: auto;
}
.select-width {
  max-width: 220px;
  min-width: 100%;
  width: 220px !important;
}
</style>
