import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис работы с пользовательским профилем
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/merchant-profile/swagger/index.html} */
export class MerchantProfileService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'merchant-profile',
    })
  }

  @Api
  async createPartnerByTDID(payload) {
    const response = await this.POST(`/v1/merchants`, payload)
    return response
  }

  @Api
  async getPartnerList(params) {
    const response = await this.GET(`/v1/merchants`, params)
    return response
  }

  @Api
  async getPartnerById(id) {
    const response = await this.GET(`/v1/merchants/${id}/profile`)
    return response
  }

  @Api
  async updateProfile(id, params) {
    const response = await this.PATCH(`/v1/merchants/${id}`, params)
    return response
  }

  @Api
  async getMerchantEmployeesList(merchant_id) {
    const response = await this.GET(`/v1/merchants/${merchant_id}/employees`)
    return response
  }

  @Api
  async connectEmployeeToMerchant(merchant_id, payload) {
    const response = await this.POST(
      `/v1/merchants/${merchant_id}/employees`,
      payload,
    )
    return response
  }

  @Api
  async updateEmployeeToMerchant(merchant_id, employee_id, payload) {
    const response = await this.PUT(
      `/v1/merchants/${merchant_id}/employees/${employee_id}`,
      payload,
    )
    return response
  }

  @Api
  async deleteMerchantEmployee(merchant_id, employee_id) {
    const response = await this.DELETE(
      `/v1/merchants/${merchant_id}/employees/${employee_id}`,
    )
    return response
  }

  @Api
  async getEmployeeDocumentList(merchant_id) {
    const response = await this.GET(`/v1/merchants/${merchant_id}/documents`)
    return response
  }

  @Api
  async addEmployeeDocuments(merchant_id, payload) {
    const response = await this.POST(
      `/v1/merchants/${merchant_id}/documents`,
      payload,
    )
    return response
  }

  @Api
  async updateEmployeeDocuments(merchant_id, doc_id, payload) {
    const response = await this.PUT(
      `/v1/merchants/${merchant_id}/documents/${doc_id}`,
      payload,
    )
    return response
  }

  @Api
  async deleteEmployeeDocumentById(merchant_id, doc_id) {
    const response = await this.DELETE(
      `/v1/merchants/${merchant_id}/documents/${doc_id}`,
    )
    return response
  }

  @Api
  async getPickupPointList(id) {
    const response = await this.GET(`/v1/merchants/${id}/pickup-points`)
    return response
  }

  @Api
  async getPickupPointById(merchantId, pickupPointId) {
    const response = await this.GET(
      `/v1/merchants/${merchantId}/pickup-points/${pickupPointId}`,
    )
    return response
  }

  @Api
  async createPickupPoint(id, params) {
    const response = await this.POST(
      `/v1/merchants/${id}/pickup-points`,
      params,
    )
    return response
  }

  @Api
  async updatePickupPoint(id, store_id, params) {
    const response = await this.PUT(
      `/v1/merchants/${id}/pickup-points/${store_id}`,
      params,
    )
    return response
  }

  @Api
  async enablePickupPoint(merchantId, pickupPointId) {
    const response = await this.PUT(
      `/v1/merchants/${merchantId}/pickup-points/${pickupPointId}/enable`,
    )
    return response
  }

  @Api
  async disablePickupPoint(merchantId, pickupPointId) {
    const response = await this.PUT(
      `/v1/merchants/${merchantId}/pickup-points/${pickupPointId}/disable`,
    )
    return response
  }

  @Api
  async updateAllPickupPointContacts(merchantId, params) {
    const response = await this.PUT(
      `/v1/merchants/${merchantId}/pickup-points/contacts`,
      params,
    )
    return response
  }

  @Api
  async getMerchantsByTdId(tdId) {
    const response = await this.GET(`/v1/merchants/employees/${tdId}`)
    return response
  }
}
