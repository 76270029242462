<template>
  <div>
    <a-form-model
      ref="bankReqform"
      :model="formState"
      :rules="bankReqStateRules"
      @submit="saveBankReqHandler"
    >
      <a-form-model-item prop="account_bank_number" label="Номер счета в банке">
        <a-input v-model="formState.account_bank_number" />
      </a-form-model-item>
      <a-form-model-item prop="bik" label="БИК">
        <a-input v-model="formState.bik" />
      </a-form-model-item>
      <a-form-model-item prop="bin" label="БИН">
        <a-input v-model="formState.bin" />
      </a-form-model-item>
      <a-form-model-item prop="kbe" label="КБЕ">
        <a-input v-model="formState.kbe" />
      </a-form-model-item>
      <a-button type="primary" html-type="submit">
        Сохранить Банковские реквизиты
      </a-button>
    </a-form-model>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import { bankReqStateRules } from 'Partner/pages/Partner/PartnerInfo/constants'

export default {
  name: 'PartnerBankDetails',
  props: {
    bankDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      bankReqStateRules,
      formState: this.bankDetails,
    }
  },
  methods: {
    async saveBankReqHandler(event) {
      event.preventDefault()
      this.$refs.bankReqform.validate(async (result) => {
        if (result) {
          try {
            this.isFetching = true
            const bankReqObj = {
              payment_requisites: this.formState,
            }
            await this.$API.MerchantProfile.updateProfile(
              this.merchantID,
              bankReqObj,
            )
            showSuccessMsg('Удачно обновлен!')
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isFetching = false
          }
        }
      })
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped></style>
