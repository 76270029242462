<template>
  <div>
    <div v-if="this.pagination.total">
      <div>
        <a-table
          :columns="columnNames"
          :data-source="logList"
          :scroll="{ x: true }"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          :row-key="({ id }) => id"
          :loading="isLoading"
          :pagination="pagination"
          @change="onPageChange"
        >
          <div slot="createdAt" slot-scope="createdAt">
            {{ createdAt | formatDate }}
          </div>
          <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <!--            <pre>{{ decodeURI(record.messages) }}</pre>-->
            <div v-for="(change, index) in record.messages" :key="index">
              <h2>Поле: <code>change.name</code></h2>
              <a-tag color="red">change.old_value</a-tag>
              <div>
                <a-icon type="caret-down" />
              </div>
              <a-tag color="green">change.new_value</a-tag>
            </div>
          </div>
        </a-table>
      </div>
    </div>
    <h2 v-else class="log-empty">Тут пусто ):</h2>
  </div>
</template>

<script>
import { getLinkLogsAPI } from 'Content/api/fastLink'
import { columnNames } from './constants'
import { showErrorMsg } from 'Core/utils'
import { eventBus } from '@/main'

export default {
  name: 'ActionLogs',
  props: {
    entityType: {
      type: String,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    columnNames,
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      page: 1,
      limit: 10,
    },
    logList: [],
  }),
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        await this.getLinkLogs()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getLinkLogs() {
      const params = {
        entity_type: this.entityType,
        event_type: this.eventType,
        sort_dir: 'desc',
        sort_key: 'created_at',
        entity_id: this.linkId,
        ...this.filter,
      }
      const { events, total } = await getLinkLogsAPI(params)
      this.pagination.total = total
      this.logList = events
      // console.log(JSON.parse(events[0].messages[0]))
      this.isLoading = false
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getLinkLogs()
    },
  },
  mounted() {
    eventBus.$on('logs:update', () => {
      this.initialize()
    })
  },
  beforeDestroy() {
    eventBus.$off('logs:update')
  },
}
</script>

<style scoped lang="scss">
.log-empty {
  color: lightslategray;
  text-align: center;
}
</style>
