

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { formState, rules } from './constants'
import { FormModel } from 'ant-design-vue'
import { showErrorMsg, showSuccessMsg } from '@/Core/utils'

@Component
class PartnerKaspiLogs extends Vue {
  @Prop()
  merchantId: string

  rules = rules
  formState = formState
  isLoading = false

  $refs: {
    form: FormModel
  }

  clearForm() {
    this.formState.files = []
    return true
  }

  handleChange(info) {
    if (info.file?.status === 'removed') this.formState.files = []
    else this.formState.files = [info.file]
  }

  onUpload() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        showErrorMsg('Необходимо выбрать файл логов')
        return
      }

      try {
        this.isLoading = true
        const formData = new FormData()
        const file = this.formState.files[0]
        formData.append('file', file, file?.name)
        await this.$API.KaspiStealer.uploadKaspiLogs(
          this.merchantId,
          file.name,
          formData,
        )
        showSuccessMsg('Логи успешно загружены')
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    })
  }
}

export default PartnerKaspiLogs
