<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-row type="flex" justify="center">
        <a-col span="16">
          <a-row class="mb-24" type="flex" justify="space-between">
            <a-col>
              <h2>Сервисная точка</h2>
            </a-col>
            <a-col>
              <a-button
                @click="
                  () =>
                    $router.push({
                      name: 'PartnerInfo',
                      params: { id: merchantID },
                      query: {
                        tab: '1',
                      },
                    })
                "
              >
                {{ $t('common.back') }}
              </a-button>
            </a-col>
          </a-row>
          <a-form-model
            ref="form"
            :model="formState"
            :rules="editRules"
            @submit="updatePickupPointHandler"
          >
            <a-divider>Основные</a-divider>
            <a-form-model-item label="Статус">
              <a-switch v-model="formState.is_active" />
            </a-form-model-item>
            <a-form-model-item prop="store_id" label="ID">
              <a-input v-model="formState.store_id" />
            </a-form-model-item>
            <a-form-model-item prop="name" label="Название">
              <a-input v-model="formState.name" />
            </a-form-model-item>
            <a-form-model-item prop="priority" label="Приоритет">
              <a-input-number v-model="formState.priority" :min="1" />
            </a-form-model-item>
            <a-form-model-item prop="contact.info" label="Имя контакта">
              <a-input v-model="formState.contact.info" />
            </a-form-model-item>
            <phone-input
              :label="'Номер контакта'"
              :prop="'contact.phone'"
              v-model="formState.contact.phone"
            />

            <a-divider>Адрес</a-divider>
            <a-form-model-item label="Город">
              <a-select v-model="formState.address.city_id">
                <a-select-option v-for="{ id, title } in cityList" :key="id">
                  {{ title.ru }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="address.street" label="Улица">
              <a-input v-model="formState.address.street" />
            </a-form-model-item>
            <div class="grid-box">
              <a-form-model-item prop="address.house" label="Дом">
                <a-input v-model="formState.address.house" />
              </a-form-model-item>
              <a-form-model-item prop="address.apartment" label="Квартира">
                <a-input v-model="formState.address.apartment" />
              </a-form-model-item>
              <a-form-model-item prop="address.floor" label="Этаж">
                <a-input-number v-model="formState.address.floor" :min="0" />
              </a-form-model-item>
              <a-form-model-item prop="address.zipcode" label="Зипкод">
                <a-input v-model="formState.address.zipcode" />
              </a-form-model-item>
            </div>
            <div class="grid-box">
              <a-form-model-item prop="address.geo.latitude" label="Широта">
                <a-input v-model="formState.address.geo.latitude" />
              </a-form-model-item>
              <a-form-model-item prop="address.geo.longitude" label="Долгота">
                <a-input v-model="formState.address.geo.longitude" />
              </a-form-model-item>
            </div>
            <yandex-map
              :lat="formState.address.geo.latitude"
              :long="formState.address.geo.longitude"
              :zoom="
                formState.address.geo.latitude &&
                formState.address.geo.longitude
                  ? 15
                  : 12
              "
              @onCoordsChange="onCoordsChange"
            />
            <a-divider>График работы</a-divider>
            <schedule-day
              v-for="(day, index) in formState.schedule"
              :key="index"
              :day-prop="day"
              :is-multiple="false"
              class="schedule__day"
              @scheduleChanged="onScheduleChanged(day.weekday, $event)"
            >
            </schedule-day>
            <a-form-item>
              <a-button type="primary" html-type="submit" class="mt--2">
                Сохранить
              </a-button>
            </a-form-item>
          </a-form-model>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import {
  formState,
  editRules,
  weekDays,
} from 'Partner/pages/PickupPoint/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { MASK } from 'Core/constants'
import ScheduleDay from 'Core/components/Selector/WorkHoursRangeSelector.vue'
import YandexMap from 'Core/components/Maps/YandexMaps/YandexMap'

export default {
  name: 'PickupPointInfo',
  components: {
    ScheduleDay,
    YandexMap,
  },
  data: () => ({
    formState,
    weekDays,
    editRules,
    MASK,
    isFetching: true,
  }),
  methods: {
    onScheduleChanged(weekday, newSchedule) {
      this.formState.schedule = this.formState.schedule.map((day) =>
        day.weekday == weekday ? newSchedule : day,
      )
    },
    async updatePickupPointHandler(event) {
      event.preventDefault()
      try {
        this.$refs.form.validate(async (result) => {
          if (result) {
            await this.$API.MerchantProfile.updatePickupPoint(
              this.merchantID,
              this.pickupPointID,
              this.formState,
            )
            showSuccessMsg('Удачно обновлен!')
            // await this.$router.push({
            //   name: 'PickupPointInfo',
            //   params: {
            //     merchantID: this.merchantID,
            //     id:
            //       this.formState.store_id === this.pickupPointID
            //         ? this.pickupPointID
            //         : this.formState.store_id,
            //   },
            // })
            window.scrollTo(0, 0)
          }
        })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    isCheckDisabled(index) {
      this.formState.schedule[index].time_range[0] = '00:00'
      this.formState.schedule[index].time_range[1] = '00:00'
    },
    async getPickupPointByIdHandler() {
      try {
        this.isFetching = true
        this.formState = await this.$API.MerchantProfile.getPickupPointById(
          this.merchantID,
          this.pickupPointID,
        )
        if (this.formState.contact.phone.length === 10) {
          this.formState.contact.phone = '7' + this.formState.contact.phone
        }
      } catch (error) {
        showErrorMsg(error.message)
        await this.$router.push({
          name: 'PickupPointList',
          params: {
            id: this.merchantID,
          },
        })
      } finally {
        this.isFetching = false
      }
    },
    onCoordsChange({ lat, long }) {
      this.formState.address.geo.latitude = lat
      this.formState.address.geo.longitude = long
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.merchantID
    },
    pickupPointID() {
      return this.$route.params.id
    },
    cityList() {
      return this.$store.getters['core/cities']
    },
    coords() {
      const { longitude, latitude } = this.formState.address.geo
      if (!longitude || !latitude) return []

      return []
    },
  },
  created() {
    this.getPickupPointByIdHandler()
  },
}
</script>

<style scoped lang="scss">
.grid-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: baseline;
  gap: 20px;
}

.flex-box {
  display: flex;
  align-items: center;

  & label {
    margin-left: 17px;
  }
}

.schedule {
  &__day {
    border-bottom: 1px solid #d2d2ec;
    padding-top: 13px;
  }
}
</style>
